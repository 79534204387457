// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Highlight from 'react-highlighter';
import {
  Anchor,
  Box,
  Button,
  Main, Menu, Select, Text,
} from 'grommet';
import { More, Refresh } from 'grommet-icons';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import UploadJSONFlow from '../shared/component/UploadJSONFlow';
import CompareJSONFlow from '../shared/component/CompareJSONFlow';
import { UserType } from '../shared/constants/UserType';
import { getEnvironmentName, isProd } from '../shared/environment/EnvironmentUtil';
import useRememberedState from '../shared/hooks/useRememberedState';
import IDUtil from '../shared/util/IDUtil';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMSearch from '../shared/component/GLBMSearch';
import { useUser } from '../stores/UserStore';
import {
  updateFiltersAndSession,
} from './redux/ServiceMeterActions';
import ServiceMeterEditor from './ServiceMeterEditor';
import { ServiceCategory } from '../services';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';

import ServiceTypeStore from '../stores/ServiceTypeStore';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import {
  useServiceMeterDeleteMutate,
  useServiceMeterImportMutate,
  useServiceMeterValidateMutate,
  useServiceMetersQuery,
} from '../../core';

/**
 * sys admin can edit in all environments:
 * service developer can edit in dev + qa but not prod:
 * all others can do nothin'.
 * @param user
 * @returns {boolean|*}
 */
const getCanEditMeter = (user) => {
  const role = user?.role;
  if (role) {
    switch (role) {
      case UserType.SUPER.value:
        return true;
      case UserType.SERVICE_DEV.value:
        return (!isProd());
      default:
        return false;
    }
  }
  return false;
};

const defaultSort = {
  direction: 'asc',
  external: true,
  property: 'role',
};

const ServiceMeterListPage = (props) => {
  // eslint-disable-next-line react/prop-types,react/destructuring-assignment
  const [filters, setFilters] = useState({ ...props.serviceMeters.filters });
  const [layer, setLayer] = useState(undefined);
  const [selectedMeter, setSelectedMeter] = useState();
  const [validatedFile, setValidatedFile] = useState();
  const user = useUser();
  const canEdit = useMemo(() => user && getCanEditMeter(user), [user]);
  const [sort, onSort] = useRememberedState('service-meters-data-sort', defaultSort);

  const serviceTypes = useMemo(() => ServiceTypeStore.getServices()
    .filter(e => e.standardUsageFileFormat === true)
    .map(({
      name, label, type,
    }) => ({
      label,
      displayName: label || '',
      value: name || type,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())), [ServiceTypeStore.getServices()]);

  const setSelectedMeterForDeleting = (meter) => {
    setSelectedMeter(meter);
    setLayer(meter ? 'deleteMeterLayer' : undefined);
  };

  const {
    data: serviceMeterList,
    refetch: refreshServiceMeters,
    isFetching: loading,
  } = useServiceMetersQuery(filters.list.serviceType);

  const { mutate: importServiceMeters } = useServiceMeterImportMutate(filters.list.serviceType, {
    onSuccess: () => {
      setValidatedFile(undefined);
      setLayer(undefined);
    },
  });

  const importFile = (file) => {
    importServiceMeters(file.object);
  };

  const { mutate: deleteServiceMeter } = useServiceMeterDeleteMutate({
    onSuccess: () => setSelectedMeterForDeleting(undefined),
  });

  const {
    mutate: validateServicesMetersFile, reset: resetValidation, isLoading: validationLoading, error: validationError,
  } = useServiceMeterValidateMutate(filters.list.serviceType, {
    cacheTime: 0,
    onSuccess: (newFile) => {
      if (newFile?.existing) {
        setValidatedFile(newFile);
        setLayer('compare');
      } else {
        importFile(newFile);
      }
    },
    onError: () => {
      setValidatedFile(undefined);
    },
  });

  useEffect(() => {
    if (!filters.list.serviceType && serviceTypes?.length) {
      filters.list.serviceType = serviceTypes[0].value;
      setFilters(prevState => ({
        ...prevState,
        ...filters,
      }));
    }
  }, [serviceTypes]);

  const onSearchChange = (event) => {
    const search = event;
    const newList = { ...filters.list };
    newList.searchText = search;
    const newFilters = { ...filters, list: newList };
    setFilters(newFilters);
    // eslint-disable-next-line react/prop-types,react/destructuring-assignment
    props.updateFiltersAndSession(newFilters);
  };

  const filterMeters = ({ list: { searchText } }) => {
    const filterByText = (!!searchText);

    if (!searchText) {
      return serviceMeterList;
    }

    const searchTextInternal = searchText.toLowerCase();

    const results = [];
    if (serviceMeterList?.length) {
      for (let i = 0; i < serviceMeterList.length; i += 1) {
        const meter = serviceMeterList[i];

        let matchOnText;

        // see if the customer is a match by text:
        if (filterByText) {
          if ((Object.prototype.hasOwnProperty.call(meter, 'id') && meter.id !== undefined ? meter.id.toLowerCase()
            .indexOf(searchTextInternal) !== -1 : false)
            || (Object.prototype.hasOwnProperty.call(meter, 'name') && meter.name !== undefined ? meter.name.toLowerCase()
              .indexOf(searchTextInternal) !== -1 : false)
            || (Object.prototype.hasOwnProperty.call(meter, 'serviceCategory') && meter.serviceCategory !== undefined ? meter.serviceCategory.toLowerCase()
              .indexOf(searchTextInternal) !== -1 : false)
            || (Object.prototype.hasOwnProperty.call(meter, 'type') && meter.type !== undefined ? meter.type.toLowerCase()
              .indexOf(searchTextInternal) !== -1 : false)
            || (Object.prototype.hasOwnProperty.call(meter, 'unitOfMeasure') && meter.unitOfMeasure !== undefined ? meter.unitOfMeasure.toLowerCase()
              .indexOf(searchTextInternal) !== -1 : false)) {
            matchOnText = true;
          }
        }

        // if both search text + filter is specified, then they both needs to be found for a customer to match:
        if (filterByText) {
          if (matchOnText) {
            results.push(meter);
          }
        }
      }
    }
    return results;
  };

  const onViewDetails = (selectedServiceMeter, refreshList) => {
    setLayer(undefined);
    setTimeout(() => {
      setSelectedMeter(selectedServiceMeter);
      if (refreshList) {
        refreshServiceMeters();
      }
    }, 0);
  };

  const renderConfirmationDetails = meter => (
    <Box margin={{ top: 'small' }}>
      <GLBMNameValueList
        title='Selected Service Meter'
        data={[
          { label: 'Name', value: meter.name },
          { label: 'Id', value: meter.id },
        ]}
      />
    </Box>
  );

  const sortMeters = (service) => {
    if (service && service.length > 0) {
      return service.sort((a, b) => a.id.localeCompare(b.id));
    }
    return service;
  };

  const validateFile = (file) => {
    validateServicesMetersFile(file);
  };

  const onDeleteConfirmed = (id) => {
    deleteServiceMeter(id);
  };

  const renderLayer = () => {
    if (layer === 'newMeterLayer') {
      return (
        <ServiceMeterEditor
          serviceType={filters.list.serviceType}
          onSubmit={onViewDetails}
          onClose={onViewDetails}
          canEdit={true}
        />
      );
    }
    if (layer === 'viewMeterLayer') {
      return (
        <ServiceMeterEditor
          onSubmit={onViewDetails}
          meter={{ ...selectedMeter }}
          onClose={onViewDetails}
          canEdit={canEdit}
        />
      );
    }
    if (layer === 'deleteMeterLayer' && selectedMeter) {
      return (
        <ConfirmationDialog
          data={{ meter: selectedMeter }}
          title='Are You Sure?'
          submitLabel='Yes, delete the Service Meter'
          cancelLabel='Cancel'
          text="Deleting a service meter will permanently remove this meter. Please ensure you are intending to completely remove this meter before choosing 'Yes' below."
          onClose={() => setSelectedMeterForDeleting(undefined)}
          onChange={() => onDeleteConfirmed(selectedMeter.id)}
          details={renderConfirmationDetails(selectedMeter)}
        />
      );
    }
    if (layer === 'upload') {
      return (
        <UploadJSONFlow
          title='Upload Service Meters'
          message='Select the json file that contains Service Meters. Only one file can be uploaded at once.'
          onVerify={validateFile}
          isLoading={validationLoading}
          error={validationError?.response?.data}
          clear={() => {
            resetValidation();
            setValidatedFile(undefined);
          }}
          onClose={() => {
            resetValidation();
            setValidatedFile(undefined);
            setLayer(undefined);
          }}
        />
      );
    }
    if (layer === 'compare') {
      const newService = cloneDeep(validatedFile ? validatedFile.object : {});
      const sortedNewService = sortMeters(newService);
      const oldService = cloneDeep(serviceMeterList);
      const sortedOldService = sortMeters(oldService);
      return (
        <CompareJSONFlow
          title={`Confirm changes in Service Meters for "${filters.list.serviceType && ServiceTypeStore.getService(filters.list.serviceType).label}"`}
          subTitle='Meters are sorted by Id to align changes'
          onClose={() => {
            setLayer(undefined);
            setValidatedFile(undefined);
          }}
          onSave={() => importFile(validatedFile)}
          oldFile={sortedOldService}
          newFile={sortedNewService}
        />
      );
    }
    return '';
  };

  const onSelectServiceType = (event) => {
    filters.list.serviceType = event.value;
    setFilters(prevState => ({
      ...prevState,
      ...filters,
    }));
    // eslint-disable-next-line react/destructuring-assignment
    props.updateFiltersAndSession(filters);
  };

  const viewMeter = (meter) => {
    setSelectedMeter(meter);
    setLayer('viewMeterLayer');
  };

  const exportServicMeters = () => {
    const blob = new Blob(
      [JSON.stringify(sortMeters(cloneDeep(serviceMeterList)), null, 2)],
      { type: 'application/json' },
    );
    return saveAs(blob, decodeURI(`service_meters_${getEnvironmentName()}_${filters.list.serviceType}.json`));
  };

  const getColumns = searchText => [
    {
      property: 'name',
      header: 'Name',
      primary: true,
      render: datum => (
        <Anchor onClick={() => viewMeter(datum)}>
          <Text weight='bold'>
            <Highlight search={searchText}>{datum.name}</Highlight>
          </Text>
        </Anchor>
      ),
    },
    {
      property: 'id',
      header: 'ID',
      size: 'medium',
    },
    {
      property: 'serviceCategory',
      header: 'Service Category',
      render: datum => (datum.serviceCategory ? <Highlight search={searchText}>{ServiceCategory.enumValueOf(datum.serviceCategory).label()}</Highlight> : '-'),
    },
    {
      property: 'type',
      header: 'Type',
    },
    {
      property: 'unitName',
      header: 'Unit',
      render: datum => (datum.unitOfMeasure ? <Highlight search={searchText}>{(datum.unitOfMeasure === 'Each' ? datum.unitName : datum.unitOfMeasure)}</Highlight> : '-'),
      dataCallback: datum => (datum.unitOfMeasure === 'Each' ? datum.unitName : datum.unitOfMeasure),
    },
    {
      property: 'actions',
      header: 'Actions',
      size: '96px',
      align: 'start',
      render: datum => (
        <Menu
          icon={<More />}
          items={[
            {
              onClick: () => viewMeter(datum),
              label: canEdit ? 'Edit' : 'View',
            },
            ...(canEdit ? [{
              onClick: () => setSelectedMeterForDeleting(datum),
              label: 'Delete',
            }] : []),
          ]}
        />
      ),
      sortable: false,
    },
  ];

  const { searchText, serviceType } = filters.list;
  const filteredMeters = filterMeters(filters);

  const values = (serviceType ? ({
    label: ServiceTypeStore.getService(serviceType) && ServiceTypeStore.getService(serviceType).label,
    value: serviceType,
  }) : undefined);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Service Meters'
        search={[
          <Box width='250px' flex={false} key='serviceTypes'>
            <Select
              options={serviceTypes}
              onChange={event => onSelectServiceType(event)}
              labelKey='label'
              valueKey={{ key: 'value', reduce: true }}
              sortFn={val => val}
              disabled={!values}
              value={values?.value}
            />
          </Box>,
          <GLBMSearch
            key='searchText'
            value={filters.list.searchText}
            onChange={onSearchChange}
            disabled={!serviceType}
          />,
        ]}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={() => {
              refreshServiceMeters();
            }}
            a11yTitle='Refresh Audit List'
            disabled={!serviceType}
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshBtn'
            label='Refresh'
            busy={loading}
          />,
          <Menu
            kind='toolbar'
            id='actionDropDown'
            items={[
              ...(canEdit ? [{
                onClick: () => setLayer('newMeterLayer'),
                a11yTitle: 'Add New Meter',
                disabled: !serviceType,
                id: IDUtil.getId('ListViewToolbarAddButton'),
                key: 'addBtn',
                label: 'Create',
              }] : []),
              {
                label: 'Upload',
                onClick: () => setLayer('upload'),
                a11yTitle: 'Import Service Meters',
                id: (IDUtil.getId('ListViewToolbarUploadButton')),
                key: 'importBtn',
              },
              {
                onClick: () => exportServicMeters(),
                a11yTitle: 'Upload Service Meters',
                disabled: !serviceMeterList?.length,
                id: IDUtil.getId('ListViewToolbarDownloadButton'),
                key: 'downloadBtn',
                label: 'Download',
              },

            ]}
            label='Actions'
            key='actions'
          />,
        ]}
      />
      <GLBMDataSummary total={serviceMeterList?.length || 0} filtered={filteredMeters?.length} />
      <GLBMDataTable
        searchText={searchText}
        data={filteredMeters || []}
        columns={getColumns(searchText)}
        loading={loading}
        total={serviceMeterList?.length || 0}
        onSort={onSort}
        sort={sort}
      />
      {renderLayer()}
    </Main>
  );
};

ServiceMeterListPage.propTypes = {
  serviceMeters: PropTypes.object.isRequired,
  updateFiltersAndSession: PropTypes.func.isRequired,
};

ServiceMeterListPage.contextTypes = {
  router: PropTypes.object || PropTypes.array,
};

const mapStateToProps = store => ({
  serviceMeters: store.serviceMeters.list,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateFiltersAndSession,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceMeterListPage);
