// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Footer,
  Paragraph, RadioButton, Spinner, Text,
} from 'grommet';
import { Checkmark, Save } from 'grommet-icons';
import { useMutationCustomerAccountDisconnect } from '../../core';
import IDUtil from '../shared/util/IDUtil';
import { StatusIcon } from '../shared/component/StatusIcon';
import GLBMLayer from '../shared/component/GLBMLayer';

const TenantDisconnectDialog = ({
  customer,
  tenant,
  onClose,
  title = 'Are you sure?',
}) => {
  const [disconnectType, setDisconnectType] = useState('DISCONNECT_DELETE');
  const [isDisconnected, setDisconnected] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const { mutate } = useMutationCustomerAccountDisconnect();

  const onSubmit = useCallback(() => {
    const deleteDatasources = (disconnectType === 'DISCONNECT_DELETE');

    setIsDisconnecting(true);

    mutate({
      customerId: customer.id,
      deleteDatasources,
      sendEmail: true,
    }, {
      onSuccess: () => {
        setIsDisconnecting(false);
        setDisconnected(true);
      }
    });
  }, [customer.id, disconnectType, mutate]);

  const renderSubmitButton = () => {
    if (!isDisconnecting && !isDisconnected) {
      return (
        <Box direction='row'>
          <Button
            style={{ 'minWidth': '280px' }}
            label={
              disconnectType === 'DISCONNECT_DELETE'
                ? 'Disconnect and Delete'
                : 'Disconnect and Keep'
}
            id={IDUtil.getId('ServicePromoteDialogOKButton')}
            primary={true}
            icon={<Save />}
            onClick={onSubmit}
          />
        </Box>
      );
    }
    if (isDisconnecting && !isDisconnected) {
      return (
        <Button
          label='Disconnecting ...'
          id={IDUtil.getId('ServicePromoteDialogOKButton')}
          plain={true}
          icon={<Spinner className='spinning-small' />}
        />
      );
    }
    return (
      <Button
        label='Disconnected'
        id={IDUtil.getId('ServicePromoteDialogOKButton')}
        primary={true}
        icon={<Checkmark />}
        onClick={onClose}
      />
    );
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      onClickOutside={onClose}
      onEsc={onClose}
      onClose={onClose}
      title={title}
    >
      <Box flex={true} pad='small'>
        <Box direction='column'>
          <Box direction='row' align='center' margin={{ bottom: 'large' }}>
            <StatusIcon value='warning' size='medium' style={{ 'marginTop': '12px', 'marginRight': '12px' }} />
            <Box>
              <Paragraph margin='small' className='tenant-disconnection-paragraph'>
                This action will sever the link between the HPE billing account,
                {' '}
                <strong>{customer ? customer.name : ''}</strong>
                , and the CA tenant ID
                {' '}
                <strong>{tenant ? `${tenant.caTenantId}` : ''}</strong>
                .
              </Paragraph>
            </Box>
          </Box>
          <Text
            size='xlarge'
          >
            Disconnect Options:
          </Text>
          <Box direction='column'>
            <RadioButton
              id='disconnect-delete'
              name='DISCONNECT_DELETE'
              label='Disconnect and Delete'
              checked={disconnectType === 'DISCONNECT_DELETE'}
              onChange={({ target: { name } }) => setDisconnectType(name)}
            />
            <Box style={{ 'marginLeft': '36px', 'marginBottom': '30px' }}>
              <Paragraph margin='small' className='tenant-disconnection-paragraph'>
                If you choose Disconnect and Delete, the data source, which is associated to this billing account,
                will be deleted from the CA tenant and users of this CA tenant will not have access to the billing account data.
              </Paragraph>
            </Box>
            <RadioButton
              id='disconnect-keep'
              name='DISCONNECT_KEEP'
              label='Disconnect and Keep'
              checked={disconnectType === 'DISCONNECT_KEEP'}
              onChange={({ target: { name } }) => setDisconnectType(name)}
            />
            <Box style={{ 'marginLeft': '36px' }}>
              <Paragraph margin='small' className='tenant-disconnection-paragraph'>
                If you choose Disconnect and Keep, the data source, which is associated to this billing account,
                will be left intact in the CA tenant and users of this CA tenant will continue to have access to
                the historical data for this billing account.
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          {renderSubmitButton()}
          {!isDisconnected ? (
            <Button
              label='Cancel'
              secondary={true}
              onClick={onClose}
              id={IDUtil.getId('EditorLayerToolbarRemoveButton')}
            />
          ) : ''}
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

TenantDisconnectDialog.propTypes = {
  customer: PropTypes.object.isRequired,
  tenant: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default TenantDisconnectDialog;
