// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import UserStore from '../stores/UserStore';
import { wrappedFetch } from '../shared/api/utils';

export default {
  loginOkta: token => wrappedFetch(
    `${window.APIURL}/fc/users/okta/login`,
    'GET',
    null,
    null,
    { 'Authorization': `Bearer ${token}` }
  ),
  logout: (skipCall) => {
    UserStore.configureSentry(undefined);
    UserStore.setUser({});
    const endSession = () => {
      if (typeof window.Storage !== 'undefined') {
        window.sessionStorage.removeItem('flex_capacity_admin_portal');
        window.sessionStorage.removeItem('lastTokenRefresh');
        window.sessionStorage.removeItem('user');
        window.sessionStorage.removeItem('token');
        window.sessionStorage.removeItem('okta-pkce-storage');
        window.sessionStorage.removeItem('okta-cache-storage');
        window.sessionStorage.removeItem('okta-token-storage');
        window.sessionStorage.removeItem('previews');
      }
    };
    if (!skipCall) {
      return fetch(`${window.APIURL}/fc/users/logout`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }).then(endSession);
    }
    endSession();
  },
  refresh: path => wrappedFetch(path, 'POST'),
  current: path => wrappedFetch(path, 'GET'),
  access: path => wrappedFetch(path, 'GET'),
  version: url => fetch(`${url || window.location.origin}/version.txt`)
    .then(x => x.text())
    .then(x => x.trim()),
};
