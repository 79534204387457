// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo } from 'react';
import {
  Box, Button, Footer, Paragraph
} from 'grommet';
import PropTypes from 'prop-types';
import GLBMLayer from '../../shared/component/GLBMLayer';
import {
  useQuoteDeleteMutate,
  useQuoteQuery,
} from '../../../core';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import GLBMSaving from '../../shared/component/GLBMSaving';
import { StatusIcon } from '../../shared/component/StatusIcon';
import Loader from '../../shared/loader';
import IDUtil from '../../shared/util/IDUtil';

const WiseQuoteIdDeletePage = ({
  quoteId,
  onClose,
}) => {
  const {
    data: quoteData,
    isSuccess: isQuoteCallSuccess,
    isFetching: isFetchingQuoteData,
  } = useQuoteQuery(quoteId);

  const isLoading = useMemo(() => isFetchingQuoteData, [isFetchingQuoteData]);

  const {
    mutate: deleteWiseQuoteId,
    isPending: isDeletingWiseQuoteId,
    error: deletingWiseQuoteIdError,
    isFetching: isFetchingDeletingWiseQuoteId,
    isSuccess: isDeletingWiseQuoteIdSuccess,
  } = useQuoteDeleteMutate();

  useEffect(() => {
    if (isDeletingWiseQuoteIdSuccess) {
      onClose();
    }
  }, [isDeletingWiseQuoteIdSuccess, onClose]);

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      isLoading={isFetchingDeletingWiseQuoteId}
      title={isLoading ? '' : 'Are you sure?'}
    >
      <Box
        pad='none'
        style={{
          'width': '600px',
          'minWidth': '600px',
        }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        {(isLoading) && (
          <Box
            direction='row'
            align='center'
            gap='small'
            justify='center'
            fill={true}
          >
            <Loader text='Loading. Please wait ...' />
          </Box>
        )}
        {(isQuoteCallSuccess) && (
          <Box pad='medium' flex={true} gap='medium'>
            <Box direction='row' align='top'>
              <StatusIcon value='warning' size='medium' />
              <Box margin={{ left: 'small' }}>
                <Paragraph margin={{ top: 'none' }}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Deleting a WISE ID  will permanently remove this it. Please ensure you are intending to completely remove this WISE ID before choosing 'Delete' below.
                </Paragraph>
              </Box>
            </Box>
            <Box>
              <GLBMNameValueList
                title='Selected WISE ID'
                data={[
                  {
                    label: 'Name',
                    value: quoteData?.quoteId,
                  },
                ]}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button
            primary={true}
            onClick={() => deleteWiseQuoteId(quoteId)}
            label='Delete'
            disabled={isDeletingWiseQuoteId}
          />
          <Button
            secondary={true}
            onClick={onClose}
            label='Cancel'
            {...{ 'data-testid': IDUtil.getId('CompanyDeleteConfirmationDialogCancelBtn') }}
            disabled={isDeletingWiseQuoteId}
          />
          <GLBMSaving
            saving={isDeletingWiseQuoteId}
            saved={isDeletingWiseQuoteIdSuccess}
            error={deletingWiseQuoteIdError}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

WiseQuoteIdDeletePage.propTypes = {
  quoteId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WiseQuoteIdDeletePage;
