// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import axios, { AxiosHeaders, RawAxiosResponseHeaders } from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  axiosConfig,
  axiosConfigCSV,
  axiosConfigCSVPost,
  axiosConfigUpload,
} from '../utils';

export * from './actions';

export const executeGet = async <T = any>(path: string, config: Partial<AxiosRequestConfig> = {}) => {
  const { data } = await axios.get<T>(path, { ...axiosConfig, ...config });
  return data;
};

export const executePut = async <T = any, R = any>(path: string, payload: T = undefined) => {
  const { data } = await axios.put<T, AxiosResponse<R>>(path, payload, axiosConfig);
  return data;
};

export const executePost = async <T = any, R = any, D = any>(path: string, payload: D = undefined) => {
  const { data } = await axios.post<T, AxiosResponse<R>, D>(path, payload, axiosConfig);
  return data;
};

export const executeUpload = async (path: string, payload: FormData) => {
  const { data } = await axios.post(path, payload, axiosConfigUpload);
  return data;
};

export const executeExportCSV = async <T = any>(path: string): Promise<{
  headers: RawAxiosResponseHeaders | (RawAxiosResponseHeaders & AxiosHeaders)
  data: T
}> => {
  const { data, headers } = await axios.get<T>(path, axiosConfigCSV);
  return { data, headers };
};

export const executeExportCSVPost = async <T = any, R = any, D = any>(path: string, payload: D) => {
  const { data } = await axios.post<T, AxiosResponse<R>, D>(path, payload, axiosConfigCSVPost);
  return data;
};

export const executeDelete = async (path: string, payload = undefined) => {
  const { data } = await axios.delete(path, {
    ...axiosConfig,
    data: payload,
  });
  return data;
};
