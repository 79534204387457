// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Menu,
} from 'grommet';
import moment from 'moment';
import { More } from 'grommet-icons';
import IDUtil from '../../shared/util/IDUtil';
import { HighlightUsingContext } from '../../shared/component/HighlightUsingContext';
import { useToast } from '../../shared/toast/ToastProvider';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import ConfirmationDialog from '../../shared/dialogs/ConfirmationDialog';
import { useMutationDeleteMock } from '../../../core';
import RunMockDataLayer from './RunDemoDataLayer';

const getActiveText = active => (active ? 'Yes' : 'No');

export const filterDataByText = (data, searchText) => data?.filter(element => element?.name?.includes(searchText)
|| element?.created?.name?.includes(searchText)
|| element?.accountId?.includes(searchText)
|| (element?.lastExecuted && moment.utc(element.lastExecuted).local().format('MMM DD, YYYY hh:mm a').includes(searchText))
|| getActiveText(element?.active).includes(searchText)).map((el, index) => ({ ...el, index })) || [];

const deleteConfirmationDetailsRender = props => (
  <Box margin={{ top: 'small' }}>
    <GLBMNameValueList
      title='Selected mock data configuration'
      data={[
        { label: 'Name', value: props.name },
        { label: 'Creator', value: props.created.name },
        { label: 'Last Ran (UTC)', value: moment.utc(props.lastExecuted).format('MMM DD, YYYY hh:mm a') },
        { label: 'Active', value: getActiveText(props.active) },
      ]}
    />
  </Box>
);

export const useColumns = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const [dataToDelete, setDataToDelete] = useState();
  const [dataToRun, setDataToRun] = useState();

  const { mutate } = useMutationDeleteMock({
    onSuccess: () => {
      addToast({
        status: 'normal',
        title: 'Deleted',
        message: 'Mock Data successfully deleted',
      });
      setDataToDelete();
    },
    onError: (data) => {
      addToast({
        status: 'critical',
        title: 'Mock Data was not deleted',
        message: data.response.data.message,
      });
    },
  });

  const columns = [
    {
      property: 'name',
      header: 'Name',
      sortable: true,
      render: datum => <div id={IDUtil.getId('name', datum.index)}><HighlightUsingContext>{datum.name}</HighlightUsingContext></div>,
    },
    {
      property: 'accountId',
      header: 'Billing ID',
      sortable: true,
      render: datum => <div id={IDUtil.getId('accountId', datum.index)}><HighlightUsingContext>{datum.accountId}</HighlightUsingContext></div>,
    },
    {
      property: 'created.name',
      header: 'Creator',
      render: datum => <div id={IDUtil.getId('creator', datum.index)}><HighlightUsingContext>{datum.created.name}</HighlightUsingContext></div>,
      dataCallback: datum => datum.created.name,
    },
    {
      property: 'lastExecuted',
      header: 'Last Ran (UTC)',
      render: datum => <div id={IDUtil.getId('lastRan', datum.index)}><HighlightUsingContext>{datum.lastExecuted ? moment.utc(datum.lastExecuted).format('MMM DD, YYYY hh:mm a') : '-'}</HighlightUsingContext></div>,
    },
    {
      property: 'active',
      header: 'Active',
      render: datum => <div id={IDUtil.getId('active', datum.index)}><HighlightUsingContext>{getActiveText(datum.active)}</HighlightUsingContext></div>,
    },
    {
      property: 'actions',
      header: 'Actions',
      size: '96px',
      align: 'start',
      render: datum => (
        <Menu
          {...{ 'data-testid': IDUtil.getId('Actions', datum.index) }}
          id={IDUtil.getId('Actions', datum.index)}
          data-testid={`Actions-${datum.index}`}
          icon={<More />}
          items={[
            {
              id: IDUtil.getId('ListItemDropDownDeleteRunMockButton', datum.index),
              label: 'Execute',
              onClick: () => setDataToRun(datum),
            }, {
              id: IDUtil.getId('ListItemDropDownEditMockButton', datum.index),
              label: 'Edit',
              onClick: () => navigate(`/administration/mock/${datum.id}`),
            }, {
              id: IDUtil.getId('ListItemDropDownCopyMockButton', datum.index),
              label: 'Copy',
              onClick: () => navigate('/administration/mock/new/copy', { state: { id: datum.id } }),
            },
            {
              id: IDUtil.getId('ListItemDropDownDeleteMockButton', datum.index),
              label: 'Delete',
              onClick: () => setDataToDelete(datum),
            },
          ]}
        />
      ),
      sortable: false,
    },
  ];

  let layers;
  if (dataToDelete && dataToDelete.active === true) {
    layers = (
      <ConfirmationDialog
        data={dataToDelete}
        title='Are you sure?'
        submitLabel='Yes, delete the Mock Data'
        cancelLabel='Cancel'
        text='Deleting will permanently erase mock data configuration settings AND stop future mock data from being generated*. Please ensure this is what you are intending before choosing "Yes" below.'
        text2='If you only want to stop future mock data from being generated, uncheck the Active status via Edit action for this configuration.'
        onClose={() => setDataToDelete()}
        onChange={() => mutate(dataToDelete?.id)}
        details={deleteConfirmationDetailsRender(dataToDelete)}
      />
    );
  }
  if (dataToDelete && dataToDelete.active === false) {
    layers = (
      <ConfirmationDialog
        data={dataToDelete}
        title='Are you sure?'
        submitLabel='Yes, delete the Mock Data'
        cancelLabel='Cancel'
        text="Deleting will permanently erase mock data configuration settings. Please ensure this is what you are intending before choosing 'Yes' below."
        onClose={() => setDataToDelete()}
        onChange={() => mutate(dataToDelete?.id)}
        details={deleteConfirmationDetailsRender(dataToDelete)}
      />
    );
  }
  if (dataToRun) {
    layers = (
      <RunMockDataLayer
        data={dataToRun}
        onClose={() => setDataToRun()}
      />
    );
  }
  return { columns, layers };
};
