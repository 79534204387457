// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

export const axiosConfig = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

export const axiosConfigCSV = {
  headers: {
    'Accept': 'text/csv',
    'Content-Type': 'text/csv',
  },
  withCredentials: true,
};

export const axiosConfigCSVPost = {
  headers: {
    'Accept': 'text/csv',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  responseType: 'blob' as const,
};

export const axiosConfigUpload = {
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  withCredentials: true,
};
