// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import pluralize from 'pluralize';
import { ServiceStep } from 'services/model/ServiceStep';
import {
  Add, Checkmark, Copy, Drag, Edit, Trash, View,
} from 'grommet-icons';
import {
  Box, Button, Header, Notification, Text, Tip,
} from 'grommet';
import IDUtil from '../../../../../../shared/util/IDUtil';
import {
  useServiceFieldQuery,
  useValidationMutation,
} from '../../../../../../../core';
import { BilledByType } from '../../../../../../shared/constants/BilledByType';

import CustomTiersEditor from './CustomTiersEditor';
import CustomTiersDetails from './CustomTiersDetails';
import { StatusIcon } from '../../../../../../shared/component/StatusIcon';
import { ListPlaceholder } from '../../../../../../shared/component/ListPlaceholder';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  borderTop: isDragging ? '2px solid #767676' : '1px solid #ccc',
  borderRight: isDragging ? '2px solid #767676' : '',
  borderBottom: isDragging ? '2px solid #767676' : '',
  borderLeft: isDragging ? '2px solid #767676' : '',
  background: isDragging ? '#f5f5f5' : 'white',
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  border: isDraggingOver ? '4px solid #01a982' : '4px solid transparent',
  background: isDraggingOver ? '#ccc' : 'transparent',
});

const CustomTiersList = (props) => {
  const [customTiers, setCustomTiers] = useState(props.tiers ? props.tiers : []);
  const [response, setResponse] = useState(undefined);
  const [selectedCustomTier, setSelectedCustomTier] = useState(undefined);
  const [layer, setLayer] = useState(undefined);
  const [isModified, setIsModified] = useState(false);
  const [copyCustomTier, setCopyCustomTier] = useState(false);

  const isBrim = useMemo(() => props.customer.billedBy === BilledByType.BRIM.enumKey, []);

  const {
    data: fields,
  } = useServiceFieldQuery(props.options.customerId, props.options.serviceType);

  // hook with redux
  const { mutate: fetchValidationIfNeeded } = useValidationMutation(ServiceStep.OPTIONS);

  const _onCustomTiersAdd = () => {
    setSelectedCustomTier(undefined);
    setLayer('customTiers');
  };

  const _onCustomTiersEdit = (tier, isCopy) => {
    setSelectedCustomTier(tier);
    setLayer('customTiers');
    setCopyCustomTier(isCopy);
  };

  const _onCustomTiersSave = (model, index, modified) => {
    if (index >= 0) {
      customTiers[index] = model;
    } else {
      customTiers.push(model);
    }

    setIsModified(modified);
    setSelectedCustomTier(undefined);
    setLayer(undefined);
    setCustomTiers(customTiers);

    setTimeout(() => {
      props.onChange(customTiers, isModified);
      fetchValidationIfNeeded();
    });
  };

  const _onDeleteCustomTier = (index) => {
    customTiers.splice(index, 1);
    setCustomTiers(customTiers);
    setTimeout(() => {
      props.onChange(customTiers, isModified);
      fetchValidationIfNeeded();
    });
  };

  const _onCustomTiersNoMatchClick = () => {
    setLayer('unconfiguredEquipment');
  };

  const _getCustomTierModel = () => ((selectedCustomTier >= 0) ? customTiers[selectedCustomTier] : {
    name: 'New Custom Tier',
    criteria: [{
      matchAll: true,
      criteria: [{
        field: fields[0].id, operator: 'equals', value: '0', caseInsensitive: false,
      }],
    }],
  });

  const _renderCustomTiersEditor = () => {
    let result = '';
    if (layer && layer === 'customTiers') {
      const model = _getCustomTierModel();
      result = (
        <CustomTiersEditor
          onClose={() => {
            setLayer(undefined);
            setSelectedCustomTier(undefined);
          }}
          customerId={props.customer.id}
          onSubmit={_onCustomTiersSave}
          model={model}
          tierIndex={selectedCustomTier}
          allTiers={customTiers}
          service={props.options.serviceType}
          fields={fields}
          isCopy={copyCustomTier}
          isBrim={isBrim}
        />
      );
    }
    return result;
  };

  const _renderUnconfiguredEquipment = () => {
    let result = '';
    if (layer && layer === 'unconfiguredEquipment') {
      result = (
        <CustomTiersDetails
          onClose={() => {
            setLayer(undefined);
            setSelectedCustomTier(undefined);
          }}
          options={props.options}
          originalOptions={props.originalOptions}
          equipment={props.dirtyEquipment}
          components={props.dirtyComponents}
          customerId={props.customer.id}
        />
      );
    }
    return result;
  };

  const _onDragEnd = (results) => {
    if (!results.destination || results.destination.index === results.source.index) {
      return;
    }

    const index = customTiers.map(x => x.id).indexOf(results.draggableId);
    if (index >= 0) {
      const removed = customTiers.splice(index, 1)[0];
      customTiers.splice(results.destination.index, 0, removed);
    }

    setCustomTiers(customTiers);
    setTimeout(() => {
      props.onChange(customTiers, isModified);
      fetchValidationIfNeeded();
    });
  };

  const _getTier = (index, tier, countPerTier, isBrim) => {
    const readOnlyTier = (
      <Box data-e2e='tier-row' flex={true} direction='row' justify='between' align='center' gap='small' style={{ height: 48, borderTop: '1px solid #ccc' }}>
        <span data-e2e='tier-name'>{tier.name}</span>
        <span>
          {countPerTier.toLocaleString()}
          &nbsp;
          {pluralize(props.type, countPerTier)}
        </span>
      </Box>
    );
    const editableTier = (
      <div key={index} data-e2e='tier-row'>
        <Draggable draggableId={`${tier.id}`} index={index} type='CUSTOM_TIER'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            >
              <Box direction='row' align='center' gap='small'>
                <Box flex={true} direction='row' align='center' gap='small'>
                  <div {...provided.dragHandleProps} style={{ marginTop: '8px', marginLeft: '2px' }}>
                    <Drag />
                  </div>
                  <span data-e2e='tier-name'>{tier.name}</span>
                </Box>
                <Box flex={true} className='secondary' align='end'>
                  {countPerTier.toLocaleString()}
                  &nbsp;
                  {pluralize(props.type, countPerTier)}
                </Box>
                <Box direction='row' flex={true} align='end' justify='end'>
                  <Button
                    icon={<Edit />}
                    onClick={() => _onCustomTiersEdit(index, false)}
                    a11yTitle={`Edit ${tier.name} Tier`}
                  />
                  {!isBrim
                    && (
                      <Button
                        icon={<Copy />}
                        onClick={() => _onCustomTiersEdit(index, true)}
                        a11yTitle={`Copy ${tier.name} Tier`}
                      />
                    )}
                  {!isBrim
                    && (
                      <Button
                        icon={<Trash />}
                        onClick={() => _onDeleteCustomTier(index)}
                        a11yTitle={`Delete ${tier.name} Tier`}
                      />
                    )}
                </Box>
              </Box>
            </div>
          )}
        </Draggable>
      </div>
    );

    return props.readOnly ? readOnlyTier : editableTier;
  };

  const _getTiers = (tiers, noMatchEquipment) => {
    const editableTiers = (
      <DragDropContext onDragEnd={_onDragEnd}>
        <Box className='tier-row'>
          <Droppable droppableId='customTiers' type='CUSTOM_TIER'>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {tiers}
              </div>
            )}
          </Droppable>
          {noMatchEquipment}
        </Box>
      </DragDropContext>
    );

    return props.readOnly ? (
      <Box>
        {tiers}
        {noMatchEquipment}
      </Box>
    ) : editableTiers;
  };

  const _onToastClose = () => {
    setResponse(undefined);
  };

  if (props.disabled) {
    return (<Box />);
  }

  const validationResults = (props?.validation?.results ? props.validation.results : undefined);
  const noCustomTiers = (customTiers.length === 0 ? (
    <ListPlaceholder
      emptyMessage='You do not have any custom tiers defined at the moment.'
      unfilteredTotal={0}
      filteredTotal={1}
    />
  ) : '');
  const tiers = [];
  customTiers.forEach((tier, index) => {
    const countPerTier = validationResults?.countPerTier[tier.id] ? validationResults.countPerTier[tier.id] : 0;

    tiers.push(
      _getTier(index, tier, countPerTier, isBrim),
    );
  });

  const hasWarning = (validationResults?.count > 0);
  const noMatchEquipment = [];
  const validationCount = validationResults ? validationResults.count : 0;
  noMatchEquipment.push(
    <Box
      key='noMatchEquipment'
      direction='row'
      justify='between'
      margin={{ horizontal: '4px' }}
      border='top'
      responsive={false}
      height='40px'
    >
      <Box flex={false} direction='row' gap='small' align='center'>
        {hasWarning ? <StatusIcon value='warning' /> : <Checkmark color='brand' />}
        <span>Resources with no tier</span>
      </Box>
      <Box flex={true} align='end' justify='center'>
        {validationCount.toLocaleString()}
        {' '}
        {pluralize(props.type, validationCount)}
      </Box>
      <Box flex={true} align='end'>
        <Button
          icon={<View />}
          disabled={!hasWarning}
          onClick={() => _onCustomTiersNoMatchClick()}
        />
      </Box>
    </Box>,
  );

  return (
    <Box pad={{ 'horizontal': 'small' }}>
      <Header size='small' justify='between' height='42px'>
        <Text size='medium' weight='bold'>Custom Tiers</Text>
        {!props.readOnly && !isBrim && (
        <Button
          icon={<Add />}
          id={IDUtil.getId('AddCustomTiersButton')}
          onClick={_onCustomTiersAdd}
          a11yTitle='Add Custom Tiers'
        />
        )}
        {isBrim && (
        <Box>
          <Tip
            content={(
              <Box width='medium'>
                When provisioned through BRIM, custom tiers can only be edited. They cannot be created or deleted.
              </Box>
                )}
            dropProps={{ align: { left: 'right' } }}
          >
            <Box direction='row'>
              <StatusIcon value='warning' size='medium' />
              <Text style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.5)' }}>BRIM Account</Text>
            </Box>
          </Tip>
        </Box>
        )}
      </Header>
      {noCustomTiers}
      {_getTiers(tiers, noMatchEquipment)}
      {_renderCustomTiersEditor()}
      {_renderUnconfiguredEquipment()}
      {response
          && (
            <Notification
              toast={true}
              status={response?.status || 'critical'}
              title={response.title}
              message={response.message}
              onClose={_onToastClose}
            />
          )}
    </Box>
  );
};

const mapStateToProps = store => ({
  validation: store.service.details.validation,
  originalOptions: store.service.details.originalOptions,
  dirtyEquipment: store.service.details.dirtyEquipment,
  dirtyComponents: store.service.details.dirtyComponents,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

CustomTiersList.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  tiers: PropTypes.array,
  type: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  validation: PropTypes.object,
  originalOptions: PropTypes.object,
  dirtyEquipment: PropTypes.array,
  dirtyComponents: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomTiersList);
