// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { Drag } from 'grommet-icons';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Service } from './Service';
import { getItemStyle, getListStyle } from './utils';

export const Location = ({ location, index, options }) => (
  <div key={location.id}>
    <Draggable
      draggableId={location.id}
      index={index}
      type='LOCATION'
      isDragDisabled={options.locations !== 'MANUAL'}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          <Box className='draggable-header location' direction='row' alignContent='center'>
            <div {...provided.dragHandleProps}>
              {
                    options.locations === 'MANUAL' && <Drag />
                  }
            </div>
            <strong>{location.name}</strong>
          </Box>

          <Droppable droppableId={location.id} type={`SERVICE&&${location.id}`}>
            {(innerProvided, innerSnapshot) => (
              <div
                ref={innerProvided.innerRef}
                style={getListStyle(innerSnapshot.isDraggingOver)}
              >
                {
                    location.services.map((service, serviceIndex) => (
                      <Service
                        location={location}
                        service={service}
                        index={serviceIndex}
                        options={options}
                        key={`${location.id}-${service.type}`}
                      />
                    ))
                  }
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  </div>
);

Location.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.node,
    services: PropTypes.array,
  }).isRequired,
  options: PropTypes.shape({
    locations: PropTypes.any,
  }).isRequired,
  index: PropTypes.any.isRequired,
};
