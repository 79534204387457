// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
/* eslint-disable react/no-array-index-key */

import React, { useCallback, useEffect, useState } from 'react';

import ArrowKeysReact from 'arrow-keys-react';
import {
  Box, Button, Text, TextInput
} from 'grommet';
import {
  Add, Edit, Search, Trash
} from 'grommet-icons';
import IDUtil from '../shared/util/IDUtil';
import { debounce, insertIf } from '../shared/util/BasicUtil';
import ListItem from '../shared/list/ListItem';
import BaseContainer from './components/BaseContainer';
import { Customer, Service } from './types';
import { usePrevious } from '../shared/hooks';

interface Props {
  services: Service[]
  selectedService: Service
  customer: Customer
  partner: boolean
  onClick: (service: Service) => void
  onDeleteService: (service: Service) => void
  onEditService: (service: Service) => void
  onAddService: () => void
  readOnly: boolean
}

const ServiceList = ({
  readOnly, services, selectedService, onClick, onDeleteService, onEditService, customer, partner, onAddService
}: Props) => {
  const [searchText, setSearchText] = useState('');

  const filterServices = useCallback(() => services.filter(service => (searchText ? service.description.toLowerCase().includes(searchText.toLowerCase()) : true)), [services, searchText]);

  const onSelectMeterByKey = useCallback((isPrev: boolean) => {
    const filteredServices = filterServices();
    const sortedServices = (filteredServices || services).sort((m1, m2) => m1.description.localeCompare(m2.description));
    const selectedServiceId = (selectedService ? selectedService.meterId : undefined);
    const serviceIndex = sortedServices.map(m => m.meterId).indexOf(selectedServiceId);
    let serviceToSelect: Service;
    if (isPrev && serviceIndex > 0) {
      serviceToSelect = sortedServices[serviceIndex - 1];
    } else if (!isPrev && serviceIndex < (sortedServices.length - 1)) {
      serviceToSelect = sortedServices[serviceIndex + 1];
    }

    if (serviceToSelect) {
      onClick(serviceToSelect);
    }
  }, [filterServices, onClick, selectedService, services]);

  useEffect(() => {
    ArrowKeysReact.config({
      up: () => {
        onSelectMeterByKey(true);
      },
      down: () => {
        onSelectMeterByKey(false);
      },
    });
  }, [onSelectMeterByKey]);

  const prevServices = usePrevious(services);
  useEffect(() => {
    if (services !== prevServices) {
      const filteredServices = filterServices();
      if (filteredServices && filteredServices.length > 0) {
        if (!selectedService || !filteredServices.find(service => service.meterId === selectedService.meterId)) {
          onClick(filteredServices[0]);
        }
      } else {
        onClick(undefined);
      }
    }
  }, [filterServices, onClick, prevServices, selectedService, services]);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const getRows = () => {
    const locationMap = {};
    if (customer) {
      customer.locations.forEach((l) => {
        locationMap[l.id] = l.name;
      });
    }
    const filteredServices = filterServices();
    return (filteredServices || services)
      .sort((m1, m2) => m1.description.localeCompare(m2.description))
      .map((m, index) => {
        const missingLocation = customer && m.type === 'LOCATION' && !locationMap[m.locationId];
        const serviceLocation = customer && m.type === 'LOCATION' && locationMap[m.locationId];
        return (
          <ListItem
            key={index}
            value={m}
            selected={selectedService === m}
            onSelect={() => onClick(m)}
          >
            <Box
              direction='row'
              align='center'
              justify='between'
              style={{ color: missingLocation ? 'red' : 'black' }}
            >
              <Box direction='column' flex={false} width='medium'>
                <Text wordBreak='break-word' id={IDUtil.getId(`NonMeterServiceDescription-${index}`)}>{m.description}</Text>
                <Text wordBreak='break-word' id={IDUtil.getId(`NonMeterServiceApplyCharges-${index}`)} className='secondary'>{m.type === 'CUSTOMER' ? 'Billing Account' : `Location: ${serviceLocation}`}</Text>
              </Box>
              {!readOnly && !m.readOnly && !partner && (
              <Box direction='row'>
                <Button icon={<Edit a11yTitle={`Edit ${m.description}`} style={{ height: '18px', width: '18px' }} />} id={IDUtil.getId(`NonMeterServiceEditBtn-${index}`)} style={{ marginLeft: 'auto' }} onClick={() => onEditService(m)} />
                <Button icon={<Trash a11yTitle={`Delete ${m.description}`} style={{ height: '18px', width: '18px' }} />} id={IDUtil.getId(`NonMeterServiceDeleteBtn-${index}`)} style={{ marginLeft: 'auto' }} onClick={() => onDeleteService(m)} />
              </Box>
              )}
            </Box>
          </ListItem>
        );
      });
  };

  return (
    <Box
      direction='column'
      fill='vertical'
      margin={{ right: 'small' }}
      pad={{ vertical: 'small' }}
    >
      <BaseContainer
        title='Services'
        expanded={true}
        id={IDUtil.getId('NonMeterServiceAttributes')}
        actions={[
          ...insertIf(!readOnly && !partner, [
            <Button key='add-button' icon={<Add />} onClick={onAddService} style={{ marginLeft: 'auto' }} id={IDUtil.getId('NonMeterServiceAddBtn')} />,
          ]),
        ]}
        fill='vertical'
      >
        <Box style={{ backgroundColor: 'rgba(248, 248, 248, 0.95)' }} className='list' {...ArrowKeysReact.events} overflow='auto'>
          {getRows()}
        </Box>
      </BaseContainer>
      <Box
        style={{ backgroundColor: 'rgba(248, 248, 248, 0.95)' }}
        border={[
          {
            side: 'bottom'
          },
          {
            side: 'vertical'
          }
        ]}
      >
        <TextInput
          placeholder='Search'
          icon={<Search />}
          onChange={debounce(onSearchChange, 200)}
          plain={true}
        />
      </Box>
    </Box>
  );
};

export default ServiceList;
