// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Box } from 'grommet';
import { ApiContext } from '../../../AppContext';

export const DownloadCellRenderer = ({ data = undefined }) => {
  const contextFn = useContext(ApiContext);
  const { s3Bucket, s3Key } = data;
  const downloadPath = contextFn('administration.emails.download', {
    s3Bucket,
    s3Key
  });
  const extractPath = contextFn('administration.emails.extract', {
    s3Bucket,
    s3Key
  });
  if (!data) {
    return '';
  }
  // eslint-disable-next-line consistent-return
  return (
    <Box direction='row' gap='small'>
      <Anchor
        id={`${s3Bucket}_${s3Key}_open_link`}
        data-testid={`${s3Bucket}_${s3Key}_open_link`}
        target='_blank'
        className='hpehf-link'
        href={downloadPath}
        style={{ color: '#444444', textDecoration: 'underline' }}
      >
        Open
      </Anchor>
      <Anchor
        id={`${s3Bucket}_${s3Key}_extract_attachments_link`}
        data-testid={`${s3Bucket}_${s3Key}_extract_attachments_link`}
        target='_blank'
        className='hpehf-link'
        href={extractPath}
        style={{ color: '#444444', textDecoration: 'underline' }}
      >
        Extract Attachments
      </Anchor>
    </Box>
  );
};

// Comes from Ag-Grid, but let's make lint happy
DownloadCellRenderer.propTypes = {
  data: PropTypes.object,
};

function currencyFormatter(num) {
  return num.value.toLocaleString();
}

// Columns in the format expected for Column Filter
export const useColumns = () => {
  const { current: columns } = useRef([{
    field: 'time',
    header: 'Time',
    filter: 'agDateColumnFilter',
  }, {
    field: 'sender',
    header: 'Sender',
    filter: 'agSetColumnFilter'
  }, {
    field: 'subject',
    header: 'Subject',
    filter: 'agSetColumnFilter'
  }, {
    field: 'accountId',
    header: 'Account Id',
    filter: 'agSetColumnFilter'
  }, {
    field: 'msgId',
    header: 'Message Id',
    filter: 'agSetColumnFilter'
  }, {
    field: 'usageAttachments',
    headerName: 'Usage Attachments',
    filter: 'agNumberColumnFilter',
    type: 'numericColumn'
  }, {
    field: 'usageDate',
    header: 'Usage Date',
    filter: 'agDateColumnFilter',
  },
  {
    field: 'attachmentNames',
    headerName: ' Attachment name(s)',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'emailSizeInBytes',
    header: 'Email size',
    filter: 'agNumberColumnFilter',
    type: 'rightAligned',
    valueFormatter: params => currencyFormatter(params)
  },
  {
    field: 'receivers',
    headerName: 'Receivers',
    filter: 'agTextColumnFilter',
  },
  {
    field: 'Download',
    pinned: 'right',
    cellRenderer: DownloadCellRenderer,
  },
  ]);
  return columns;
};
