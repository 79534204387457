// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button, CheckBox,
  Footer,
  Text,
} from 'grommet';
import moment from 'moment/moment';
import { useAlertAcknowlegeMutate } from '../../core';
import GLBMLayer from '../shared/component/GLBMLayer';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import GLBMSaving from '../shared/component/GLBMSaving';
import GLBMTitleBox from '../shared/component/GLBMTitleBox';

import { useToast } from '../shared/toast/ToastProvider';

const AlertsAckDialog = ({ alert, onClose = null, onChange = null }) => {
  const [bulkAck, setBulkAck] = useState(false);
  const { addToast } = useToast();

  const {
    isPending,
    mutate: ackAlert,
    error: ackAlertError,
  } = useAlertAcknowlegeMutate({
    onSuccess: (_data) => {
      const showSuccess = (count) => {
        addToast({
          status: 'normal',
          title: `${count.toLocaleString()} alert${count > 1 ? 's' : ''} acknowledged`,
        });
      };
      if (bulkAck) {
        onChange(_data);
        showSuccess(_data?.length);
      } else {
        onChange([alert.id]);
        showSuccess(1);
      }
    },
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Alerts were not acknowledged',
        message: error.response.data.message,
      });
    },
  });

  const _onSubmit = () => {
    ackAlert({ isBulk: bulkAck, alert });
  };

  const renderConfirmationDetails = () => (
    <GLBMNameValueList
      title='Alert'
      data={[
        { label: 'Date', value: moment(alert.start).format('ll') },
        { label: 'Alert', value: alert.typeDescription },
        { label: 'Billing Account', value: alert.customerId },
        { label: 'Service', value: alert.serviceTypeDescription },
        { label: 'Message', value: alert.message },
      ]}
    />
  );

  const renderBulkDetails = () => (
    <GLBMTitleBox
      title='Bulk Acknowledge'
    >
      <CheckBox
        data-testid='bulk-edit-checkbox'
        checked={bulkAck}
        label='Acknowledge all alerts of this type for this account.'
        onChange={event => setBulkAck(event.target.checked)}
      />
    </GLBMTitleBox>
  );

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      title='Acknowledge Alert'
    >
      <Box
        pad='none'
        style={{ 'width': '600px', 'minWidth': '600px' }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box pad='medium' flex={true}>
          <Box direction='row' align='start' margin={{ vertical: 'medium' }} gap='small'>
            <Text weight={100} margin={{ top: 'none' }}>
              {'Acknowledging an alert permanently hides it from all users, indicating that there\'s no remedial action to be taken. Acknowledge?'}
            </Text>
          </Box>
          <Box gap='medium'>
            {renderConfirmationDetails()}
            {renderBulkDetails()}
          </Box>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button
            primary={true}
            onClick={_onSubmit}
            label={bulkAck ? 'Acknowledge All' : 'OK'}
            disabled={isPending}
          />
          <Button
            secondary={true}
            label='Cancel'
            onClick={onClose}
            disabled={isPending}
          />
          <GLBMSaving saving={isPending} error={ackAlertError} />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

AlertsAckDialog.propTypes = {
  alert: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

export default AlertsAckDialog;
