// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import {
  Button, Main,
} from 'grommet';
import { Refresh } from 'grommet-icons';
import CustomerSelector from '../../shared/component/CustomerSelector';
import GLBMHeading from '../../shared/component/GLBMHeading';
import GLBMDataSummary from '../../shared/component/GLBMDataSummary';
import GLBMDataTable from '../../shared/component/GLBMDataTable';
import GLBMSearch from '../../shared/component/GLBMSearch';
import {
  useCompaniesQueryMap,
  useCustomersQuery,
  usePaygISVDataMappings,
} from '../../../core';
import {
  SearchTextContextProvider
} from '../../shared/component/HighlightUsingContext';
import { useColumns } from './handler';

interface DataMapping {
  site?: string;
  sku?: string;
  beginMonth?: string;
  term?: number;
  context?: {
    companyName?: string;
    customerName?: string;
  };
  externalQuoteId?: string;
  billingAccount?: string;
}

const DataMappingListPage: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [billingAccount, setBillingAccount] = useState<string | undefined>(undefined);

  const {
    data: dataMappings,
    isFetching: fetchingQuotes,
    refetch: refreshQuotes,
  } = usePaygISVDataMappings();

  const {
    data: customerMap,
    isFetching: fetchingCustomers,
  } = useCustomersQuery({
    select: data => data?.reduce((_map, c) => {
      // eslint-disable-next-line no-param-reassign
      _map[c.id] = {
        id: c.id,
        name: c.name,
        companyId: c.companyId,
      };
      return _map;
    }, {}),
  });

  const {
    data: companyMap,
    isFetching: fetchingCompanies,
  } = useCompaniesQueryMap();

  // combine customers and company maps into a single object so I can look up company name using billingAccount id:
  const customerCompanyMap = useMemo(() => {
    const map = {};
    if (customerMap && companyMap) {
      Object.keys(customerMap)
        .forEach((customerId) => {
          const company = companyMap[customerMap[customerId].companyId];
          map[customerId] = {
            customerName: customerMap?.[customerId].name,
            companyName: company?.name,
          };
        });
    }
    return map;
  }, [customerMap, companyMap]);

  // now that we have customerCompanyMap, loop on all fetched dataMappings and add company name to each item:
  const dataMappingsWithContext = useMemo(() => {
    if (dataMappings && customerCompanyMap) {
      return dataMappings.map((item: DataMapping) => {
        const customerCompany = customerCompanyMap[item.billingAccount];
        const years = item.term !== 0 ? Math.floor(item.term / 12) : 0;
        return {
          ...item,
          context: customerCompany,
          termInYears: `${years} year${years === 1 ? '' : 's'}`,
        };
      });
    }
    return dataMappings;
  }, [dataMappings, customerCompanyMap]);

  const { columns, layers } = useColumns();

  const filteredList = useMemo(() => {
    const filterBySearchText = item => [
      item.site,
      item.sku,
      item.beginMonth,
      item.termInYears,
      item.context?.companyName,
      item.context?.customerName,
      item.externalQuoteId
    ].some(field => field?.toLowerCase().includes(searchText.toLowerCase()));

    const filterByBillingAccount = item => (billingAccount ? item.billingAccount === billingAccount : true);

    return dataMappingsWithContext?.filter(item => filterBySearchText(item) && filterByBillingAccount(item)) || [];
  }, [dataMappingsWithContext, searchText, billingAccount]);

  return (
    <Main direction='column' fill='vertical' overflow='hidden' data-testid='wiseQuoteIdListPage'>
      <GLBMHeading
        search={[
          <CustomerSelector
            key='customerSelector'
            enableAllOption={true}
            onCustomerSelected={value => (value !== 'All' ? setBillingAccount(value) : setBillingAccount(undefined))}
            initialSelection={undefined}
            excludedStates={undefined}
            excludedServiceStates={undefined}
            excludedPurposes={undefined}
            excludeNoServices={undefined}
            multiple={undefined}
            persistSelection={undefined}
            allowNoSelection={undefined}
          />,
          <GLBMSearch
            key='searchText'
            value={searchText}
            onChange={setSearchText}
          />,
        ]}
        actions={[
          <Button
            kind='toolbar'
            data-testid='wiseQuoteIdListPageRefreshButton'
            icon={<Refresh />}
            onClick={() => refreshQuotes()}
            a11yTitle='Refresh Data Mapping List'
            key='refreshBtn'
            label='Refresh'
          />,
        ]}
      />
      <GLBMDataSummary
        total={dataMappingsWithContext?.length}
        filtered={filteredList?.length}
      />
      <SearchTextContextProvider searchText={searchText}>
        <GLBMDataTable
          columns={columns}
          data={filteredList}
          searchText={searchText}
          loading={fetchingQuotes || fetchingCustomers || fetchingCompanies}
          total={filteredList?.length}
          primaryKey='id'
        />
      </SearchTextContextProvider>
      {layers}
    </Main>
  );
};

export default DataMappingListPage;
