// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  Box, Button, Notification, Spinner, Text,
} from 'grommet';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import { ApiContext } from '../../../AppContext';
import GLBMLayer from '../../shared/component/GLBMLayer';
import ImportISVContract from './ImportISVContract';

const actionHandlers = {
  'Import': {
    label: 'Import',
    component: props => <ImportISVContract {...props} />,
  },
};

const ActionRow = ({ action }) => {
  const [notification, setNotification] = useState(null);
  const [showExecParams, setShowExecParams] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const handler = useMemo(() => actionHandlers[action.type], [action.type]);
  const contextFn = useContext(ApiContext);

  const downloadOneFile = (fileName, rawForm) => {
    const path = contextFn('payg.vmware.action.generate.path', {
      fileName,
      rawForm,
    });
    window.open(path, '_self');
  };
  const onExtract = () => {
    downloadOneFile();
  };
  const executeActionHandler = async (handlerFunction) => {
    setShowSpinner(true);
    try {
      await handlerFunction();
      setShowExecParams(false);
    } catch (error) {
      const { message } = (error.response?.data && error.response?.data?.message) ? error.response.data : error;
      setNotification({ status: 'critical', message });
    } finally {
      setShowSpinner(false);
    }
  };
  const onExecuteAction = async () => {
    if (handler.component) {
      setShowExecParams(true);
    } else {
      setShowExecParams(false);
      await executeActionHandler(() => onExtract());
    }
  };

  const hideExecParams = () => {
    setShowExecParams(false);
  };

  const renderExecParams = () => {
    if (showExecParams) {
      return (
        <GLBMLayer
          a11yTitle={`Execute administrative action: ${action.name}`}
          data-testid='exec-params-layer'
          onEsc={hideExecParams}
          onClickOutside={hideExecParams}
          onClose={hideExecParams}
          position='right'
          full='vertical'
          title={action.description}
        >
          <handler.component action={action} onExecute={executeActionHandler} onClose={hideExecParams} />
        </GLBMLayer>
      );
    }
    return null;
  };

  const renderSpinner = () => (showSpinner ? <Spinner data-testid='spinner' margin={{ right: 'xsmall' }} /> : null);

  const clearNotification = () => setNotification(null);

  const renderNotification = () => {
    if (notification) {
      return (
        <Notification toast={true} status={notification.status} message={notification.message} onClose={clearNotification} />
      );
    }
    return null;
  };

  return (
    <Box
      data-e2e='action-item'
      data-testid='action-item'
      justify='between'
      flex={false}
      direction='row'
      border='horizontal'
      pad='small'
      align='center'
    >
      <Box align='start' flex={true}>
        <Text data-e2e='main-text' weight='bold'>{action.name}</Text>
        <Text data-e2e='sub-text'>{action.description}</Text>
      </Box>
      <Box direction='row' align='center'>
        {renderSpinner()}
        <Button
          label={handler?.label}
          onClick={onExecuteAction}
          secondary={true}
          data-testid='handler-label'
        />
      </Box>
      {renderExecParams()}
      {renderNotification()}
    </Box>
  );
};

ActionRow.propTypes = {
  action: PropTypes.object.isRequired,
};

export default ActionRow;
