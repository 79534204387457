// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class MinChargeInterval extends Enumify {
  static Second = new MinChargeInterval('Second', 1);

  static Minute = new MinChargeInterval('Minute', 2);

  static Hour = new MinChargeInterval('Hour', 3);

  static Day = new MinChargeInterval('Day', 4);

  static Month = new MinChargeInterval('Month', 5);

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label, order) {
    super();
    this.label = label;
    this.order = order;
  }
}
