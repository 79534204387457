// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Layer } from 'grommet';
import { Close } from 'grommet-icons';
import GLBMHeading from './GLBMHeading';

const GLBMLayer = ({
  children, onClose = undefined, title = undefined, subtitle = undefined, style = undefined, ...rest
}) => {
  useEffect(() => {
    // way around to fix Layer's issue
    document.body.style.removeProperty('overflow');
  }, []);

  const hasTitle = !!(title || subtitle);

  return (
    <Layer {...rest} style={{ minWidth: '350px', ...style }}>
      <Box direction='row' justify={title ? 'between' : 'end'} flex={false} align={subtitle ? 'start' : 'center'}>
        {hasTitle && <GLBMHeading title={title} subtitle={subtitle} />}
        {onClose && (
          <Button
            pad='small'
            margin={subtitle ? { top: 'medium' } : undefined}
            icon={<Close />}
            onClick={onClose}
          />
        )}
      </Box>
      {children}
    </Layer>
  );
};

GLBMLayer.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default GLBMLayer;
