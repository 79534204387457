// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  Box, Button, CheckBox, Footer, FormField, TextInput,
} from 'grommet';
import React, { useEffect, useState } from 'react';
import { useAccountKeyUpdateMutate } from '../../../core';
import GLBMLayer from '../../shared/component/GLBMLayer';
import IDUtil from '../../shared/util/IDUtil';

interface EncryptionKeyEditDialogProps {
  accountId: string;
  keyId: string;
  name?: string;
  active?: boolean;
  onClose: () => void;
}

const EncryptionKeyEditDialog: React.FC<EncryptionKeyEditDialogProps> = ({
  accountId,
  keyId,
  name: initialName = '',
  active: initialActive = false,
  onClose
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [active, setActive] = useState(initialActive);
  const [name, setName] = useState(initialName);

  const {
    mutate: updateAccountKey,
    isSuccess: isUpdateSuccess,
  } = useAccountKeyUpdateMutate(accountId, keyId);

  const onSubmitAccountKey = () => {
    if (name) {
      updateAccountKey({
        active,
        name,
      });
    }
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      onClose();
    }
  }, [isUpdateSuccess, onClose]);

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      title='Edit Usage File Encryption'
      style={undefined}
    >
      <Box
        pad='none'
        style={{
          'width': '600px',
          'minWidth': '600px'
        }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box pad='medium' flex={false} gap='medium'>
          <FormField
            label='Active'
            htmlFor='account-key-active'
          >
            <CheckBox
              id='account-key-active'
              checked={active}
              onChange={event => setActive(event.target.checked)}
              toggle={true}
            />
          </FormField>
          <FormField
            label='Name'
            required={true}
            error={submitted && !name ? 'Required' : undefined}
            help='limit of 64 characters.'
            htmlFor='account-key-name'
          >
            <TextInput
              id='account-key-name'
              value={name}
              onChange={event => setName(event.target.value)}
              maxLength={64}
            />
          </FormField>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button
            primary={true}
            onClick={() => {
              setSubmitted(true);
              onSubmitAccountKey();
            }}
            label='Save'
            {...{ 'data-testid': IDUtil.getId('EncryptionKeyEditSubmitBtn') }}
          />
          <Button
            secondary={true}
            onClick={onClose}
            label='Cancel'
            {...{ 'data-testid': IDUtil.getId('EncryptionKeyEditCancelBtn') }}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

export default EncryptionKeyEditDialog;
