// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import {
  INVALIDATE_CUSTOMER,
  INVALIDATE_CUSTOMERS, RECEIVE_CUSTOMER,
  RECEIVE_CUSTOMERS, RECEIVE_CUSTOMER_METERS,
  RECEIVE_PARTNER_CONFIG,
  REQUEST_CUSTOMER, REQUEST_CUSTOMERS,
  REQUEST_CUSTOMER_METERS,
  REQUEST_PARTNER_CONFIG, SAVE_CUSTOMER_FINISH,
  SAVE_CUSTOMER_START, UPDATE_CUSTOMER_LIST_PAGE_FILTERS,

} from './CustomerActions';

// eslint-disable-next-line @typescript-eslint/default-param-last
export function customerList(state = {
  isFetching: false,
  items: null,
  filters: {
    list: { searchText: '', sortIndex: 2, sortAscending: true },
    panel: {},
  },
}, action) {
  switch (action.type) {
    case UPDATE_CUSTOMER_LIST_PAGE_FILTERS:
      return { ...state, filters: action.filters };
    case INVALIDATE_CUSTOMERS:
      return {
        ...state,
        isFetching: false,
        items: []
      };
    case REQUEST_CUSTOMERS:
      return {
        ...state,
        isFetching: true,
        items: []
      };
    case RECEIVE_CUSTOMERS:
      return {
        ...state,
        isFetching: false,
        items: action.customers
      };
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function customerDetails(state = {
  isFetching: false,
  item: null,
}, action) {
  switch (action.type) {
    case INVALIDATE_CUSTOMER:
      return {
        ...state,
        isFetching: false,
        item: null
      };
    case REQUEST_CUSTOMER:
      return {
        ...state,
        isFetching: true,
        item: null
      };
    case RECEIVE_CUSTOMER:
      return {
        ...state,
        isFetching: false,
        item: action.customer
      };
    case SAVE_CUSTOMER_START:
      return { ...state, ...action.customer };
    case SAVE_CUSTOMER_FINISH:
      return { ...state, ...action.customer };
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function partnerConfig(state = {
  isFetching: false,
  item: null,
}, action) {
  switch (action.type) {
    case REQUEST_PARTNER_CONFIG:
      return {
        ...state,
        isFetching: true,
        item: null
      };
    case RECEIVE_PARTNER_CONFIG:
      return {
        ...state,
        isFetching: false,
        item: action.customer
      };
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export function customerMeter(state = {
  isFetching: false,
  item: null,
}, action) {
  switch (action.type) {
    case REQUEST_CUSTOMER_METERS:
      return {
        ...state,
        isFetching: true,
        items: null
      };
    case RECEIVE_CUSTOMER_METERS:
      return {
        ...state,
        isFetching: false,
        items: action.meters
      };
    default:
      return state;
  }
}
