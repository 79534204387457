// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Box, Notification } from 'grommet';
import { StatusCritical } from 'grommet-icons';
import * as React from 'react';
import PropTypes from 'prop-types';
import { pagePermissions } from '../../shared/constants/Permissions';
import ActionRow from './ActionRow';
import { usePermissionChecker } from '../../shared/hooks';

const ActionsList = ({ error = {}, isError = false }) => {
  const { hasPermissions } = usePermissionChecker();
  if (isError) {
    console.error(error);
    return (
      <>
        <Box direction='column' fill={true} align='center' pad='large'>
          <Box direction='row' flex={false} gap='small'>
            <StatusCritical size='medium' />
            Error fetching list of actions
          </Box>
        </Box>
        <Notification toast={true} status='critical' message={error.message} />
      </>
    );
  }
  const actions1 = [
    {
      name: 'PAYG',
      description: 'Import ISV Contract Data',
      type: 'Import',
      method: 'POST',
      permissions: pagePermissions.payg.quotes.edit,
      path: '/fc/payg/contracts/actions/upload'
    }
  ];
  // Create an ActionRow for each data element
  return (
    <>
      {
        actions1
          .filter(action => hasPermissions(action.permissions))
          .map(action => <ActionRow key={action.name} action={action} actions1={actions1} />)
      }
    </>
  );
};
ActionsList.propTypes = {
  error: PropTypes.object,
  isError: PropTypes.bool,
};

export default ActionsList;
