// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiContext } from '../../AppContext';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { executeGet, executePost } from '../api';

export const useQueryAccountConnections = (billingId, options) => {
  const path = useContext(ApiContext)('account.connections.path', { billingId });
  return useQuery({
    queryKey: ['account.connections.path', billingId],
    queryFn: () => executeGet(path),
    ...options,
    enabled: !!billingId,
  });
};

export const useMutationCustomerAccountDisconnect = (options) => {
  const apiFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation({
    mutationFn: ({ customerId, deleteDatasources, sendEmail }) => {
      const path = apiFn('customers.disconnect.path', { customerId }, {
        deleteDatasources,
        sendEmail
      });
      return executePost(path);
    },
    onSuccess: () => {
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'Billing Account Successfully Disconnected',
      });
      options?.onSuccess?.();
    },
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Billing Account Disconnect Error',
        message: error?.response?.data?.message,
      });
      options?.onError?.();
    }
  });
};

export const useQueryCustomerPromote = (customerId, options) => {
  const path = useContext(ApiContext)('customers.promote.path', { customerId });
  return useQuery({
    queryKey: ['customers.promote.path', customerId],
    queryFn: () => executeGet(path),
    ...options,
  });
};

export const useMutationCustomerPromote = (options) => {
  const apiFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation({
    mutationFn: ({ customerId, caTenantId }) => {
      const path = apiFn('customers.promote.path', { customerId }, { caTenantId });
      return executePost(path);
    },
    onSuccess: () => {
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'Billing Account Successfully Connected',
      });
      options?.onSuccess?.();
    },
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'Billing Account Connect Error',
        message: error?.response?.data?.message,
      });
      options?.onError?.();
    }
  });
};
