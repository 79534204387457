// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { EventEmitter } from 'events';
import AppDispatcher from '../dispatcher/AppDispatcher';
import AuthActions from '../actions/AuthActions';
import UserConstants from '../constants/UserConstants';
import UserStore from '../stores/UserStore';
import { isAssignedRole } from '../shared/constants/UserType';

const refreshMinutes = 10; // How many minutes between refresh attempts
const timeoutMinutes = 30;

const CHANGE_EVENT = 'change';

class UserSessionClass extends EventEmitter {
  constructor() {
    super();
    this.updateLastAction = this.updateLastAction.bind(this);
    this.doRefresh = this.doRefresh.bind(this);
    this.scheduleRefresh = this.scheduleRefresh.bind(this);
    this.initialize = this.initialize.bind(this);
    this.lastAction = new Date();
    this.showModal = false;
    this.apiDefs = undefined;
    this.continueSession = this.continueSession.bind(this);
    this.endSession = this.endSession.bind(this);

    document.onmousedown = this.updateLastAction; // touchscreen presses
    document.ontouchstart = this.updateLastAction;
    document.onclick = this.updateLastAction; // touchpad clicks22
    document.onscroll = this.updateLastAction; // scrolling with arrow keys
    document.onkeypress = this.updateLastAction;
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  updateLastAction() {
    this.lastAction = new Date();
  }

  doRefresh() {
    const path = isAssignedRole(UserStore.getUser()?.role)
      ? this.apiDefs['auth.refresh.assigned.path'] : this.apiDefs['auth.refresh.unassigned.path'];
    if (this.apiDefs) {
      AuthActions.refresh(path).then(() => {
        this.lastRefresh = new Date();
        sessionStorage.setItem('lastTokenRefresh', this.lastRefresh.toISOString());
      });
    }
  }

  scheduleRefresh() {
    this.interval = setInterval(() => {
      const timeDiff = (new Date().getTime() - this.lastAction.getTime());
      if (timeDiff + 60000 <= 60000 * timeoutMinutes) {
        this.doRefresh();
      } else {
        this.showModal = true;
        this.finalLogoutTimer = setInterval(() => {
          this.timeoutLogout();
        }, 90000);
        AppDispatcher.dispatch({
          actionType: UserConstants.LOGIN_TIMED_OUT,
        });
      }
    }, 60000 * refreshMinutes);
  }

  endSession() {
    window.clearInterval(this.interval);
    window.clearInterval(this.finalLogoutTimer);
    AuthActions.logout();
  }

  timeoutLogout() {
    window.clearInterval(this.interval);
    window.clearInterval(this.finalLogoutTimer);
    localStorage.setItem('timeout', 'true');
    AuthActions.logout();
  }

  continueSession() {
    this.showModal = false;
    window.clearInterval(this.finalLogoutTimer);
    AppDispatcher.dispatch({
      actionType: UserConstants.LOGIN_TIMED_OUT,
    });
  }

  initialize() {
    this.lastRefresh = new Date();
    this.lastAction = new Date();
    this.scheduleRefresh();
  }

  setApiDefs(apiDefs) {
    this.apiDefs = apiDefs;
  }

  current() {
    return AuthActions.current(this.apiDefs['auth.current.path']);
  }

  access() {
    return AuthActions.access(this.apiDefs['auth.access.path']);
  }
}

const UserSession = new UserSessionClass();

AppDispatcher.register((action) => {
  if (action.actionType === UserConstants.LOGIN_SUCCESS) {
    UserSession.initialize();
  } else if (action.actionType === UserConstants.LOGIN_TIMED_OUT) {
    UserSession.emitChange();
  }
});

export default UserSession;
