// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import * as Sentry from '@sentry/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Grommet } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { deepMerge } from 'grommet/utils';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { OktaContext, UserContext } from './AppContext';
import { getOktaAuthClient } from './components/authentication/config';
import UnsupportedConfigurationPage
  from './components/authentication/UnsupportedConfigurationPage';
import UserConstants from './components/constants/UserConstants';
import AppDispatcher from './components/dispatcher/AppDispatcher';
import messages from './components/i18n/messages/en-US';
import { queryConfig } from './components/query/config';
import fcPortalApp from './components/redux/reducers';
import { sessionStorageKey } from './components/session/sessionStorageKey';
import ToastProvider from './components/shared/toast/ToastProvider';
import UserStore from './components/stores/UserStore';
// eslint-disable-next-line import/extensions
import GlbmApp from './GlbmApp';

/** Function which will read userDataConcent cookie.
 * @returns {boolean} true if opted in, false if not
 */
// istanbul ignore next
// eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
function hpeuckCheckPrefsBit(cookie, bitNo) {
  let displayBeacon = true;
  if (cookie && cookie.substring(bitNo - 1, bitNo) === '0') {
    displayBeacon = false;
  }
  return displayBeacon;
}
// istanbul ignore next
// eslint-disable-next-line unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars
function hpeuckGetCookie() {
  // eslint-disable-next-line no-useless-concat
  let cookie = document.cookie.match('hpeuck_prefs' + '=(.*?)(;|$)');
  if (cookie !== null) {
    // jshint ignore : start
    cookie = unescape(cookie[1]);
    // jshint ignore : end
  }
  return cookie;
}

// create the redux store:
const store = createStore(
  fcPortalApp,
  JSON.parse(sessionStorage.getItem(sessionStorageKey)) || {},
  applyMiddleware(thunk),
);

// Theme Modifications ////////////////////////////////////////////
const hpeModifiedTheme = deepMerge(hpe, {
  formField: {
    disabled: {
      label: {
        color: '#444444',
      },
      border: {
        opacity: 0.5,
      },
    },
  },
  textInput: {
    disabled: {
      opacity: 0.6,
    },
  },
});

window.addEventListener('beforeunload', (event) => {
  // stop the even so the browser doesn't ask 'do you want to stay or leave':
  event.stopImmediatePropagation();
});

// ////////////////////////////////////////////////////////////////
export default class AppContainer extends Component {
  constructor(props) {
    super(props);

    const user = sessionStorage.getItem('user');
    // set the initial component state
    this.state = {
      user: (typeof user === 'string') ? JSON.parse(user) : undefined,
    };

    this.onChange = this.onChange.bind(this);
    this.componentWillMount = this.UNSAFE_componentWillMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);

    const { appEnv } = { ...props.config };
    this.oktaAuthClient = appEnv ? getOktaAuthClient(appEnv) : undefined;
  }

  UNSAFE_componentWillMount() {
    UserStore.addChangeListener(this.onChange);
    let lastRefresh = new Date(0);

    if (typeof Storage !== 'undefined' && Object.prototype.hasOwnProperty.call(window.sessionStorage, 'lastTokenRefresh')) {
      lastRefresh = new Date(sessionStorage.getItem('lastTokenRefresh'));
    }
    const diff = new Date().getTime() - lastRefresh.getTime();
    const minutes = Math.floor(diff / 1000 / 60);
    if (minutes < 15) {
      const { state } = this;
      AppDispatcher.dispatch({
        actionType: UserConstants.LOGIN_SUCCESS,
        user: state.user,
        source: 'refresh',
      });
    }
  }

  // istanbul ignore next
  componentWillUnmount() {
    UserStore.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState(
      {
        user: UserStore.getUser(),
      },
    );
  }

  render() {
    // Create a client
    const queryClient = new QueryClient(queryConfig);
    const { user } = this.state;

    const router = createBrowserRouter([
      {
        path: '/*',
        element: <GlbmApp />,
      },
    ]);

    return (
      <Provider store={store}>
        <IntlProvider defaultLocale='en' locale='en' messages={messages}>
          <Grommet full={true} theme={hpeModifiedTheme}>
            {this.oktaAuthClient
              ? (
                <ToastProvider>
                  <Sentry.ErrorBoundary fallback='An error has occured'>
                    <QueryClientProvider client={queryClient}>
                      <UserContext.Provider value={user}>
                        <OktaContext.Provider value={this.oktaAuthClient}>
                          <RouterProvider router={router} />
                        </OktaContext.Provider>
                      </UserContext.Provider>
                    </QueryClientProvider>
                  </Sentry.ErrorBoundary>
                </ToastProvider>
              )
              : (
                <UnsupportedConfigurationPage
                  message='Invalid appEnv from Window.config'
                  details='The webapp was not passed a proper configuration object and is unable to determine which environment it is running in.'
                />
              )}
          </Grommet>
        </IntlProvider>
      </Provider>
    );
  }
}

AppContainer.propTypes = {
  config: PropTypes.shape({
    appEnv: PropTypes.string,
  }),
};

AppContainer.defaultProps = {
  config: {
    appEnv: undefined,
  },
};
