// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, FormField } from 'grommet';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import DateTime from '../shared/component/DateTime';
import IDUtil from '../shared/util/IDUtil';
import { useCustomerAddEndMonthMutate } from '../../core';

const AddEndMonthLayer = ({
  data = undefined,
  onClose = undefined,
}) => {
  const [endMonth, setEndMonth] = useState(moment().subtract(1, 'month').format('YYYY-MM'));
  const [error, setError] = useState();

  const {
    mutate
  } = useCustomerAddEndMonthMutate(data.customer.id, {
    onSuccess: onClose,
  });

  const onContractDateChange = (date) => {
    // validate date to be proper date:
    const momentDate = moment(date, 'YYYY-MM', true);
    const newErrors = [];
    const isValid = momentDate.isValid();
    if (!date) { // at least 8 character
      newErrors.push('Required');
    } else if (!isValid) {
      newErrors.push('Invalid end month format (YYYY-MM)');
    } else if (momentDate.isAfter(moment().subtract(1, 'month'))) {
      newErrors.push('No later than previous month');
    } else if (momentDate.isBefore('2013-01-01', 'month')) {
      newErrors.push('No earlier than 2013-01');
    } else if (data?.customer && momentDate.isBefore(data?.customer.contractStartMonth, 'month')) {
      newErrors.push('No earlier than Start Month');
    }

    setEndMonth(date);
    setError(newErrors.join(', '));
  };

  const details = useMemo(() => {
    const customer = data?.customer;
    return (
      <Box margin={{ top: 'small' }} gap='medium'>
        <GLBMNameValueList
          title='Selected Billing Account'
          data={[
            { label: 'Billing ID', value: customer.id },
            { label: 'Billing Account Name', value: customer.name },
          ]}
        />
        <FormField
          label='End Month'
          error={error}
        >
          <DateTime
            id={IDUtil.getId('EndMonthLayerDateInput')}
            {...{ 'data-testid': IDUtil.getId('EndMonthLayerDateInput') }}
            name='contractEndMonth'
            format='YYYY-MM'
            onChange={(event) => {
              onContractDateChange(event);
            }}
            value={endMonth || ''}
            calendarProps={{
              bounds: ['2013-01-01', '2116-01-01'],
            }}
          />
        </FormField>
      </Box>
    );
  }, [data?.customer, endMonth, error]);

  const onSubmit = () => {
    if (!error) {
      mutate(endMonth);
    }
  };

  return (
    <ConfirmationDialog
      data={data}
      title='Add End Month'
      submitLabel='OK'
      cancelLabel='Cancel'
      text="Setting the 'End Month' on a billing account will stop all usage processing and billing for that billing account as of the last day of the 'End Month'.  If this is not your intention, please click the 'Cancel' button."
      onClose={onClose}
      onChange={() => onSubmit()}
      details={details}
    />
  );
};

AddEndMonthLayer.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default AddEndMonthLayer;
