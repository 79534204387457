// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, CheckBox, FormField, Paragraph,
} from 'grommet';
import { StatusIcon } from '../../shared/component/StatusIcon';
import { useToast } from '../../shared/toast/ToastProvider';
import {
  useGlpBindingsQuery,
  useMutationCustomerPromote,
  useQueryCustomerPromote,
} from '../../../core';
import Loader from '../../shared/loader';
import GLCAccountDetails from './GLCAccountDetails';
import GLPAccountDetails from './GLPAccountDetails';

import NavButtons from './NavButtons';
import PromoteResults from './PromoteResults';
import ErrorBanner from '../../shared/banner/ErrorBanner';
import PartnerDetails from './PartnerDetails';

const PromoteDialog = ({
  customer,
  onClose,
}) => {
  const [enableGlcsSetup, setEnableGlcsSetup] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(undefined);

  const customerId = customer.id;
  const { addToast } = useToast();

  // load the GLP bindings
  const {
    isLoading: bindingsIsLoading,
    data: bindingsData,
  } = useGlpBindingsQuery({
    retry: false,
  });

  // load the customer details if the end user has selected to enable GLCS setup
  const {
    isLoading: detailsIsLoading,
    isError: detailsIsError,
    data: detailsData,
  } = useQueryCustomerPromote(customerId, {
    enabled: true,
    retry: false,
    onError: (err) => {
      addToast({
        status: 'critical',
        title: 'Fetch Details Error',
        message: err?.message,
      });
    },
  });

  const {
    mutate: promoteCustomer,
    isLoading: isPromoting,
    isError: isPromoteError,
    isSuccess: isPromoted,
    error: promoteError,
  } = useMutationCustomerPromote();

  const onSubmit = () => {
    try {
      promoteCustomer({
        customerId,
        caTenantId: selectedWorkspace?.caTenantId,
      })
        .then(() => {
        }, (error) => {
          console.error('promoteCustomer.error', error);
        });
    } catch (error) {
      console.error('error', error);
      // Uh oh, something went wrong
    }
  };
  const noServicesError = detailsData ? !detailsData?.customer?.services?.some(el => el.status === 'COMPLETE') : false;

  return (
    <Box fill={true}>
      <Box margin='small' pad='small' direction='row' align='start'>
        <StatusIcon value='warning' size='medium' />
        <Box margin={{ left: 'small' }}>
          <Paragraph margin='none'>
            Once you promote this Billing Account to production users will be
            able to access its data. You cannot undo this action.
          </Paragraph>
        </Box>
      </Box>
      {(bindingsIsLoading || detailsIsLoading) && (
        <Box
          direction='row'
          align='center'
          gap='small'
          justify='center'
          fill={true}
        >
          <Loader text='Loading. Please wait ...' />
        </Box>
      )}

      <Box pad='none' direction='column' flex={true} overflow='auto'>
        <Box margin={{ horizontal: 'medium' }} height={{ min: 'xsmall' }}>
          <FormField
            label='Enable GLCS Setup'
            help='Check this box if billing account will be configured in GLCS.'
          >
            <CheckBox
              name='enableGlcsSetup'
              checked={enableGlcsSetup}
              onChange={(event) => {
                setSelectedWorkspace(undefined);
                setEnableGlcsSetup(event.target.checked);
              }}
            />
          </FormField>
        </Box>
        {enableGlcsSetup ? (
          <Box>
            {(detailsIsLoading) && (
              <Box
                direction='row'
                align='center'
                gap='small'
                justify='center'
                fill={true}
              >
                <Loader text='Loading. Please wait ...' />
              </Box>
            )}
            {(detailsData && !detailsIsError) && (
              <Box>
                <GLCAccountDetails data={noServicesError ? { status: 'NO_SERVICES' } : detailsData?.customerDetails} />
                {(detailsData?.resellerDetails)
                  && (
                    <PartnerDetails
                      title='Reseller'
                      data={detailsData?.resellerDetails}
                    />
                  )}
                {(detailsData?.distributorDetails)
                  && (
                    <PartnerDetails
                      title='Distributor'
                      data={detailsData?.distributorDetails}
                    />
                  )}
              </Box>
            )}
          </Box>
        ) : (
          <GLPAccountDetails
            noServicesError={noServicesError}
            workspace={selectedWorkspace}
            bindings={bindingsData}
            onChange={setSelectedWorkspace}
          />
        )}
      </Box>

      {isPromoted && (
        <PromoteResults customer={customer} />
      )}
      {isPromoteError && (
        <Box fill='vertical' margin='medium'>
          <ErrorBanner
            title='Unable to Promote Billing Account'
            message={promoteError.message}
          />
        </Box>
      )}
      {detailsIsError && (
        <Box fill='vertical' margin='medium'>
          <ErrorBanner
            title='Unable to Fetch Promote Details'
            message={detailsIsError.message}
          />
        </Box>
      )}
      <NavButtons
        submit={(enableGlcsSetup && detailsData?.everythingOk === true && !noServicesError) || (!enableGlcsSetup && selectedWorkspace && !noServicesError) ? onSubmit : undefined}
        close={onClose}
        saving={isPromoting}
        saved={isPromoted}
      />
    </Box>
  );
};

PromoteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default PromoteDialog;
