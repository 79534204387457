// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import pluralize from 'pluralize';
import {
  invalidateValidation,
  locationTypeChanged,
  setDirtyOptions,
  setOptions,
} from 'services/redux/ServiceActions';
import { ReportingUnits } from 'services/model/ReportingUnits';
import { ServiceStep } from 'services/model/ServiceStep';
import { configurableDeviceAndComponent } from 'services/Util';
import {
  Box,
  Button,
  CheckBox,
  FormField, Header,
  RadioButton, Text, TextInput,
} from 'grommet';
import { FormEdit } from 'grommet-icons';
import PropTypes from 'prop-types';
import CurrencyUtils from '../../../../i18n/CurrencyUtil';
import ReadOnlyField from '../../../../shared/component/ReadOnlyField';
import CARadioButton from '../../../../shared/form/CARadioButton';
import IDUtil from '../../../../shared/util/IDUtil';
import ReportByEditor from './ReportByEditor';
import LocationsList from '../../../../locations/LocationsList';
import VMBillingMethodField from './types/VMBillingMethodField';
import NutanixBillingMethodField from './types/NutanixBillingMethodField';
import LinuxBillingMethodField from './types/LinuxBillingMethodField';
import WindowsBillingMethodField from './types/WindowsBillingMethodField';
import EHRBillingMethodField from './types/EHRBillingMethodField';
import XPBillingMethodField from './types/XPBillingMethodField';
import AzureStackBillingMethodField from './types/AzureStackBillingMethodField';
import GoogleAnthosBillingMethodField from './types/GoogleAnthosBillingMethodField';
import LicenseTypesBillingMethodField from './types/LicenseTypesBillingMethodField';
import CustomTiersList from './tiers/customTiers/CustomTiersList';
import MappedTiersList from './tiers/mappedTiers/MappedTiersList';
import SingleTierEditor from './tiers/singleTiers/SingleTierEditor';
import CatalogPlanList from '../../catalog/CatalogPlanList';
import {
  LocationOption,
  ReportBy,
  ServicePrecisionOption,
  TierOptions,
} from '../../../model';
import ServiceTypeStore from '../../../../stores/ServiceTypeStore';
import GpuUtilizationThresholdField from './types/GpuUtilizationThresholdField';
import { useValidationMutation } from '../../../../../core';

const ServiceOptions = (props) => {
  const [layer, setLayer] = useState(undefined);
  const { customTiers } = props.options.config;
  const _createDefaultSingleTier = (serviceType) => {
    const formatLabel = (label) => {
      const [suffix, ...prefixes] = label.split(' ').reverse();
      // if the label has no suffix, the assumption is we do not want it to be plural (e.g. SAN, StoreOnce, P2000)
      const formattedSuffix = prefixes.length > 0 ? pluralize.plural(suffix) : suffix;
      return [...prefixes.reverse(), formattedSuffix].join('_');
    };

    const label = formatLabel(serviceType.longLabel || serviceType.label);

    return [{
      id: uuidv4(),
      name: label,
      criteria: [{
        matchAll: true,
        criteria: [{ field: 'sys_InstanceType_s', operator: 'notEmpty' }],
      }],
    }];
  };

  // default customTiers for SINGLE tier option if one doesn't already exist.
  // NOTE: There should not be both SINGLE and CUSTOM tier options for a service (since they both use the "customTiers" field)
  if (props.tierOptions.includes(TierOptions.SINGLE) && (!customTiers || customTiers.length === 0)) {
    props.options.config.customTiers = _createDefaultSingleTier(props.serviceType);
  }

  // default precision values to customer properties if not set:
  if (!props.options.config.hasOwnProperty('unitPrecision') || !props.options.config.hasOwnProperty('ratePrecision')) {
    props.options.config.unitPrecision = props.customer?.defaultServiceConfig?.unitPrecision;
    props.options.config.ratePrecision = props.customer?.defaultServiceConfig?.ratePrecision;
  }

  const { mutate: fetchValidationIfNeeded } = useValidationMutation(ServiceStep.OPTIONS);

  useEffect(() => {
    fetchValidationIfNeeded();
  }, []);

  useEffect(() => () => props.invalidateValidation(), []);

  const _onReportByApplied = (newOptions) => {
    fetchValidationIfNeeded();
    const { options } = props;
    options.config.reportBy = newOptions.reportBy;
    setLayer(undefined);
    props.setOptions(options);
    props.setDirtyOptions();
    props.setDirty();
  };

  const _onLayerClose = () => {
    setLayer(undefined);
  };

  // TODO REVIEW PASTED CODE!
  /* PASTED CODE: START */
  const _reportByLayer = () => {
    let result;
    if (layer) {
      if (layer === 'reportBy') {
        const heading = 'Report By Setting';
        result = (
          <ReportByEditor
            onClose={_onLayerClose}
            onChange={_onReportByApplied}
            heading={heading}
            reportBy={props.options.config.reportBy}
            reportByOptions={props.serviceType.serviceOptions.reportByOptions}
          />
        );
      }
    }
    return result;
  };

  const _onChange = (property, value) => {
    const { options } = props;
    options.config[property] = value;
    props.setOptions(options);
    props.setDirtyOptions();
    props.setDirty();
  };

  const _getBillingPrecisionHelp = (precision = '0') => {
    const example = CurrencyUtils.getNumberString(0.123456789, parseInt(precision));
    return example ? `Example: ${example}` : '';
  };

  const _onReportByEdit = () => {
    setLayer('reportBy');
  };

  const _onTierTypeChange = (tierOption) => {
    const { options } = props;
    // assign tier apiValue...this is a hack since the API doesn't support SINGLE tier type directly
    options.config.tierType = tierOption.apiValue;
    props.setOptions(options);
    props.setDirtyOptions();
    props.setDirty();
    // TierListComponents will automatically check validation.
    if (tierOption.enumKey !== 'MAPPED' && tierOption.enumKey !== 'SINGLE' && tierOption.enumKey !== 'CUSTOM') {
      fetchValidationIfNeeded();
    }
  };

  const _onTierListChanged = (tiersLookupKey, tiers, isModified) => {
    const { options } = props;
    options.config[tiersLookupKey] = tiers;
    props.setOptions(options);
    if (isModified) {
      props.setDirtyOptions();
    }
    props.setDirty();
  };

  const _onPrecisionChange = (event) => {
    const { options } = props;

    if (event) {
      switch (event.target.name) {
        case 'unitPrecision':
        case 'ratePrecision':
          options.config[event.target.name] = event.target.value;

          if (event.target.attributes.hasOwnProperty(('min')) && (!options.config[event.target.name] || +options.config[event.target.name] < +event.target.attributes.min.value)) {
            options.config[event.target.name] = event.target.attributes.min.value;
          }
          if (event.target.attributes.hasOwnProperty(('max')) && (+options.config[event.target.name] > +event.target.attributes.max.value)) {
            options.config[event.target.name] = event.target.attributes.max.value;
          }
          break;
      }
    }

    props.setOptions(options);
    fetchValidationIfNeeded();
    props.setDirtyOptions();
    props.setDirty();
  };

  const _onLocationChange = (value) => {
    _onChange('location', value);
    props.locationTypeChanged(value);
    props.setDirtyOptions();
    props.setDirty();
    fetchValidationIfNeeded();
  };

  /**
   * API interprets SINGLE as CUSTOM...so we have to infer when it's the tierType is custom.
   * we do this by checking the ServiceType tier options to see if they include the SINGLE tier option
   * Note: We should not show both SINGLE and CUSTOM tier options for a service type.
   */
  const _determineTierType = (serviceType) => {
    const tierType = TierOptions.enumValueOf(props.options.config.tierType);
    const hasSingleTierOptions = serviceType.serviceOptions.tierOptions.includes(TierOptions.SINGLE);
    return tierType === TierOptions.CUSTOM && hasSingleTierOptions ? TierOptions.SINGLE : tierType;
  };

  const getSpareDiskConfigField = () => {
    if (props.options.config.hasOwnProperty('spareDiskIncluded')) {
      return (
        <FormField label='Spare disk handling'>
          <CheckBox
            id={IDUtil.getId('SpareDiskCheckBox')}
            name='spareDiskIncluded'
            disabled={props.readOnly}
            label='Include spare disk in usable capacity'
            checked={props.options.config.spareDiskIncluded === true}
            onChange={() => _onChange('spareDiskIncluded', !props.options.config.spareDiskIncluded)}
          />
        </FormField>
      );
    }
  };

  const getIncludeInfrastructureLineItemsField = () => {
    if (props.options.config.hasOwnProperty('includeInfrastructureLineItems')) {
      return (
        <FormField label='HPE infrastructure handling'>
          <CheckBox
            id={IDUtil.getId('IncludeInfrastructureLineItemsCheckBox')}
            name='includeInfrastructureLineItems'
            disabled={props.readOnly}
            label='Include HPE infrastructure line items'
            checked={props.options.config.includeInfrastructureLineItems === true}
            onChange={() => _onChange('includeInfrastructureLineItems', !props.options.config.includeInfrastructureLineItems)}
          />
        </FormField>
      );
    }
  };

  const getChargingUnitsField = () => {
    const { reportingUnits } = props.options.config;
    if (reportingUnits === ReportingUnits.Each.enumKey) {
      return;
    }

    const { chargingUnits } = props.serviceType;
    if (!chargingUnits || chargingUnits.length === 0) {
      return;
    }

    let label = 'Charging units';

    // TOOD???
    switch (props.serviceType.type) {
      case 'COHESITY_LICENSES':
        label = 'License Units (for Storage License Types)';
        break;
    }

    const radioButtons = chargingUnits.map(({ enumKey }) => (
      <RadioButton
        key={enumKey}
        id={`${enumKey}-reporting-units`}
        name={`${enumKey}-reporting-units`}
        label={enumKey}
        checked={reportingUnits === enumKey}
        onChange={() => _onChange('reportingUnits', enumKey)}
      />
    ));

    const editableField = <FormField label={label}>{radioButtons}</FormField>;
    const readOnlyField = <ReadOnlyField label={label} value={reportingUnits} />;

    return props.readOnly ? readOnlyField : editableField;
  };

  const getTierRadioButtons = (serviceType) => {
    const selectedTierOption = _determineTierType(serviceType);
    const { tierOptions } = { ...serviceType.serviceOptions };
    const readOnlyTiers = serviceType.serviceOptions.readOnlyTiers || props.options.config.readOnlyTiers;
    if (tierOptions.length > 1 && !readOnlyTiers) {
      return tierOptions
        .map(tierOption => (
          <CARadioButton
            key={tierOption.enumKey}
            id={`${tierOption.enumKey}-tier`}
            name={`${tierOption.enumKey}-tier`}
            label={tierOption.label}
            checked={selectedTierOption === tierOption}
            tooltip={tierOption.tooltip}
            onChange={() => _onTierTypeChange(tierOption)}
          />
        ));
    }
  };

  const getTierList = (serviceType) => {
    const tierOptionEnum = _determineTierType(serviceType);
    const tierListMap = new Map()
      .set(TierOptions.CUSTOM, CustomTiersList)
      .set(TierOptions.MAPPED, MappedTiersList)
      .set(TierOptions.SINGLE, SingleTierEditor);

    const { tiersLookupKey } = tierOptionEnum || {};
    const tiers = props.options.config[tiersLookupKey] || [];
    const TierListComponent = tierListMap.get(tierOptionEnum);

    // some services provide Tiers from the backend, so the user is shown tiers but should not be able to create any more:
    const readOnlyTiers = serviceType.serviceOptions.readOnlyTiers || props.options.config.readOnlyTiers;

    return TierListComponent ? (
      <TierListComponent
        customer={props.customer}
        options={props.options}
        readOnly={props.readOnly || readOnlyTiers}
        tiers={tiers}
        type={_getResourceTierMatchLabel(serviceType)}
        onChange={(_tiers, isModified) => _onTierListChanged(tiersLookupKey, _tiers, isModified)}
      />
    ) : null;
  };

  /**
   * This is to handle the unique situations, like with SAN, where the component is the resource (port) but it *is not* configurable.
   * In other words, SAN ports are metered but it is included/excluded on the device level not the component level. For this reason
   * the resource/tier match label should use the device name for SAN (switch).
   */
  const _getResourceTierMatchLabel = (serviceType) => {
    try {
      return configurableDeviceAndComponent(serviceType) ? serviceType[serviceType.meteringResource] : serviceType.device;
    } catch (e) {
      console.error(e);
    }
    return 'UNKNOWN MATCH LABEL';
  };

  const getTierField = () => {
    const { serviceType, readOnly, options } = props;
    const { tierOptions } = serviceType.serviceOptions;
    const readOnlyTiers = serviceType.serviceOptions.readOnlyTiers || options.config.readOnlyTiers;
    const formFieldLabel = (readOnlyTiers || readOnly) ? 'Tier' : 'Set Tier to';
    return (tierOptions && tierOptions.length
      ? (
        <FormField label={formFieldLabel}>
          {(!readOnly && !readOnlyTiers) && getTierRadioButtons(serviceType)}
          <Box>
            {getTierList(serviceType)}
          </Box>
        </FormField>
      )
      : false
    );
  };

  const getPrecisionFields = () => {
    const { hidePrecisionFields } = props.serviceType.serviceOptions;
    if (!hidePrecisionFields) {
      const readOnlyInputFn = ({ label, field }) => (
        <ReadOnlyField
          label={label}
          value={props.options.config[field]}
        />
      );
      const editableInputFn = ({ label, field }) => (
        <FormField
          key={field}
          htmlFor={field}
          help={_getBillingPrecisionHelp(props.options.config[field])}
          label={label}
        >
          <TextInput
            type='number'
            name={field}
            value={props.options.config[field]}
            min={0}
            max={9}
            onChange={_onPrecisionChange}
          />
        </FormField>
      );
      return ServicePrecisionOption.enumValues.map(props.readOnly ? readOnlyInputFn : editableInputFn);
    }
  };

  const getLocationRadioButtons = (serviceType) => {
    const { locationOptions } = serviceType.serviceOptions;
    const selectedLocationOption = LocationOption.enumValueOf(props.options.config.location);

    return locationOptions.map(locationOption => (
      <CARadioButton
        key={locationOption.enumKey}
        id={`${locationOption.enumKey}-location`}
        name={`${locationOption.enumKey}-location`}
        label={locationOption.label(serviceType)}
        checked={selectedLocationOption === locationOption}
        onChange={() => _onLocationChange(locationOption.enumKey)}
        tooltip={locationOption.tooltip}
      />
    ));
  };

  const getLocationField = () => {
    const {
      options, serviceType, customer, readOnly,
    } = props;
    const { reportBy, location } = options.config;
    const { locationOptions } = serviceType.serviceOptions;
    const singleLocationOption = locationOptions.length === 1;
    const formFieldLabel = (singleLocationOption || readOnly) ? 'Location' : 'Set Location to';
    const locationEnum = LocationOption.enumValueOf(props.options.config.location);
    const locationLabel = locationEnum ? pluralize.plural(locationEnum.label(serviceType)) : undefined;
    const locationHeading = (
      <Header justify='between' pad='small'>
        <Text size='medium' weight='bold'>{locationLabel}</Text>
      </Header>
    );

    return (locationOptions && locationOptions.length && reportBy !== 'TIER'
      ? (
        <FormField label={formFieldLabel}>
          {(singleLocationOption || readOnly) ? locationHeading : getLocationRadioButtons(serviceType)}
          <LocationsList
            hidden={location !== LocationOption.MAPPED.enumKey}
            locations={customer.locations}
            readOnly={true}
          />
        </FormField>
      ) : false
    );
  };

  const getServicePlans = () => {
    const { options } = props;

    return (
      <FormField label='Service Plans'>
        <CatalogPlanList
          plans={options.config.provisionedPlans}
        />
      </FormField>
    );
  };

  const getServiceBillingMethodField = () => {
    switch (props.serviceType.type) {
      case 'COHESITY_LICENSES':
      case 'SWLIC':
        return <LicenseTypesBillingMethodField readOnly={props.readOnly} setDirty={props.setDirty} />;
      case 'VM':
        return <VMBillingMethodField readOnly={props.readOnly} setDirty={props.setDirty} />;
      case 'NUTANIX':
        return <NutanixBillingMethodField readOnly={props.readOnly} setDirty={props.setDirty} />;
      case 'LINUX_UTILIZATION':
        return <LinuxBillingMethodField readOnly={props.readOnly} setDirty={props.setDirty} />;
      case 'XP':
        return <XPBillingMethodField readOnly={props.readOnly} setDirty={props.setDirty} />;
      case 'WINDOWS_UTILIZATION':
      case 'RHOCP_PERCORE':
        return <WindowsBillingMethodField readOnly={props.readOnly} setDirty={props.setDirty} />;
      case 'ILO_SERVER':
      case 'ILO_PERCORE':
        return (
          <WindowsBillingMethodField
            readOnly={props.readOnly}
            setDirty={props.setDirty}
            noteUtilization='This field should be set to a value between 3% and 5%.  Any values outside of this range would require approval from GreenLake Management.'
            labelUtilization='CPU Utilization Threshold'
          />
        );
      case 'AZURE_STACK':
        return <AzureStackBillingMethodField readOnly={props.readOnly} setDirty={props.setDirty} />;
      case 'GOOGLE_ANTHOS':
        return <GoogleAnthosBillingMethodField readOnly={props.readOnly} setDirty={props.setDirty} />;
      case 'EHR':
        return <EHRBillingMethodField readOnly={props.readOnly} setDirty={props.setDirty} />;
      case 'GPUUTIL':
        return <GpuUtilizationThresholdField readOnly={props.readOnly} setDirty={props.setDirty} />;
    }
  };

  const getReportByField = () => {
    const reportByEnum = ReportBy.enumValueOf(props.options.config.reportBy);
    const { reportByOptions } = props.serviceType.serviceOptions;
    return ((reportByOptions && reportByOptions.length > 0) ? (
      <Box direction='row' margin={{ bottom: 'small' }} gap='small' align='center'>
        <Text weight='bold' size='medium'>{`Reporting by ${reportByEnum.label}`}</Text>
        {!props.readOnly && reportByOptions.length > 1 ? (
          <Button
            icon={<FormEdit />}
            onClick={() => _onReportByEdit()}
            id={IDUtil.getId('ReportByEditButton')}
          />
        ) : ''}
      </Box>
    ) : false);
  };

  return (
    <Box style={{ minWidth: '600px', width: '600px' }} direction='column' flex='grow' overflow='auto' gap='small'>
      {getReportByField()}
      {getTierField()}
      {getPrecisionFields()}
      {getLocationField()}
      {getServiceBillingMethodField()}
      {getChargingUnitsField()}
      {getSpareDiskConfigField()}
      {getIncludeInfrastructureLineItemsField()}
      {getServicePlans()}
      {_reportByLayer()}
    </Box>
  );
};

/* PASTED CODE: START */
const mapStateToProps = ({
  service: {
    details: {
      options, customer, validation,
    },
  },
}) => {
  const serviceType = ServiceTypeStore.getService(options.config.serviceType);
  const { tierOptions, locationOptions } = serviceType.serviceOptions;

  return {
    serviceType,
    tierOptions,
    locationOptions,
    options,
    customer,
    validation,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setOptions,
  locationTypeChanged,
  invalidateValidation,
  setDirtyOptions,
}, dispatch);
/* PASTED CODE: END */

const TierPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    criteria: PropTypes.arrayOf(PropTypes.shape({
      matchAll: PropTypes.bool,
      criteria: PropTypes.arrayOf(PropTypes.shape({
        caseInsensitive: PropTypes.bool,
        field: PropTypes.string,
        operator: PropTypes.string,
      })),
    })),
  }),
);

ServiceOptions.propTypes = {
  serviceType: PropTypes.object,
  serviceOptions: PropTypes.shape({
    tierOptions: PropTypes.arrayOf(PropTypes.string),
    locationOptions: PropTypes.arrayOf(PropTypes.string),
  }),
  locationTypeChanged: PropTypes.func,
  invalidateValidation: PropTypes.func,
  setOptions: PropTypes.func,
  setDirtyOptions: PropTypes.func,
  setDirty: PropTypes.func,
  customer: PropTypes.object,
  options: PropTypes.shape({
    config: PropTypes.shape({
      billingMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      billingMethodCapUsage: PropTypes.bool,
      billingMethodUtilization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      type: PropTypes.string,
      location: PropTypes.string,
      customTiers: TierPropTypes,
      mappedTiers: TierPropTypes,
      reportBy: PropTypes.string,
      tierType: PropTypes.string,
      unitPrecision: PropTypes.number,
      ratePrecision: PropTypes.number,
      spareDiskIncluded: PropTypes.bool,
      includeInfrastructureLineItems: PropTypes.bool,
      reportingUnits: PropTypes.string,
    }),
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceOptions);
