// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

export const queryConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: false,
      cacheTime: 1000,
      retry: false,
    },
  },
};
