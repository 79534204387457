// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, CheckBox, Footer, Paragraph,
} from 'grommet';
import GLBMLayer from '../shared/component/GLBMLayer';
import { useToast } from '../shared/toast/ToastProvider';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import { useAccessApproversQuery, useUserApprovalUpdateMutate } from '../../core';

const EditApprovalPermission = ({ onClose = null, user = {} }) => {
  const [selectedIds, setSelectedIds] = useState(user?.assignedApproverIds || []);
  const { addToast } = useToast();
  const {
    data: approversData,
    isLoading,
    isFetching,
  } = useAccessApproversQuery();
  const list = useMemo(() => (approversData ? approversData.map((el, i) => ({ ...el, index: i })) : []), [approversData]);
  const {
    mutate: updateIdsForApprover,
  } = useUserApprovalUpdateMutate(user?.id, {
    onSuccess: () => {
      onClose();
    },
    onError: (data) => {
      addToast({
        status: 'critical',
        title: 'Approver was not saved',
        message: data.response.data.message,
      });
    },
  });

  const selectApprovalIds = (checked, id) => {
    if (checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      const ids = selectedIds.filter(x => x !== id);
      setSelectedIds([...ids]);
    }
  };

  const getSelectedIds = (id) => {
    if (selectedIds.includes(id)) {
      return true;
    }
    return false;
  };
  const getColumns = () => ([
    {
      header: 'Select',
      render: ({ id }) => (<CheckBox checked={getSelectedIds(id)} onChange={ev => selectApprovalIds(ev.target.checked, id)} />),
    },
    {
      property: 'role',
      header: 'Role',
    },
    {
      property: 'location',
      header: 'Location',
    }
  ]);

  const saveIdsForApprover = () => {
    const payload = {
      'userId': user.id,
      'approverIds': selectedIds
    };

    updateIdsForApprover(payload);
  };
  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      title={`Edit Approval Permission: ${user?.firstName} ${user?.lastName}`}
    >
      <Box
        pad='none'
        style={{ 'width': '600px', 'minWidth': '600px' }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box pad='small' flex={true}>
          <Box direction='row' align='center' margin={{ left: 'small' }}>
            <Paragraph>
              Click the box(es) to the left of the Role/Location combination to make the user an approver for that
              Role/Location.
            </Paragraph>
          </Box>
          <Box border='top' flex={true} pad='small'>
            <GLBMDataTable
              columns={getColumns()}
              data={list || []}
              total={list.length}
              loading={isLoading || isFetching}
            />
          </Box>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button primary={true} label='Save' onClick={saveIdsForApprover} />
          <Button secondary={true} onClick={onClose} label='Cancel' />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

EditApprovalPermission.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
};

export default EditApprovalPermission;
