// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useState } from 'react';
import {
  Box, Button, Footer, Form, FormExtendedEvent, FormField, TextInput,
} from 'grommet';
import GLBMLayer from '../shared/component/GLBMLayer';
import type { CapacityAlertSettingsV2 } from '../../core/types';
import { validateNumber, validateNumber0to100 } from './utils';

type Threshold = CapacityAlertSettingsV2['categories'][number];

interface Props {
  threshold: Threshold
  onClose: () => void
  onChange: (type: string, threshold: Threshold) => void
}

const ThresholdEditor = ({
  threshold: thresholdProp,
  onChange,
  onClose,
}: Props) => {
  const [threshold, setThreshold] = useState(() => ({
    ...thresholdProp,
    pctFree: thresholdProp.pctFree.toString(),
    pctChange: thresholdProp.pctChange.toString(),
  }));

  const onSubmitCb = useCallback(({ value: submitValues }: FormExtendedEvent<typeof threshold>) => {
    onChange(submitValues.id, ({
      ...submitValues,
      pctFree: +submitValues.pctFree,
      pctChange: +submitValues.pctChange,
    }));
  }, [onChange]);

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onClickOutside={onClose}
      onEsc={onClose}
      overlayClose={true}
      title='Edit capacity alert threshold settings'
      subtitle={threshold.displayName}
    >
      <Box flex={true}>
        <Form
          style={{ height: '100%' }}
          value={threshold}
          onChange={setThreshold}
          onSubmit={onSubmitCb}
        >
          <Box fill='vertical' flex={false}>
            <Box flex={true} overflow='auto' pad={{ horizontal: 'small' }}>
              <FormField
                htmlFor='free'
                name='pctFree'
                help='An alert is raised when Free Capacity drops below this percentage'
                label='Percent Free'
                validate={validateNumber0to100}
                required={true}
              >
                <TextInput
                  id='free'
                  name='pctFree'
                />
              </FormField>
              <FormField
                htmlFor='change'
                name='pctChange'
                help='An alert is raised when Free Capacity changes by more than this percentage'
                label='Percent Change'
                validate={validateNumber}
                required={true}
              >
                <TextInput
                  id='change'
                  name='pctChange'
                />
              </FormField>
            </Box>
            <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
              <Footer flex={false} justify='start' gap='small'>
                <Button primary={true} type='submit' label='OK' />
                <Button label='Cancel' secondary={true} onClick={onClose} />
              </Footer>
            </Box>
          </Box>
        </Form>
      </Box>
    </GLBMLayer>
  );
};

export default ThresholdEditor;
