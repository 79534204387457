// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Tip } from 'grommet';

// Include wrapping Tip if show is true, otherwise just render the children
function ConditionalTip({ children, show, ...rest }) {
  return show ? <Tip {...rest}>{children}</Tip> : children;
}
ConditionalTip.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
};

function Legend({ items }) {
  return (
    <Box
      data-e2e='chartLegend'
      fill='horizontal'
      direction='row'
      justify='center'
      align='center'
      margin={{ bottom: 'small' }}
      flex={false}
    >
      {items.map((item) => {
        const description = item.tip;
        const shape = item.squareIcon ? (
          <Box
            width='20px'
            height='20px'
            background={item.color}
            style={item.squareStyle}
          />
        ) : (
          <Box
            width='20px'
            height='0'
            border={[
              {
                side: 'top',
                color: item.color,
                size: `${item.lineWidth || 2}px`,
                style: item.lineStyle || 'solid',
              },
            ]}
          />
        );

        return (
          <ConditionalTip
            content={description}
            key={item.key}
            show={!!description}
          >
            <Box
              flex={false}
              direction='row'
              align='center'
              wrap={false}
              margin={{ right: 'medium' }}
              overflow='hidden'
              key={`legend${item.key}`}
            >
              {shape}
              <Text margin={{ horizontal: 'xsmall' }}>
                {item.key}
                {item.value !== undefined && `: ${item.value}`}
              </Text>
            </Box>
          </ConditionalTip>
        );
      })}
    </Box>
  );
}

Legend.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    tip: PropTypes.string,
    value: PropTypes.string,
    squareIcon: PropTypes.bool,
    squareStyle: PropTypes.object,
    lineStyle: PropTypes.string,
    lineWidth: PropTypes.number,
  })).isRequired,
};

export default Legend;
