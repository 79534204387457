// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { merge, reduce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import {
  Anchor,
  Box, Button, Footer, Spinner, Text,
} from 'grommet';
import { v1 as uuid } from 'uuid';
import { StatusCritical, Trash } from 'grommet-icons';
import PropTypes from 'prop-types';
import GLBMLayer from '../shared/component/GLBMLayer';
import {
  useUsageFileUploadMutate,
  useUsageFilenameValidationQuery,
} from '../../core';
import { StatusIcon } from '../shared/component/StatusIcon';
import { pagePermissions } from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';

const UsageUpload = ({ onClose = null, message = undefined }) => {
  const navigate = useNavigate();
  const { hasPermissions } = usePermissionChecker();

  const [isUploading, setIsUploading] = useState(false);
  const [errorUpload, setErrorUpload] = useState();
  const [files, setFiles] = useState([]);
  const [policies, setPolicies] = useState({});

  // validate files:
  const queries = useUsageFilenameValidationQuery(files, {
    combine: results => ({
      data: results.map(result => result.data),
      pending: results.some(result => result.isPending),
      success: results.some(result => result.isSuccess),
      error: results.some(result => result.isError),
    }),
  });

  useEffect(() => {
    if (!queries.pending && queries.success) {
      const merged = reduce(queries.data, (acc, data) => {
        if (data) {
          acc[data.fileName] = data;
        }
        return acc;
      }, {});
      setPolicies(prevState => ({
        ...prevState,
        ...merged,
      }));
    }
  }, [queries.pending, queries.success, queries.data]);

  const _onDrop = (selectedFiles) => {
    const fileNames = files.map(file => file.name);
    const newFiles = selectedFiles.filter(file => !fileNames.length || fileNames.indexOf(file.name) === -1);
    const updatedFiles = files.concat(newFiles);
    setFiles(updatedFiles);
  };

  const gotoAdministrationTab = () => {
    navigate('/administration', { state: { tab: 'pendingFiles' } });
  };

  const _renderFiles = () => {
    const items = [];
    const finished = files.filter(file => (policies[file.name] && policies[file.name].uploaded)).length === files.length;

    files.forEach((file, index) => {
      const error = policies[file.name] && (!policies[file.name].valid || !policies[file.name].uploadAllowed);
      const errorMsg = error ? (!policies[file.name].valid ? 'Invalid File Name' : `Not allowed to upload data for billing account ${policies[file.name].accountId}`) : '';
      const loading = (!policies[file.name]);
      items.push(
        <Box key={uuid()} direction='row' alignContent='center' justify='center' flex={false}>
          <Box direction='column' justify='center' alignContent='center' flex='grow'>
            <Box justify='between' alignContent='center' direction='column' size='full' width={{ max: '480px' }}>
              <span><strong>{file.name}</strong></span>
              {!error && !loading && (
              <span>
                <em>
                  {file.size.toLocaleString()}
                  {' '}
                  bytes
                </em>
              </span>
              )}
              {error && <span className='critical'><em>{(policies[file.name].uploaded) ? 'Failed to Upload' : errorMsg}</em></span>}
              {loading && <span><em>Validating filename...</em></span>}
            </Box>
          </Box>
          {!isUploading && !loading && (
            <Box height={{ max: '22px' }}>
              <Button
                icon={<Trash />}
                href='#'
                onClick={() => {
                  const updatedFiles = [...files];
                  updatedFiles.splice(index, 1);
                  setFiles(updatedFiles);
                }}
              />
            </Box>
          )}
          {(isUploading || loading) && !finished && <Spinner />}
          <Box width={{ min: '24px' }}>
            {!loading && (policies[file.name].failed || error) && <StatusCritical color='status-critical' />}
            {!error && !loading && policies[file.name].uploaded && !policies[file.name].failed && <StatusIcon value='ok' />}
          </Box>
        </Box>
      );
    });

    return items;
  };

  const _onFinish = () => {
    onClose();
  };

  const {
    mutateAsync: uploadFile,
  } = useUsageFileUploadMutate({
    // This will run whenever the mutation is successful
    onSuccess: (response, payload) => {
      const updatedPolicy = policies[payload.name];
      updatedPolicy.uploaded = true;
      const merged = merge(policies, {
        [payload.name]: updatedPolicy,
      });
      setPolicies(prevState => ({
        ...prevState,
        ...merged,
      }));
    },
    // This will run whenever the mutation encounters an error
    onError: (error) => {
      // Handle the error as needed
      console.error('Mutation error:', error);
    },
  });

  const _uploadFiles = async () => {
    try {
      setIsUploading(true);
      setErrorUpload(undefined);
      // Perform multiple mutations
      const mutationPromises = files.map(file => uploadFile(file));

      // Wait for all mutation promises to resolve
      await Promise.all(mutationPromises);
    } catch (error) {
      setIsUploading(false);
      setErrorUpload(error);
    }
  };

  const _renderUploadButton = () => {
    const validCount = files.filter(file => (policies[file.name] && policies[file.name].valid)).length === files.length;
    const uploadAllowedCount = files.filter(file => (policies[file.name] && policies[file.name].valid && policies[file.name].uploadAllowed)).length === files.length;
    const loading = files.filter(file => policies.hasOwnProperty(file.name)).length !== files.length;
    const finished = files.length && files.filter(file => (policies[file.name] && policies[file.name].uploaded)).length === files.length;
    const successfulUploads = Object.keys(policies).map(x => policies[x]).filter(policy => (policy.uploaded && !policy.failed)).length;

    if (finished) {
      return (<Button label={`Uploaded ${successfulUploads} of ${files.length} files`} onClick={_onFinish} />);
    }
    if (isUploading) {
      return (<Button primary={true} label='Uploading...' />);
    }
    if (files.length && validCount && uploadAllowedCount) {
      return (<Button primary={true} onClick={() => _uploadFiles()} label='Upload' />);
    }
    return (
      <div>
        <Button primary={true} label='Upload' />
        {!validCount && !loading && <span className='critical'>&nbsp;&nbsp;&nbsp;Please remove invalid files before uploading.</span>}
      </div>
    );
  };

  let dropzoneRef;
  const finished = files.length && files.filter(file => (policies[file.name] && policies[file.name].uploaded)).length === files.length;
  // if no alert:
  const userMessage = (!finished)
    ? message || 'Select the files that contain the missing data. Data for dates prior to the six most recent calendar months is ignored.'
    : 'Your files have been uploaded and are being processed. It will take up to 2 hours for alerts, usage, and cost to reflect this new data.';
  return (
    <GLBMLayer
      position='right'
      flush={true}
      closer={true}
      onClose={_onFinish}
      onEsc={_onFinish}
      onClickOutside={_onFinish}
      overlayClose={true}
      title='Upload missing data'
      full='vertical'
    >
      <Box
        pad='medium'
        direction='column'
        flex={true}
        fill='vertical'
        style={{ 'maxWidth': '600px', 'width': '600px' }}
      >
        <Box flex={false} pad='medium'>
          <Text>{userMessage}</Text>
        </Box>
        {!finished && !isUploading
            && (
              <Box flex={false}>
                <Dropzone className='file-upload-target' activeClassName='file-upload-target-active' rejectClassName='file-upload-target-reject' onDrop={_onDrop} ref={(n) => { dropzoneRef = n; }}>
                  {({ getRootProps, getInputProps }) => (
                    <Box align='center' pad={{ vertical: 'medium' }} {...getRootProps()}>
                      Try dropping files here, or click to select files to upload.
                      <input {...getInputProps()} />
                    </Box>
                  )}
                </Dropzone>
                <Box pad={{ vertical: 'small' }} basis='full'>
                  <Button fill={true} label='Select files' onClick={() => { dropzoneRef.open(); }} />
                </Box>
              </Box>
            )}
        <Box
          direction='column'
          flex={true}
          fill='vertical'
          overflow='auto'
        >
          {_renderFiles()}
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            {_renderUploadButton()}
            <Button label='Cancel' secondary={true} onClick={onClose} />
            {errorUpload && <Box className='critical'>{errorUpload.message}</Box>}
          </Box>
          <Box margin={{ right: 'small' }}>
            {hasPermissions(pagePermissions.administration.tabs.pendingFiles) && !!finished && (
              <Anchor
                onClick={() => { gotoAdministrationTab(); }}
              >
                Take me to the Pending Files Page
              </Anchor>
            )}
          </Box>
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

UsageUpload.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.string,
};

export default UsageUpload;

export const userPath = false;
