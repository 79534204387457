// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useErrorToast } from '../../components/shared/hooks';
import {
  executeGet
} from '../api';
import { ApiContext } from '../../AppContext';

export const useFetchActionsQuery = (options) => {
  // Temporary query param for forwards-compatibility.
  // Remove it after migration is complete.
  const path = `${useContext(ApiContext)('administration.actions.path')}?newStyle=true`;
  return useErrorToast(useQuery({
    queryKey: ['GLBM:FETCH-ACTIONS'],
    queryFn: () => executeGet(path)
  }, options), 'Fetch Actions Error');
};
