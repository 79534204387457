// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CheckBox,
  Footer,
  FormField,
  Text,
  TextInput,
} from 'grommet';
import moment from 'moment';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import GLBMLayer from '../../shared/component/GLBMLayer';
import DateTime from '../../shared/component/DateTime';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import { Feed } from '../../../core/types';

const DATE_FORMAT = 'YYYY-MM-DD';

const Field = ({
  applyValue, setApplyValue, input, ...rest
}: {
  applyValue: boolean,
  setApplyValue: React.Dispatch<React.SetStateAction<boolean>>,
  input: React.ReactElement
} & React.ComponentProps<typeof FormField>) => (
  <Box direction='row' align='end' gap='small'>
    <Box pad={{ bottom: 'xsmall' }} margin={rest.error ? { bottom: '22px' } : undefined} flex={false}>
      <CheckBox a11yTitle={`Set ${rest.label}`} checked={applyValue} onChange={({ target: { checked } }) => setApplyValue(checked)} />
    </Box>
    <Box flex={true}>
      <FormField {...rest} disabled={!applyValue}>
        {React.cloneElement(input, { disabled: !applyValue })}
      </FormField>
    </Box>
  </Box>
);

// eslint-disable-next-line @typescript-eslint/comma-dangle
const getUniqValue = <P extends keyof Feed,>(objs: Feed[], property: P, defaultValue: Feed[P]): Feed[P] => {
  const uniqValues: Feed[P][] = uniq(map(objs, property));
  if (uniqValues.length === 1) {
    return uniqValues[0];
  }
  return defaultValue;
};

interface Props {
  feeds: Feed[]
  onSubmit: (feeds: Feed[]) => void
  onClose: () => void
}

const BulkFeedEditor = ({
  feeds, onSubmit: onSubmitProp, onClose,
}: Props) => {
  const [applyExclude, setApplyExclude] = useState(false);
  const [exclude, setExclude] = useState(() => getUniqValue(feeds, 'exclude', false));
  const [applyExcludeBefore, setApplyExcludeBefore] = useState(false);
  const [excludeBefore, setExcludeBefore] = useState(() => getUniqValue(feeds, 'excludeBefore', ''));
  const [applyExcludeAfter, setApplyExcludeAfter] = useState(false);
  const [excludeAfter, setExcludeAfter] = useState(() => getUniqValue(feeds, 'excludeAfter', ''));
  const [applyNotes, setApplyNotes] = useState(false);
  const [notes, setNotes] = useState(() => getUniqValue(feeds, 'notes', ''));
  const [notesError, setNotesError] = useState<string>();

  const onSubmit = useCallback(() => {
    if (((applyExcludeBefore && excludeBefore) || (applyExcludeAfter && excludeAfter)) && (!applyNotes || !notes)) {
      setNotesError('Notes are required when exclude date is set');
      return;
    }
    setNotesError('');

    const updatedValues: Partial<Feed> = {};
    if (applyExclude) {
      updatedValues.exclude = exclude;
    }
    if (applyExcludeBefore) {
      updatedValues.excludeBefore = excludeBefore;
    }
    if (applyExcludeAfter) {
      updatedValues.excludeAfter = excludeAfter;
    }
    if (applyNotes) {
      updatedValues.notes = notes;
    }

    onSubmitProp(map(feeds, (feed: Feed) => ({
      ...feed,
      ...updatedValues,
    })));
  }, [applyExclude, applyExcludeAfter, applyExcludeBefore, applyNotes, exclude, excludeAfter, excludeBefore, feeds, notes, onSubmitProp]);

  const updateNotes = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  }, []);

  const updateExclude = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setExclude(!e.target.checked);
  }, []);

  const updateExcludeBefore = useCallback((newExcludeBefore: string) => {
    if (moment(newExcludeBefore, DATE_FORMAT, true).isValid() || !newExcludeBefore) {
      // ensure that the exclude after date is later than exclude before
      if (newExcludeBefore && excludeAfter && applyExcludeAfter && moment(newExcludeBefore).isAfter(moment(excludeAfter))) {
        setExcludeAfter(newExcludeBefore);
      }
      setExcludeBefore(newExcludeBefore);
    }
  }, [excludeAfter, applyExcludeAfter]);

  const updateExcludeAfter = useCallback((newExcludeAfter: string) => {
    if (moment(newExcludeAfter, DATE_FORMAT, true).isValid() || !newExcludeAfter) {
      // ensure that the exclude before date is earlier than exclude after
      if (newExcludeAfter && excludeBefore && applyExcludeBefore && moment(excludeBefore).isAfter(moment(newExcludeAfter))) {
        setExcludeBefore(newExcludeAfter);
      }
      setExcludeAfter(newExcludeAfter);
    }
  }, [applyExcludeBefore, excludeBefore]);

  const renderContextDetails = () => (
    <GLBMNameValueList
      title='Selected Feeds'
      data={[
        { label: 'Count', value: feeds.length },
        {
          label: 'Feeds',
          value: (
            <Box gap='xsmall' height={{ max: 'medium' }} overflow='auto' data-testid='selected-feeds'>
              {/* eslint-disable-next-line react/no-unstable-nested-components */}
              {map(feeds, ({ feed }) => <Text>{feed}</Text>)}
            </Box>
          )
        },
      ]}
      pairProps={{ direction: 'column' }}
      valueProps={{ width: 'medium' }}
    />
  );

  return (
    <GLBMLayer
      position='right'
      onClose={onClose}
      onClickOutside={onClose}
      onEsc={onClose}
      closer={true}
      flush={true}
      overlayClose={true}
      full='vertical'
      title='Edit Feeds'
    >
      <Box
        pad='none'
        direction='column'
        flex={true}
        fill='vertical'
        overflow='auto'
      >
        <Box margin={{ horizontal: 'medium' }} flex={false} width='medium'>
          {renderContextDetails()}
          <Box pad={{ vertical: 'medium' }}>
            <Field
              label='Include'
              applyValue={applyExclude}
              setApplyValue={setApplyExclude}
              htmlFor='input-exclude'
              input={(<CheckBox id='input-exclude' name='exclude' toggle={true} checked={!exclude} onChange={updateExclude} />)}
            />

            <Field
              label='Exclude Before'
              help='(Will drop all usage prior to this date)'
              applyValue={applyExcludeBefore}
              setApplyValue={setApplyExcludeBefore}
              htmlFor='input-excludeBefore'
              input={(
                <DateTime
                  id='input-excludeBefore'
                  name='excludeBefore'
                  format={DATE_FORMAT}
                  value={excludeBefore}
                  onChange={updateExcludeBefore}
                />
              )}
            />

            <Field
              label='Exclude After'
              help='(Will drop all usage following this date)'
              htmlFor='input-excludeAfter'
              input={(
                <DateTime
                  id='input-excludeAfter'
                  name='excludeAfter'
                  format={DATE_FORMAT}
                  value={excludeAfter}
                  onChange={updateExcludeAfter}
                />
              )}
              applyValue={applyExcludeAfter}
              setApplyValue={setApplyExcludeAfter}
            />

            <Field
              label='Notes'
              help='(limit of 255 characters)'
              error={notesError}
              htmlFor='input-notes'
              input={(
                <TextInput
                  id='input-notes'
                  name='notes'
                  maxLength={254}
                  value={notes}
                  onChange={updateNotes}
                />
              )}
              applyValue={applyNotes}
              setApplyValue={setApplyNotes}
            />
          </Box>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button label='OK' primary={true} onClick={onSubmit} />
          <Button label='Cancel' secondary={true} onClick={onClose} />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

export default BulkFeedEditor;
