// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  Box, Button, Footer, Paragraph
} from 'grommet';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import GLBMLayer from '../../shared/component/GLBMLayer';
import { StatusIcon } from '../../shared/component/StatusIcon';
import { validatePartnerSelection } from './handler';
import PartnerRelationshipForm from './PartnerRelationshipForm';

const PartnerRelationshipsDialog = ({
  onCancel,
  onSubmit,
  customer: initialCustomer,
}) => {
  const [errors, setErrors] = useState({});
  const [customer, setCustomer] = useState({ ...initialCustomer });

  const onSubmitHandler = () => {
    const {
      resellerPartnerId,
      distributorPartnerId,
      dealType,
    } = { ...customer };
    const [hasErrors, newErrors] = validatePartnerSelection(resellerPartnerId, distributorPartnerId, dealType);

    if (hasErrors) {
      setErrors(newErrors);
      return;
    }

    onSubmit(customer);
  };

  const onDealTypeChangeHandler = (dealType) => {
    const updatedCustomer = { ...customer };
    const {
      resellerPartnerId,
      distributorPartnerId,
    } = { ...updatedCustomer };

    updatedCustomer.dealType = dealType;

    const [, newErrors] = validatePartnerSelection(resellerPartnerId, distributorPartnerId, dealType);

    setCustomer(updatedCustomer);
    setErrors(newErrors);
  };

  const onPartnerChangeHandler = (name, isMultiple, value) => {
    const updatedCustomer = { ...customer };
    const attribute = name;
    switch (attribute) {
      case 'name':
        updatedCustomer[attribute] = value;
        break;
      default:
        updatedCustomer[attribute] = value;
    }

    const {
      resellerPartnerId,
      distributorPartnerId,
      dealType,
    } = { ...updatedCustomer };
    const [, newErrors] = validatePartnerSelection(resellerPartnerId, distributorPartnerId, dealType);
    setCustomer(updatedCustomer);
    setErrors(newErrors);
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onCancel}
      onEsc={onCancel}
      onClickOutside={onCancel}
      title='Edit Partner Relationship'
    >
      <Box
        flex={true}
        direction='column'
        style={{
          'maxWidth': '500px',
          'width': '500px',
        }}
        pad='medium'
      >
        <Box direction='row' align='center'>
          <StatusIcon value='warning' size='medium' />
          <Box margin={{ left: 'small' }}>
            <Paragraph>
              Any changes to partners will reset all downstream rates. Please
              contact the partner to re-enter the rates after the change.
            </Paragraph>
          </Box>
        </Box>
        <PartnerRelationshipForm
          onPartnerChange={onPartnerChangeHandler}
          onDealTypeChange={onDealTypeChangeHandler}
          readOnly={false}
          customer={customer}
          errors={errors}
        />
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start'>
          <Button
            label='Apply'
            primary={true}
            onClick={onSubmitHandler}
          />
          <Button
            label='Cancel'
            onClick={onCancel}
            secondary={true}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

PartnerRelationshipsDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    dealType: PropTypes.string,
    resellerPartnerId: PropTypes.string,
    distributorPartnerId: PropTypes.string,
  }).isRequired,
};

export default PartnerRelationshipsDialog;
