// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { ValueTypes } from '../../services';

export default {

  createColumns: (serviceType, contextColumns) => {
    const columns = [];

    function getQuickFilterText(params) {
      return params.value;
    }

    // eslint-disable-next-line no-restricted-globals
    function resolve(path, obj = self, separator = '.') {
      const properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    }

    function valueGetter(params) {
      let value = resolve(params.colDef.field, params.data);
      switch (params.colDef.type) {
        case 'numericColumn':
          value = parseInt(value, 10);
          break;
        default:
      }
      return value;
    }

    function gridType(type) {
      switch (type) {
        case ValueTypes.NUMERIC: return 'numericColumn';
        default:
          return '';
      }
    }

    for (let i = 0; i < contextColumns.length; i += 1) {
      const col = contextColumns[i];

      columns.push({
        field: col.field(serviceType),
        headerName: col.header(serviceType),
        type: gridType(col.type(serviceType)),
        sortable: true,
        getQuickFilterText,
        valueGetter,
        suppressMenu: true,
      });
    }

    return columns;
  },

};
