// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class CapacityOption extends Enumify {
  static NONE = new CapacityOption();

  static READ_ONLY = new CapacityOption();

  static REQUIRE_INSTALLED_CAPACITY = new CapacityOption();

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()
}
