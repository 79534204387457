// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import styled from 'styled-components';

import { Menu } from 'grommet';

export const AnchorStyledMenu = styled(Menu)`
  .grommetux-button__label {
    font-size: 16px;
    text-decoration: underline;
  }
`;
