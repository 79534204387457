// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';

import {
  Box, Button, Footer, FormField, TextInput,
} from 'grommet';
import { FormAdd } from 'grommet-icons';
import PropTypes from 'prop-types';
import GLBMLayer from '../shared/component/GLBMLayer';
import IDUtil from '../shared/util/IDUtil';

const LocationEditor = ({
  location: initialLocation,
  heading,
  onChange,
  onClose,
  locations = [],
}) => {
  const [location, setLocation] = useState(initialLocation);
  const [errors, setErrors] = useState({});

  const onSubmit = (addAnother) => {
    const addedLocation = { id: location.id || undefined, name: location.name };
    let noErrors = true;
    if (!addedLocation.name) {
      errors.name = 'Required';
      noErrors = false;
    }
    const index = locations.findIndex(item => item.name === addedLocation.name);
    const otherNames = locations.map(x => x.name);

    if (otherNames && otherNames.length) {
      if ((index > -1 && (!locations[index].isEditing || addAnother)) && otherNames.indexOf(addedLocation.name) > -1) {
        errors.name = 'Duplicate';
        noErrors = false;
      }
    }

    if (noErrors) {
      const submitErrors = onChange(addedLocation, addAnother);
      if (submitErrors) {
        setErrors(prevState => ({
          ...prevState,
          ...submitErrors,
        }));
      }
    } else {
      setErrors(prevState => ({
        ...prevState,
        ...errors,
      }));
    }
  };

  const onChangeFn = (event) => {
    const attribute = event.target.getAttribute('name');
    const { value } = event.target;
    location[attribute] = value;
    delete errors[attribute];
    setLocation(prevState => ({
      ...prevState,
      ...location,
    }));
    setErrors(prevState => ({
      ...prevState,
      ...errors,
    }));
  };

  return (
    <GLBMLayer
      position='right'
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      full='vertical'
      title={heading}
    >
      <Box pad='medium' flex={true} width='medium'>
        <FormField
          key='locationEditorId'
          htmlFor='locationEditorId'
          label='Name'
          error={errors.name}
        >
          <TextInput
            id='locationId'
            name='name'
            value={location.name}
            onChange={onChangeFn}
          />
        </FormField>
      </Box>
      <Footer pad='small' justify='between' direction='row' border='top'>
        <Box direction='row' gap='small'>
          <Button
            label='OK'
            id={IDUtil.getId('LocationEditorOKButton')}
            primary={true}
            onClick={() => onSubmit(false)}
          />
          <Button
            label='Cancel'
            id={IDUtil.getId('LocationEditorCancelButton')}
            secondary={true}
            onClick={onClose}
          />
        </Box>
        <Button
          plain={true}
          icon={<FormAdd />}
          label='Add Another'
          onClick={() => onSubmit(true)}
          id={IDUtil.getId('LocationEditorAddAnotherButton')}
          a11yTitle='Add Another Location'
        />
      </Footer>
    </GLBMLayer>
  );
};

LocationEditor.propTypes = {
  heading: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
  }).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    isEditing: PropTypes.bool,
  })),
};

export default LocationEditor;
