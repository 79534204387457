// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Text } from 'grommet';
import Highlight from 'react-highlighter';
import { usePermissionChecker } from '../hooks';

const OptionalAnchor = ({
  permissions,
  highlight = '',
  children,
  onClick,
  disabled = false,
  ...rest
}) => {
  const { hasPermissions } = usePermissionChecker();
  const allowed = useMemo(() => !disabled && hasPermissions(permissions), [disabled, permissions, hasPermissions]);

  if (allowed) {
    return (
      <Anchor {...rest} onClick={onClick}>
        {children}
      </Anchor>
    );
  }
  return (
    <Text {...rest}>
      <Highlight search={highlight}>
        {children}
      </Highlight>
    </Text>
  );
};

OptionalAnchor.propTypes = {
  permissions: PropTypes.array.isRequired,
  highlight: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default OptionalAnchor;
