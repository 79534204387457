// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import {
  INVALIDATE_SERVICE_METERS,
  RECEIVE_SERVICE_METERS,
  REQUEST_SERVICE_METERS,
  UPDATE_SERVICE_METERS_FILTERS,
} from './ServiceMeterActions';

export function serviceMeterList(state = {
  isFetching: false,
  didInvalidate: true,
  items: [],
  total: 0,
  filters: {
    list: {
      serviceType: undefined, searchText: '', sort: { property: 'name', direction: 'asc', external: true },
    },
  },
  error: undefined,
}, action) {
  switch (action.type) {
    case INVALIDATE_SERVICE_METERS:
      return {
        ...state,
        didInvalidate: true,
        items: [],
        total: 0
      };
    case REQUEST_SERVICE_METERS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        filters: action.filters,
        items: [],
        total: 0
      };
    case UPDATE_SERVICE_METERS_FILTERS:
      return { ...state, filters: action.filters };
    case RECEIVE_SERVICE_METERS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        total: action.items.length,
        lastUpdated: action.receivedAt,
        error: action.error
      };
    default:
      return state;
  }
}
