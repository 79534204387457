// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

function getFilterType(type) {
  switch (type) {
    case 'decimal':
    case 'integer':
      return 'agNumberColumnFilter';
    case 'date':
    case 'timestamp':
      return 'agDateColumnFilter';
    case 'string':
    default:
      return 'agTextColumnFilter';
  }
}

function getType(type) {
  switch (type) {
    case 'decimal':
    case 'integer':
    case 'currency':
      return 'numericColumn';
    default:
      return '';
  }
}

function getColumn(colDef) {
  return {
    field: colDef.id,
    headerName: colDef.name,
    width: 200,
    sortable: true,
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: getFilterType(colDef.type),
    type: getType(colDef.type),
  };
}

const ProcessedFilesUtil = {

  getGridData(data) {
    return {
      cols: this.createCols(data ? data.columns : []),
      rows: this.createRows(data ? data.data : []),
      gridOptions: {
        // enable sorting on all columns by default
        defaultColDef: {
          sortable: true,
          flex: 1,
          minWidth: 100,
          resizable: true,
          floatingFilter: true,
        },
        enableFilter: true,
        animateRows: true,
        enableSorting: true,
      },
    };
  },
  createCols: (colDefs) => {
    if (!colDefs || colDefs.length === 0) {
      return [];
    }

    return colDefs?.reduce(
      (columns, colDef) => {
        const groupIndex = columns.map(e => e.headerName).indexOf(colDef.group);
        if (groupIndex === -1) {
          columns.push({ headerName: colDef.group, children: [getColumn(colDef)] });
        } else {
          columns[groupIndex].children.push(getColumn(colDef));
        }
        return columns;
      },
      [],
    );
  },

  createRows: (dataRows) => {
    const rows = [];

    if (!dataRows || dataRows.length === 0) {
      return rows;
    }

    dataRows.forEach((d) => {
      rows.push(d);
    });

    return rows;
  },
  getStages: (tenant) => {
    if (tenant && tenant !== 'master') {
      if (tenant === 'customer') {
        return [
          { label: 'Pre Load', value: 'preload' }];
      }
      return [
        { label: 'Pre Charge', value: 'precharge' },
        { label: 'Pre Load', value: 'preload' }];
    }
    return [
      { label: 'Pre Config', value: 'preconfig' },
      { label: 'Pre Charge', value: 'precharge' },
      { label: 'Pre Load', value: 'preload' }];
  }
};
export default ProcessedFilesUtil;
