// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import {
  Box, Button, Footer, TextArea,
} from 'grommet';
import PropTypes from 'prop-types';
import GLBMLayer from '../../shared/component/GLBMLayer';

const RejectAccessRequest = ({
  onClose, onSave, setRejectionComment = undefined, data = undefined
}) => (
  <GLBMLayer
    flush={true}
    overlayClose={true}
    margin='xlarge'
  >
    <Box margin='medium'>
      Please add a note about why the request was rejected (optional):
    </Box>
    <Box direction='column'>
      <Box
        flex={true}
        pad={{ horizontal: 'small' }}
        direction='column'
        fill='vertical'
        gap='xsmall'
      >
        <TextArea
          style={{ resize: 'none' }}
          overflow='auto'
          size='small'
          onChange={event => setRejectionComment(event.target.value)}
          placeholder='Enter comment'
        />
      </Box>
      <Box border='top' pad='small' margin={{ top: 'small' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button
            label='Save'
            key='saveButton'
            primary={true}
            onClick={() => onSave(data)}
          />
          <Button
            label='Close'
            key='closeButton'
            secondary={true}
            onClick={() => onClose()}
          />
        </Footer>
      </Box>
    </Box>
  </GLBMLayer>
);

RejectAccessRequest.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setRejectionComment: PropTypes.func,
  data: PropTypes.object,
};

export default RejectAccessRequest;
