// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect } from 'react';

import {
  Box, Button, Text,
} from 'grommet';
import { Close } from 'grommet-icons';
import PropTypes from 'prop-types';
import { StatusIcon } from './StatusIcon';
import GLBMLayer from './GLBMLayer';

const Toast = ({
  status,
  children,
  open,
  onClose = undefined,
}) => {
  const [show, setShow] = React.useState(open);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
      if (onClose) {
        onClose();
      }
    }, 3000);
  }, [open]);

  return show && (
    <GLBMLayer
      position='top'
      modal={false}
      full='horizontal'
      onEsc={() => setShow(false)}
      onClickOutside={() => setShow(false)}
    >
      <Box
        pad='medium'
        border={true}
        direction='row'
        align='center'
        justify='between'
      >
        <Box direction='row' align='center' gap='small'>
          <StatusIcon value={status} />
          <Text>{children}</Text>
        </Box>
        <Button
          icon={<Close />}
          onClick={() => setShow(false)}
        />
      </Box>
    </GLBMLayer>
  );
};

Toast.propTypes = {
  status: PropTypes.string,
  children: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Toast;
