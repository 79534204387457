// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { indexOf } from 'lodash/array';
import filter from 'lodash/filter';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, {
  useCallback, useMemo, useState
} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Box, Notification, Tab, Tabs, Text,
} from 'grommet';
import { useLocation } from 'react-router-dom';
import { pagePermissions } from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import ActionsList from './Actions/ActionsList';
import { useCustomerActionsQuery } from '../../core';

import {
  updateAdminBillingAccountFiltersAndSession,
} from './redux/AdministrationActions';
import ProcessedFilesPage from './ProcessedFiles/ProcessedFilesPage';
import CustomersOption from './components/CustomerOption';
import ApproversList from './ApproversList/ApproversList';
import GLBMHeading from '../shared/component/GLBMHeading';
import PendingFiles from './PendingFiles/PendingFiles';
import ExtractPage from './Extract/ExtractPage';
import EmailList from './Emails/EmailList';
import ListDemoData from './DemoDataGeneration/ListDemoData';
import CustomerSelector from '../shared/component/CustomerSelector';

const AdministrationPage = ({
  customerActions = undefined,
  updateAdminBillingAccountFiltersAndSession: updateFn
}) => {
  const { state } = useLocation();

  const { hasPermissions } = usePermissionChecker();
  const visibleTabs = useMemo(() => (
    filter(
      ['processedFiles', 'billingAccounts', 'extract', 'approvers', 'pendingFiles', 'mockData', 'emails', 'actions'],
      k => hasPermissions(pagePermissions.administration.tabs[k])
    )
  ), [hasPermissions]);

  const [options, setOptions] = useState({
    selectedCustomer: customerActions.filters.customerId,
    selectedCustomerName: undefined,
  });
  const [activeTab, setActiveTab] = useState(() => {
    if (state) {
      const found = indexOf(visibleTabs, state?.tab);
      if (found >= 0) {
        return found;
      }
    }
    return 0;
  });
  const [filters] = useState(customerActions.filters);
  const [response, setResponse] = useState();

  const {
    data: customerActionsItems,
  } = useCustomerActionsQuery({
    enabled: hasPermissions(pagePermissions.administration.tabs.billingAccounts)
  });

  const CustomerElements = useMemo(() => {
    const customerElements = [];
    const actions = customerActionsItems || [];

    actions.sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB)).forEach((option) => {
      customerElements.push(<CustomersOption
        key={option.name}
        option={option}
        context={options}
      />);
    });

    return (
      <Box direction='column' data-e2e='jobElement'>
        {customerElements}
      </Box>
    );
  }, [customerActionsItems, options]);

  const onToastClose = () => {
    setResponse(undefined);
  };

  const renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Notification
          toast={true}
          status={response.status}
          title={response.title}
          message={response.message}
          onClose={() => onToastClose()}
        />
      );
    }
    return message;
  };

  const onSelectCustomer = useCallback((option) => {
    options.selectedCustomer = option;
    const newFilters = { ...filters, customerId: option };

    setOptions(prevState => ({
      ...prevState,
      ...options,
    }));

    updateFn(newFilters);
  }, [filters, options, updateFn]);

  const tabs = useMemo(() => ({
    processedFiles: (
      <Tab title='Processed Files' key='processed_files'>
        <Box flex={true} direction='column' fill='vertical' overflow='auto' height='full'>
          <ProcessedFilesPage />
        </Box>
      </Tab>
    ),
    billingAccounts: (
      <Tab title='Billing Accounts' key='billing_accounts'>
        <Box
          direction='column'
          overflow='auto'
          height='full'
        >
          <Box direction='row' flex={false} gap='small' pad='small' align='center' data-e2e='billing-selector'>
            <Text>
              &nbsp;&nbsp;Select Billing Account:
            </Text>
            <CustomerSelector
              onCustomerSelected={option => onSelectCustomer(option)}
              excludedStates={['REQUEST']}
              excludedServiceStates={['NEW']}
              persistSelection={true}
            />
          </Box>
          <Box flex={true} direction='column' border='top' overflow='auto'>
            {CustomerElements}
          </Box>
        </Box>
      </Tab>
    ),
    extract: (
      <Tab title='Extract' key='extract'>
        <Box flex={true} direction='column' fill='vertical' overflow='auto' height='full'>
          <ExtractPage />
        </Box>
      </Tab>
    ),
    approvers: (
      <Tab title='Approvers List' key='approvers'>
        <Box flex={true} overflow='auto' height='full'>
          <ApproversList />
        </Box>
      </Tab>
    ),
    pendingFiles: (
      <Tab title='Pending Files' key='pending_files'>
        <Box flex={true} overflow='auto' height='full'>
          <PendingFiles />
        </Box>
      </Tab>
    ),
    mockData: (
      <Tab title='Mock Data Generation'>
        <Box flex={true} overflow='auto' height='full'>
          <ListDemoData />
        </Box>
      </Tab>
    ),
    emails: (
      <Tab title='Emails'>
        <Box flex={true} fill='vertical' overflow='auto' height='full'>
          <EmailList />
        </Box>
      </Tab>
    ),
    actions: (
      <Tab title='Actions'>
        <Box flex={true} fill='vertical' overflow='auto' height='full'>
          <ActionsList />
        </Box>
      </Tab>
    ),
  }), [CustomerElements, onSelectCustomer]);

  return (
    <Box direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading title='Administration' />
      <Box border='top' flex={true}>
        <Box flex={true} className='tabWrapper'>
          <Tabs activeIndex={activeTab} onActive={index => setActiveTab(index)} flex={true}>
            {map(visibleTabs, k => tabs[k])}
          </Tabs>
        </Box>
        {renderToast()}
      </Box>
    </Box>
  );
};

AdministrationPage.propTypes = {
  customerActions: PropTypes.object,
  updateAdminBillingAccountFiltersAndSession: PropTypes.func.isRequired,
};

const mapStateToProps = store => ({
  customerActions: store.administration.customerActions,
  administration: store.administation,
  customer: store.customer,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateAdminBillingAccountFiltersAndSession,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdministrationPage);
