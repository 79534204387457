// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class MeterOption extends Enumify {
  static NONE = new MeterOption();

  static READ_ONLY = new MeterOption();

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()
}
