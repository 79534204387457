// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box,
  Button,
  Table,
  TableBody, TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import {
  Add,
  CaretUpFill,
  Contract,
  Copy,
  Edit,
  Expand, Trash,
} from 'grommet-icons';
import moment from 'moment';
import IDUtil from '../../../../shared/util/IDUtil';
import GLBMTooltip from '../../../../shared/component/GLBMTooltip';
import BaseContainer from './components/BaseContainer';
import MeterCapacitiesEditor from './MeterCapacitiesEditor';
import { CapacityOption } from '../../../model';
import { StatusIcon } from '../../../../shared/component/StatusIcon';

const MeterCapacities = (props) => {
  const [layer, setLayer] = useState(undefined);
  const [selectedRevision, setSelectedRevision] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [lastEditedRevision, setLastEditedRevision] = useState(undefined);

  useEffect(() => {
    if (lastEditedRevision) setLastEditedRevision(undefined);
  }, [lastEditedRevision]);

  const _showMaxInvoiceableCap = () => props.rate && props.rate.capacityRevisions && props.rate && props.rate.capacityRevisions.some(el => el.maximumInvoiceableCapacity);

  const _onClick = (state) => {
    props.onToggleExpansion(state);
  };

  const _getAddAction = () => {
    setLayer('add');
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const _getEditAction = (revision, index) => {
    setLayer('edit');
    setSelectedRevision(JSON.parse(JSON.stringify(revision)));
    setSelectedIndex(index);
  };

  const _getCopyAction = (revision, index) => {
    setLayer('copy');
    setSelectedRevision(JSON.parse(JSON.stringify(revision)));
    setSelectedIndex(index);
  };

  const _getDeleteAction = (index) => {
    const rate = { ...props.rate };
    rate.capacityRevisions.splice(index, 1);
    props.onChange(rate);
  };

  const _getActions = () => {
    const { expanded } = { ...props };
    const toggle = (expanded === 'NONE' || expanded !== 'CAPACITY'
      ? (
        <Button
          key='expand'
          icon={<Expand />}
          onClick={() => _onClick('CAPACITY')}
          hoverIndicator={true}
        />
      )
      : (
        <Button
          key='collapse'
          icon={<Contract />}
          onClick={() => _onClick('NONE')}
          hoverIndicator={true}
        />
      ));
    const addNew = (!props.rate || props.readOnly ? undefined : (
      <Button
        key='edit'
        icon={<Add />}
        label='Add Revision'
        id={IDUtil.getId('BillingInfoEditorAddRevisionCapacities')}
        onClick={_getAddAction}
        hoverIndicator={true}

      />
    ));

    return ([
      addNew,
      toggle,
    ]);
  };

  const _requireInstalledCapacities = () => {
    const options = (props.serviceType.rateManagement ? props.serviceType.rateManagement.capacityOptions : []);
    return ((options || []).includes(CapacityOption.REQUIRE_INSTALLED_CAPACITY));
  };

  const _isTieredRate = () => props?.rate?.tierType === 'TIERED';

  const _getColumns = () => {
    let columns = _isTieredRate() ? ['Status', 'Start Date'] : ['Start Date'];
    if (_requireInstalledCapacities()) {
      columns.push('Installed Capacity');
    }
    columns = columns.concat(['Requested Cap.', 'Reserved %', 'Reserved Capacity.', 'Adjusted Reserved Cap.']);
    if (_showMaxInvoiceableCap()) {
      columns.push('Max. Invoiceable Capacity.');
    }
    if (!props.readOnly) {
      columns.push('Action');
    }
    return columns.map(column => (
      <TableCell scope='col' border='bottom'>
        {column}
      </TableCell>
    ));
  };

  const _formatDate = date => new Date(date).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' });

  const _formatNumber = (x) => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  const _changeElement = (current, previous, property) => {
    let changeElement;
    if (current && previous) {
      if (current[property] > previous[property]) {
        changeElement = (<CaretUpFill style={{ paddingTop: '5px' }} color='black' size='small' />);
      } else if (current[property] < previous[property]) {
        changeElement = (<CaretUpFill style={{ paddingTop: '5px' }} color='black' size='small' />);
      }
    }
    return (
      <span
        style={{
          display: 'inline-block', marginLeft: '-18px', minWidth: '18px',
        }}
      >
        {changeElement}
&nbsp;
      </span>
    );
  };

  const _getMinContractualCommit = (r) => {
    if (!r.hasOwnProperty('minimumContractualCapacity') || r.minimumContractualCapacity === 0 || r.resetMinContractualCapacity) {
      return (r.committedCapacityOverrideEnabled ? (r.committedCapacityOverride || 0) : (r.committedCapacity || 0));
    }
    return (r.minimumContractualCapacity || 0).toFixed(2);
  };

  const _getMaximumInvoiceableCapacity = (r) => {
    if (r.hasOwnProperty('maximumInvoiceableCapacity') && r.maximumInvoiceableCapacity !== undefined) {
      return _formatNumber(parseFloat(Math.round((r.maximumInvoiceableCapacity) * 1000) / 1000));
    }
    return undefined;
  };

  const _getStatus = (currentCapacity) => {
    const failedCapacities = [];
    props.rate?.rateRevisions?.forEach((currentRate) => {
      const { effectiveDate: rateEffectiveDate } = currentRate;
      const foundCapacity = props.rate?.capacityRevisions?.find((cap, index, allCapacities) => {
        const currentEffectiveDate = cap?.effectiveDate;
        const nextEffectiveDate = allCapacities[index + 1]?.effectiveDate;
        return moment(currentEffectiveDate, 'YYYY-MM-DD').isSameOrBefore(moment(rateEffectiveDate, 'YYYY-MM-DD')) && (!nextEffectiveDate || moment(nextEffectiveDate, 'YYYY-MM-DD').isAfter(moment(rateEffectiveDate, 'YYYY-MM-DD')));
      });
      const limit = foundCapacity?.committedCapacityOverrideEnabled ? foundCapacity?.committedCapacityOverride : foundCapacity?.committedCapacity;
      const formattedLimit = limit && (typeof limit === 'string') ? +limit : limit;
      Object.keys(currentRate.tiers).forEach((tier) => {
        if (formattedLimit && (+tier >= formattedLimit)) {
          failedCapacities.push(foundCapacity?.effectiveDate);
        }
      });
    });
    return failedCapacities.includes(currentCapacity.effectiveDate) ? (
      <GLBMTooltip content='The Reserved Capacity for this revision is lower than one or more start ranges for the pricing bands in the corresponding Rate revision.  Please increase the Reserved Capacity or remove the offending pricing bands to fix this issue.'>
        <Box><StatusIcon value='critical' size='small' /></Box>
      </GLBMTooltip>
    ) : <StatusIcon value='ok' size='small' />;
  };

  const _getRows = () => {
    let prevCapacity;
    if (props.rate && props.rate.capacityRevisions) {
      return props.rate.capacityRevisions.sort((c1, c2) => {
        const momentA = moment(c1.effectiveDate, 'YYYY-MM-DD');
        const momentB = moment(c2.effectiveDate, 'YYYY-MM-DD');
        if (momentA > momentB) return 1;
        if (momentA < momentB) return -1;
        return 0;
      }).map((r, index) => {
        const minConCom = _getMinContractualCommit(r);
        const maxInvCap = _getMaximumInvoiceableCapacity(r);

        const row = (
          <TableRow
            key={index}
            className={`rate-card-revision ${r === lastEditedRevision && 'edited'}`}
            id={IDUtil.getId('Capacity', index)}
          >
            {_isTieredRate() ? (
              <TableCell>{_getStatus(r)}</TableCell>
            ) : undefined}
            <TableCell scope='row' key={`startDate_${index}`} id={IDUtil.getId('CapacityStartDate', index)}>{_formatDate(r.effectiveDate)}</TableCell>
            {_requireInstalledCapacities()
            && (
            <TableCell key={`installedCapacity_${index}`} id={IDUtil.getId('InstalledCapacity', index)}>
              <Box direction='row' align='center'>
                {_changeElement(r, prevCapacity, 'installedCapacity')}
                {_formatNumber(parseFloat(Math.round(r.installedCapacity * 1000) / 1000))}
              </Box>
            </TableCell>
            )}
            <TableCell key={`requestedCapacity${index}`} id={IDUtil.getId('RequestedCapacity', index)}>
              <Box direction='row' align='center'>
                {_changeElement(r, prevCapacity, 'requestedCapacity')}
                {_formatNumber(parseFloat(Math.round(r.requestedCapacity * 1000) / 1000))}
              </Box>
            </TableCell>
            <TableCell key={`committedPct_${index}`} id={IDUtil.getId('CommitedPct', index)}>
              <Box direction='row' align='center'>
                {_changeElement(r, prevCapacity, 'committedPct')}
                {r.committedPct}
              </Box>
            </TableCell>
            {!r.committedCapacityOverrideEnabled
          && (
          <TableCell key={`committedCapacity_${index}`} id={IDUtil.getId('CommitedCapacity', index)}>
            <Box direction='row'>
              {_changeElement(r, prevCapacity, 'committedCapacity')}
              {_formatNumber(parseFloat(Math.round(r.committedCapacity * 1000) / 1000).toFixed(2))}
            </Box>
          </TableCell>
          )}
            {r.committedCapacityOverrideEnabled
          && (
          <TableCell key={`committedCapacityOverride_${index}`} id={IDUtil.getId('CommitedCapacityOverride', index)}>
            <Box direction='row'>
              {_changeElement(r, prevCapacity, 'committedCapacityOverride')}
              {_formatNumber(parseFloat(Math.round(r.committedCapacityOverride * 1000) / 1000))}
              <sup>override</sup>
            </Box>
          </TableCell>
          )}
            <TableCell key={`minConCom_${index}`} id={IDUtil.getId('MinConCom', index)}>
              <GLBMTooltip
                content={(
                  <div>
                    Adjusted Reserved Capacity is based on Reserved Capacity, Actual Usage from previous months, and
                    shrink rules or other billing model rules. This value will be calculated after the rate plan has been
                    saved.
                  </div>
)}
              >
                <span
                  className='dotted-tooltip'
                >
                  {_formatNumber(parseFloat(Math.round((minConCom) * 1000) / 1000))}
                </span>
              </GLBMTooltip>
            </TableCell>
            {_showMaxInvoiceableCap() && (
            <TableCell key={`maxInvCap_${index}`} id={IDUtil.getId('MaxInvCap', index)}>
              <span>
                {maxInvCap || '-'}
              </span>
            </TableCell>
            )}
            {!props.readOnly
          && (
          <TableCell key={`actions_${index}`} align='start' id={IDUtil.getId('CapacitiesActions', index)}>
            <Box direction='row' gap='medium' align='center'>
              <Button
                size='large'
                id={IDUtil.getId('CapacitiesListItemEditButton', index)}
                icon={<Edit style={{ height: '18px', width: '18px' }} />}
                onClick={() => _getEditAction(r, index)}
                style={{ padding: '0' }}
              />
              <Button
                size='large'
                id={IDUtil.getId('CapacitiesListItemCopyButton', index)}
                icon={<Copy style={{ height: '18px', width: '18px' }} />}
                onClick={() => _getCopyAction(r, index)}
                style={{ padding: '0' }}
              />
              <Button
                size='large'
                id={IDUtil.getId('CapacitiesListItemDeleteButton', index)}
                icon={<Trash style={{ height: '18px', width: '18px' }} />}
                disabled={props.rate.capacityRevisions.length === 1}
                onClick={() => _getDeleteAction(index)}
                style={{ padding: '0' }}
              />
            </Box>
          </TableCell>
          )}
          </TableRow>
        );
        prevCapacity = r;
        return row;
      });
    }
    return [];
  };

  const canEdit = () => {
    if (!props.permissions) {
      return true;
    }
    return props.permissions.canEditRates;
  };

  const _onLayerClose = () => {
    setLayer(undefined);
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const _onLayerChange = (revision) => {
    const rate = { ...props.rate };

    if (layer === 'add' || layer === 'copy') {
      rate.capacityRevisions.push(revision);
    } else if (layer === 'edit') {
      rate.capacityRevisions[selectedIndex] = revision;
    }

    _onLayerClose();
    props.onChange(rate);

    setLastEditedRevision(revision);
  };

  const _getDefaultRevision = () => {
    const revision = {
      effectiveDate: undefined,
      committedCapacity: 0.0,
      committedCapacityOverrideEnabled: false,
      requestedCapacity: 0.0,
      committedPct: 0.0,
    };

    if (_requireInstalledCapacities()) {
      revision.installedCapacity = 0.0;
    }

    return revision;
  };

  const _renderLayer = () => {
    const { customer, meter, rate } = props;

    const startDates = (rate ? rate.capacityRevisions : []).reduce(
      (map, revision) => {
        map.push(revision.effectiveDate);
        return map;
      },
      [],
    );

    if (layer === 'add') {
      return (
        <MeterCapacitiesEditor
          onClose={_onLayerClose}
          revision={_getDefaultRevision()}
          onChange={_onLayerChange}
          canEdit={canEdit()}
          preferences={customer.preferences}
          meter={meter}
          startDates={startDates}
          serviceType={props.serviceType}
          userType={props.userType}
        />
      );
    } if ((layer === 'edit' || layer === 'copy') && selectedRevision) {
      const customer = { ...props.customer };

      if (layer === 'edit') {
        startDates.splice(startDates.indexOf(selectedRevision.effectiveDate), 1);
      } else {
        selectedRevision.effectiveDate = undefined;
      }

      return (
        <MeterCapacitiesEditor
          onClose={_onLayerClose}
          revision={selectedRevision}
          onChange={_onLayerChange}
          canEdit={canEdit()}
          preferences={customer.preferences}
          meter={meter}
          startDates={startDates}
          serviceType={props.serviceType}
          userType={props.userType}
        />
      );
    }
  };

  return (
    <BaseContainer
      title='Capacities'
      actions={_getActions()}
      fill='vertical'
      expanded={props.expanded === 'NONE' || props.expanded === 'CAPACITY'}
    >
      <Box
        direction='column'
        fill='vertical'
        data-e2e='capacities'
      >
        <Table
          responsive={false}
          scrollable={true}
          className='narrow-table'
        >
          <TableHeader
            sortIndex={0}
            sortAscending={true}
          >
            <TableRow>
              {_getColumns()}
            </TableRow>
          </TableHeader>
          <TableBody>
            {_getRows()}
          </TableBody>
        </Table>
      </Box>
      {_renderLayer()}
    </BaseContainer>
  );
};

const mapStateToProps = ({
  service: {
    details: {
      customer, options, rates, permissions, migration, validation,
    },
  },
}) => ({});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeterCapacities);
