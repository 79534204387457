// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import {
  Box, Header, Heading, Paragraph,
} from 'grommet';
import PropTypes from 'prop-types';
import { StatusIcon } from '../../shared/component/StatusIcon';

function PromoteResults(props) {
  const renderDetails = () => {
    const { customer } = props;
    return (
      <Box margin={{ top: 'small' }} height={{ max: 'medium' }}>
        <Header size='small' align='center' justify='start'>
          <StatusIcon value='ok' size='medium' />
          <Heading level='3'>Successfully Promoted</Heading>
        </Header>
        <Box margin='small' direction='row' align='start'>
          <Box>
            <Paragraph margin='none'>
              {`You have successfully promoted the data for ${customer.name} (${customer.id}) to production.`}
            </Paragraph>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box pad='none' style={{ 'width': '600px', 'minWidth': '600px' }} direction='column'>
      <Box flex={false} pad={{ 'horizontal': 'small' }} align='start' margin='medium'>
        {renderDetails()}
      </Box>
    </Box>
  );
}

PromoteResults.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default PromoteResults;
