// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Notification } from 'grommet';

const ErrorBanner = ({
  title = undefined,
  message,
  toast = false,
}) => (
  <Notification
    toast={toast}
    title={title}
    message={message}
    status='critical'
  />
);

ErrorBanner.propTypes = {
  toast: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default ErrorBanner;
