// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Accordion, Box } from 'grommet';
import map from 'lodash/map';
import keys from 'lodash/keys';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import uniqByFp from 'lodash/fp/uniqBy';
import mapFp from 'lodash/fp/map';
import compose from 'lodash/fp/compose';
import fromPairs from 'lodash/fromPairs';
import {
  useLocationRateProps, useLocationRequireCommitmentProps,
  useRateProps,
} from './utils';
import MeterItems from './MeterItems';
import LocationGroupItem from './LocationGroupItem';
import { CommitmentType } from '../../../../model/CommitmentType';

const MeterListByLocation = ({
  searchText = '',
  meters = [],
  selectedMeter = undefined,
  onClick,
  tenant = undefined,
  rates = [],
  locationRates = [],
  serviceType = undefined,
  onGroupSelect,
  selectedGroupId = undefined,
}) => {
  const groupsAreSelectable = serviceType?.commitmentType === CommitmentType.MONETARY;

  // map of location ids to location objects (with id, name)
  const locations = useMemo(() => (
    compose(
      fromPairs,
      mapFp(l => [l.locationId, { id: l.locationId, name: l.locationName }]),
      uniqByFp('locationId')
    )(meters)
  ), [meters]);

  // map of locationId to list of meters
  const groupedMeters = useMemo(() => {
    const sortedMeters = meters?.sort((m1, m2) => m1.name.localeCompare(m2.name));
    return groupBy(sortedMeters, 'locationId');
  }, [meters]);

  // array of location ids in sorted order (sorted by location name)
  const sortedGroups = useMemo(() => (
    compose(
      mapFp('id'),
      arr => arr.sort((l1, l2) => l1.name.localeCompare(l2.name)),
      mapFp(id => locations[id]),
      keys,
    )(groupedMeters)
  ), [groupedMeters, locations]);

  // map of locationIds to list of meters after applying searchText filter
  const filteredMeters = useMemo(() => {
    if (searchText) {
      return mapValues(groupedMeters, meterList => meterList.filter(m => m.name.toLowerCase().includes(searchText.toLowerCase())));
    }
    return groupedMeters;
  }, [searchText, groupedMeters]);

  const selectedMeterId = (selectedMeter ? selectedMeter.id : undefined);

  const rateProps = useRateProps({ tenant, rates });
  const locationRateProps = useLocationRateProps({ locationRates });
  const locationRequireCommitmentProps = useLocationRequireCommitmentProps({ meters, rates, locationRates });

  const [expandedGroup, setExpandedGroupState] = useState(0);
  const setExpandedGroup = useCallback((newVal) => {
    setExpandedGroupState((oldVal) => {
      // Don't collapse location if something else was selected.  First select this location.  Next click will collapse it.
      if (groupsAreSelectable && newVal === undefined && oldVal !== undefined && sortedGroups[oldVal] !== selectedGroupId) {
        onGroupSelect(sortedGroups[oldVal]);
        return oldVal;
      }
      return newVal;
    });
  }, [onGroupSelect, groupsAreSelectable, selectedGroupId, sortedGroups]);

  // When group expansion changes, select something appropriate from list.
  useEffect(() => {
    if (sortedGroups) {
      if (groupsAreSelectable) {
        if (expandedGroup !== undefined) {
          onGroupSelect(sortedGroups[expandedGroup]);
        } else if (selectedMeter) {
          onGroupSelect(selectedMeter.locationId);
        } else if (!selectedGroupId) {
          onGroupSelect(sortedGroups[0]);
        }
      } else if (expandedGroup !== undefined) {
        onClick(filteredMeters?.[sortedGroups?.[expandedGroup]]?.[0]?.id);
      } else {
        onClick(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedGroup, sortedGroups]);

  const onPrevGroup = useCallback(() => {
    if (expandedGroup !== undefined) {
      if (selectedGroupId && expandedGroup > 0) {
        setExpandedGroup(expandedGroup - 1);
      } else if (groupsAreSelectable) {
        onGroupSelect(sortedGroups?.[expandedGroup]);
      } else if (expandedGroup > 0) {
        setExpandedGroup(expandedGroup - 1);
      }
    } else if (sortedGroups && selectedGroupId) {
      const index = sortedGroups.indexOf(selectedGroupId);
      if (index > 0) {
        onGroupSelect(sortedGroups[index - 1]);
      }
    }
  }, [expandedGroup, groupsAreSelectable, onGroupSelect, selectedGroupId, sortedGroups, setExpandedGroup]);

  const onNextGroup = useCallback(() => {
    if (expandedGroup !== undefined) {
      if (selectedGroupId) {
        onClick(filteredMeters[selectedGroupId][0]?.id);
      } else if (expandedGroup < sortedGroups.length - 1) {
        setExpandedGroup(expandedGroup + 1);
      }
    } else if (sortedGroups && selectedGroupId) {
      const index = sortedGroups.indexOf(selectedGroupId);
      if (index < sortedGroups.length - 1) {
        onGroupSelect(sortedGroups[index + 1]);
      }
    }
  }, [expandedGroup, filteredMeters, onClick, onGroupSelect, selectedGroupId, sortedGroups, setExpandedGroup]);

  return (
    <Box overflow='auto' fill='vertical'>
      <Accordion activeIndex={expandedGroup} onActive={([idx]) => setExpandedGroup(idx)}>
        {map(sortedGroups, (locationId, index) => (
          <LocationGroupItem
            key={locationId}
            locationRate={locationRateProps[locationId]}
            expectLocationRate={groupsAreSelectable}
            requireLocationRate={!!locationRequireCommitmentProps[locationId]}
            selected={locationId === selectedGroupId}
            location={locations[locationId]}
            locationId={locationId}
            onPrevGroup={onPrevGroup}
            onNextGroup={onNextGroup}
          >
            <MeterItems
              selectedMeterId={selectedMeterId}
              onSelectMeter={(meter) => { onClick(meter.id); }}
              meters={filteredMeters[locationId] || []}
              rateProps={rateProps}
              leftPad='medium'
              onPrevGroup={onPrevGroup}
              onNextGroup={onNextGroup}
              idPrefix={index}
            />
          </LocationGroupItem>
        ))}
      </Accordion>
    </Box>
  );
};

MeterListByLocation.propTypes = {
  serviceType: PropTypes.shape({
    commitmentType: PropTypes.object,
  }),
  meters: PropTypes.arrayOf(PropTypes.shape({
    locationId: PropTypes.string,
    locationName: PropTypes.string,
    name: PropTypes.string,
  })),
  searchText: PropTypes.string,
  selectedMeter: PropTypes.shape({
    id: PropTypes.string,
    locationId: PropTypes.string,
  }),
  tenant: PropTypes.string,
  rates: PropTypes.arrayOf(PropTypes.object),
  locationRates: PropTypes.arrayOf(PropTypes.object),
  onGroupSelect: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedGroupId: PropTypes.string,
};

export default MeterListByLocation;
