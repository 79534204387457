// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import { Anchor, Menu, Text } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { More } from 'grommet-icons';
import moment from 'moment/moment';
import get from 'lodash/get';
import filter from 'lodash/filter';
import some from 'lodash/some';
import { UserType, isUnassignedRole } from '../shared/constants/UserType';
import IDUtil from '../shared/util/IDUtil';
import { HighlightUsingContext } from '../shared/component/HighlightUsingContext';
import { pagePermissions, roles } from '../shared/constants/Permissions';
import {
  usePermissionChecker,
  useRoleChecker,
} from '../shared/hooks/permissions';
import { insertIf } from '../shared/util/BasicUtil';

export const filterUsers = (searchText, filters, userList, selectedColumns) => {
  if (!searchText && Object.entries(filters).length === 0) {
    return userList;
  }

  const { panel: { role, email, apiClient } } = filters;

  const filterColumns = filter(selectedColumns, ['filter', true]);
  const lowerSearch = searchText?.toLowerCase();
  return userList?.filter((user) => {
    const userRole = (user.role.trim());
    const rolePredicate = !role || role.includes(userRole);
    if (!rolePredicate) {
      return false;
    }
    const userEmail = (user.email.trim());
    const emailPredicate = !email || some(email, e => userEmail.match(e));
    if (!emailPredicate) {
      return false;
    }
    const userApiClient = (user.apiClient || false).toString();
    const apiClientPredicate = !apiClient || some(apiClient, e => e === userApiClient);
    if (!apiClientPredicate) {
      return false;
    }
    return !lowerSearch || some(filterColumns, (c) => {
      const val = c.dataCallback ? c.dataCallback(user) : get(user, c.property);
      return val.toLowerCase().includes(lowerSearch);
    });
  }).map((el, index) => ({ ...el, index }));
};

export const getUserRoleDescription = role => (UserType.enumValueOf(role) ? UserType.enumValueOf(role).label : role);
export const useAvailableColumns = (setLayer, setSelectedUserId) => {
  const navigate = useNavigate();
  const { hasPermissions } = usePermissionChecker();
  const { isOneOfRoles, isRole } = useRoleChecker();
  return useMemo(() => ([
    {
      property: 'name',
      header: 'Name',
      primary: true,
      dataCallback: ({ firstName, lastName }) => `${firstName} ${lastName}`,
      render: ({
        id, firstName, lastName, index,
      }) => (
        <Anchor onClick={() => navigate(`/users/${id}`, { state: { origin: '/users' } })} id={IDUtil.getId('Name', index)}>
          <Text weight='bold'>
            <HighlightUsingContext>{`${firstName} ${lastName}`}</HighlightUsingContext>
          </Text>
        </Anchor>
      ),
      size: 'medium',
      filter: true,
    }, {
      property: 'email',
      header: 'Email',
      render: ({ email, index }) => (
        <div id={IDUtil.getId('Email', index)}>
          <HighlightUsingContext>{email}</HighlightUsingContext>
        </div>
      ),
      size: 'small',
      filter: true,
    }, {
      property: 'role',
      header: 'Role',
      dataCallback: ({ role }) => getUserRoleDescription(role),
      render: ({ role, index }) => (
        <div id={IDUtil.getId('Role', index)}>
          <HighlightUsingContext>{getUserRoleDescription(role)}</HighlightUsingContext>
        </div>
      ),
      size: 'small',
    }, {
      property: 'id',
      header: 'ID',
      dataCallback: ({ id }) => id,
      render: ({ id, index }) => (
        <div id={IDUtil.getId('ID', index)}>
          <HighlightUsingContext>{id}</HighlightUsingContext>
        </div>
      ),
      size: 'medium',
      filter: true,
    },
    {
      property: 'lastLogin',
      header: 'Last login (UTC)',
      dataCallback: ({ lastLogin }) => lastLogin || '',
      render: ({ lastLogin, index }) => (
        <div id={IDUtil.getId('lastLogin', index)}>
          {lastLogin && moment(lastLogin).format('lll')}
        </div>
      )
    },
    {
      property: 'requestedAccessTime',
      header: 'Access requested on (UTC)',
      dataCallback: ({ requestedAccessTime }) => requestedAccessTime || '',
      render: ({ requestedAccessTime, index }) => (
        <div id={IDUtil.getId('requestedAccessTime', index)}>
          {requestedAccessTime && moment(requestedAccessTime).format('lll')}
        </div>
      )
    },
    {
      property: 'grantedAccessTime',
      header: 'Access granted on (UTC)',
      dataCallback: ({ grantedAccessTime }) => grantedAccessTime || '',
      render: ({ grantedAccessTime, index }) => (
        <div id={IDUtil.getId('grantedAccessTime', index)}>
          {grantedAccessTime && moment(grantedAccessTime).format('lll')}
        </div>
      )
    },
    {
      property: 'rolesGrantedBy.email',
      header: 'Access approved by',
      dataCallback: ({ rolesGrantedBy }) => rolesGrantedBy?.email ?? '',
      render: ({ rolesGrantedBy, index }) => (
        <div id={IDUtil.getId('approvalGrantedBy', index)}>
          <HighlightUsingContext>{rolesGrantedBy?.email ?? ''}</HighlightUsingContext>
        </div>
      ),
      filter: true,
    },
    {
      property: 'lastRevokedTime',
      header: 'Access revoked on (UTC)',
      dataCallback: ({ lastRevokedTime }) => lastRevokedTime || '',
      render: ({ lastRevokedTime, index }) => (
        <div id={IDUtil.getId('lastRevokedTime', index)}>
          {lastRevokedTime && moment(lastRevokedTime).format('lll')}
        </div>
      )
    },
    {
      property: 'lastRevokedBy.email',
      header: 'Access revoked by',
      dataCallback: ({ lastRevokedBy }) => lastRevokedBy?.email ?? '',
      render: ({ lastRevokedBy, index }) => (
        <div id={IDUtil.getId('lastRevokedBy', index)}>
          <HighlightUsingContext>{lastRevokedBy?.email ?? ''}</HighlightUsingContext>
        </div>
      ),
      filter: true,
    }, {
      property: 'managerEmail',
      header: 'Manager Email',
      dataCallback: ({ managerEmail }) => managerEmail || '',
      render: ({ managerEmail, index }) => (
        <div id={IDUtil.getId('managerEmail', index)}>
          <HighlightUsingContext>{managerEmail || ''}</HighlightUsingContext>
        </div>
      ),
      filter: true,
    },
    {
      property: 'actions',
      header: 'Actions',
      size: '96px',
      align: 'start',
      render: (user) => {
        let canEdit = hasPermissions(pagePermissions.users.edit);
        if (canEdit) {
          if (user.role === 'ASM' || user.role === 'SUPPORT' || isUnassignedRole(user.role)) {
            canEdit = isOneOfRoles([roles.SUPER, roles.SUPPORT]);
          } else if (user.role === 'BUSINESS') {
            canEdit = isOneOfRoles([roles.SUPER, roles.BUSINESS]);
          }
        }
        const actionItems = [
          ...insertIf(canEdit, [
            {
              label: 'Edit User',
              id: IDUtil.getId('Edit', user.index),
              onClick: () => navigate(`/users/${user.id}`),
            },
          ]),
          ...insertIf(canEdit && isRole(roles.SUPER), [
            {
              label: 'Edit Approval Permissions',
              id: IDUtil.getId('Edit', user.index),
              onClick: () => {
                setLayer('editApproversPermission');
                setSelectedUserId(user.id);
              },
            }
          ]),
          ...insertIf(hasPermissions(pagePermissions.users.history), [
            {
              label: 'History',
              id: IDUtil.getId('History', user.index),
              onClick: () => {
                setLayer('historyActive');
                setSelectedUserId(user.id);
              }
            },
          ]),
        ];
        if (actionItems.length > 0) {
          return (
            <Menu
              icon={<More />}
              id={IDUtil.getId('Actions', user.index)}
              responsive={true}
              items={actionItems}
            />
          );
        }
        return null;
      },
      sortable: false,
    },
  ]), [hasPermissions, isOneOfRoles, isRole, navigate, setLayer, setSelectedUserId]);
};
