// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';

import { CaretDown, CaretUp } from 'grommet-icons';

const PinnedRowCellRenderer = ({ value: valueProp = undefined }) => {
  const value = (valueProp ? valueProp.value : 0);
  const change = (valueProp ? valueProp.change : 0);

  let changeElement;
  if (change > 0) {
    changeElement = (
      <span>
        <CaretUp color='green' size='small' />
&nbsp;
      </span>
    );
  } else if (change < 0) {
    changeElement = (
      <span>
        <CaretDown color='red' size='small' />
&nbsp;
      </span>
    );
  }

  return (
    <div>
      {changeElement}
      <span>{(value !== undefined) ? value.toLocaleString() : 0}</span>
    </div>
  );
};

PinnedRowCellRenderer.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.any,
    change: PropTypes.number,
  }),
};

export default PinnedRowCellRenderer;
