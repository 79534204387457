// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import ChartLegend from '../ChartLegend';

function Legend({ data, keys }) {
  const items = keys.map((label) => {
    const series = data.filter(d => d.legendKey === label)[0];
    return series && series.color ? {
      key: series.id,
      tip: series.description,
      color: series.color,
      squareIcon: !!series.fill,
      lineStyle: series.lineStyle?.strokeDasharray ? 'dashed' : 'solid',
      lineWidth: series.lineStyle?.strokeWidth || 2,
    } : undefined;
  }).filter(x => x);

  return <ChartLegend items={items} />;
}

Legend.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    legendKey: PropTypes.string,
    id: PropTypes.string,
    description: PropTypes.string,
    color: PropTypes.string,
    fill: PropTypes.string,
    lineStyle: PropTypes.object,
  })).isRequired,
};

export default Legend;
