// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { updateStateAndSession } from 'session/sessionUtil';

export const INVALIDATE_USAGE_FILES = 'INVALIDATE_USAGE_FILES';
export const REQUEST_USAGE_FILES = 'REQUEST_USAGE_FILES';
export const SUCCESS_USAGE_FILES = 'SUCCESS_USAGE_FILES';
export const FAILURE_USAGE_FILES = 'FAILURE_USAGE_FILES';
export const UPDATE_USAGE_FILTERS = 'UPDATE_USAGE_FILTERS';

const handleErrors = (response) => {
  if (!response.ok) {
    const json = response.json();
    return json.then(Promise.reject.bind(Promise));
  }
  return response;
};

export function invalidateUsageFiles() {
  return { type: INVALIDATE_USAGE_FILES };
}

const requestUsageFiles = filters => ({
  type: REQUEST_USAGE_FILES,
  files: [],
  filters,
  error: false,
  loading: true,
});

const successUsageFiles = res => ({
  type: SUCCESS_USAGE_FILES,
  files: res,
  error: false,
  loading: false,
});

const failureUsageFiles = error => ({
  type: FAILURE_USAGE_FILES,
  files: [],
  error,
  loading: false,
});

export function updateFilters(filters) {
  return {
    type: UPDATE_USAGE_FILTERS,
    filters,
  };
}

export const updateFiltersAndSession = updateStateAndSession(updateFilters);
