// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import flatten from 'lodash/flatten';
import moment from 'moment';
import {
  computeYTicks as commonComputeYTicks,
  formatNumberWithSigDigits,
  formatShortNumber
} from '../utils';

export const formatNumber = (value, locale = null) => {
  const numeric = value || 0;
  if (typeof numeric === 'number') {
    try {
      return Math.round(numeric).toLocaleString(locale || '');
    } catch (error) {
      return Math.round(numeric).toLocaleString();
    }
  }
  return null;
};

export const computeBarData = (values, monthDate) => {
  const filteredValues = values
    .filter(v => !v.forecast)
    .map(v => ({ ...v, date: moment.utc(v.date).toDate() }))
    .filter(v => v.date.getUTCMonth() === monthDate.getUTCMonth() && v.date.getUTCFullYear() === monthDate.getUTCFullYear());
  const allDates = filteredValues.map(v => v.date.getUTCDate());
  const d = new Date(monthDate);
  while (d.getUTCMonth() === monthDate.getUTCMonth()) {
    if (!allDates.includes(d.getUTCDate())) {
      filteredValues.push({ date: new Date(d) });
    }
    d.setUTCDate(d.getUTCDate() + 1);
  }
  return filteredValues;
};

export const computeYTicks = (filteredValues) => {
  const allYValues = flatten(filteredValues.map(v => [v.used || 0, (v.available + v.used) || 0, v.committed || 0]));
  const yMax = allYValues.length ? Math.max(...allYValues) : 0;
  let yMin = allYValues.length ? Math.min(...allYValues) : 0;
  yMin = yMin > 0 ? 0 : yMin;
  return commonComputeYTicks(yMin, yMax * 1.05, 5);
};

// this only renders a label once per week
export const formatterX = date => (date.getUTCDate() % 7 === 1 ? moment.utc(date).format('M/D/YYYY') : '');

export const formatterY = formatShortNumber;

export const formatTooltipDate = date => (date ? moment.utc(date).format('M/D/YYYY') : '');
export const formatValueWithUnit = (value, unit) => `${formatNumberWithSigDigits(value, 2)} ${unit || ''}`;
export const formatPercentage = value => (value !== undefined ? `${formatNumber(value)}%` : '');
