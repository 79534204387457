// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { updateStateAndSession } from 'session/sessionUtil';

export const UPDATE_COMPANY_PAGE_FILTERS = 'UPDATE_COMPANY_PAGE_FILTERS';

function updateFilters(filters) {
  return {
    type: UPDATE_COMPANY_PAGE_FILTERS,
    filters,
  };
}

export const updateFiltersAndSession = updateStateAndSession(updateFilters);
