// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  RECEIVE_CAPACITY_PLANNING,
  REQUEST_CAPACITY_PLANNING,
  UPDATE_ANALYTICS_PAGE_FILTERS,
  UPDATE_INVOICE_MODES,
} from './AnalyticsActions';

export function capacityPlanning(state = {
  loading: false,
  details: null,
  filter: null,
}, action = {}) {
  switch (action.type) {
    case REQUEST_CAPACITY_PLANNING:
      return {
        ...state,
        loading: true,
        details: null
      };

    case RECEIVE_CAPACITY_PLANNING:
      return {
        ...state,
        loading: false,
        details: action.capacityPlanning
      };

    default:
      return state;
  }
}

export function analyticsList(state = {
  filters: {
    customerId: '',
  },
  modes: null,
}, action = {}) {
  switch (action.type) {
    case UPDATE_ANALYTICS_PAGE_FILTERS:
      return { ...state, filters: action.filters };
    case UPDATE_INVOICE_MODES:
      return { ...state, modes: action.modes };
    default:
      return state;
  }
}
