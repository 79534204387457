// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet';
import React from 'react';
import { pagePermissions } from '../../shared/constants/Permissions';
import ExtractQuarterlyBillingAccountDataDialog
  from './dialogs/ExtractQuarterlyBillingAccountDataDialog';
import ExtractAllCustomerInvoices from './dialogs/ExtractAllCustomerInvoices';
import ExtractAssignedUsersDialog from './dialogs/ExtractAssignedUsersDialog';
import ExtractBillingPSAData from './dialogs/ExtractBillingPSAData';
import ExtractCustomerRateCards from './dialogs/ExtractCustomerRateCards';
import ExtractDigitalMonthlyBusinessReviewData
  from './dialogs/ExtractDigitalMonthlyBusinessReviewData';
import ExtractISVServiceData from './dialogs/ExtractISVServiceData';
import ExtractRow from './ExtractRow';
import PermissionCheck from './PermissionCheck';

const ExtractPage = () => (
  <Box flex={true} overflow='auto'>
    <PermissionCheck permissions={pagePermissions.administration.extract.allCustomerInvoices}>
      <ExtractRow
        action={{
          type: 'DYNAMIC',
          name: 'Extract All Billing Account Data',
          description: 'Extract customer usage account invoices in CSV format for a given month.',
          inputs: ExtractAllCustomerInvoices,
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.customerRateCards}>
      <ExtractRow
        action={{
          type: 'DYNAMIC',
          name: 'Extract All Rate Card Data',
          description: 'Extract all or selected customer rate card data.',
          inputs: ExtractCustomerRateCards,
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.restrictedPartiesList}>
      <ExtractRow
        action={{
          type: 'RUN',
          name: 'Extract Restricted Parties List',
          description: 'Extract restricted parties list in CSV for all billing accounts.',
          apiPath: 'customers.export.restrictedPartiesList.path',
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.serviceDefinitionList}>
      <ExtractRow
        action={{
          type: 'RUN',
          name: 'Extract Service Definitions List',
          description: 'Extract service definitions list list in CSV format.',
          apiPath: 'customers.export.serviceDefinitionList.path',
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.billingAccountDetails}>
      <ExtractRow
        action={{
          type: 'RUN',
          name: 'Extract Billing Account Details',
          description: 'Exports CSV with one row per billing account with many columns of contextual information.',
          apiPath: 'customers.export.billingAccountsDetails.path',
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.serviceDetails}>
      <ExtractRow
        action={{
          type: 'RUN',
          name: 'Extract Service Details',
          description: 'Exports CSV with one row per billing account and service with details on the configuration of that service.',
          apiPath: 'customers.export.serviceDetails.path',
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.assignedUsersList}>
      <ExtractRow
        action={{
          type: 'DYNAMIC',
          name: 'Extract Users',
          description: 'Extract users list in CSV format.',
          inputs: ExtractAssignedUsersDialog,
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.isvServiceData}>
      <ExtractRow
        action={{
          type: 'DYNAMIC',
          name: 'Extract ISV Service Data',
          description: 'Extract ISV Service Data in CSV format.',
          inputs: ExtractISVServiceData,
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.customerASMDetails}>
      <ExtractRow
        action={{
          type: 'RUN',
          name: 'Extract Billing Accounts with Associated ASMs',
          description: 'Exports CSV with one row per billing account and associated ASM.',
          apiPath: 'customers.export.customerASMDetails.path',
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.allCustomerInvoices}>
      <ExtractRow
        action={{
          type: 'DYNAMIC',
          name: 'Extract All Billing and PSA Data',
          description: 'Extract all billing info and PSA data for all billing accounts in CSV format for a given month.',
          inputs: ExtractBillingPSAData,
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.allCapacityPlanningData}>
      <ExtractRow
        action={{
          type: 'DYNAMIC',
          name: 'Extract for Digital Monthly Business Review',
          description: 'Extract and aggregate usage data for production accounts and generate capacity planning forecasts to ensure optimal resource allocation and scalability.',
          inputs: ExtractDigitalMonthlyBusinessReviewData,
        }}
      />
    </PermissionCheck>
    <PermissionCheck permissions={pagePermissions.administration.extract.accountQuarterlyCharges}>
      <ExtractRow
        action={{
          type: 'DYNAMIC',
          name: 'Extract Quarterly Billing Account Data in USD',
          description: 'Extract in CSV format.',
          inputs: ExtractQuarterlyBillingAccountDataDialog,
        }}
      />
    </PermissionCheck>
  </Box>
);

export default ExtractPage;
