// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { Enumify } from 'enumify';

export class ReportBy extends Enumify {
  static TIER = new ReportBy(
    'Tier',
    'Charges and Capacity Alerts are by Tier'
  );

  static LOCATION = new ReportBy(
    'Location',
    'Charges and Capacity Alerts are by Location and Tier'
  );

  static TIER_LOCATION = new ReportBy(
    'Tier And Location',
    'Charges are by Tier and Capacity Alerts are by Location and Tier'
  );

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label, description) {
    super();
    this.label = label;
    this.description = description;
  }
}
