// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { produce } from 'immer';
import {
  Box, Main, Notification, Tab, Tabs,
} from 'grommet';
import { useCustomersQuery } from '../../core';
import CustomerSelector from '../shared/component/CustomerSelector';
import {
  pagePermissions,
} from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import CapacityPlanning from './capacityPlanning/CapacityPlanning';
import InvoicePage from './invoice/InvoicePage';
import SortOrderPage from './invoice/SortOrderPage';
import { updateAnalyticsPageFiltersAndSession } from './redux/AnalyticsActions';
import GLBMHeading from '../shared/component/GLBMHeading';

const AnalyticsPage = ({
  analytics,
  updateAnalyticsPageFiltersAndSession: updateAnalyticsPageFiltersAndSessionProp
}) => {
  const params = useParams();

  const [selectedTabIndex, setSelectedTabIndex] = useState(params.tab === 'capacity' ? 1 : 0);
  const [showSortOrder, setShowSortOrder] = useState(false);
  const [response, setResponse] = useState(undefined);

  const filters = useMemo(() => produce(analytics.list.filters, (draft) => {
    // eslint-disable-next-line no-param-reassign
    draft.customerId = params.customerId;
    // eslint-disable-next-line no-param-reassign
    draft.selectedService = params.serviceType;
    return draft;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);
  const {
    data: customerData,
  } = useCustomersQuery();

  const [selectedCustomer, setSelectedCustomer] = useState(undefined);

  const _onSelectCustomer = (id) => {
    updateAnalyticsPageFiltersAndSessionProp(produce(filters, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.customerId = id;
      // eslint-disable-next-line no-param-reassign
      draft.selectedService = undefined;
      return draft;
    }));
    setSelectedCustomer(customerData.find(customer => customer.id === id));
  };

  const _capacityPlanningTab = customer => (
    <Tab title='Capacity Planning' key='analytics_capacityPlanning'>
      {customer && (
      <CapacityPlanning
        selectedCustomer={customer}
        selectedService={analytics.list.filters.selectedService}
      />
      )}
    </Tab>
  );

  const _toggleShowOrder = () => {
    setShowSortOrder(!showSortOrder);
  };

  const _invoiceTab = customer => (
    <Tab title='Monthly Charges' key='analytics_invoice'>
      {customer && <InvoicePage selectedCustomer={customer} toggleSetOrder={_toggleShowOrder} />}
    </Tab>
  );

  const { hasPermissions } = usePermissionChecker();

  const tabs = useMemo(() => {
    const myTabs = [];
    if (selectedCustomer) {
      if (hasPermissions(pagePermissions.analytics.tabs.invoice)) {
        myTabs.push(_invoiceTab(selectedCustomer));
      }
      if (hasPermissions(pagePermissions.analytics.tabs.capacity)) {
        myTabs.push(_capacityPlanningTab(selectedCustomer));
      }
    }
    return myTabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer, hasPermissions]);

  const _onTabChange = (index) => {
    setSelectedTabIndex(index);
  };

  const _onToastClose = () => {
    setResponse(undefined);
  };
  const _renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Notification
          toast={true}
          status={response.status}
          title={response.title}
          message={response.message}
          onClose={() => _onToastClose()}
        />
      );
    }
    return message;
  };

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Analytics'
        search={(
          <Box flex={false} width='500px'>
            <CustomerSelector
              onCustomerSelected={option => _onSelectCustomer(option)}
              excludedStates={['REQUEST']}
              excludedServiceStates={['NEW']}
              excludeNoServices={true}
              persistSelection={true}
            />
          </Box>
          )}
      />
      <Box border='top' flex={true}>
        {selectedCustomer && (
          <Box flex={true} className='tabWrapper'>
            {showSortOrder && <SortOrderPage selectedCustomer={selectedCustomer.id} onClose={() => _toggleShowOrder()} />}
            {!showSortOrder && (
            <Tabs activeIndex={selectedTabIndex} onActive={index => _onTabChange(index)}>
              {tabs}
            </Tabs>
            )}
          </Box>
        )}
      </Box>
      {_renderToast()}
    </Main>
  );
};

AnalyticsPage.propTypes = {
  analytics: PropTypes.shape({
    list: PropTypes.shape({
      filters: PropTypes.shape({
        customerId: PropTypes.string,
        selectedService: PropTypes.any,
      })
    })
  }).isRequired,
  updateAnalyticsPageFiltersAndSession: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  analytics,
}) => ({
  analytics,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateAnalyticsPageFiltersAndSession,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);
