// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class RateInterval extends Enumify {
  static Hour = new RateInterval('Hour');

  static Day = new RateInterval('Day');

  static Month = new RateInterval('Month');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label) {
    super();
    this.label = label;
  }
}
