// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import {
  Box, CheckBox, Text, Tip,
} from 'grommet';
import PropTypes from 'prop-types';

const CACheckbox = ({
  id,
  name,
  label,
  checked,
  onChange,
  disabled = false,
  tooltip = undefined,
}) => (
  <Box
    margin={{ bottom: '4px' }}
  >
    <CheckBox
      label={
            tooltip ? (
              <Tip
                content={(
                  <Box width='medium'>
                    {tooltip}
                  </Box>
                )}
                dropProps={{ align: { left: 'right' } }}
              >
                <Text style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.5)' }}>{label}</Text>
              </Tip>
            ) : <Text>{label}</Text>
}
      id={id}
      name={name}
      disabled={disabled}
      checked={checked}
      className={tooltip ? 'dotted-tooltip-radio' : ''}
      onChange={onChange}
    />
  </Box>
);

CACheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CACheckbox;
