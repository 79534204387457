// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useMemo } from 'react';
import isArray from 'lodash/isArray';
import tail from 'lodash/tail';
import some from 'lodash/some';
import every from 'lodash/every';
import includes from 'lodash/includes';
import { useUserContext } from '../../../AppContext';

const hasPermissions = (requestedPerms, permissions) => {
  if (!requestedPerms) {
    console.warn('Checking against undefined requested permissions');
    return false;
  }
  if (isArray(requestedPerms)) {
    if (requestedPerms[0] === 'anyOf') {
      return some(tail(requestedPerms), p => hasPermissions(p, permissions));
    }
    return every(requestedPerms, p => hasPermissions(p, permissions));
  }
  return includes(permissions, requestedPerms);
};

const noPermissions = [];

const getPermissionCheckers = permissions => ({
  hasPermissions: requestedPerms => hasPermissions(requestedPerms, permissions),
});

/** @deprecated */
export const getPermissionCheckersForUser = (user) => {
  const permissions = user?.permissions ?? noPermissions;
  return getPermissionCheckers(permissions);
};

/** @deprecated */
export const getRoleCheckerForUser = user => ({
  isRole: r => r === user.role,
  isOneOfRoles: roles => includes(roles, user.role),
});

export const usePermissionChecker = () => {
  const user = useUserContext();
  const permissions = user?.permissions ?? noPermissions;
  return useMemo(() => getPermissionCheckers(permissions), [permissions]);
};

export const useRoleChecker = () => {
  const user = useUserContext();
  const myRole = user.role;
  return useMemo(() => ({
    isRole: r => r === myRole,
    isOneOfRoles: roles => includes(roles, myRole),
  }), [myRole]);
};
