// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  INITIALIZE, INVALIDATE_CUSTOMER, RECEIVE_CUSTOMER, REQUEST_CUSTOMER, SET_CUSTOMER,
} from './actions';
import { extendType } from './extendType';

export function createCustomerReducer(customerTypeValue = '') {
  const customerType = extendType.bind(this, customerTypeValue);

  return function customer(state = {
    isFetching: false,
    didInvalidate: false,
  }, action) {
    switch (action.type) {
      case customerType(INITIALIZE):
        return null;
      case customerType(SET_CUSTOMER):
        return { ...state, ...action.customer };
      case customerType(REQUEST_CUSTOMER):
        return { ...state, ...action.customer };
      case customerType(RECEIVE_CUSTOMER):
        return { ...state, ...action.customer };
      case customerType(INVALIDATE_CUSTOMER):
        return { ...state, ...action.customer };
      default:
        return state;
    }
  };
}
