// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setDirtyOptions, setOptions } from 'services/redux/ServiceActions';
import { Box, FormField } from 'grommet';
import PropTypes from 'prop-types';
import CACheckbox from '../../../../../shared/form/CACheckbox';
import CATextInput from '../../../../../shared/form/CATextInput';

class NutanixBillingMethodField extends Component {
  constructor(props) {
    super(props);
  }

  _isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  _onChangeUtilization(property, value) {
    const { options } = this.props;

    if (value && value.length >= 2 && value.startsWith('0')) {
      value = value.slice(1);
    }

    if (this._isNumber(value)) {
      if (parseFloat(value) > 100) {
        options.config[property] = 100;
      } else if (parseFloat(value) < 0) {
        options.config[property] = 0;
      } else {
        options.config[property] = value;
      }
    } else if (value === '') {
      options.config[property] = 0;
    }
    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _onChangeBillingCapUsage(event) {
    const { options } = this.props;
    options.config.capUsage = event.target.checked;

    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _getNutanix20Fields(readOnly) {
    return (
      <FormField label='Billing Method VM2.0 (GiB)'>
        <Box gap='small' pad={{ vertical: 'small' }}>
          <Box flex='grow' className='tiny-inputs' direction='row' margin={{ left: 'small' }}>
            <CATextInput
              id='input_subscription_ratio'
              label='Subscription Ratio %'
              name='input_subscription_ratio'
              value={this.props.options.config.subscriptionRatio}
              onChange={(value) => {
                this._onChangeUtilization('subscriptionRatio', value);
              }}
              tooltip='Subscription Ratio is the percentage of physical memory available for Nutanixs (the rest is reserved for overhead).'
            />
          </Box>
          <Box flex='grow' className='tiny-inputs' direction='row' gap='small'>
            <div>
              <CACheckbox
                label='Cap usage at Installed Capacity'
                id='check_billing_options_cap_usage'
                name='check_billing_options_cap_usage'
                disabled={readOnly}
                onChange={(event) => {
                  this._onChangeBillingCapUsage(event);
                }}
                checked={this.props.options.config.capUsage || false}
                tooltip='When checked, memory usage exceeding the physical memory of a host due to overprovisioned Nutanixs is not charged for.'
              />
            </div>
          </Box>
        </Box>
      </FormField>
    );
  }

  render() {
    return this._getNutanix20Fields(this.props.readOnly);
  }
}

NutanixBillingMethodField.propTypes = {
  setOptions: PropTypes.func.isRequired,
  setDirtyOptions: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  options: PropTypes.shape({
    config: PropTypes.shape({
      billingMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      capUsage: PropTypes.bool,
      billingMethodUtilization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

const mapStateToProps = ({ service: { details: { options } } }) => ({ options });

const mapDispatchToProps = dispatch => bindActionCreators({
  setOptions,
  setDirtyOptions,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NutanixBillingMethodField);
