// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { useErrorToast } from '../../components/shared/hooks/useErrorToast';
import {
  executeDelete, executeGet, executePost, executePut, executeUpload
} from '../api';
import { ApiContext } from '../../AppContext';

export const useServiceMetersQuery = (serviceType, options) => {
  const path = useContext(ApiContext)('serviceMeters.list.path', { serviceType });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:SERVICE-METERS-LIST', serviceType],
    queryFn: () => executeGet(path),
    ...(options || []),
    enabled: !!serviceType,
  }), 'Fetch Meters Error');
};

export const useServiceMeterDeleteMutate = (options) => {
  const queryClient = useQueryClient();
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation({
    mutationFn: (meterId) => {
      const path = contextFn('serviceMeters.delete.path', { meterId });
      return executeDelete(path);
    },
    ...(options || {}),
    onSuccess: () => {
      addToast({
        status: 'normal',
        title: 'Service Meter was successfully deleted.',
        message: '',
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-METERS-LIST']
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Deleting Meter Failed',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    })
  });
};

export const useServiceMeterImportMutate = (serviceType, options) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const path = useContext(ApiContext)('serviceMeters.import.path', { serviceType });
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      addToast({
        status: 'normal',
        title: 'Service Meters were successfully imported.',
        message: '',
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-METERS-LIST']
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Import Service Meters Failed',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};

export const useServiceMeterCreateMutate = (options) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const path = useContext(ApiContext)('serviceMeters.create.path');
  return useMutation({
    mutationFn: payload => executePost(path, payload),
    ...(options || {}),
    onSuccess: () => {
      addToast({
        status: 'normal',
        title: 'Service Meters were successfully created.',
        message: '',
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-METERS-LIST']
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Creating Service Meters Failed',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};

export const useServiceMeterUpdateMutate = (meterId, options) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const path = useContext(ApiContext)('serviceMeters.update.path', { meterId });
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      addToast({
        status: 'normal',
        title: 'Service Meter was successfully updated.',
        message: '',
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-METERS-LIST']
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Updating Service Meters Failed',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};

export const useServiceMeterValidateMutate = (serviceType, options) => {
  const path = useContext(ApiContext)('serviceMeters.validate.path', { serviceType });
  return useMutation({
    mutationFn: payload => executeUpload(path, payload),
    ...(options || {}),
  });
};
