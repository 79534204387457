// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class BilledByType extends Enumify {
  static COMPASS = new BilledByType('Compass');

  static BRIM = new BilledByType('Brim');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label) {
    super();
    this.label = label;
  }
}
