// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect } from 'react';
import { useAccountKeyDeleteMutate } from '../../../core';
import ConfirmationDialog from '../../shared/dialogs/ConfirmationDialog';
import EncryptionKeyContext from '../components/EncryptionKeyContext';
import { AccountKey } from '../types';

interface EncryptionKeyDeleteDialogProps {
  accountKey: AccountKey;
  onClose: () => void;
}

const EncryptionKeyDeleteDialog: React.FC<EncryptionKeyDeleteDialogProps> = ({
  accountKey, onClose
}) => {
  const {
    mutate: deleteAccountKey,
    isSuccess: isKeyDeleted,
  } = useAccountKeyDeleteMutate(accountKey.accountId);

  useEffect(() => {
    if (isKeyDeleted) {
      onClose();
    }
  }, [isKeyDeleted, onClose]);

  return (
    <ConfirmationDialog
      data={{ accountKey }}
      title='Are You Sure?'
      submitLabel='Yes, delete the Key'
      text="Deleting an Account Encryption Key will immediately permanently erase it from this billing account. Please be absolutely sure you want to completely remove this key from this billing account before choosing 'Yes' below."
      onClose={onClose}
      onChange={() => {
        // @ts-ignore
        deleteAccountKey(accountKey.keyId);
      }}
      details={<EncryptionKeyContext accountKey={accountKey} />}
    />
  );
};

export default EncryptionKeyDeleteDialog;
