// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { OktaAuth } from '@okta/okta-auth-js';

const isProd = (environment) => {
  switch (environment) {
    case 'Prod':
      return true;
    case 'Dev':
      return false;
    default:
      console.error('+++ Missing environment', environment);
  }
  return false;
};

// TODO: In the future we need to adjust the non-prod auth server...
// - we are not benefiting from single sign on GLC --> GLBM... but not...
// hpe-greenlake-hub.oktapreview.com -> auth-itg.hpe.com
export const getBaseConfig = environment => ({
  issuer: isProd(environment) ? 'https://mylogin.hpe.com/oauth2/aus5016946Vxb1HI6697' : 'https://mylogin-itg.hpe.com/oauth2/aus4dauk4l8LnWiTn1d7',
  clientId: isProd(environment) ? '0oa70q6706qcWQGCy697' : '0oa8i7ps8dZMx8oQp1d7',
  redirectUri: `${window.location.origin}/login/callback`,
  postLogoutRedirectUri: `${window.location.origin}/logout`,
  pkce: true,
  cookies: {
    secure: true,
    sessionCookie: false,
  },
  tokenManager: {
    storage: 'sessionStorage',
  },
  responseType: [
    'code',
  ],
  scopes: [
    'email',
    'profile',
    'openid',
    'manager',
  ],
  authParams: {
    issuer: isProd(environment) ? 'https://mylogin.hpe.com/oauth2/aus5016946Vxb1HI6697' : 'https://mylogin-itg.hpe.com/oauth2/aus4dauk4l8LnWiTn1d7',
    pkce: true,
  },
});

const getOktaAuthConfig = (environment, options) => {
  const baseConfig = getBaseConfig(environment);
  const result = { ...baseConfig, ...options };

  const issuer = new URL(result.issuer);
  result.tokenManager.storageKey = `${issuer.hostname}-${result.clientId}-glbm-okta-tokens`;

  return result;
};

const getOktaAuthClient = (environment, options) => {
  const currentAuthConfig = getOktaAuthConfig(environment, options);
  return new OktaAuth(currentAuthConfig);
};

export { getOktaAuthClient, getOktaAuthConfig };
