// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import {
  Box, Button
} from 'grommet';
import PropTypes from 'prop-types';
import GLBMLayer from '../../shared/component/GLBMLayer';
import WiseQuoteCreateEditForm from './WiseQuoteCreateEditForm';
import {
  useQuoteQuery, useQuotesQuery, useWISEIdCreateMutate, useWISEIdUpdateMutate
} from '../../../core';
import GLBMSaving from '../../shared/component/GLBMSaving';

const WiseQuoteIdCreatePage = ({ refreshQuotes = () => {}, quoteId: id = undefined, onClose }) => {
  const [errors, setErrors] = useState({});
  const [quoteId, setQuoteId] = useState('');
  const [scsCodeLicense, setScsCodeLicense] = useState('');
  const [scsCodeSupport, setScsCodeSupport] = useState('');
  const [scsCodeService, setScsCodeService] = useState('');
  const [wbsCodeLicense, setWbsCodeLicense] = useState('');
  const [wbsCodeSupport, setWbsCodeSupport] = useState('');
  const [wbsCodeService, setWbsCodeService] = useState('');

  const requiredFields = ['quoteId'];
  const fieldSizes = {
    quoteId: 30,
    scsCodeLicense: 30,
    scsCodeSupport: 30,
    scsCodeService: 30,
    wbsCodeLicense: 30,
    wbsCodeSupport: 30,
    wbsCodeService: 30,
  };
  const getFieldValue = (fieldName) => {
    const fieldValues = {
      quoteId,
      scsCodeLicense,
      scsCodeSupport,
      scsCodeService,
      wbsCodeLicense,
      wbsCodeSupport,
      wbsCodeService
    };
    return fieldValues[fieldName];
  };

  const {
    data: quotesData,
  } = useQuotesQuery();
  const {
    data: quoteData,
    isSuccess: isQuoteCallSuccess,
  } = useQuoteQuery(id);
  const isNew = (!id);
  const onSaveError = (submitError) => {
    if (submitError?.response?.data?.validationErrors) {
      const { validationErrors } = submitError.response.data;
      validationErrors?.forEach((error) => {
        const attribute = error.propertyName.substring(error.propertyName.lastIndexOf('.') + 1);
        errors[attribute] = [error.message];
      });
      setErrors(prevState => ({
        ...prevState,
        ...errors,
      }));
    }
  };

  const {
    mutate: createWiseId,
    isPending: isCreatingWiseId,
    isSuccess: isCreatingWiseIdSuccess,
  } = useWISEIdCreateMutate({
    onError: onSaveError,
  });

  const {
    mutate: updateWiseId,
    isPending: isUpdatingWiseId,
    isSuccess: isUpdatingWiseIdSuccess,
  } = useWISEIdUpdateMutate(id, {
    onError: onSaveError,
  });
  useEffect(() => {
    if (isCreatingWiseIdSuccess || isUpdatingWiseIdSuccess) {
      onClose();
      refreshQuotes();
    }
  }, [isCreatingWiseIdSuccess, isUpdatingWiseIdSuccess]);

  const onChange = (event) => {
    const { name, value } = event;
    switch (name) {
      case 'quoteId':
        setQuoteId(value);
        break;
      case 'scsCodeLicense':
        setScsCodeLicense(value);
        break;
      case 'scsCodeSupport':
        setScsCodeSupport(value);
        break;
      case 'scsCodeService':
        setScsCodeService(value);
        break;
      case 'wbsCodeLicense':
        setWbsCodeLicense(value);
        break;
      case 'wbsCodeSupport':
        setWbsCodeSupport(value);
        break;
      case 'wbsCodeService':
        setWbsCodeService(value);
        break;
      default:
        break;
    }
    const newErrors = { ...errors };
    newErrors[name] = null;
    setErrors(newErrors);
  };

  const onSubmit = () => {
    let noErrors = true;
    const newErrors = { ...errors };

    // Check required fields
    requiredFields.forEach((field) => {
      if (!getFieldValue(field)) {
        noErrors = false;
        newErrors[field] = 'Required';
      }
    });

    // Check field sizes
    Object.keys(fieldSizes).forEach((field) => {
      const value = getFieldValue(field);
      if (value && value.length > fieldSizes[field]) {
        noErrors = false;
        newErrors[field] = `Maximum length exceeded (${fieldSizes[field]} characters)`;
      }
    });

    // Check uniqueness of quoteId
    const quoteIdValue = getFieldValue('quoteId');
    const isQuoteIdNotUnique = Array.isArray(quotesData) && quotesData.map(quote => quote.quoteId).includes(quoteIdValue);
    if (isQuoteIdNotUnique && !id) {
      noErrors = false;
      newErrors.quoteId = 'WISE Quote ID must be unique';
    }

    if (noErrors) {
      const modifiedQuote = { ...quoteData };
      // Assign the state variables to modifiedQuote
      modifiedQuote.quoteId = quoteId;
      modifiedQuote.scsCodeLicense = scsCodeLicense;
      modifiedQuote.scsCodeSupport = scsCodeSupport;
      modifiedQuote.scsCodeService = scsCodeService;
      modifiedQuote.wbsCodeLicense = wbsCodeLicense;
      modifiedQuote.wbsCodeSupport = wbsCodeSupport;
      modifiedQuote.wbsCodeService = wbsCodeService;
      refreshQuotes();
      if (id) {
        updateWiseId(modifiedQuote);
      } else {
        createWiseId(modifiedQuote);
      }
    } else {
    // If there are errors, set the new error state
      setErrors(newErrors);
    }
  };
  useEffect(() => {
    if (quoteData) {
      setQuoteId(quoteData.quoteId || '');
      setScsCodeLicense(quoteData.scsCodeLicense || '');
      setScsCodeSupport(quoteData.scsCodeSupport || '');
      setScsCodeService(quoteData.scsCodeService || '');
      setWbsCodeLicense(quoteData.wbsCodeLicense || '');
      setWbsCodeSupport(quoteData.wbsCodeSupport || '');
      setWbsCodeService(quoteData.wbsCodeService || '');
    }
  }, [quoteData, isQuoteCallSuccess]);
  return (
    <GLBMLayer
      position='right'
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      closer={true}
      flush={true}
      overlayClose={true}
      full='vertical'
      title={isNew ? 'Create WISE Quote ID' : 'Edit WISE Quote ID'}
      style={undefined}
    >
      <Box
        pad='none'
        style={{ 'width': '600px', 'minWidth': '600px' }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box flex={true} pad={{ horizontal: 'medium' }} overflow='auto'>
          <Box flex={true}>
            <Box flex={false} width='480px'>
              <WiseQuoteCreateEditForm
                quoteData={quoteData}
                errors={errors}
                onChange={onChange}
                quoteId={quoteId}
                scsCodeLicense={scsCodeLicense}
                scsCodeSupport={scsCodeSupport}
                scsCodeService={scsCodeService}
                wbsCodeLicense={wbsCodeLicense}
                wbsCodeSupport={wbsCodeSupport}
                wbsCodeService={wbsCodeService}
                refreshQuotes={refreshQuotes}
              />
            </Box>
          </Box>
        </Box>
        <Box direction='row' gap='small' pad={{ horizontal: 'small', vertical: 'small' }} border='top'>
          <Button
            label='Save'
            type='button'
            primary={true}
            onClick={() => onSubmit()}
            disabled={isUpdatingWiseId || isCreatingWiseId}
          />
          <Button
            label='Cancel'
            type='button'
            secondary={true}
            onClick={onClose}
            disabled={isUpdatingWiseId || isCreatingWiseId}
          />
          <GLBMSaving
            saving={isUpdatingWiseId || isCreatingWiseId}
          />
        </Box>
      </Box>
    </GLBMLayer>
  );
};
WiseQuoteIdCreatePage.contextTypes = {
  router: PropTypes.object,
};

WiseQuoteIdCreatePage.propTypes = {
  refreshQuotes: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  quoteId: PropTypes.string,
};

export default WiseQuoteIdCreatePage;
