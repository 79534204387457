// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { difference } from 'lodash';
import moment from 'moment';
import { ValidationError } from './ValidationErrors';
import { determineTierType, getEquipmentId } from '../Util';
import { ValidationIssue } from './ValidationIssue';
import { LocationOption, ReportBy, TierOptions } from '../model';
import { PartnerType } from '../../shared/constants/PartnerType';
import { getTotalMeterCost } from '../service-edit/steps/rates/MeterList/utils';
import { CommitmentType } from '../model/CommitmentType';
import {
  getEffectiveRevision
} from '../service-edit/steps/configure-resources/components/resource-revision/Util';

const validateSingleTier = ({ customTiers: tiers }) => {
  if (tiers && tiers.length > 0 && tiers[0].name === '') {
    return new ValidationError({ issue: ValidationIssue.OPTIONS_SINGLE_TIER_REQUIRED });
  }
  if (tiers && tiers.length > 0 && !/^[a-zA-Z0-9_]*$/.test(tiers[0].name)) {
    return new ValidationError({ issue: ValidationIssue.OPTIONS_SINGLE_TIER_INVALID });
  }
  return false;
};

const validateCustomTier = ({ customTiers = [] }, count) => {
  if (customTiers.length === 0) {
    return new ValidationError({ issue: ValidationIssue.OPTIONS_CUSTOM_TIERS_REQUIRED });
  }
  if (count > 0) {
    return new ValidationError({ issue: ValidationIssue.OPTIONS_CUSTOM_TIERS_UNACCOUNTED });
  }
  return false;
};

const validateMappedTier = ({ mappedTiers = [] }, count) => {
  if (mappedTiers.length === 0) {
    return new ValidationError({ issue: ValidationIssue.OPTIONS_MAPPED_TIERS_REQUIRED });
  }
  if (count > 0) {
    return new ValidationError({ issue: ValidationIssue.OPTIONS_MAPPED_TIERS_UNACCOUNTED });
  }
  return false;
};

const tierValidation = (config, count) => {
  const tierTypeValidationMap = new Map()
    .set(TierOptions.SINGLE, validateSingleTier.bind(this, config))
    .set(TierOptions.CUSTOM, validateCustomTier.bind(this, config, count))
    .set(TierOptions.MAPPED, validateMappedTier.bind(this, config, count));

  const tierType = determineTierType(config);

  return tierTypeValidationMap.has(tierType) ? tierTypeValidationMap.get(tierType)() : null;
};

const validateLocation = (config, locations) => {
  const { reportBy, location: locationSelection } = config;
  const hasError = reportBy !== ReportBy.TIER.enumKey && locationSelection === LocationOption.MAPPED.enumKey && locations.length === 0;
  if (hasError) {
    return new ValidationError({ issue: ValidationIssue.OPTIONS_LOCATION_REQUIRED });
  }
  return false;
};

const validateDiscountTiers = (discountTiers) => {
  if (discountTiers) {
    return Object.entries(discountTiers).reduce((hasError, [, { rate }]) => hasError || (rate === '' || rate === undefined || rate === null), false);
  }
  return false;
};

const validateAzureStack = (config) => {
  const {
    type, cspId, cspCustomerId, discountTiers,
  } = config;
  const discountTiersError = validateDiscountTiers(discountTiers);
  const hasError = (type === 'AZURE_STACK' && (!cspId || cspCustomerId === null || discountTiersError));
  if (hasError) {
    if (!cspId) {
      return new ValidationError({ issue: ValidationIssue.OPTIONS_AZURE_TENANT_REQUIRED });
    }
    if (cspCustomerId === null) {
      return new ValidationError({ issue: ValidationIssue.OPTIONS_AZURE_TENANT_REQUIRED_ALL });
    }
    if (discountTiersError) {
      return new ValidationError({ issue: ValidationIssue.OPTIONS_AZURE_DISCOUNT_TIERS });
    }
  }
  return false;
};

const validateGoogleAnthos = ({ type, projectExport }) => (type === 'GOOGLE_ANTHOS' && (!projectExport || !projectExport.length)
  ? new ValidationError({ issue: ValidationIssue.OPTIONS_GOOGLE_ANTHOS_PROJECT_EXPORT_REQUIRED })
  : false);

const validateLicense = ({ type, licenseType }) => {
  const isTypeValid = ['SWLIC', 'COHESITY_LICENSES'].includes(type);
  const isLicenseTypeValid = licenseType?.length;

  if (isTypeValid && !isLicenseTypeValid) {
    return new ValidationError({ issue: ValidationIssue.OPTIONS_SWLIC_LICENSE_TYPE_REQUIRED });
  }
  return false;
};

/**
   * @param config
   * @param count
   * @param locations
   * @returns ValidationCritical | Warning
   */
// eslint-disable-next-line @typescript-eslint/default-param-last
export const validateOptions = ({ config }, { count } = { count: 0 }, { locations = [] }) => {
  const tierValidationError = tierValidation(config, count);
  const locationValidationError = validateLocation(config, locations);
  const azureStackValidationError = validateAzureStack(config);
  const googleAnthosValidationError = validateGoogleAnthos(config);
  const licenseValidationError = validateLicense(config);

  return azureStackValidationError || googleAnthosValidationError || licenseValidationError || tierValidationError || locationValidationError;
};

export const validateResources = (customer, options, equipment, serviceType, reportBy, tierType, location, props) => {
  const locationIDs = customer.locations.reduce((arr, l) => {
    arr.push(l.id);
    return arr;
  }, []);
  let mappedTierIds = [];
  if (options?.config?.mappedTiers?.length) {
    mappedTierIds = options.config.mappedTiers.reduce((arr, o) => {
      arr.push(o.id);
      return arr;
    }, []);
  }

  if (equipment?.items?.length) {
    for (let i = 0; i < equipment.items.length; i += 1) {
      const e = equipment.items[i];
      const equipmentId = getEquipmentId(e, serviceType);
      if (e.include) {
        const locationRev = e.locationRevisions && e.locationRevisions.length > 0 ? getEffectiveRevision(e.locationRevisions) : undefined;
        const tierRev = e.mappedTierRevisions && e.mappedTierRevisions.length > 0 ? getEffectiveRevision(e.mappedTierRevisions) : undefined;
        if (reportBy !== 'TIER' && tierType === 'MAPPED' && location === 'MAPPED' && (!locationRev || locationIDs.indexOf(locationRev.id) === -1) && (!tierRev || mappedTierIds.indexOf(tierRev.id) === -1)) { // missing both location + tier
          props.addIssue({
            id: equipmentId,
            issue: ValidationIssue.RESOURCES_MISSING_LOCATION_TIER,
          });
        } else if (reportBy !== 'TIER' && location === 'MAPPED' && (!locationRev || locationIDs.indexOf(locationRev.id) === -1)) { // missing location:
          props.addIssue({
            id: equipmentId,
            issue: ValidationIssue.RESOURCES_MISSING_LOCATION,
          });
        } else if (tierType === 'MAPPED' && (!tierRev || mappedTierIds.indexOf(tierRev.id) === -1)) { // missing tier
          props.addIssue({
            id: equipmentId,
            issue: ValidationIssue.RESOURCES_MISSING_TIER,
          });
        } else {
          props.addIssue({
            id: equipmentId,
            issue: ValidationIssue.VALID,
          });
        }
      }
    }
  }
};

/**
 * Validate that locations with commitments exist where meters with rates exist. Meaning,
 * if a meter has rate > 0, make sure that there is also a location commitment for that meters location.
 * If not --> that's a validation error.
 * @param rates
 * @param locationRates
 * @returns {boolean}
 */
const locationCommitmentValidation = (meters, rates, locationRates) => {
  const locationsWithCommitments = locationRates?.reduce((acc, location) => {
    if (location.commitments?.length > 0) {
      const { locationId } = location;
      if (acc.indexOf(locationId) === -1) {
        acc.push(locationId);
      }
    }
    return acc;
  }, []).sort();

  const locationsWithMeterRates = meters?.reduce((acc, meter) => {
    const { id, locationId } = meter;
    const rate = rates.find(r => r.meterId === id);
    if (rate) {
      const totalCost = getTotalMeterCost(rate);
      if (totalCost > 0) {
        if (locationId && acc.indexOf(locationId) === -1) {
          acc.push(locationId);
        }
      }
    }
    return acc;
  }, []);

  return !(difference(locationsWithMeterRates, locationsWithCommitments).length === 0);
};

export const validateRates = (customer, props, meters, rates, tenant, locationRates, serviceType, tierType) => {
  if (serviceType.commitmentType === CommitmentType.MONETARY && locationCommitmentValidation(meters, rates, locationRates)) {
    props.addIssue({ issue: ValidationIssue.LOCATION_MISSING_COMMITMENTS });
  }

  if (meters?.length) {
    const meterIDs = meters.reduce((arr, meter) => {
      arr.push(meter.id);
      return arr;
    }, []);
    let incorrectRateRevisions = 0;
    if (rates && rates.length) {
      for (let i = 0; i < rates.length; i += 1) {
        let totalRate = 0;
        const r = rates[i];
        if (meterIDs.indexOf(r.meterId) !== -1) {
          const revisions = (r.revisions || r.rateRevisions);
          // lets see how many revisions we have:
          if (Object.keys(revisions).length === 0) {
            // no revisions... definitely no cost...
          } else {
            switch (tenant) {
              case PartnerType.RESELLER.enumKey:
              case PartnerType.DISTRIBUTOR.enumKey:
                Object.keys(revisions)
                  .forEach((revisionValue) => {
                    const revision = revisions[revisionValue];
                    const hasPricingMethod = revision && Object.prototype.hasOwnProperty.call(revision, 'explicitDownstreamRate');
                    if (hasPricingMethod) {
                      totalRate += 1;
                    }
                  });
                break;
              default:
                Object.keys(revisions)
                  .forEach((revisionValue) => {
                    const revision = revisions[revisionValue];
                    // lets see how many tiers this revision has:
                    if (Object.keys(revision.tiers).length === 0) {
                      // no tiers... definitely no cost...
                    } else {
                      // now calculate total cost by adding up all the rates for each tier for each revision:
                      Object.keys(revision.tiers)
                        .forEach((tierValue) => {
                          const tier = revision.tiers[tierValue];
                          totalRate += tier.rate;
                        });
                    }
                  });
            }
          }
          // if we get here for a meter and have a totalRate of zero - then no cost exists at all across revisions:
          if (!r.tierType) {
            props.addIssue({
              id: r.meterId,
              issue: ValidationIssue.RATES_MISSING_TIER_TYPE,
            });
          }
          if (totalRate === 0) {
            switch (tenant) {
              case PartnerType.RESELLER.enumKey:
              case PartnerType.DISTRIBUTOR.enumKey:
                props.addIssue({
                  id: r.meterId,
                  issue: ValidationIssue.RATES_MISSING_PRICING_METHOD,
                });
                break;
              default:
                props.addIssue({
                  id: r.meterId,
                  issue: ValidationIssue.RATES_MISSING_RATE,
                });
                break;
            }
          }
          if (r.tierType === 'TIERED') {
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            r?.rateRevisions?.forEach((currentRate) => {
              const { effectiveDate: rateEffectiveDate } = currentRate;
              const foundCapacity = r.capacityRevisions?.find((cap, index, allCapacities) => {
                const currentEffectiveDate = cap?.effectiveDate;
                const nextEffectiveDate = allCapacities[index + 1]?.effectiveDate;
                return moment(currentEffectiveDate, 'YYYY-MM-DD').isSameOrBefore(moment(rateEffectiveDate, 'YYYY-MM-DD')) && (!nextEffectiveDate || moment(nextEffectiveDate, 'YYYY-MM-DD').isAfter(moment(rateEffectiveDate, 'YYYY-MM-DD')));
              });
              const limit = foundCapacity?.committedCapacityOverrideEnabled ? foundCapacity?.committedCapacityOverride : foundCapacity?.committedCapacity;
              const formattedLimit = limit && (typeof limit === 'string') ? +limit : limit;
              if (Object.keys(currentRate.tiers).some(tier => formattedLimit && (+tier >= formattedLimit))) {
                incorrectRateRevisions += 1;
              }
            });
          }
        }
      }
    }
    if (incorrectRateRevisions > 0) {
      props.addIssue({
        id: 'INCORRECT_RATES_REVISIONS',
        issue: ValidationIssue.getRateRevisionError(incorrectRateRevisions),
      });
    }
  } else {
    props.addIssue({
      id: 'RATES_MISSING_METERS',
      issue: ValidationIssue.RATES_MISSING_METERS,
    });
  }
};
