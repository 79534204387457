// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormField, Select } from 'grommet';
import { range } from 'lodash';
import { setDirtyOptions, setOptions } from '../../../../redux/ServiceActions';

export const GpuUtilizationThresholdField = ({
  options,
  setOptions: setOptionsInjected,
  setDirtyOptions: setDirtyOptionsInjected,
  readOnly = false,
  setDirty
}) => {
  const values = useMemo(() => range(0, 16).map(n => ({ label: n.toString(), value: n })), []);

  const onChange = (property, value) => {
    const updatedOptions = { ...options, config: { ...options.config, [property]: value } };
    setOptionsInjected(updatedOptions);
    setDirtyOptionsInjected();
    setDirty();
  };

  return (
    <FormField
      label='GPU memory utilization'
    >
      <Select
        data-testid={readOnly ? 'disabled-select' : 'select'}
        disabled={readOnly === true}
        placeHolder='None'
        name='input_gpuMemoryUtilization'
        inline={false}
        multiple={false}
        labelKey='label'
        valueKey='value'
        value={values.find(option => options?.config?.gpuMemoryUtilization === option.value)}
        options={values}
        onChange={event => onChange('gpuMemoryUtilization', event.option.value)}
      />
    </FormField>
  );
};

GpuUtilizationThresholdField.propTypes = {
  setOptions: PropTypes.func.isRequired,
  setDirtyOptions: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  options: PropTypes.shape({
    config: PropTypes.shape({
      gpuMemoryUtilization: PropTypes.oneOfType([PropTypes.number]),
    }),
  }).isRequired,
  readOnly: PropTypes.bool,
};

const mapStateToProps = ({ service: { details: { options } } }) => ({ options });

const mapDispatchToProps = dispatch => bindActionCreators({
  setOptions,
  setDirtyOptions,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GpuUtilizationThresholdField);
