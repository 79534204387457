// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { setDirtyOptions, setOptions } from 'services/redux/ServiceActions';
import { ServiceStep } from 'services/model/ServiceStep';
import { FormField } from 'grommet';
import PropTypes from 'prop-types';
import CACheckbox from '../../../../../shared/form/CACheckbox';
import { useValidationMutation } from '../../../../../../core';

const LicenseTypesBillingMethodField = (props) => {
  const { mutate: fetchValidationIfNeeded } = useValidationMutation(ServiceStep.OPTIONS);

  const _onChangeLicenseType = (licenseType) => {
    const { options } = props;
    if (!Array.isArray(options.config.licenseType)) {
      options.config.licenseType = [];
    }
    if (options.config.licenseType.indexOf(licenseType) !== -1) {
      options.config.licenseType.splice(options.config.licenseType.indexOf(licenseType), 1);
    } else {
      options.config.licenseType.push(licenseType);
    }
    props.setOptions(options);
    props.setDirtyOptions();
    props.setDirty();
    fetchValidationIfNeeded();
  };

  const _hasLicenseType = (licenseType) => {
    const { options } = props;
    return (options.config.licenseType ? options.config.licenseType.indexOf(licenseType) !== -1 : false);
  };

  const _getEditableLayout = () => {
    const licenseTypes = props.options.config.supportedLicenseTypes;
    const licenseTypesArray = Object.entries(licenseTypes).sort((a, b) => a[1].localeCompare(b[1]));

    const checkBoxes = licenseTypesArray.map((licenseType) => {
      const type = licenseType[0];
      return (
        <CACheckbox
          key={`radio_license_type_${type}`}
          label={<FormattedMessage id={`fc.service.license.type.${type}`} />}
          id={`radio_license_type_${type}`}
          name={`radio_license_type_${type}`}
          onChange={() => _onChangeLicenseType(type)}
          checked={_hasLicenseType(type)}
          disabled={props.readOnly}
        />
      );
    });

    return (
      <FormField label='License Types'>
        {checkBoxes}
      </FormField>
    );
  };

  return _getEditableLayout();
};

LicenseTypesBillingMethodField.propTypes = {
  setOptions: PropTypes.func.isRequired,
  setDirtyOptions: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  options: PropTypes.shape({
    config: PropTypes.shape({
      licenseType: PropTypes.oneOfType([PropTypes.array]),
    }),
  }),
};

const mapStateToProps = ({ service: { details: { options } } }) => ({ options });

const mapDispatchToProps = dispatch => bindActionCreators({
  setOptions,
  setDirtyOptions,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LicenseTypesBillingMethodField);
