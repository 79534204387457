// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class DealType extends Enumify {
  static DIRECT = new DealType('Direct Customer');

  static RESELLER = new DealType('Reseller');

  static DISTRIBUTOR_RESELLER = new DealType('Distributor and Reseller');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label) {
    super();
    this.label = label;
  }
}
