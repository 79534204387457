// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';

import { Line } from '@nivo/line';

const StateSparklineFormatter = ({ value = undefined }) => {
  if (value) {
    return (
      <Line
        width={100}
        height={25}
        data={[{ id: 'line', data: value && value.map((item, index) => ({ y: item, x: index })) }]}
        margin={{
          top: 2, right: 0, bottom: 2, left: 0,
        }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear', min: 'auto', max: 'auto',
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        enablePoints={false}
        colors={['#01A982']}
        lineWidth={1}
        isInteractive={false}
        useMesh={true}
        legends={[]}
      />
    );
  }

  return (<span>Total:</span>);
};

StateSparklineFormatter.propTypes = {
  value: PropTypes.array,
};

export default StateSparklineFormatter;
