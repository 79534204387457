// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class ServicePrecisionOption extends Enumify {
  static UNIT = new ServicePrecisionOption('Unit Precision', 'unitPrecision');

  static RATE = new ServicePrecisionOption('Rate Precision', 'ratePrecision');

  static _ = this.closeEnum();

  constructor(label, field) {
    super();
    this.label = label;
    this.field = field;
  }
}
