// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import * as Case from 'case';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box, Button, Footer, Header, Text,
} from 'grommet';
import { FormPreviousLink } from 'grommet-icons';
import PropTypes from 'prop-types';
import IDUtil from '../shared/util/IDUtil';
import WarningBanner from '../shared/banner/WarningBanner';
import WizardStepValidation from './WizardStepValidation';

class Wizard extends Component {
  constructor(props) {
    super(props);

    this._prev = this._prev.bind(this);
    this._next = this._next.bind(this);
    this._finish = this._finish.bind(this);
    this._cancel = this._cancel.bind(this);
    this._navButtons = this._navButtons.bind(this);
    this._renderStepTitle = this._renderStepTitle.bind(this);

    this.state = {
      model: props.model || {},
      currentStepIndex: (this.props.forcePage) ? this.props.forcePage : 0,
      callback: props.callback,
      origin: props.origin,
    };
  }

  // Change to previous step
  _prev() {
    // if step has a 'onNext' function, call it...
    if (this.props.children[this.state.currentStepIndex].props.onPrev) {
      this.props.children[this.state.currentStepIndex].props.onPrev();
    }

    const prevStepIndex = Math.max(this.state.currentStepIndex - 1, 0);
    this.setState({
      currentStepIndex: prevStepIndex,
    });
  }

  _canNext() {
    const props = this.props.validation.properties;
    if (props && props.hasIssues('critical')) {
      return false;
    }
    if (this.props?.validation?.errors) {
      return false;
    }
    return true;
  }

  // Change to next step
  _next() {
    if (this.props.children[this.state.currentStepIndex].props.validation()) {
      // if step has a 'onNext' function, call it...
      if (this.props.children[this.state.currentStepIndex].props.onNext) {
        this.props.children[this.state.currentStepIndex].props.onNext();
      }

      const nextStepIndex = Math.min(this.state.currentStepIndex + 1, this.props.children.length - 1);
      this.setState({
        currentStepIndex: nextStepIndex,
      });
    } else {
      console.warn('INVALID STEP!');
    }
  }

  // Finish callback or noop if not assigned
  _finish() {
    this.state.callback(this.state.model);
  }

  // If they click Back on the first step
  _cancel() {
    window.history.back();
  }

  _navButtons() {
    const buttons = [];

    // Previous:
    if (this.state.currentStepIndex === 0) {
      buttons.push(
        <Button
          id={IDUtil.getId('WizardPreviousButton')}
          key='previous-button'
          label={this.state.origin ? `Back to ${Case.title(this.state.origin)}` : 'Previous'}
          onClick={this._cancel}
          secondary={true}
        />,
      );
    } else {
      buttons.push(
        <Button
          key='previous-button'
          id={IDUtil.getId('WizardPreviousButton')}
          label={`Previous - ${this.props.children[this.state.currentStepIndex - 1].props.label}`}
          onClick={this._prev}
          secondary={true}
        />,
      );
    }

    // Next/Save:
    if (this.state.currentStepIndex === this.props.children.length - 1) {
      if (this.state.callback) {
        buttons.push(
          <Box direction='row' gap='small' align='center' key='wizardButtons'>
            <WizardStepValidation step={this.props.children[this.state.currentStepIndex].props.type} />
            <Button
              label='Save'
              primary={true}
              key='finish-button'
              id={IDUtil.getId('WizardFinishButton')}
              onClick={this._finish}
              disabled={!this._canNext()}
            />
          </Box>,
        );
      } else {
        buttons.push(
          <Box direction='row' gap='small' align='center' key='wizardButtons'>
            <WizardStepValidation step={this.props.children[this.state.currentStepIndex].props.type} />
            <Button
              label='Close'
              secondary={true}
              key='finish-button'
              id={IDUtil.getId('WizardFinishButton')}
              onClick={this._cancel}
            />

          </Box>,
        );
      }
    } else {
      buttons.push(
        <Box direction='row' gap='small' align='center' key='wizardButtons'>
          <WizardStepValidation step={this.props.children[this.state.currentStepIndex].props.type} />
          <Button
            label={`Next - ${this.props.children[this.state.currentStepIndex + 1].props.label}`}
            primary={true}
            key='next-button'
            id={IDUtil.getId('WizardNextButton')}
            onClick={this._next}
            disabled={!this._canNext()}
          />
        </Box>,
      );
    }

    return (
      <Box border='top' direction='row' gap='small' pad={{ horizontal: 'medium', vertical: 'small' }} flex={false}>
        <Footer flex={true} justify='between'>
          {buttons}
        </Footer>
      </Box>
    );
  }

  _renderStepTitle(currentStep) {
    if (this.props.children.length > 1) {
      return (`Step ${this.state.currentStepIndex + 1} of ${this.props.children.length} - ${currentStep.props.label}`);
    }
    return currentStep.props.label;
  }

  _isDirty() {
    return (this.props.dirtyState.options || this.props.dirtyState.resources || this.props.dirtyState.rates);
  }

  _renderWarning() {
    if (this.props.dirtyWarning && this._isDirty()) {
      if (this.props.customer.dealType === 'DIRECT') {
        return (
          <WarningBanner
            message='The changes you are making could affect historical reports. If this is not your intention, please cancel these changes and start again.'
          />
        );
      }
      return (
        <WarningBanner
          message='The changes you are making could affect historical reports and may reset downstream rates, which would require partners to re-enter rates after the change. If this is not your intention, please cancel these changes and start again.'
        />
      );
    }
    if (this.props?.customer?.contractEndMonth) {
      return (
        <WarningBanner
          message="This billing account has End Month set. Any changes made could affect historical data but will not affect anything after the 'End Month'"
        />
      );
    }
  }

  render() {
    const currentStep = this.props.children[this.state.currentStepIndex];

    return (
      <Box direction='column' fill='vertical'>
        { this._renderWarning()}
        <Header justify='start' gap='small' align='center' pad={{ left: 'small' }}>
          <Button
            kind='toolbar'
            id={IDUtil.getId('WizardPreviousLink')}
            hoverIndicator={true}
            plain={true}
            onClick={this._cancel}
            icon={<FormPreviousLink size='xxlarge' color='brand' />}
          />
          <Text size='xlarge' weight='bold'>{this.props.title}</Text>
        </Header>
        <Box flex={false} pad={{ horizontal: 'medium', bottom: 'small' }}>
          <Text size='large' weight='normal'>{this._renderStepTitle(currentStep)}</Text>
        </Box>
        <Box flex={true}>
          { currentStep }
        </Box>
        { this._navButtons() }
      </Box>
    );
  }
}

/**
 * This function is called to let us know about the state changes in the store. The goal here is to return from the overall store, what
 * THIS component cares about from the uber-store. In this case, this panel wants to for now only see the 'service' options (ie. first step in Blades wizard):
 */
const mapStateToProps = store => ({
  validation: store.service.details.validation,
  dirtyState: store.service.details.dirtyState,
  customer: store.service.details.customer,
});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

Wizard.contextTypes = {
  router: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Wizard);
