// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Footer, FormField, Select
} from 'grommet';
import PropTypes from 'prop-types';
import {
  useExtractDigitalMonthlyBusinessReviewPeriodsQuery,
  useQueryExtractDigitalMonthlyBusinessReviewData,
} from '../../../../core';
import ErrorBanner from '../../../shared/banner/ErrorBanner';
import GLBMLayer from '../../../shared/component/GLBMLayer';

const ExtractDigitalMonthlyBusinessReviewData = ({ action, onClose }) => {
  const [period, setPeriod] = useState();
  const [submitted, setSubmitted] = useState(false);

  const {
    mutate: extract, isPending, isError, error: extractError, isSuccess
  } = useQueryExtractDigitalMonthlyBusinessReviewData();

  const periodValuesQuery = useExtractDigitalMonthlyBusinessReviewPeriodsQuery();

  useEffect(() => {
    if (periodValuesQuery.status === 'success' && periodValuesQuery.data && !period) {
      setPeriod(periodValuesQuery.data[0].id);
    }
  }, [period, periodValuesQuery]);

  const periodOptions = useMemo(() => (
    periodValuesQuery.status === 'success' && periodValuesQuery.data
      ? periodValuesQuery.data.sort(({ id: aDate }, { id: bDate }) => {
        const aMoment = moment(aDate, 'YYYY-MM');
        const bMoment = moment(bDate, 'YYYY-MM');
        return bMoment - aMoment; // Newest first
      }).map(el => ({
        label: moment(el.id, 'YYYY-MM').format('MMM YYYY'),
        value: el.id,
        lastModified: moment(el.lastModified).format('ll'),
      }))
      : []
  ), [periodValuesQuery]);

  const errors = useMemo(() => ({
    period: period ? undefined : 'Required'
  }), [period]);

  const hasErrors = useMemo(() => Object.values(errors).some(error => error !== undefined), [errors]);

  const lastModified = useMemo(() => {
    if (period) {
      const selectedOption = periodOptions.find(el => (el.value === period));
      if (selectedOption) {
        return selectedOption.lastModified;
      }
    }
    return '-';
  }, [period, periodOptions]);

  const onExtract = () => {
    setSubmitted(true);
    if (!hasErrors && period) {
      extract(period);
    }
  };

  // close the dialog when the extract is successful:
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <GLBMLayer
      a11yTitle={`Extract: ${action.name}`}
      data-testid='exec-params-layer'
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      position='right'
      full='vertical'
      title={action.name}
    >
      <Box direction='column' gap='small' flex={true} margin='small'>
        <FormField
          required={true}
          label='Period'
          error={submitted && errors.period}
          info={`Last modified: ${lastModified}`}
        >
          <Select
            options={periodOptions}
            value={period}
            labelKey='label'
            valueKey={{
              key: 'value',
              reduce: true,
            }}
            onChange={({ value: nextValue }) => setPeriod(nextValue)}
            placeholder='Select Period'
          />
        </FormField>
      </Box>
      {isError && (
        <Box margin='medium'>
          <ErrorBanner
            title={`Unable to extract ${action.name}`}
            message={extractError.message}
          />
        </Box>
      )}
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Extract'
              type='button'
              primary={true}
              onClick={onExtract}
              busy={isPending}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ExtractDigitalMonthlyBusinessReviewData.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    inputs: PropTypes.elementType,
    apiPath: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExtractDigitalMonthlyBusinessReviewData;
