// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useEffect } from 'react';
import { useToast } from '../toast/ToastProvider';
import ExportUtil from '../util/ExportUtil';

export const useDownloadCsvFile = (result, fileName) => {
  const { addToast } = useToast();
  useEffect(() => {
    if (result.isSuccess) {
      ExportUtil.downloadCSV(fileName, result.data);
      addToast({
        status: 'normal',
        title: `${fileName} downloaded`,
      });
    }
    // show fn changes on every render, so don't include in the effect deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.isSuccess, fileName, result.data]);
  return result;
};
