// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { combineReducers } from 'redux';

import { auth } from '../authentication/redux/reducers';
import { alertList, alertUpload } from '../alerts/redux/AlertReducers';
import { companyList } from '../companies/redux';
import { psa } from '../customers/redux/PSAReducers';
import {
  context,
  dirtyComponents,
  dirtyEquipment,
  dirtyState,
  equipment,
  locationRates,
  meters,
  options,
  originalOptions,
  permissions,
  rates,
  validation,
} from '../services/redux/ServiceReducers';
import { usageList } from '../usage/redux/UsageReducers';
import { jobList } from '../jobs/redux/JobReducers';
import { createCustomerReducer } from './higher-order-reducers/reducers';
import { SERVICE_EDITOR } from '../services/redux/ServiceActions';
import { BILLING_EDITOR } from '../customers/redux/CustomerActions';
import { customerAdmin, migrationAdmin, processedFileAdmin } from '../administration/redux/AdministrationReducers';
import { partnerList } from '../partners/redux';
import {
  analyticsList, capacityPlanning,
} from '../analytics/redux';
import { serviceMappingList } from '../serviceMappings/redux/ServiceMappingsReducers';
import { serviceMeterList } from '../serviceMeters/redux/ServiceMeterReducers';
import {
  customerDetails, customerList, customerMeter, partnerConfig,
} from '../customers/redux/CustomerReducers';

const fcPortalApp = combineReducers({
  auth,
  alert: combineReducers({
    list: alertList,
    upload: alertUpload,
  }),
  serviceMappings: combineReducers({
    list: serviceMappingList,
  }),
  serviceMeters: combineReducers({
    list: serviceMeterList,
  }),
  usage: combineReducers({
    list: usageList,
  }),
  job: combineReducers({
    list: jobList,
  }),
  administration: combineReducers({
    customerActions: customerAdmin,
    migrationOptions: migrationAdmin,
    processedFilesOptions: processedFileAdmin,
  }),
  partner: combineReducers({
    list: partnerList,
  }),
  billingEditor: combineReducers({
    customer: createCustomerReducer(BILLING_EDITOR),
  }),
  service: combineReducers({
    details: combineReducers({
      customer: createCustomerReducer(SERVICE_EDITOR),
      options,
      originalOptions,
      equipment,
      meters,
      rates,
      locationRates,
      dirtyEquipment,
      dirtyComponents,
      permissions,
      validation,
      dirtyState,
      context,
    }),
  }),
  analytics: combineReducers({
    // invoice,
    // meter,
    capacityPlanning,
    list: analyticsList,
  }),
  customer: combineReducers({
    list: customerList,
    details: customerDetails,
    partner: partnerConfig,
    meter: customerMeter,
  }),
  psa,
  company: combineReducers({
    list: companyList,
  }),
});

// @ts-ignore
export default fcPortalApp;
