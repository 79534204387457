// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { Drag } from 'grommet-icons';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getItemStyle, getListStyle } from './utils';
import { Meter } from './Meter';

export const Service = ({
  location, service, index, options,
}) => (
  <div>
    <Draggable
      draggableId={`${location.id}&&${service.type}`}
      index={index}
      type={`SERVICE&&${location.id}`}
      isDragDisabled={options.services !== 'MANUAL'}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          <Box className='draggable-header service' direction='row' alignContent='center'>
            <div {...provided.dragHandleProps}>
              {
                    options.services === 'MANUAL' && <Drag />
                  }
            </div>
            <strong>{service.name}</strong>
          </Box>
          <Droppable droppableId={`${location.id}&&${service.type}`} type={`METER&&${location.id}&&${service.type}`}>
            {(innerProvided, innerSnapshot) => (
              <div
                ref={innerProvided.innerRef}
                style={getListStyle(innerSnapshot.isDraggingOver)}
              >
                {
                    service.meters.map((meter, meterIndex) => (
                      <Meter
                        location={location}
                        service={service}
                        meter={meter}
                        index={meterIndex}
                        options={options}
                        key={`service&&${location.id}${service.type}-${meter.id}`}
                      />
                    ))
                  }
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  </div>
);

Service.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  service: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.node,
    meters: PropTypes.array,
  }).isRequired,
  index: PropTypes.any.isRequired,
  options: PropTypes.shape({
    services: PropTypes.string,
  }).isRequired,
};
