// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
export class EquipmentColumn {
  constructor(column) {
    this.column = column;
  }

  header = () => this.column.header;

  field = () => this.column.field;

  type = () => this.column.type;

  unique = () => this.column.unique;

  drilldown = () => this.column.drilldown;
}
