// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useQuery } from '@tanstack/react-query';
import moment from 'moment/moment';
import { useContext } from 'react';
import { ApiContext } from '../../AppContext';
import { useDownloadCsvFile, useErrorToast } from '../../components/shared/hooks';
import { executeExportCSVPost, executeGet, executePost } from '../api';

const acceptableConversionUnits = ['Each', 'PB', 'PiB', 'TB', 'TiB', 'GB', 'GiB', 'MB', 'MiB', 'KB', 'KiB', 'Bytes', 'Byte'];
const sortByUsed = (a, b) => {
  const aPct = a.used / a.usable;
  const bPct = b.used / b.usable;
  if (aPct === bPct) {
    return 0;
  }
  return aPct < bPct ? 1 : -1;
};

export const useQueryCapacityDownload = (enableQuery, billingAccount, serviceType, groupby, monthsBack, fieldDisplayName, monthsBackForForecast, forecastMonths, outputUnit) => {
  const path = useContext(ApiContext)('capacity.export.path', { accountId: billingAccount });
  const payload = {
    accountId: billingAccount,
    serviceType,
    field: groupby,
    monthsBack,
    fieldDisplayName,
    monthsBackForForecast,
    forecastMonths,
    outputUnits: acceptableConversionUnits.includes(outputUnit) ? outputUnit : null,
  };

  const start = moment.utc().subtract(monthsBack, 'M').format('YYYY-MM-DD');
  const end = moment.utc().format('YYYY-MM-DD');
  const fileName = decodeURI(`${billingAccount}_CapacityPlanning_${start}_${end}.csv`);

  return useDownloadCsvFile(useQuery({
    queryKey: ['cap-download', billingAccount, serviceType, groupby, monthsBack, fieldDisplayName, monthsBackForForecast, forecastMonths, outputUnit],
    queryFn: () => executeExportCSVPost(path, payload),
    enabled: enableQuery,
    gcTime: 0,
  }), fileName);
};

export const useQueryCapacityHistogram = (accountId, locationId, groupBy, group, monthsBack, monthsBackForForecast, forecastMonths, displayUnit) => {
  const path = useContext(ApiContext)('capacity.histogram.path', { accountId });
  const payload = {
    accountId,
    locationId: locationId === 'ALL' ? null : locationId,
    serviceType: group.serviceType,
    fieldName: groupBy.fieldName,
    fieldValue: group.groupValue,
    units: group.unit,
    monthsBack,
    monthsBackForForecast,
    forecastMonths,
    outputUnits: acceptableConversionUnits.includes(displayUnit) && group.unit !== displayUnit ? displayUnit : null,
    serviceCategory: group.serviceCategory,
  };
  return useErrorToast(useQuery({
    queryKey: ['cap-histogram', { ...payload }],
    queryFn: () => executePost(path, payload),
    retry: false,
  }), 'Fetch Meters Error');
};

export const useQueryCapacityGroups = (enableQuery, accountId, locationType, serviceType, groupBy, monthsBack, outputUnits) => {
  const path = useContext(ApiContext)('capacity.groups.path', { accountId });
  const payload = {
    accountId,
    locationId: locationType === 'ALL' ? null : locationType,
    serviceType,
    field: groupBy,
    monthsBack,
    outputUnits: acceptableConversionUnits.includes(outputUnits) ? outputUnits : null,
  };

  return useQuery({
    queryKey: ['cap-groups', accountId, locationType, serviceType, groupBy, monthsBack, outputUnits],
    queryFn: () => executePost(path, payload),
    enabled: enableQuery && accountId !== null && serviceType !== null && groupBy !== null,
    select: data => []
      .concat(data.filter(item => item.band === 'HIGH').sort(sortByUsed))
      .concat(data.filter(item => item.band === 'LOW').sort(sortByUsed))
      .concat(data.filter(item => item.band === 'OPTIMAL').sort(sortByUsed))
      .concat(data.filter(item => (item.band !== 'HIGH' && item.band !== 'LOW' && item.band !== 'OPTIMAL')).sort(sortByUsed))
  });
};

export const useQueryCapacityOptions = (enableQuery, accountId) => {
  const path = useContext(ApiContext)('capacity.locationsOptions.path', { accountId });
  return useQuery({
    queryKey: ['cap-location-options', accountId],
    queryFn: () => executeGet(path),
    enabled: enableQuery && accountId !== null,
  });
};
