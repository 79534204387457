// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Box, Text } from 'grommet';
import * as PropTypes from 'prop-types';

export const ListPlaceholder = ({
  addControl, emptyMessage = 'None', filteredTotal, unfilteredTotal,
}) => {
  let content1; let
    content2;
  if (unfilteredTotal === 0) {
    content1 = <span className='secondary'>{emptyMessage}</span>;
    content2 = addControl;
  } else if (filteredTotal === 0) {
    content1 = (
      <Text>No matches</Text>
    );
  }
  if (content1) {
    content1 = (
      <Box
        justify='center'
        align='center'
        pad={{
          horizontal: 'medium', vertical: 'large', between: 'medium',
        }}
      >
        {content1}
        {content2}
      </Box>
    );
  }
  return content1 || null;
};

ListPlaceholder.propTypes = {
  addControl: PropTypes.element,
  emptyMessage: PropTypes.string,
  filteredTotal: PropTypes.number,
  unfilteredTotal: PropTypes.number,
};
