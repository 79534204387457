// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  Box, Button, CheckBox, Footer, Main
} from 'grommet';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';

import {
  useCustomerQuery,
  useFeaturesQuery,
  useFeaturesUpdateMutate,
} from '../../core';
import GLBMHeading from '../shared/component/GLBMHeading';
import { pagePermissions } from '../shared/constants/Permissions';
import SaveChangesDialog from '../shared/dialogs/SaveChangesDialog';
import { usePermissionChecker } from '../shared/hooks';
import ListItem from '../shared/list/ListItem';
import Loader from '../shared/loader';
import IDUtil from '../shared/util/IDUtil';

const FeaturesListPage = () => {
  const { hasPermissions } = usePermissionChecker();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const [allowPrompt, setAllowPrompt] = useState(false);
  const [customer, setCustomer] = useState();

  const {
    data,
    isSuccess,
    isFetching: isFetchingCustomer,
  } = useCustomerQuery(customerId);

  useEffect(() => {
    if (isSuccess) {
      setCustomer(data);
    }
  }, [isSuccess, data]);

  const originalFeatureSelection = useMemo(() => {
    if (customer) {
      return customer.features.sort();
    }
    return [];
  }, [customer]);

  const {
    data: features,
    isFetching: isFetchingFeatures,
  } = useFeaturesQuery();

  const {
    mutate: updateFeatures,
  } = useFeaturesUpdateMutate(customerId, {
    onSuccess: () => setTimeout(() => {
      navigate('/customers');
    }, 100),
  });

  const onSubmitFeatures = () => {
    updateFeatures(customer.features);
    setAllowPrompt(false);
  };

  const onClickCancel = () => {
    setAllowPrompt(false);
    setTimeout(() => {
      navigate('/customers');
    }, 0);
  };

  const isFeatureSelected = featureId => (customer ? customer.features.includes(featureId) : false);

  const onCheckboxChange = (featureId, checked) => {
    if (checked) {
      if (!customer.features.includes(featureId)) {
        const updatedCustomer = { ...customer };
        updatedCustomer.features.push(featureId);
        setCustomer(updatedCustomer);
      }
    } else {
      const updatedCustomer = { ...customer };
      updatedCustomer.features = updatedCustomer.features.filter(featureIdIndex => featureIdIndex !== featureId);
      setCustomer(updatedCustomer);
    }
  };

  const getFeatureList = () => {
    if (!customer || !features) {
      return null;
    }

    const elements = features.map(feature => (
      <ListItem
        justify='start'
        flex={true}
        direction='row'
        border='horizontal'
        key={feature.id}
        id={`feature-${feature.id}`}
      >
        <CheckBox
          toggle={true}
          checked={isFeatureSelected(feature.id)}
          onChange={event => onCheckboxChange(feature.id, event.target.checked)}
        />
        {' '}
        <span>{feature.name}</span>
      </ListItem>
    ));

    return (
      <Box>
        {elements}
      </Box>
    );
  };

  const isDirty = useMemo(() => {
    if (!customer || !allowPrompt) {
      return false;
    }
    return customer.status === 'BILLABLE' && customer.features.sort()
      .join() !== originalFeatureSelection;
  }, [customer, allowPrompt, originalFeatureSelection]);

  const canEdit = features?.length && hasPermissions(pagePermissions.customers.view.features.actions.edit);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title={`Features: ${customer ? `${customer.name} (${customer.id})` : ''}`}
        back='/customers'
      />
      {isFetchingCustomer || isFetchingFeatures ? (
        <Box direction='row' align='center' gap='small' justify='center' fill={true}>
          <Loader text='Loading. Please wait ...' />
        </Box>
      ) : null}
      <Box flex={true} direction='column' fill='horizontal'>
        {getFeatureList()}
      </Box>

      <Box
        direction='row'
        gap='small'
        pad={{
          horizontal: 'small',
          vertical: 'small',
        }}
        flex={false}
        border='top'
      >
        {canEdit
          ? (
            <Footer gap='small'>
              <Button
                label='Save'
                type='button'
                primary={true}
                id={IDUtil.getId('EditorViewToolbarSaveButton')}
                onClick={onSubmitFeatures}
              />
              <Button
                label='Cancel'
                type='button'
                secondary={true}
                id={IDUtil.getId('EditorViewToolbarCancelButton')}
                onClick={() => onClickCancel()}
              />
            </Footer>
          )
          : (
            <Button
              label='Close'
              type='button'
              secondary={true}
              id={IDUtil.getId('EditorViewToolbarCancelButton')}
              onClick={() => onClickCancel()}
            />
          )}
      </Box>
      <ReactRouterPrompt when={isDirty}>
        {({
          onConfirm,
          onCancel,
        }) => (
          <SaveChangesDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        )}
      </ReactRouterPrompt>
    </Main>
  );
};

FeaturesListPage.contextTypes = {
  router: PropTypes.object,
};

export default FeaturesListPage;
