// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { EventEmitter } from 'events';
import AppDispatcher from '../dispatcher/AppDispatcher';
import UserConstants from '../constants/UserConstants';

const CHANGE_EVENT = 'change';

class UserTypeStoreClass extends EventEmitter {
  constructor() {
    super();
    this.roles = [];
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getRoles() {
    return this.roles;
  }

  setRoles(roles) {
    this.roles = roles;
  }

  getTypesEnum() {
    const res = {};
    this.roles.forEach((el) => {
      res[el.id] = { value: el.id, label: el.displayName, ...el };
    });
    return res;
  }
}

const UserTypeStore = new UserTypeStoreClass();

export const isUnassignedRole = role => (role === UserTypeStore.getTypesEnum()?.UNASSIGNED?.value || role === UserTypeStore.getTypesEnum()?.REVOKED?.value);
export const isAssignedRole = role => !!(role && !isUnassignedRole(role));

UserTypeStore.dispatchToken = AppDispatcher.register((action) => {
  switch (action.actionType) {
    case UserConstants.LOAD_USER_TYPE_DEFINITIONS:
      UserTypeStore.setRoles(action.types);
      UserTypeStore.emitChange();
      break;
    default:
  }
});

export default UserTypeStore;
