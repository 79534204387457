// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import {
  Radial,
  StatusCriticalSmall,
  StatusDisabledSmall,
  StatusGoodSmall,
  StatusInfo,
  StatusWarningSmall,
} from 'grommet-icons';

export const StatusIcon = ({ size = undefined, value = undefined, useColor = true }) => {
  const getColor = c => (useColor ? c : 'text-strong');
  switch (value) {
    case 'ok': return (<StatusGoodSmall color={getColor('status-ok')} size={size} />);
    case 'unknown': return (<Radial color={getColor('status-unknown')} size={size} />);
    case 'warning': return (<StatusWarningSmall color={getColor('status-warning')} size={size} />);
    case 'error': return (<StatusCriticalSmall color={getColor('status-error')} size={size} />);
    case 'critical': return (<StatusCriticalSmall color={getColor('status-critical')} size={size} />);
    case 'disabled': return (<StatusDisabledSmall color={getColor('status-disabled')} size={size} />);
    case 'info': return (<StatusInfo color={getColor(undefined)} size={size} />);
    default: return '';
  }
};

StatusIcon.propTypes = {
  size: PropTypes.string,
  value: PropTypes.string,
  useColor: PropTypes.bool,
};
