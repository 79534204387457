// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { updateStateAndSession } from '../../session/sessionUtil';

export const UPDATE_ANALYTICS_PAGE_FILTERS = 'UPDATE_ANALYTICS_PAGE_FILTERS';
export const UPDATE_INVOICE_MODES = 'UPDATE_INVOICE_MODES';
export const REQUEST_CAPACITY_PLANNING = 'REQUEST_CAPACITY_PLANNING';
export const RECEIVE_CAPACITY_PLANNING = 'RECEIVE_CAPACITY_PLANNING';

function updateFilters(filters) {
  return {
    type: UPDATE_ANALYTICS_PAGE_FILTERS,
    filters,
  };
}

export const updateAnalyticsPageFiltersAndSession = updateStateAndSession(updateFilters);

function updateInvoiceModes(modes) {
  return {
    type: UPDATE_INVOICE_MODES,
    modes,
  };
}

export const updateInvoiceModesAndSession = updateStateAndSession(updateInvoiceModes);
