// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Footer,
  Form,
  Notification,
  Text,
} from 'grommet';
import keys from 'lodash/keys';
import map from 'lodash/map';
import { cloneDeep, forEach } from 'lodash';
import toNumber from 'lodash/toNumber';
import GLBMLayer from '../shared/component/GLBMLayer';
import IDUtil from '../shared/util/IDUtil';
import { useQueryCapacityAlertExceptionDefinitions } from '../../core';
import Loader from '../shared/loader';
import type { CapacityAlertSettingsV2 } from '../../core/types';
import ServiceTypeExceptionsTable from './ServiceTypeExceptionsTable';
import { ExceptionsFormValues } from './types';

type Threshold = CapacityAlertSettingsV2['categories'][number];
interface Props {
  threshold: Threshold
  onChange: (type: string, threshold: Threshold) => void
  customerId: string
  onClose: () => void
  canEdit: boolean
}

const ExceptionsEditor = ({
  threshold,
  onChange,
  onClose,
  customerId,
  canEdit,
}: Props) => {
  const [formValues, setFormValues] = useState<ExceptionsFormValues>(() => {
    const values = {};
    forEach(threshold?.overrides, (serviceOverrides, serviceType) => {
      forEach(serviceOverrides, (override) => {
        // replace . with , to avoid issues with location as ip address. When for location 10.192.12.12 it creates nested objects {10: {192: {12: {12: {}}}}}
        const tierId = override.tierId?.replace(/\./gi, ',');
        const locationId = override.locationId?.replace(/\./gi, ',');
        if (!values[serviceType]) {
          values[serviceType] = {};
        }
        if (!values[serviceType][tierId]) {
          values[serviceType][tierId] = {};
        }
        values[serviceType][tierId][locationId ?? 'undefined'] = {
          excluded: override.excluded || false,
          pctFree: override.pctFree === undefined ? '' : override.pctFree.toString(),
          pctChange: override.pctChange === undefined ? '' : override.pctChange.toString(),
        };
      });
    });
    return values;
  });

  const {
    data: allDefinitions,
    isFetching: loading,
    error: response
  } = useQueryCapacityAlertExceptionDefinitions(customerId);
  const definitions = useMemo(() => {
    const defs = allDefinitions?.[threshold.id];
    if (keys(defs).length) {
      return defs;
    }
    return undefined;
  }, [allDefinitions, threshold.id]);
  useEffect(() => {
    setFormValues((prevState) => {
      const newState = cloneDeep(prevState);
      forEach(definitions, ({ tiers, locations }, serviceType) => {
        if (!newState[serviceType]) {
          newState[serviceType] = {};
        }
        forEach(tiers, ({ id }) => {
          // replace . with , to avoid issues with location as ip address. When for location 10.192.12.12 it creates nested objects {10: {192: {12: {12: {}}}}}
          const tierId = id?.replace(/\./gi, ',');
          if (!newState[serviceType][tierId]) {
            newState[serviceType][tierId] = {};
          }
          if (locations?.length) {
            forEach(locations, ({ id: locId }) => {
              const locationId = locId?.replace(/\./gi, ',');
              if (!newState[serviceType][tierId][locationId]) {
                newState[serviceType][tierId][locationId] = {
                  excluded: false,
                  pctFree: '',
                  pctChange: '',
                };
              }
            });
          } else if (!newState[serviceType][tierId].undefined) {
            newState[serviceType][tierId].undefined = {
              excluded: false,
              pctFree: '',
              pctChange: '',
            };
          }
        });
      });
      return newState;
    });
  }, [definitions]);

  const onSubmit = ({ value }) => {
    const overrides = {};
    forEach(value, (serviceOverrides, serviceType) => {
      if (!overrides[serviceType]) {
        overrides[serviceType] = [];
      }
      forEach(serviceOverrides, (tierOverrides, tId) => {
        // replace , back to . on submit.
        const tierId = tId?.replace(/,/gi, '.');
        forEach(tierOverrides, (override, lId) => {
          const locationId = lId?.replace(/,/gi, '.');
          overrides[serviceType].push({
            tierId,
            locationId: locationId === 'undefined' ? undefined : locationId,
            excluded: override.excluded,
            pctFree: override.pctFree === '' || override.pctFree === undefined ? undefined : toNumber(override.pctFree),
            pctChange: override.pctChange === '' || override.pctChange === undefined ? undefined : toNumber(override.pctChange)
          });
        });
      });
    });
    onChange(threshold.id, { ...threshold, overrides });
  };

  const subTitle = useMemo(() => {
    if (definitions) {
      return (
        <Box direction='row' align='start' key='defaultValuesMsg' flex={false} data-testid='defaultsMsg'>
          <Text>
            Thresholds entered here take precedence over the defaults,
            {' '}
            <strong>
              {threshold.pctFree}
              %
            </strong>
            {' '}
            Free and
            {' '}
            <strong>
              {threshold.pctChange}
              %
            </strong>
            {' '}
            Change.
          </Text>
        </Box>
      );
    }
    return null;
  }, [definitions, threshold.pctChange, threshold.pctFree]);

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      closer={true}
      flush={true}
      title={canEdit ? 'Edit exceptions' : 'View exceptions'}
      subtitle={(
        <Box>
          <Text>{threshold.displayName}</Text>
          <Text margin={{ top: 'small' }}>{subTitle}</Text>
        </Box>
      )}
    >
      <Box flex={true}>
        <Form
          value={formValues}
          onChange={setFormValues}
          onSubmit={onSubmit}
          style={{ height: '100%' }}
        >
          <Box fill='vertical' flex={false}>
            <Box direction='column' fill='vertical' border='top'>
              <Box flex={true} overflow='auto' gap='medium' pad={{ horizontal: 'small', bottom: 'medium' }}>
                {!loading && map(definitions, (def, serviceType) => (
                  <ServiceTypeExceptionsTable
                    key={serviceType}
                    serviceType={serviceType}
                    definitions={def}
                    formValues={formValues}
                    canEdit={canEdit}
                  />
                ))}
                {!loading && response && (
                  <Notification
                    title='Error retrieving alert exceptions'
                    message={response.message}
                    status='critical'
                  />
                )}
                {!loading && !definitions && (
                  <Notification
                    title='No Services for this service group'
                    message='Configure services and then return here to configure capacity alert exceptions.'
                    status='warning'
                  />
                )}
                {loading && (
                  <Box direction='row' align='center' gap='small' justify='center' fill={true}>
                    <Loader text='Loading Services. Please wait ...' />
                  </Box>
                )}
              </Box>
              <Box border='top' pad='small' flex={false}>
                <Footer flex={false} justify='start' gap='small'>
                  {definitions && canEdit ? (
                    <Button
                      label='OK'
                      type='submit'
                      primary={true}
                      id={IDUtil.getId('ExceptionListOkButton')}
                    />
                  ) : ''}
                  <Button
                    label={canEdit ? 'Cancel' : 'Close'}
                    secondary={true}
                    onClick={onClose}
                  />
                </Footer>
              </Box>
            </Box>
          </Box>
        </Form>
      </Box>
    </GLBMLayer>
  );
};

export default ExceptionsEditor;
