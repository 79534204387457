// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { Fragment, useEffect, useState } from 'react';
import {
  Box, Button, Header, Text,
} from 'grommet';
import {
  Add, Edit, Trash,
} from 'grommet-icons';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import IDUtil from '../shared/util/IDUtil';
import LocationEditor from './LocationEditor';
import { ListPlaceholder } from '../shared/component/ListPlaceholder';
import DeleteLocationConfirmDialog from './DeleteLocationConfirmDialog';

/**
 * The LocationsList component shows a list of locations identified by Name + Id. The user can
 * create a new location by clicking a + button which opens a SidePanel. The SidePanel allows
 * a Name data entry. Locations with the same name is not allowed. The sidePanel is also the
 * one way of removing an existing location. The end user can rename a location by clicking
 * edit from the LocationList, changing the name of the location, and then applying the change
 * from the SidePanel.
 */
const LocationsList = ({
  customerId,
  locations: locationsProp,
  onChange = undefined,
  readOnly = false,
  hidden = false,
  border = false,
}) => {
  const [locations, setLocations] = useState(locationsProp);
  const [addLocation, setAddLocation] = useState(false);
  const [editLocationIndex, setEditLocationIndex] = useState(undefined);
  const [removeLocationIndex, setRemoveLocationIndex] = useState(undefined);
  const [layer, setLayer] = useState(undefined);

  useEffect(() => {
    setLocations(locationsProp);
  }, [locationsProp]);

  const onLocationRemoveConfirm = (index) => {
    setAddLocation(false);
    setRemoveLocationIndex(index);
    setLayer('removeLocation');
  };

  const onLocationEdit = (index) => {
    setAddLocation(false);
    setEditLocationIndex(index);
    setLayer('editLocation');
  };

  const onLocationAdd = () => {
    setAddLocation(true);
    setLayer('editLocation');
  };

  const onLocationRemove = () => {
    const modifiedLocations = [...locations];
    modifiedLocations.splice(removeLocationIndex, 1);
    setEditLocationIndex(-1);
    setLayer(undefined);
    setLocations(modifiedLocations);

    // locations has changed, call to parent container to let it know as well:
    if (onChange) {
      onChange(modifiedLocations, true);
    }
  };

  const onLocationChange = (location, addAnother) => {
    // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
    let layer;

    const modifiedLocations = [...locations];

    if (addLocation || (modifiedLocations[editLocationIndex].isEditing && addAnother)) {
      // generate unique UUId for the new location"
      // eslint-disable-next-line no-prototype-builtins
      if (!location.hasOwnProperty('id') || location.id === undefined) {
        // eslint-disable-next-line no-param-reassign
        location.id = uuidv4();
      }

      modifiedLocations.push(location);
    } else {
      modifiedLocations[editLocationIndex] = location;
    }

    if (addAnother) {
      layer = 'editLocation';
    }

    setAddLocation(addAnother);
    setEditLocationIndex(-1);
    setLayer(layer);
    setLocations(modifiedLocations);

    // locations has changed, call to parent container to let it know as well:
    if (onChange) {
      onChange(modifiedLocations, !addLocation);
    }
  };

  const renderLocationFields = () => {
    const locationElements = locations?.map((location, index) => (
      <Box
        key={`location-${location.id}_${location.name}_key`}
        height='50px'
        justify='between'
        pad='none'
        border={index === 0 ? false : 'top'}
        align='center'
        direction='row'
      >
        <span>
          {location.name}
        </span>
        {!readOnly
        && (
        <Box direction='row' flex={true} align='end' justify='end'>
          <Button
            icon={<Edit />}
            id={IDUtil.getId('LocationListEditButton', index)}
            onClick={() => onLocationEdit(index)}
            a11yTitle={`Edit ${location.name} Location`}
          />
          <Button
            icon={<Trash />}
            id={IDUtil.getId('LocationListDeleteButton', index)}
            onClick={() => onLocationRemoveConfirm(index)}
            a11yTitle={`Delete ${location.name} Location`}
          />
        </Box>
        )}
      </Box>
    ));

    return (
      <Box border={border} round='xsmall' pad={{ horizontal: 'small' }}>
        {
          !readOnly
          && (
          <Header size='small' justify='between'>
            <Text margin={{ vertical: 'small' }} size='small'>Locations</Text>
            <Button
              icon={<Add />}
              id={IDUtil.getId('LocationListAddButton')}
              onClick={onLocationAdd}
              a11yTitle='Add Location'
            />
          </Header>
          )
        }
        {locationElements.length === 0
          ? (
            <ListPlaceholder
              key='no-locations-placeholder'
              emptyMessage='You do not have any locations defined at the moment.'
              unfilteredTotal={0}
              filteredTotal={1}
            />
          )
          : ''}
        <Box>
          {locationElements}
        </Box>
      </Box>
    );
  };

  const onLayerClose = () => {
    setLayer(undefined);
  };

  const renderLayer = () => {
    let result;
    if (layer) {
      if (layer === 'editLocation') {
        const heading = (addLocation ? 'Add Location' : 'Edit Location');
        const location = (addLocation ? ({
          id: undefined,
          name: '',
          isEditing: false
        }) : ({ ...locations[editLocationIndex] }));
        if (!addLocation) {
          locations[editLocationIndex].isEditing = true;
        }
        result = (
          <LocationEditor
            onClose={onLayerClose}
            heading={heading}
            location={location}
            locations={locations}
            addLocation={addLocation}
            onChange={onLocationChange}
          />
        );
      } else if (layer === 'removeLocation') {
        const location = ({ ...locations[removeLocationIndex] });
        result = (
          <DeleteLocationConfirmDialog
            onClose={onLayerClose}
            customerId={customerId}
            location={location}
            onChange={onLocationRemove}
          />
        );
      }
    }
    return result;
  };

  if (hidden) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (<></>);
  }

  return (
    <Box>
      {renderLocationFields()}
      {renderLayer()}
    </Box>
  );
};

LocationsList.propTypes = {
  readOnly: PropTypes.bool,
  customerId: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  locations: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  border: PropTypes.bool,
};

export default LocationsList;
