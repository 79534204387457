// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useMemo } from 'react';
import {
  Anchor, RadioButton, Text,
} from 'grommet';
import { Trash } from 'grommet-icons';
import { insertIf } from '../shared/util/BasicUtil';
import IDUtil from '../shared/util/IDUtil';
import { HighlightUsingContext } from '../shared/component/HighlightUsingContext';

export const useAvailableColumns = ({
  canEdit,
  onRemoveClick,
  primaryASMId,
  onPrimaryASMSelected,
}) => {
  const roleOptions = useMemo(() => [
    { value: 'READ', label: 'Read' },
    { value: 'EDIT', label: 'Edit' },
    { value: 'SUPER_EDIT', label: 'Super Edit' },
  ], []);

  const getRoleValue = useCallback(
    role => roleOptions.filter(r => r.value === role)[0] || roleOptions[0],
    [roleOptions]
  );

  return useMemo(() => ([
    {
      property: 'name',
      header: 'Name',
      primary: true,
      dataCallback: ({ firstName, lastName }) => `${firstName} ${lastName}`,
      render: ({
        firstName, lastName,
      }) => (
        <Text weight='bold'>
          <HighlightUsingContext>{`${firstName} ${lastName}`}</HighlightUsingContext>
        </Text>
      ),
      size: 'medium',
      filter: true,
    }, {
      property: 'email',
      header: 'Email',
      dataCallback: ({ email }) => email,
      render: ({ email, index }) => (
        <div id={IDUtil.getId('Email', index)}>
          <HighlightUsingContext>{email}</HighlightUsingContext>
        </div>
      ),
      size: 'medium',
      filter: true,
    }, {
      property: 'primary',
      header: 'Primary ASM',
      render: row => (
        canEdit ? (
          <RadioButton
            checked={primaryASMId === row.id}
            onChange={() => {
              onPrimaryASMSelected(row.id);
            }}
            name='primary'
            id={IDUtil.getId('ASMListViewListPrimaryRadioButton', row.id)}
            disabled={!canEdit}
          />
        ) : (primaryASMId === row.id && 'Yes') || 'No'
      ),
      size: 'small',
      sortable: false,
    }, {
      property: 'role',
      header: 'Role',
      dataCallback: ({ role }) => role,
      render: row => (getRoleValue(row.role).label),
      size: 'small',
    },
    ...insertIf(canEdit, [{
      property: 'actions',
      header: 'Actions',
      size: '96px',
      align: 'start',
      render: row => (
        canEdit ? (
          <Anchor
            icon={<Trash />}
            label='Remove'
            id={IDUtil.getId('ASMListViewListRemoveButton', row.index)}
            onClick={() => onRemoveClick(row.id)}
          />
        ) : null
      ),
      sortable: false,
    },
    ]),
  ]), [canEdit, getRoleValue, onPrimaryASMSelected, onRemoveClick, primaryASMId, roleOptions]);
};
