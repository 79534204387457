// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class PartnerType extends Enumify {
  static MASTER = new PartnerType('Master');

  static RESELLER = new PartnerType('Reseller');

  static DISTRIBUTOR = new PartnerType('Distributor');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label) {
    super();
    this.label = label;
  }
}
