// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class ValueTypes extends Enumify {
  static NUMERIC = new ValueTypes('numericColumn');

  static STRING = new ValueTypes('');

  static BOOLEAN = new ValueTypes('');

  static DATE = new ValueTypes('');

  static DATE_TIME = new ValueTypes('');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(agGridType) {
    super();
    this.agGridType = agGridType;
  }
}
