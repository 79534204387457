// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Footer,
  FormField,
  TextInput,
} from 'grommet';
import { FormAdd, Trash } from 'grommet-icons';
import PropTypes from 'prop-types';
import GLBMLayer from '../../../../../../shared/component/GLBMLayer';

const MappedTiersEditor = ({
  heading,
  onChange,
  onClose,
  onRemove = undefined,
  tier: initialTier,
  otherNames: initialOtherNames,
}) => {
  const [tier, setTier] = useState(initialTier);
  const [otherNames, setOtherNames] = useState(initialOtherNames);
  const [errors, setErrors] = useState({});
  const [didSubmit, setDidSubmit] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    setTier(initialTier);
    setErrors({});
    setIsModified(false);
    setDidSubmit(false);
  }, [initialTier]);

  useEffect(() => {
    setOtherNames(initialOtherNames);
  }, [initialOtherNames]);

  const onSubmit = (addAnother) => {
    setDidSubmit(true);

    let noErrors = true;

    if (!tier.name) {
      setErrors(prevErrors => ({ ...prevErrors, name: 'Required' }));
      noErrors = false;
    } else if (otherNames && otherNames.includes(tier.name)) {
      setErrors(prevErrors => ({ ...prevErrors, name: 'Duplicate' }));
      noErrors = false;
    } else {
      setErrors({ });
    }

    if (noErrors) {
      const submitErrors = onChange(tier, isModified, addAnother);
      if (submitErrors) {
        setErrors(submitErrors);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTier(prevTier => ({ ...prevTier, [name]: value }));
    setIsModified(true);
  };

  useEffect(() => {
    if (didSubmit) {
      if (!tier.name) {
        setErrors(prevErrors => ({
          ...prevErrors,
          name: 'Required'
        }));
      } else if (otherNames && otherNames.includes(tier.name)) {
        setErrors(prevErrors => ({
          ...prevErrors,
          name: 'Duplicate'
        }));
      } else {
        setErrors({});
      }
    }
  }, [tier]);

  let secondaryControl;
  if (onRemove) {
    secondaryControl = (
      <Button
        plain={true}
        icon={<Trash />}
        label='Remove'
        onClick={onRemove}
        id='LocationEditorRemoveButton'
        a11yTitle={`Remove ${tier.name} Location`}
      />
    );
  } else {
    secondaryControl = (
      <Button
        plain={true}
        icon={<FormAdd />}
        label='Add Another'
        onClick={() => onSubmit(true)}
        id='LocationEditorAddAnotherButton'
        a11yTitle='Add Another Location'
      />
    );
  }

  return (
    <GLBMLayer
      position='right'
      onClickOutside={onClose}
      onEsc={onClose}
      onClose={onClose}
      flush={true}
      full='vertical'
      title={heading}
    >
      <Box pad='none' direction='column' fill='vertical' flex={true} style={{ maxWidth: '575px', width: '575px' }}>
        <Box pad='small' flex={true} direction='column' fill='vertical'>
          <FormField
            key='locationEditorId'
            htmlFor='locationEditorId'
            label='Name'
            error={errors.name}
          >
            <TextInput
              id='locationId'
              name='name'
              value={tier.name}
              onChange={handleChange}
            />
          </FormField>
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={true} justify='between' gap='small'>
            <Box
              direction='row'
              gap='small'
            >
              <Button
                label='OK'
                id='MappedTierEditorOKButton'
                primary={true}
                onClick={() => onSubmit(false)}
              />
              <Button
                label='Cancel'
                id='MappedTierEditorCancelButton'
                secondary={true}
                onClick={onClose}
              />
            </Box>
            {secondaryControl}
          </Footer>
        </Box>
      </Box>
    </GLBMLayer>
  );
};

MappedTiersEditor.propTypes = {
  heading: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  tier: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
  }).isRequired,
  otherNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MappedTiersEditor;
