// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';

import {
  Box,
  Button,
} from 'grommet';
import GLBMLayer from '../../../../../../shared/component/GLBMLayer';

function ResourceRevisionAlertModal({ onClose }) {
  return (
    <GLBMLayer>
      <Box
        flex={true}
        pad='medium'
      >
        <Box margin={{ bottom: 'small' }}>
          <h3 style={{ margin: '0px' }}>
            Bulk Edit Not Supported
          </h3>
        </Box>
        <Box margin={{ top: 'none', bottom: 'medium' }}>
          <div>
            Bulk edit is not supported because you have selected one or more resources which have revisions for tier and/or location.
          </div>
        </Box>
        <Box direction='row' flex={true} justify='center'>
          <Button label='Ok' primary={true} onClick={() => onClose()} />
        </Box>
      </Box>
    </GLBMLayer>
  );
}

export default ResourceRevisionAlertModal;
