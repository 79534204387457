// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';
import {
  Box, Button, FormField, TextInput
} from 'grommet';
import GLBMLayer from '../../../../../../shared/component/GLBMLayer';
import CustomTiersCriteria from './CustomTiersCriteria';

const CustomTiersEditor = (props) => {
  const [model, setModel] = useState(JSON.parse(JSON.stringify(props.model)));
  const [isModified, setIsModified] = useState(false);

  const onModelChange = ({ criteria: newCriteria }) => {
    setModel(prevModel => ({
      ...prevModel,
      criteria: newCriteria,
    }));
    setIsModified(true);
  };

  const onNameChange = (event) => {
    setModel({
      ...model,
      name: event.target.value
    });
    setIsModified(true);
  };

  const onClose = () => {
    props.onClose();
  };

  const onSubmit = () => {
    props.onSubmit(model, props.isCopy ? undefined : props.tierIndex, isModified);
  };

  const isGroupValid = (group) => {
    let valid = true;
    group.criteria.forEach((criteria) => {
      if (criteria.value.length === 0) {
        valid = false;
      }
    });
    if (group.groups && group.groups.length) {
      group.groups.forEach((g) => {
        if (!isGroupValid(g)) {
          valid = false;
        }
      });
    }
    return valid;
  };

  const getNameError = () => {
    if (model.name.length === 0) {
      return 'a name is required';
    }

    const tiers = props.allTiers;
    let tierNameExists = false;
    tiers.forEach((tier, index) => {
      if (props.isCopy && model.name === tier.name) {
        tierNameExists = true;
      } else if (index !== props.tierIndex && model.name === tier.name) {
        tierNameExists = true;
      }
    });
    if (tierNameExists) {
      return 'duplicate tier name';
    }
  };

  const getSubmitButton = () => {
    let errors = 0;
    model.criteria.forEach((group) => {
      if (!isGroupValid(group)) {
        // eslint-disable-next-line no-plusplus
        errors += 1;
      }
    });
    if (getNameError()) {
      // eslint-disable-next-line no-plusplus
      errors += 1;
    }
    if (!errors) {
      return onSubmit;
    }
  };

  return (
    <GLBMLayer flush={true} className='full-hack' title='Tier Definition'>
      <Box width='xlarge'>
        <Box flex={true} pad={{ horizontal: 'medium' }} overflow='auto'>
          <Box direction='row' flex={false} pad={{ bottom: 'small' }} gap='small'>
            <Box flex={true}>
              <FormField label='Tier Name' error={getNameError()}>
                <TextInput
                  name='name'
                  value={model.name}
                  onChange={onNameChange}
                  disabled={props.isBrim}
                />
              </FormField>
            </Box>
            <Box flex={true}>
              {props.isBrim && (
                <FormField label='Service Instance Id'>
                  <TextInput
                    name='serviceInstanceId'
                    value={model.serviceInstanceId}
                    disabled={true}
                  />
                </FormField>
              )}
            </Box>
          </Box>
          <CustomTiersCriteria
            service={props.service}
            fields={props.fields}
            model={model}
            onModelChange={onModelChange}
            isCopy={props.isCopy}
          />
        </Box>
        <Box
          flex={false}
          direction='row'
          align='center'
          justify='start'
          pad={{
            horizontal: 'small',
            vertical: 'small'
          }}
          gap='small'
        >
          <Button primary={true} label='Save Changes' onClick={getSubmitButton()} />
          <Button secondary={true} label='Cancel' onClick={onClose} />
        </Box>
      </Box>
    </GLBMLayer>
  );
};

export default CustomTiersEditor;
