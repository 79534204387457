// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useErrorToast } from '../../components/shared/hooks';
import { executeGet } from '../api';
import { ApiContext } from '../../AppContext';

export const useEmailQuery = (accountId, from, to, options) => {
  const path = useContext(ApiContext)('administration.emails.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:EMAIL-LIST', accountId, from, to],
    queryFn: () => executeGet(`${path}?${accountId ? `accountId=${accountId}` : ''}&from=${from}&to=${to}`),
    ...(options || []),
  }), 'Fetch Email Error');
};
