// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import keyMirror from 'keymirror';

export default keyMirror({
  UPDATE_SERVICES: null,
  UPDATE_SERVICE: null,
  UPDATE_SERVICE_OPTIONS: null,
  UPDATE_SERVICE_EQUIPMENT: null,
  UPDATE_SERVICE_COMPONENT: null,
  UPDATE_SERVICE_RATES: null,
  UPDATE_SERVICE_METERS: null,
  START_DATE: '2000-01-01',
  FILTER_EQUIPMENT_ACTIVE: null,
  FILTER_EQUIPMENT_EOST: null,
  LOAD_SERVICE_DEFINITIONS: null,
});
