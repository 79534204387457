// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  UPDATE_COMPANY_PAGE_FILTERS,
} from './CompanyAction';

// eslint-disable-next-line default-param-last
export function companyList(state = {
  filters: {
    list: {
      searchText: '',
      sort: {
        property: 'name',
        direction: 'asc',
        external: true,
      },
    },
  },

}, action) {
  switch (action.type) {
    case UPDATE_COMPANY_PAGE_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    default:
      return state;
  }
}
