// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import {
  Box,
  Button,
  Footer,
  RadioButtonGroup,
  Text,
} from 'grommet';
import PropTypes from 'prop-types';
import GLBMLayer from '../../../../shared/component/GLBMLayer';
import { EquipmentFilter } from '../../../model';

class ConfigureResourcesFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this._onChangeTermFilter = this._onChangeTermFilter.bind(this);
    this._onChangeStatusFilter = this._onChangeStatusFilter.bind(this);
  }

  _onChangeTermFilter(filter) {
    this.props.onChange({ term: [filter], status: this.props.filters.status });
  }

  _onChangeStatusFilter(filter) {
    this.props.onChange({ term: this.props.filters.term, status: [filter] });
  }

  _renderSystemOptions() {
    return (
      <RadioButtonGroup
        name='_renderSystemOptions'
        value={this.props.filters.term[0]}
        options={[
          { label: EquipmentFilter.ALL.label, value: EquipmentFilter.ALL.enumKey },
          { label: EquipmentFilter.ACTIVE.label, value: EquipmentFilter.ACTIVE.enumKey },
          { label: EquipmentFilter.END_OF_SYSTEM_TERM.label, value: EquipmentFilter.END_OF_SYSTEM_TERM.enumKey },
        ]}
        onChange={event => this._onChangeTermFilter(event.target.value)}
      />
    );
  }

  _renderStatusOptions() {
    return (
      <RadioButtonGroup
        name='_renderStatusOptions'
        value={this.props.filters.status[0]}
        options={[
          { label: 'All', value: 'ALL' },
          { label: 'Configured', value: 'CONFIGURED' },
          { label: 'Warning', value: 'WARNING' },
        ]}
        onChange={event => this._onChangeStatusFilter(event.target.value)}
      />
    );
  }

  render() {
    return (
      <GLBMLayer
        position='right'
        onEsc={this.props.onClose}
        onClickOutside={this.props.onClose}
        onClose={this.props.onClose}
        full='vertical'
        title='Filter Resources'
      >
        <Box
          pad='none'
          direction='column'
          fill='vertical'
          style={{ 'maxWidth': '600px', 'width': '600px' }}
        >
          <Box flex={true}>
            <Box tag='section' pad={{ horizontal: 'medium', vertical: 'small' }}>
              <Text size='large'>System Term</Text>
              {this._renderSystemOptions()}
            </Box>
            <Box tag='section' pad={{ horizontal: 'medium', vertical: 'small' }}>
              <Text size='large'>Resource Status</Text>
              {this._renderStatusOptions()}
            </Box>
          </Box>
          <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
            <Footer flex={true} justify='between' gap='small'>
              <Box direction='row' gap='small'>
                <Button
                  label='Close'
                  primary={true}
                  onClick={this.props.onClose}
                />
              </Box>
            </Footer>
          </Box>

        </Box>
      </GLBMLayer>
    );
  }
}

ConfigureResourcesFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

ConfigureResourcesFilter.defaultProps = {
  onChange: () => null, // onChange is optional
};

export default ConfigureResourcesFilter;
