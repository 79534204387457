// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class ReportingUnits extends Enumify {
  static GB = new ReportingUnits();

  static GiB = new ReportingUnits();

  static TB = new ReportingUnits();

  static TiB = new ReportingUnits();

  static Each = new ReportingUnits();

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()
}
