// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class MarkupType extends Enumify {
  static MARKUP_PERCENT = new MarkupType('Markup Percentage', 'Enter a markup percentage to the rate you pay to calculate the price for your customer.');

  static DIRECT_RATE = new MarkupType('Direct Rate', 'Enter an explicit rate independent of the rate you pay to set the price for your customer.');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label, tooltip) {
    super();
    this.label = label;
    this.tooltip = tooltip;
  }
}
