// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class Environment extends Enumify {
  static DEV = new Environment();

  static PROD = new Environment();

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()
}
