// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class RatesOption extends Enumify {
  static NONE = new RatesOption();

  static READ_ONLY = new RatesOption();

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()
}
