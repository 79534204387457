// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Box, Text } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
import { StatusIcon } from './StatusIcon';

const StatusLabel = ({
  value = '',
  label = '',
}) => (
  <Box direction='row' align='center' gap='small'>
    <StatusIcon value={value} size='small' />
    <Text>{label}</Text>
  </Box>
);

StatusLabel.propTypes = {
  value: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default StatusLabel;
