// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class UserType extends Enumify {
  static ASM = new UserType('ASM', 'ASM', [], true);

  static SUPER = new UserType('SUPER', 'System Administrator', ['ASM', 'SUPER', 'SPECIALIST', 'SERVICE_DEV', 'SUPPORT', 'BUSINESS', 'UNASSIGNED', 'REVOKED', 'UAM']);

  static SPECIALIST = new UserType('SPECIALIST', 'System Specialist', []);

  static SERVICE_DEV = new UserType('SERVICE_DEV', 'Service Developer', [], true, true);

  static SUPPORT = new UserType('SUPPORT', 'GreenLake Support', ['ASM', 'SUPPORT']);

  static BUSINESS = new UserType('BUSINESS', 'GreenLake Business', ['BUSINESS']);

  static UNASSIGNED = new UserType('UNASSIGNED', 'Unassigned', []);

  static REVOKED = new UserType('REVOKED', 'Revoked', []);

  static UAM = new UserType('UAM', 'User Access Management', []);

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(value, label, canSetRoles, restrictAccounts = false, restrictServices = false) {
    super();
    this.value = value;
    this.label = label;
    this.canSetRoles = canSetRoles;
    this.restrictAccounts = restrictAccounts;
    this.restrictServices = restrictServices;
  }
}

export const isUnassignedRole = role => (role === UserType.UNASSIGNED.value || role === UserType.REVOKED.value);
export const isAssignedRole = role => !!(role && !isUnassignedRole(role));
