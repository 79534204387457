// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { ApiContext } from '../../AppContext';
import { useErrorToast } from '../../components/shared/hooks/useErrorToast';
import {
  executeDelete, executeGet, executePost, executePut
} from '../api';

export const usePartnersQuery = (options) => {
  const path = useContext(ApiContext)('partners.list.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:PARTNERS-LIST'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Partners Error');
};

export const usePartnerQuery = (partnerId, options) => {
  const path = useContext(ApiContext)('partners.read.path', { partnerId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:PARTNER', partnerId],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Partner Error');
};

export const usePartnerConnectionQuery = (partnerId, options) => {
  const path = useContext(ApiContext)('partners.connection.path', { partnerId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:PARTNER-CONNECTION', partnerId],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Partner Connection Error');
};

export const usePartnerCreateMutate = (options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('partners.create.path');
  return useMutation({
    mutationFn: payload => executePost(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:PARTNERS-LIST'],
      });
      options?.onSuccess?.();
    }
  });
};

export const usePartnerUpdateMutate = (partnerId, options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('partners.update.path', { partnerId });
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:PARTNERS-LIST'],
      });
      queryClient.removeQueries({
        queryKey: ['GLBM:PARTNER', partnerId]
      });
      options?.onSuccess?.();
    }
  });
};

export const usePartnerDeleteMutate = (options) => {
  const queryClient = useQueryClient();
  const contextFn = useContext(ApiContext);
  return useMutation({
    mutationFn: (partnerId) => {
      const path = contextFn('partners.delete.path', { partnerId });
      return executeDelete(path);
    },
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:PARTNERS-LIST']
      });
      options?.onSuccess?.();
    }
  });
};
