// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import * as Case from 'case';
import { FormField, TextInput } from 'grommet';
import PropTypes from 'prop-types';

const ReadOnlyField = ({
  id = '',
  label = '',
  value = '',
  border = true,
  error = '',
  help = '',
  children = undefined,
  ...rest
}) => {
  const borderStyle = border ? {} : { style: { border: 'none' } };
  return (
    <FormField label={label} help={help} error={error} {...borderStyle}>
      <TextInput id={id} name={Case.camel(label)} disabled={true} value={value.toString()} data-e2e={rest['data-e2e']} />
      {children /* optional read only content area */}
    </FormField>
  );
};

ReadOnlyField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  border: PropTypes.bool,
  help: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.node,
};

export default ReadOnlyField;
