// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

/*
 * action types
 */

import { updateStateAndSession } from '../../session/sessionUtil';

export const RECEIVE_ALERT_COUNT = 'RECEIVE_ALERT_COUNT';
export const UPDATE_ALERT_FILTERS = 'UPDATE_ALERT_FILTERS';
export const REQUEST_FILE_VALIDATION = 'REQUEST_FILE_VALIDATION';
export const RECEIVE_FILE_VALIDATION = 'RECEIVE_FILE_VALIDATION';
export const FILE_UPLOAD_SUCCESSFUL = 'FILE_UPLOAD_SUCCESSFUL';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';
export const CLEAR_UPLOAD = 'CLEAR_UPLOAD';

export function updateFilters(filters) {
  return {
    type: UPDATE_ALERT_FILTERS,
    filters,
  };
}

export const updateFiltersAndSession = updateStateAndSession(updateFilters);
