// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { Enumify } from 'enumify';

export class TierOptions extends Enumify {
  static CUSTOM = new TierOptions(
    'CUSTOM',
    'customTiers',
    'Custom Tiers',
    '',
  );

  static MAPPED = new TierOptions(
    'MAPPED',
    'mappedTiers',
    'Mapped Tiers',
    'Mapped tiers are defined on this page but mapped to specific resources on the Configure Resources page',
  );

  static SINGLE = new TierOptions(
    'CUSTOM', // API do not support SINGLE tier type...CUSTOM has to be used instead
    'customTiers',
    'Single Tier',
    '',
  );

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(apiValue, tiersLookupKey, label, tooltip) {
    super();
    this.apiValue = apiValue;
    this.tiersLookupKey = tiersLookupKey;
    this.label = label;
    this.tooltip = tooltip;
  }
}
