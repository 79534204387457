// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { useCallback } from 'react';
import { useToast } from '../toast/ToastProvider';
import ExportUtil from '../util/ExportUtil';

export const useOnSuccessDownload = () => {
  const { addToast } = useToast();
  return useCallback(({ data, headers }) => {
    const fileName = headers['content-disposition']?.split('"')[1] || 'download.csv';
    ExportUtil.downloadCSV(fileName, data);
    addToast({
      status: 'normal',
      title: `${fileName} downloaded`,
    });
  }, [addToast]);
};
