// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Footer, FormField, Select,
} from 'grommet';
import {
  useFiscalQuartersValuesQuery,
  useQueryExtractQuarterlyBillingAccountData,
} from '../../../../core';
import ErrorBanner from '../../../shared/banner/ErrorBanner';
import GLBMLayer from '../../../shared/component/GLBMLayer';
import { useFormFieldProps } from '../../../shared/component/InputProperties/utils';

const ExtractQuarterlyBillingAccountDataDialog = ({ action, onClose }) => {
  const getFormFieldProps = useFormFieldProps();
  const [quarter, setQuarter] = useState(undefined);

  const fiscalQuartersValuesQuery = useFiscalQuartersValuesQuery();

  const quarterOptions = useMemo(() => {
    if (fiscalQuartersValuesQuery.status === 'success' && fiscalQuartersValuesQuery?.data) {
      return fiscalQuartersValuesQuery?.data;
    }
    return [];
  }, [fiscalQuartersValuesQuery]);

  // set the default quarter value to the last quarter:
  useEffect(() => {
    if (!quarter && quarterOptions.length > 0) {
      setQuarter(quarterOptions[0].id);
    }
  }, [quarter, quarterOptions]);

  const selectedQuarter = quarterOptions.find(q => q.id === quarter);

  // mutation function to extract the assigned users list:
  const {
    isSuccess,
    mutate: extract,
    isPending,
    isError,
    error
  } = useQueryExtractQuarterlyBillingAccountData(); // TODO: implement this hook with the right path

  // close the dialog when the extract is successful:
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  const onExecuteReport = () => {
    // build up params to pass to the mutation function only if the values are not null:
    const payload = {
      quarter
    };
    extract(payload);
  };

  return (
    <GLBMLayer
      a11yTitle={`Extract: ${action.name}`}
      data-testid='exec-params-layer'
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      position='right'
      full='vertical'
      title={action.name}
    >
      <Box direction='column' gap='small' flex={true} margin='small'>
        <FormField
          {...getFormFieldProps('fiscalQuarter', 'Fiscal Quarter')}
          htmlFor='fiscalQuarter'
          required={true}
          help='Select the fiscal quarter to extract the billing account data'
          info={`${moment(selectedQuarter?.start).format('MMM YYYY')} - ${moment(selectedQuarter?.end).format('MMM YYYY')}`}
        >
          <Select
            id='fiscalQuarter'
            name='fiscalQuarter'
            options={quarterOptions}
            value={quarter}
            labelKey='label'
            data-e2e='fiscalQuarter'
            valueKey={{ key: 'id', reduce: true }}
            onChange={({ value: nextValue }) => setQuarter(nextValue)}
          />

        </FormField>
      </Box>
      {isError && (
        <Box margin='medium'>
          <ErrorBanner
            title={`Unable to extract ${action.name}`}
            message={error.message}
          />
        </Box>
      )}
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Extract'
              type='button'
              primary={true}
              onClick={onExecuteReport}
              busy={isPending}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ExtractQuarterlyBillingAccountDataDialog.propTypes = {
  action: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExtractQuarterlyBillingAccountDataDialog;
