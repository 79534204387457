// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';

const StateCellFormatter = ({ value: valueProp = undefined }) => {
  if (!valueProp) {
    return (<span>-</span>);
  }

  const { value, estimated } = valueProp;
  if (value !== undefined) {
    return (value !== 0
      ? (
        <div className={`${estimated ? 'blade-on-estimated' : 'blade-on'}`}>
          <span>
            {estimated ? '*  ON' : 'ON'}
          </span>
        </div>
      )
      : (
        <div className={`${estimated ? 'blade-off-estimated' : 'blade-off'}`}>
          <span>{estimated ? '*  off' : 'off'}</span>
        </div>
      ));
  }
  return <div><span>-</span></div>;
};

StateCellFormatter.propTypes = {
  value: PropTypes.shape({ value: PropTypes.any, estimated: PropTypes.bool }),
};

export default StateCellFormatter;
