// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import {
  Navigate, Route,
  useNavigate
} from 'react-router-dom';
import CustomersListPage from '../customers/CustomersListPage';
import ASMListPage from '../asms/ASMListPage';
import FixedCharges from '../fixedcharges/FixedCharges';
import FeatureListPage from '../customers/FeatureListPage';
import CapacityAlertSettingsPage
  from '../capacityalertsV2/CapacityAlertSettingsPage';
import CustomerTenantPage from '../tenant/CustomerTenantPage';
import PSAManagePage from '../customers/psa-project-info/PSAManagePage';
import AlertsListPage from '../alerts/AlertsListPage';
import AuditListPage from '../audit/AuditListPage';
import AnalyticsPage from '../analytics/AnalyticsPage';
import JobsListPage from '../jobs/JobsListPage';
import ServiceMeterListPage from '../serviceMeters/ServiceMeterListPage';
import AccessRequests from '../accessRequests/AccessRequests';
import AdministrationPage from '../administration/AdministrationPage';
import PaygAdministrationPage from '../paygAdministration/paygAdministration';
import ServiceMappingsListPage
  from '../serviceMappings/ServiceMappingsListPage';
import RequestRolePage from '../requestRole/RequestRolePage';
import UsageFilePage from '../usage/UsageFilePage';
import DemoDataGeneration
  from '../administration/DemoDataGeneration/DemoDataGeneration';
import CustomerPage from '../customers/CustomerPage';
import ServiceEditPage from '../services/service-edit/ServiceEditContainer';
import ServicesListPage from '../services/ServicesListPage';
import EncryptionKeyListPage from '../encryptionkeys/EncryptionKeyListPage';
import UserPage from '../users/UserPage';
import UsersListPage from '../users/UsersListPage';
import PartnerEditorPage from '../partners/PartnerEditorPage';
import PartnerListPage from '../partners/PartnerListPage';
import PartnerTenantPage from '../partners/PartnerTenantPage';
import { insertIf } from '../shared/util/BasicUtil';
import { usePermissionChecker } from '../shared/hooks';
import {
  pagePermissions,
} from '../shared/constants/Permissions';
import CompanyListPage from '../companies/CompanyListPage';
import CompanyEditorPage from '../companies/CompanyEditorPage';
import DeleteEquipmentList
  from '../services/service-delete/DeleteEquipmentList';
import FeedPage from '../services/feeds/FeedPage';

export const useRenderRoutes = (me) => {
  const navigate = useNavigate();
  const { hasPermissions } = usePermissionChecker();
  const customerRoutes = [
    <Route key='listRoute' path='customers/' element={<CustomersListPage />} />,
    <Route key='addRoute' path='customers/add' element={<CustomerPage />} />,
    <Route
      key='adminRoute'
      path='customers/:customerId/admins'
      element={<ASMListPage />}
    />,
    <Route
      key='chargesRoute'
      path='customers/:customerId/charges'
      element={<FixedCharges />}
    />,
    <Route
      key='featuresRoute'
      path='customers/:customerId/features'
      element={<FeatureListPage />}
    />,
    <Route
      key='alertsRoute'
      path='customers/:customerId/capacityalert'
      element={<CapacityAlertSettingsPage />}
    />,
    <Route
      key='tenantRoute'
      path='customers/:customerId/tenant'
      element={<CustomerTenantPage />}
    />,
    <Route
      key='psaRoute'
      path='customers/:customerId/psa'
      element={<PSAManagePage />}
    />,
    <Route
      key='editRoute'
      path='customers/:customerId'
      element={<CustomerPage />}
    />,
    <Route
      key='serviceList'
      path='customers/:customerId/services'
      element={<ServicesListPage />}
    />,
    <Route
      key='serviceWizard'
      path='customers/:customerId/services/:serviceId/:page?'
      element={<ServiceEditPage />}
    />,
    <Route
      key='deleteEquipment'
      path='customers/:customerId/equipments/:serviceId'
      element={<DeleteEquipmentList />}
    />,
    <Route
      key='feeds'
      path='customers/:customerId/feeds/:serviceId'
      element={<FeedPage />}
    />,
    <Route
      key='encryptionKeys'
      path='customers/:customerId/account-keys'
      element={<EncryptionKeyListPage />}
    />,
  ];
  return useMemo(() => (me ? [
    ...insertIf(hasPermissions(pagePermissions.customers.page), customerRoutes),
    ...insertIf(hasPermissions(pagePermissions.companies.page), [<Route
      key='listRoute'
      path='companies/'
      element={<CompanyListPage />}
    />]),
    ...insertIf(hasPermissions([pagePermissions.companies.page, pagePermissions.companies.add]), [
      <Route
        key='addRoute'
        path='companies/add'
        element={<CompanyEditorPage />}
      />]),
    ...insertIf(hasPermissions([pagePermissions.companies.page, pagePermissions.companies.edit]), [
      <Route
        key='editRoute'
        path='companies/:companyId'
        element={<CompanyEditorPage />}
      />]),
    ...insertIf(hasPermissions(pagePermissions.payg.page), [<Route
      key='paygAdministration/*'
      path='paygAdministration/*'
      element={<PaygAdministrationPage navigate={navigate} />}
    />]),
    ...insertIf(hasPermissions(pagePermissions.analytics.page), [<Route
      key='analytics'
      path='analytics'
      element={<AnalyticsPage />}
    />,
      <Route
        key='analyticsAlt'
        path='analytics/:customerId/:serviceType/:tab'
        element={<AnalyticsPage />}
      />]),
    ...insertIf(hasPermissions(pagePermissions.serviceMappings.page), [<Route
      key='servicemappings'
      path='servicemappings'
      element={<ServiceMappingsListPage />}
    />]),
    ...insertIf(hasPermissions(pagePermissions.alerts.page), [<Route
      key='alerts'
      path='alerts'
      element={<AlertsListPage navigate={navigate} />}
    />]),
    ...insertIf(hasPermissions(pagePermissions.jobs.page), [<Route
      key='jobs'
      path='jobs'
      element={(
        <JobsListPage
          navigate={navigate}
        />
                                                                   )}
    />]),
    ...insertIf(hasPermissions(pagePermissions.users.page), [<Route
      path='users/'
      element={<UsersListPage />}
    />,
      <Route path='users/:userId' element={<UserPage />} />]),
    ...insertIf(hasPermissions(pagePermissions.accessRequests.page), [<Route
      key='accessrequests'
      path='accessrequests'
      element={<AccessRequests navigate={navigate} />}
    />]),
    ...insertIf(hasPermissions(pagePermissions.usage.page), [<Route
      key='usage'
      path='usage'
      element={
        <UsageFilePage />
}
    />]),
    ...insertIf(
      hasPermissions(pagePermissions.administration.mock.page),
      [
        <Route
          key='administration'
          path='administration/mock/:id'
          element={<DemoDataGeneration />}
        />,
        <Route
          key='administration'
          path='administration/mock/new'
          element={<DemoDataGeneration />}
        />,
        <Route
          key='administration'
          path='administration/mock/:id/copy'
          element={<DemoDataGeneration />}
        />,
      ]
    ),
    ...insertIf(hasPermissions(pagePermissions.administration.page), [<Route
      key='administration'
      path='administration'
      element={<AdministrationPage />}
    />]),

    ...insertIf(hasPermissions(pagePermissions.partners.page), [<Route
      key='partnerListRoute'
      path='partners'
      element={<PartnerListPage />}
    />]),
    ...insertIf(hasPermissions([pagePermissions.partners.page, pagePermissions.partners.add]), [
      <Route
        key='partnerAddRoute'
        path='partners/add'
        element={<PartnerEditorPage />}
      />]),
    ...insertIf(hasPermissions([pagePermissions.partners.page, pagePermissions.partners.edit]), [
      <Route
        key='partnerEditRoute'
        path='partners/:partnerId'
        element={<PartnerEditorPage />}
      />]),
    ...insertIf(hasPermissions([pagePermissions.partners.page, pagePermissions.partners.tenant]), [
      <Route
        key='partnerTenantRoute'
        path='partners/:partnerId/tenant'
        element={<PartnerTenantPage />}
      />]),
    ...insertIf(hasPermissions(pagePermissions.audit.page), [<Route
      key='audit'
      path='audit'
      element={
        <AuditListPage />
}
    />]),
    <Route key='requestrole' path='requestrole' element={<RequestRolePage />} />,
    ...insertIf(hasPermissions(pagePermissions.serviceMeters.page), [<Route
      key='servicemeters'
      path='servicemeters'
      element={<ServiceMeterListPage />}
    />]),
    ...insertIf(hasPermissions(pagePermissions.serviceMeters.page), [<Route
      key='servicemeters'
      path='servicemeters'
      element={<ServiceMeterListPage />}
    />]),
    (hasPermissions(pagePermissions.customers.page)
      ? (
        <Route
          key='analyticsRedirect'
          path='*'
          element={<Navigate to='analytics' replace={true} />}
        />
      )
      : (
        <Route
          key='requestroleRedirect'
          path='*'
          element={<Navigate to='requestrole' replace={true} />}
        />
      )),
  ] : []), [me, hasPermissions, navigate]);
};
