// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box, Button, Main,
} from 'grommet';
import PropTypes from 'prop-types';
import IDUtil from '../shared/util/IDUtil';
import {
  useCompanyCreateMutate,
  useCompanyQuery, useCompanyUpdateMutate,
} from '../../core';
import GLBMSaving from '../shared/component/GLBMSaving';

import Toast from '../shared/component/Toast';
import GLBMHeading from '../shared/component/GLBMHeading';
import CompanyEditorForm from './CompanyEditorForm';

// array of required fields
const requiredFields = ['name', 'address1', 'city', 'postalCode', 'country'];
const fieldSizes = {
  name: 250,
  address1: 250,
  address2: 250,
  city: 250,
  stateOrProvince: 20,
  postalCode: 20,
  country: 2,
};

const CompanyEditorPage = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState({});
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState();

  const {
    isSuccess: isCompanyDataSuccess,
    data: companyData,
  } = useCompanyQuery(companyId, {
    enabled: !!companyId,
  });

  useEffect(() => {
    if (isCompanyDataSuccess) {
      setCompany(companyData);
    }
  }, [isCompanyDataSuccess, companyData]);

  const onSaveError = (submitError) => {
    if (submitError?.response?.data?.validationErrors) {
      const { validationErrors } = submitError.response.data;
      validationErrors?.forEach((error) => {
        const attribute = error.propertyName.substring(error.propertyName.lastIndexOf('.') + 1);
        errors[attribute] = [error.message];
      });
      setErrors(prevState => ({
        ...prevState,
        ...errors,
      }));
    }
  };

  const {
    mutate: createCompany,
    isPending: isCreatingCompany,
    error: creatingCompanyError,
    isSuccess: isCreatingCompanySuccess,
  } = useCompanyCreateMutate({
    onError: onSaveError,
  });

  const {
    mutate: updateCompany,
    isPending: isUpdatingCompany,
    error: updatingCompanyError,
    isSuccess: isUpdatingCompanySuccess,
  } = useCompanyUpdateMutate(companyId, {
    onError: onSaveError,
  });

  // if create or update company is success, then we want to navigate away from the page
  useEffect(() => {
    if (isCreatingCompanySuccess || isUpdatingCompanySuccess) {
      navigate('/companies');
    }
  }, [isCreatingCompanySuccess, isUpdatingCompanySuccess]);

  const onChange = (event) => {
    const modifiedCompany = { ...company };
    const updatedErrors = { ...errors };
    const attribute = event.target.getAttribute('name');

    delete updatedErrors[attribute];
    updatedErrors[attribute] = undefined;

    // update partner object:
    switch (attribute) {
      case 'country':
        modifiedCompany[attribute] = event.value.value;
        break;
      default:
        modifiedCompany[attribute] = event.target.value;
    }

    // validation for required fields in array
    if (requiredFields.includes(attribute) && !modifiedCompany[attribute]) {
      updatedErrors[attribute] = 'Required';
    }

    // validation for field sizes in array
    if (modifiedCompany[attribute] && modifiedCompany[attribute].length > fieldSizes[attribute]) {
      updatedErrors[attribute] = `Exceeds maximum length of ${fieldSizes[attribute]} characters`;
    }

    setCompany(modifiedCompany);
    setErrors(updatedErrors);
  };

  const _getTotalErrors = () => {
    let totalErrors = 0;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const property in errors) {
      totalErrors += (!!errors[property]);
    }
    return totalErrors;
  };

  const onSubmit = () => {
    // Save to Backend
    const updatedError = { ...errors };

    let noErrors = (_getTotalErrors() === 0);

    ['name', 'address1', 'city', 'postalCode', 'country'].forEach((field) => {
      if (!company[field]) {
        updatedError[field] = 'Required';
        noErrors = false;
      }
    });

    if (!noErrors) {
      setErrors(updatedError);
      return;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (company.hasOwnProperty('id') && !!company.id) {
      updateCompany(company);
    } else {
      createCompany(company);
    }
  };

  const isNew = (!company.id);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        back='/companies'
        title={isNew ? 'Create New Company' : 'Edit Existing Company'}
      />
      <Box flex={true} pad={{ horizontal: 'medium' }} overflow='auto'>
        <Box flex={true}>
          <Box flex={false} width='480px'>
            <CompanyEditorForm
              company={company}
              errors={errors}
              onChange={onChange}
            />
          </Box>
        </Box>
      </Box>
      <Box direction='row' gap='small' pad={{ horizontal: 'small', vertical: 'small' }} border='top'>
        <Button
          label='Save'
          id={IDUtil.getId('EditorViewToolbarSaveButton')}
          type='button'
          primary={true}
          onClick={() => onSubmit()}
          disabled={isUpdatingCompany || isCreatingCompany}
        />
        <Button
          label='Cancel'
          id={IDUtil.getId('EditorViewToolbarCancelButton')}
          type='button'
          secondary={true}
          onClick={() => navigate('/companies')}
          disabled={isUpdatingCompany || isCreatingCompany}
        />
        <GLBMSaving saving={isCreatingCompany || isUpdatingCompany} error={creatingCompanyError || updatingCompanyError} />
      </Box>
      {response && (
        <Toast
          open={response}
          status={(response.status ? response.status : 'critical')}
          onClose={setResponse(undefined)}
        >
          {response.message}
        </Toast>
      )}
    </Main>
  );
};

CompanyEditorPage.contextTypes = {
  router: PropTypes.object,
};

export default CompanyEditorPage;
