// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import clone from 'lodash/clone';
import get from 'lodash/get';
import { isDev, isLocalhost } from '../environment/EnvironmentUtil';

import { LOCALDEV } from './http';

const APPLICATION_JSON = 'application/json';
const CONTENT_TYPE = 'Content-Type';

const parseJSON = response => response.json().catch(() => null);
const parseFile = response => response.blob();
const parseHeaders = (response, keys) => keys.reduce(
  (obj, key) => ({
    ...obj,
    [key]: response.headers.get(key),
  }),
  {}
);
const insertIf = (condition, elements) => (condition ? elements : []);

export const CONTENT_TYPE_MAP = {
  'application/json; charset=utf-8': parseJSON,
  [APPLICATION_JSON]: parseJSON,
  'application/vnd.pdf; charset=utf-8': parseJSON,
  'application/pdf': parseFile,
  'text/csv': parseFile,
  'application/vnd.ms-excel': parseFile,
};

export class APIError extends Error {
  constructor({ status, body }) {
    // Note, the error log default message does not need to be translated
    const message = get(body, 'message', 'No API message provided');
    super(message);
    this.name = 'APIError';
    this.status = status;
    this.body = body;
  }
}

const headersObj = {
  'Accept': APPLICATION_JSON,
  [CONTENT_TYPE]: APPLICATION_JSON,
};

// eslint-disable-next-line default-param-last
export async function headers(headersParam = headersObj, requestHeaders) {
  return {
    ...headersParam,
    ...requestHeaders,
    ...insertIf(isDev() && isLocalhost(), { [LOCALDEV]: 'c00bd7f0-babc-42f3-9994-4e3661aef4f9' }),
  };
}

// eslint-disable-next-line default-param-last
export const getFetchInit = async (data, method = 'GET', requestHeaders) => {
  const body = (typeof data === 'object') ? JSON.stringify(data) : data;
  const initObj = {
    headers: clone(await headers(undefined, requestHeaders)),
    method,
    redirect: 'follow',
    body: data ? body : undefined,
    credentials: 'include',
  };

  if (data instanceof FormData) {
    delete initObj.headers[CONTENT_TYPE];
    initObj.body = data;
  }

  return initObj;
};

export const handleError = response => new Promise((resolve, reject) => {
  if (response.status === 401) {
    // this is currently inelegant, but it's very effective
    if (window.location.pathname !== '/login') {
      // window.location = '/login';
    }
    response.json()
      .then(content => reject(new APIError({
        status: response.status,
        body: content,
      })));
  } else {
    // Ignore JSON parsing failures, normal for 204 No Content response
    response.json()
      .then(content => reject(new APIError({
        status: response.status,
        body: content,
      })))
      .catch(() => reject(new APIError({ status: response.status })));
  }
});

// Does a simple GET by default, using the app headers, returning the payload promise.
// It will retry the number of retries specified,or use the default retry count.
// Optional headerKeys is an array of strings to be passed to retrieve values from response.headers
// and return an object with key(s) from the array and value(s) from response.headers.
// For example, passing ['location'] will return { location: 'some value' } if 'location' exists
export function wrappedFetch(url, method, data, responseHeaderKeys, requestHeaders) {
  return getFetchInit(data, method, requestHeaders)
    .then(fetchInit => fetch(url, fetchInit))
    // Note, the following rejection is caught below.
    .then(response => (response.ok ? Promise.resolve(response) : Promise.reject(response)))
    .then((response) => {
      // Parse response.header and return it if headerKeys passed in
      if (responseHeaderKeys) {
        return parseHeaders(response, responseHeaderKeys);
      }
      const contentParser = CONTENT_TYPE_MAP[response.headers.get('content-type')] || parseJSON;
      return contentParser(response);
    })
    .catch(handleError);
}

export const apiCall = (...params) => wrappedFetch(...params)
  .then(response => ({ response }))
  .catch(error => ({ error }));

export const axiosConfig = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

export const axiosConfigCSV = {
  headers: {
    'Accept': 'text/csv',
    'Content-Type': 'text/csv',
  },
  withCredentials: true,
};

export const axiosConfigUpload = {
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  withCredentials: true,
};
