// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';
import { Box } from 'grommet';
import moment from 'moment';
import { useNormalizeColor } from '@saturn/charts';
import Legend from './Legend';
import Tooltip from './Tooltip';
import {
  computeYTicks, formatNumber, formatNumberWithSigDigits, formatShortNumber
} from '../utils';

const Areas = ({ areaGenerator, series, yScale }) => {
  const gen = areaGenerator.y0(d => yScale(d.y0));
  return series.filter(x => x.fill).map(({ id, data, fill }) => (
    <path
      d={gen(data.map(d => ({ ...d.position, y0: d.data.y0 })))}
      fill={fill}
      strokeWidth={0}
      style={{
        mixBlendMode: 'normal',
      }}
      key={`area${id}`}
    />
  ));
};

const Lines = ({
  series, lineGenerator, xScale, yScale,
}) => (
  series.filter(x => x.lineStyle)
    .map(({
      id,
      data,
      color,
      lineStyle,
    }) => (
      <path
        d={lineGenerator(
          data.map(d => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          })),
        )}
        fill='none'
        stroke={color}
        style={lineStyle}
        key={`line${id}`}
      />
    ))
);

function CapacityPlanningChart({ values, unit, missing }) {
  const normalizeColor = useNormalizeColor();

  const legendKeys = missing.committed || missing.requested
    ? ['installed', 'usage', 'forecasted']
    : ['high', 'optimal', 'low', 'usage', 'forecasted'];

  const nivoValues = useMemo(() => (values && Array.isArray(values) ? [
    {
      id: 'Reserved Capacity',
      legendKey: 'committed',
      color: normalizeColor('status-warning'),
      lineStyle: { strokeWidth: 3 },
      data: values.map(el => ({
        x: new Date(el.date),
        y: el.committed,
      })).filter(el => el.y !== undefined && el.y !== null && !Number.isNaN(el.y)),
    },
    {
      id: 'High Usage',
      legendKey: 'high',
      description: 'Actual Usage is above Requested Capacity',
      color: normalizeColor('status-critical'),
      fill: normalizeColor('validation-critical'),
      data: values.map(el => ({
        x: new Date(el.date),
        y: el.high && el.high[1],
        y0: el.high && el.high[0],
      })).filter(el => el.y0 !== undefined && el.y0 !== null && !Number.isNaN(el.y0) && el.y !== undefined && el.y !== null && !Number.isNaN(el.y)),
    },
    {
      id: 'Optimal Usage',
      legendKey: 'optimal',
      description: 'Actual Usage is between Reserved and Requested Capacity',
      color: normalizeColor('status-ok'),
      fill: normalizeColor('validation-ok'),
      data: values.map(el => ({
        x: new Date(el.date),
        y: el.optimal && el.optimal[1],
        y0: el.optimal && el.optimal[0],
      })).filter(el => el.y0 !== undefined && el.y0 !== null && !Number.isNaN(el.y0) && el.y !== undefined && el.y !== null && !Number.isNaN(el.y)),
    },
    {
      id: 'Low Usage',
      legendKey: 'low',
      description: 'Actual Usage is below Reserved Capacity',
      color: normalizeColor('status-warning'),
      fill: normalizeColor('validation-warning'),
      data: values.map(el => ({
        x: new Date(el.date),
        y: el.low && el.low[1],
        y0: el.low && el.low[0],
      })).filter(el => el.y0 !== undefined && el.y0 !== null && !Number.isNaN(el.y0) && el.y !== undefined && el.y !== null && !Number.isNaN(el.y)),
    },
    {
      id: 'Requested Capacity',
      legendKey: 'requested',
      color: normalizeColor('status-ok'),
      lineStyle: { strokeWidth: 3 },
      data: values.map(el => ({
        x: new Date(el.date),
        y: el.requested,
      })).filter(el => el.y !== undefined && el.y !== null && !Number.isNaN(el.y)),
    },
    {
      id: 'Installed Capacity',
      legendKey: 'installed',
      color: normalizeColor('status-critical'),
      lineStyle: { strokeWidth: 3 },
      data: values.map(el => ({
        x: new Date(el.date),
        y: el.usable,
      })).filter(el => el.y !== undefined && el.y !== null && !Number.isNaN(el.y)),
    },
    {
      id: 'Actual Usage',
      legendKey: 'usage',
      color: '#000000',
      lineStyle: {
        strokeWidth: 3,
      },
      data: values.filter(el => !el.forecast).map(el => ({
        x: new Date(el.date),
        y: el.used,
      })).filter(el => el.y !== undefined && el.y !== null && !Number.isNaN(el.y)),
    },
    {
      id: 'Forecasted Usage',
      legendKey: 'forecasted',
      color: '#000000',
      lineStyle: {
        strokeDasharray: '6, 3',
        strokeWidth: 3,
      },
      data: values.filter(el => el.forecast).map(el => ({
        x: new Date(el.date),
        y: el.used,
      })).filter(el => el.y !== undefined && el.y !== null && !Number.isNaN(el.y)),
    },
    {
      id: 'Available Capacity',
      data: values.map(el => ({
        x: new Date(el.date),
        y: el.available,
      })).filter(el => el.y !== undefined && el.y !== null && !Number.isNaN(el.y)),
    },
  ] : []), [normalizeColor, values]);

  const formatterX = date => moment(date).format('M/D/YYYY');
  const layers = [Areas, 'grid', 'axes', Lines, 'crosshair', 'slices', 'markers', 'points', 'legends'];

  const yMax = Math.max(...values.map(el => Math.max(
    el.used ? el.used : 0,
    el.usable ? el.usable : 0,
    el.requested ? el.requested : 0,
    el.committed ? el.committed : 0,
  ))) * 1.05;
  let yMin = Math.min(...values.map(el => Math.min(
    el.used ? el.used : 0,
    el.usable ? el.usable : 0,
    el.requested ? el.requested : 0,
    el.committed ? el.committed : 0,
  )));

  const xMin = values[0].date;
  yMin = yMin > 0 ? 0 : yMin;
  // Array of ticks for axis Y
  const yTicks = computeYTicks(yMin, yMax, 5);

  // Array of ticks for axis X
  const xTicks = [moment(xMin)];
  values.forEach((el) => {
    if (moment(el.date).format('MMM') !== moment(xTicks[xTicks.length - 1]).format('MMM')) {
      xTicks.push(new Date(el.date));
    }
  });

  const getLegendOffset = (tickValues) => {
    if (tickValues && tickValues[tickValues.length - 1]) {
      return -15 - (10 * formatShortNumber(tickValues[tickValues.length - 1]).replace(/,/gi, '').length);
    }
    return undefined;
  };

  const sliceTooltip = useCallback(props => (
    <Tooltip {...props} values={nivoValues} unit={unit} formatNumber={formatNumber} formatNumberWithSigDigits={formatNumberWithSigDigits} />
  ), [nivoValues, unit]);

  return (
    <Box flex={false} direction='column' height='350px'>
      <ResponsiveLine
        height={300}
        data={nivoValues}
        margin={{
          top: 30,
          right: 50,
          bottom: 60,
          left: 100,
        }}
        xScale={{
          type: 'time',
          format: 'native',
        }}
        yScale={{
          type: 'linear',
          max: yTicks ? yTicks[yTicks.length - 1] : 0,
        }}
        xFormat={formatterX}
        gridXValues={yTicks}
        gridYValues={5}
        axisBottom={{
          format: x => formatterX(x),
          tickValues: xTicks,
          tickPadding: 18,
        }}
        axisLeft={{
          legend: <tspan style={{ fontSize: '18px' }}>{unit}</tspan>,
          legendPosition: 'middle',
          legendOffset: getLegendOffset(yTicks),
          tickValues: 5,
          format: formatShortNumber,
        }}
        sliceTooltip={sliceTooltip}
        enableGridX={false}
        enablePoints={false}
        enableSlices='x'
        enableCrosshair={true}
        layers={layers}
        enableArea={true}
        colors={{ datum: 'color' }}
      />
      <Legend keys={legendKeys} data={nivoValues} />
    </Box>
  );
}

CapacityPlanningChart.propTypes = {
  values: PropTypes.array.isRequired,
  unit: PropTypes.string.isRequired,
  missing: PropTypes.shape({
    committed: PropTypes.bool,
    requested: PropTypes.bool,
  }).isRequired,
};

export default CapacityPlanningChart;
