// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';
import {
  Box,
  Button,
  Footer,
  FormField,
} from 'grommet';
import {
  usePaygISVDataMappingsMutation,
} from '../../../core';
import GLBMLayer from '../../shared/component/GLBMLayer';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import WiseQuoteIdSelector from '../WiseQuoteId/components/WiseQuoteIdSelector';

interface Context {
  companyName?: string;
  customerName?: string;
}

interface Data {
  quoteRecordId?: string;
  site?: string;
  sku?: string;
  beginMonth?: string;
  term?: number;
  termInYears?: string;
  context?: Context;
}

interface DataMappingEditorProps {
  onClose: () => void;
  onChange: () => void;
  data: Data;
}

const DataMappingEditor: React.FC<DataMappingEditorProps> = ({ data, onClose, onChange }) => {
  const [dataMapping, setDataMapping] = useState<Data>(JSON.parse(JSON.stringify(data)));
  const [error, setError] = useState<string | undefined>(undefined);

  const { mutate: updateContractMapping } = usePaygISVDataMappingsMutation();

  const onSubmit = () => {
    if (!dataMapping?.quoteRecordId) {
      setError('Required');
      return;
    }
    // @ts-ignore
    updateContractMapping(dataMapping, {
      onSuccess: () => {
        onChange();
      }
    });
  };

  const onChangeWiseQuoteId = (name: string, value: string) => {
    const newDataMapping = { ...dataMapping };
    if (!value) {
      delete newDataMapping[name];
    } else {
      newDataMapping[name] = value;
      if (newDataMapping[name].length === 0) {
        delete newDataMapping[name];
      }
    }
    setDataMapping(newDataMapping);
    setError(undefined);
  };

  return (
    <GLBMLayer
      position='right'
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      closer={true}
      flush={true}
      overlayClose={true}
      full='vertical'
      title='Assign Wise Quote ID'
      style={undefined}
    >
      <Box
        pad='none'
        style={{ 'width': '600px', 'minWidth': '600px' }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box pad='medium' flex={true}>
          <Box direction='row' align='center' pad={{ vertical: 'small' }}>
            <GLBMNameValueList
              title='Selected ISV Contract'
              data={[
                { label: 'Site', value: dataMapping.site || '--' },
                { label: 'SKU', value: dataMapping.sku || '--' },
                { label: 'Begin Month', value: dataMapping.beginMonth || '--' },
                { label: 'Term', value: dataMapping.termInYears || '--' },
                { label: 'Company', value: dataMapping?.context?.companyName || '--' },
                { label: 'Billing Account', value: dataMapping?.context?.customerName || '--' },
              ]}
            />
          </Box>
          <Box tag='section' pad={{ vertical: 'small' }} flex={false}>
            <FormField label='Specify WISE Quote ID' required={true} error={error}>
              <WiseQuoteIdSelector
                initialSelection={dataMapping?.quoteRecordId}
                allowNoSelection={false}
                onChange={value => onChangeWiseQuoteId('quoteRecordId', value)}
                style={{ width: '100%' }}
                plain={true}
                enableAllOption={false}
              />
            </FormField>
          </Box>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button label='OK' primary={true} onClick={onSubmit} type='submit' />
          <Button label='Cancel' secondary={true} onClick={onClose} />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

export default DataMappingEditor;
