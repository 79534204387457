// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setDirtyOptions, setOptions } from 'services/redux/ServiceActions';
import { FormField, TextInput } from 'grommet';
import PropTypes from 'prop-types';

export const EHRBillingMethodField = ({
  options,
  setOptions: setOptionsInjected,
  setDirtyOptions: setDirtyOptionsInjected,
  readOnly = false,
  setDirty
}) => {
  const isNumber = n => !Number.isNaN(parseFloat(n)) && Number.isFinite(parseFloat(n));

  const onChange = (property, value) => {
    let updatedValue = value;
    const updatedOptions = { ...options };
    // strip leading zero
    if (updatedValue && updatedValue.length >= 2 && updatedValue.startsWith('0')) {
      updatedValue = value.slice(1);
    }

    // make sure only whole numbers:
    updatedValue = updatedValue.toString().replace(/\D/ig, '');

    if (isNumber(updatedValue)) {
      const number = parseFloat(updatedValue);
      if (number > 24) {
        updatedOptions.config[property] = 24;
      } else if (number < 1) {
        updatedOptions.config[property] = 1;
      } else {
        updatedOptions.config[property] = number;
      }
    } else if (updatedValue === '') {
      updatedOptions.config[property] = 1;
    }
    setOptionsInjected(updatedOptions);
    setDirtyOptionsInjected();
    setDirty();
  };

  return (
    <FormField
      label='Peak Usage Hours Count'
      help='The Peak Usage Hours Count will be used to determine the number of peak hours to use when calculating the average number of concurrent users per day.'
    >

      <TextInput
        data-testid={readOnly ? 'disabled-input' : 'input'}
        disabled={readOnly === true}
        id='input_peakUsageHoursCount'
        name='input_peakUsageHoursCount'
        value={options.config.peakUsageHoursCount || 15}
        onChange={(event) => {
          onChange('peakUsageHoursCount', event.target.value);
        }}
      />
    </FormField>
  );
};

EHRBillingMethodField.propTypes = {
  setOptions: PropTypes.func.isRequired,
  setDirtyOptions: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  options: PropTypes.shape({
    config: PropTypes.shape({
      peakUsageHoursCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  readOnly: PropTypes.bool,
};

const mapStateToProps = ({ service: { details: { options } } }) => ({ options });

const mapDispatchToProps = dispatch => bindActionCreators({
  setOptions,
  setDirtyOptions,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EHRBillingMethodField);
