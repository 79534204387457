// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import ConfirmationDialog from '../../../../shared/dialogs/ConfirmationDialog';

const LocationRateDelete = ({ data, onClose, onSubmit }) => (
  <ConfirmationDialog
    title='Delete Revision'
    details={`Delete revision with effective date ${moment(data.effectiveDate).format('l')}?`}
    submitLabel='Yes, Delete'
    onClose={onClose}
    onChange={onSubmit}
    cancelLabel='Cancel'
    data={data}
  />
);

LocationRateDelete.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default LocationRateDelete;
