// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setDirtyOptions, setOptions } from 'services/redux/ServiceActions';
import {
  Box, FormField, Text, TextInput,
} from 'grommet';
import PropTypes from 'prop-types';
import CARadioButton from '../../../../../shared/form/CARadioButton';
import CACheckbox from '../../../../../shared/form/CACheckbox';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';

class LinuxBillingMethodField extends Component {
  constructor(props) {
    super(props);

    this._onChangeBillingMethod = this._onChangeBillingMethod.bind(this);
  }

  _isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  _onChangeUtilization(value) {
    const { options } = this.props;

    if (value && value.length >= 2 && value.startsWith('0')) {
      value = value.slice(1);
    }

    if (this._isNumber(value)) {
      if (parseFloat(value) > 100) {
        options.config.billingMethodUtilization = 100;
      } else if (parseFloat(value) < 0) {
        options.config.billingMethodUtilization = 0;
      } else {
        options.config.billingMethodUtilization = value;
      }
    } else if (value === '') {
      options.config.billingMethodUtilization = 0;
    }
    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _onChangeBillingMethod(linuxUtilBillingMethod) {
    const { options } = this.props;
    options.config.linuxUtilBillingMethod = linuxUtilBillingMethod;
    switch (linuxUtilBillingMethod) {
      case 'PER_HOST_UTILIZATION':
        options.config.billingMethodUtilization = 3;
        break;
      case 'PER_CORE_UTILIZATION':
        options.config.billingMethodUtilization = 3;
        break;
    }
    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _onChangeBillingCapUsage(event) {
    const { options } = this.props;
    options.config.capUsage = event.target.checked;

    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _getCapUsageCheckbox(readOnly) {
    return (
      <Box flex='grow' className='tiny-inputs' direction='row' gap='small'>
        <Box style={{ minWidth: '24px' }} />
        <div>
          <CACheckbox
            label='Cap usage at Installed Capacity'
            id='check_billing_options_cap_usage'
            name='check_billing_options_cap_usage'
            disabled={readOnly}
            onChange={(event) => {
              this._onChangeBillingCapUsage(event);
            }}
            checked={this.props.options.config.capUsage || false}
            tooltip='When checked, memory usage exceeding the physical memory of a host due to overprovisioned VMs is not charged for.'
          />
        </div>
      </Box>
    );
  }

  _getEditableLayout() {
    return (
      <FormField label='Set billing method to'>

        <CARadioButton
          label='Per core utilization (Core ON/OFF based on core utilization)'
          id='radio_billing_options_per_core_utilization'
          name='radio_billing_options_per_core_utilization'
          onChange={this._onChangeBillingMethod.bind(this, 'PER_CORE_UTILIZATION')}
          checked={this.props.options.config.linuxUtilBillingMethod === 'PER_CORE_UTILIZATION'}
        />

        {this.props.options.config.linuxUtilBillingMethod === 'PER_CORE_UTILIZATION'
        && (
        <Box flex='grow' className='tiny-inputs' direction='row' gap='small' align='center'>
          <Box style={{ minWidth: '50px' }} />
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}>
            <TextInput
              value={this.props.options.config.billingMethodUtilization}
              name='perCoreUtilization'
              onChange={(event) => {
                this._onChangeUtilization(event.target.value);
              }}
              id='perCoreUtilization'
            />
          </div>
          <Text weight={100} size='small'>Utilization %</Text>
        </Box>
        )}

        <CARadioButton
          label='Per host utilization (Host ON/OFF based on total CPU utilization per host)'
          id='radio_billing_options_per_host_utilization'
          name='radio_billing_options_per_host_utilization'
          onChange={this._onChangeBillingMethod.bind(this, 'PER_HOST_UTILIZATION')}
          checked={this.props.options.config.linuxUtilBillingMethod === 'PER_HOST_UTILIZATION'}
        />

        {this.props.options.config.linuxUtilBillingMethod === 'PER_HOST_UTILIZATION'
        && (
        <Box flex='grow' className='tiny-inputs' direction='row' gap='small' align='center'>
          <Box style={{ minWidth: '50px' }} />
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}>
            <TextInput
              value={this.props.options.config.billingMethodUtilization}
              name='perHostUtilization'
              onChange={(event) => {
                this._onChangeUtilization(event.target.value);
              }}
              id='perHostUtilization'
            />
          </div>
          <Text weight={100} size='small'>Utilization %</Text>
        </Box>
        )}
      </FormField>
    );
  }

  _getReadOnlyLayout() {
    const labelFromBillingMethodMap = new Map()
      .set('PER_CORE_UTILIZATION', 'Per core utilization (Core ON/OFF based on core utilization)')
      .set('PER_HOST_UTILIZATION', 'Per host utilization (Host ON/OFF based on average core CPU utilization per host)');

    const { linuxUtilBillingMethod } = this.props.options.config;

    return (
      <ReadOnlyField label='Billing Method' value={labelFromBillingMethodMap.get(linuxUtilBillingMethod)} />
    );
  }

  render() {
    return this.props.readOnly ? this._getReadOnlyLayout() : this._getEditableLayout();
  }
}

LinuxBillingMethodField.propTypes = {
  setOptions: PropTypes.func.isRequired,
  setDirtyOptions: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  options: PropTypes.shape({
    config: PropTypes.shape({
      linuxUtilBillingMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      capUsage: PropTypes.bool,
      billingMethodUtilization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

const mapStateToProps = ({ service: { details: { options } } }) => ({ options });

const mapDispatchToProps = dispatch => bindActionCreators({
  setOptions,
  setDirtyOptions,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinuxBillingMethodField);
