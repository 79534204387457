// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  Box, Notification, Spinner, Text,
} from 'grommet';
import { Checkmark } from 'grommet-icons';
import PropTypes from 'prop-types';
import React from 'react';

const GLBMSaving = ({
  saving = false,
  saved = false,
  error = undefined,
  warning = undefined,
}) => (
  <Box direction='row' align='center' gap='small' justify='start' fill={true} margin='none' pad='none'>
    {saving && (
      <Box direction='row' gap='small' pad={{ left: 'medium' }}>
        <Spinner />
        <Text>Saving. Please wait ...</Text>
      </Box>
    )}
    {saved && (
      <Box direction='row' gap='small' pad={{ left: 'medium' }}>
        <Checkmark color='brand' />
        <Text>Saved.</Text>
      </Box>
    )}
    { (error && typeof error === 'object') && (
      <Notification
        title='Saving Failed:'
        message={error.message || error.consumerMessage || 'Unknown Error'}
        status='critical'
      />
    )}
    { (error && typeof error === 'string') && (
      <Notification
        title='Saving Failed:'
        message={error || 'Unknown Error'}
        status='critical'
      />
    )}
    { warning && (
      <Notification
        message={warning}
        status='warning'
        size='medium'
      />
    )}
  </Box>
);

GLBMSaving.propTypes = {
  saving: PropTypes.bool,
  saved: PropTypes.bool,
  error: PropTypes.object,
  warning: PropTypes.string,
};

export default GLBMSaving;
