// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Box, Select } from 'grommet';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useQuotesQuery } from '../../../../core';

const WiseQuoteIdSelector = ({
  initialSelection = '',
  onChange = undefined,
  allowNoSelection = false,
  enableAllOption = false,
  ...rest
}) => {
  const [selected, setSelected] = useState(undefined);
  const [searchText, setSearchText] = useState(undefined);

  const {
    isFetching: fetchingQuotes,
    data: quotesDataMap,
  } = useQuotesQuery();

  useEffect(() => {
    if (onChange && selected) {
      onChange(selected.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const onSelect = (id, option) => {
    const singleId = Array.isArray(id) ? id[0] : id;
    const item = {
      values: singleId,
      last: option?.value
    };
    setSelected(item);
  };

  const makeInitialSelection = (options) => {
    if (initialSelection) {
      const initialSelectionList = Array.isArray(initialSelection) ? initialSelection : [initialSelection];
      const matchingOptions = options.filter(c => initialSelectionList.includes(c.value));
      const matchingSelections = matchingOptions.map(o => o.value);
      const option = matchingOptions.length > 0 ? matchingOptions[matchingOptions.length - 1] : undefined;
      if (option) { // found at least some of the initial data
        onSelect(matchingSelections, option);
      } else {
        onSelect(options[0].value, options[0]);
      }
    }
  };

  const selectOptions = useMemo(() => {
    if (!fetchingQuotes && quotesDataMap) {
      const options = [];
      quotesDataMap
        .forEach(({ id, quoteId: name }) => {
          options.push({
            label: name,
            value: id,
          });
        });

      options.sort((a, b) => {
        const aTitle = (a.label || a.value).toLowerCase();
        const bTitle = (b.label || b.value).toLowerCase();
        return (aTitle <= bTitle ? -1 : 1);
      });

      // Add all option
      if (enableAllOption) {
        const allOption = {
          label: <Box direction='row' align='center' justify='between'><span>All</span></Box>,
          value: 'ALL'
        };
        options.unshift(allOption);
      }

      if (allowNoSelection && !initialSelection) {
        return options;
      }

      makeInitialSelection(options);

      return options;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingQuotes, quotesDataMap]);

  const filteredOptions = useMemo(() => {
    if (selectOptions) {
      if (searchText) {
        return selectOptions.filter(option => option.label.toLowerCase()
          .includes(searchText.toLowerCase()));
      }
      return selectOptions;
    }
    return [];
  }, [selectOptions, searchText]);

  useEffect(() => {
    // Verify initial selection content.  If this changed, then a filter may have been reset
    if (selected && !isEqual(selected.values, initialSelection)) {
      makeInitialSelection(selectOptions);
    }
  }, [selectOptions, initialSelection]);

  return (
    (
      <Select
        options={filteredOptions || []}
        value={selected?.values}
        onChange={({ value, option }) => onSelect(value, option)}
        labelKey='label'
        data-e2e='open drop'
        valueKey={{ key: 'value', reduce: true }}
        onSearch={(text) => {
          // The line below escapes regular expression special characters:
          // [ \ ^ $ . | ? * + ( )
          const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
          setSearchText(escapedText);
        }}
        onClose={() => setSearchText(undefined)}
        {...rest}
      />
    )
  );
};

WiseQuoteIdSelector.propTypes = {
  initialSelection: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  onChange: PropTypes.func,
  allowNoSelection: PropTypes.bool,
  enableAllOption: PropTypes.bool,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  plain: PropTypes.bool,
};

export default WiseQuoteIdSelector;
