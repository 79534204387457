// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { usePermissionChecker } from '../../shared/hooks';

const PermissionCheck = ({
  permissions, children,
}) => {
  const { hasPermissions } = usePermissionChecker();
  const allowed = useMemo(() => hasPermissions(permissions), [hasPermissions, permissions]);
  if (!allowed) {
    return null;
  }
  return children;
};

PermissionCheck.propTypes = {
  permissions: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

export default PermissionCheck;
