// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useMemo } from 'react';
import {
  Anchor, Box, Card, CardBody, Heading, Text,
} from 'grommet';
import { Alert, FormEdit } from 'grommet-icons';
import IDUtil from '../shared/util/IDUtil';
import {
  useQueryCapacityAlertExceptionDefinitions
} from '../../core';
import type { CapacityAlertSettingsV2 } from '../../core/types';
import { isCategoryDisabled } from './utils';

interface Props {
  type: string
  customerId: string
  canEdit: boolean
  threshold: CapacityAlertSettingsV2['categories'][number]
  onEdit: (type: string) => void
  onException: (type: string) => void
}

const ThresholdComponent = ({
  customerId,
  type,
  onEdit,
  onException,
  threshold,
  canEdit
}: Props) => {
  const {
    data: definitions,
    isFetching
  } = useQueryCapacityAlertExceptionDefinitions(customerId);

  const isDisabled = useMemo(() => isCategoryDisabled(type, definitions, isFetching), [definitions, isFetching, type]);

  const onEditCb = useCallback(() => {
    onEdit(type);
  }, [onEdit, type]);

  const onExceptionCb = useCallback(() => {
    onException(type);
  }, [onException, type]);

  const styleClass = (isDisabled ? 'disabled' : '');

  return (
    <Card
      flex={true}
      data-e2e='widget'
      direction='column'
      style={{ minHeight: '225px' }}
      className={styleClass}
      data-testid={`card-${threshold.id}${isDisabled ? '-disabled' : ''}`}
    >
      <CardBody gap='medium'>
        <Heading level={4} margin='none'>
          {threshold.displayName}
        </Heading>
        <Box>
          <Box direction='row' justify='between'>
            <Text>Percent Free:</Text>
            <Box direction='row' gap='xsmall' data-testid='pctFree'>
              <Text size='large' weight='bold'>{threshold.pctFree}</Text>
              <Text size='large'>%</Text>
            </Box>
          </Box>
          <Box direction='row' justify='between'>
            <Text>Percent Change:</Text>
            <Box direction='row' gap='xsmall' data-testid='pctChange'>
              <Text size='large' weight='bold'>{threshold.pctChange}</Text>
              <Text size='large'>%</Text>
            </Box>
          </Box>
        </Box>
        <Box align='start' margin={{ top: 'small' }}>
          {canEdit ? (
            <Anchor
              label='Edit Defaults'
              disabled={isDisabled}
              color='brand'
              icon={<FormEdit />}
              onClick={isDisabled ? undefined : onEditCb}
              id={IDUtil.getId(`ThresholdComponent${type}EditButton`)}
            />
          ) : ''}
          <Anchor
            label={canEdit ? 'Edit Exceptions' : 'View Exceptions'}
            disabled={isDisabled}
            color='brand'
            icon={<Alert size='small' />}
            onClick={isDisabled ? undefined : onExceptionCb}
            id={IDUtil.getId(`ThresholdComponent${type}ExceptionButton`)}
          />
        </Box>
      </CardBody>
    </Card>
  );
};

export default ThresholdComponent;
