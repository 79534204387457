// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { Drag } from 'grommet-icons';
import { Draggable } from 'react-beautiful-dnd';
import { getItemStyle } from './utils';

export const Meter = ({
  location, service, meter, index, options,
}) => (
  <div>
    <Draggable
      draggableId={`${location.id}&&${service.type}&&${meter.id}`}
      index={index}
      type={`METER&&${location.id}&&${service.type}`}
      test='123'
      isDragDisabled={options.meters !== 'MANUAL'}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          <Box className='draggable-header meter' direction='row' alignContent='center'>
            <div {...provided.dragHandleProps}>
              {
                    options.meters === 'MANUAL' && <Drag />
                  }
            </div>
            <strong>{meter.name}</strong>
          </Box>
        </div>
      )}
    </Draggable>
  </div>
);
Meter.propTypes = {
  location: PropTypes.shape({ id: PropTypes.string }).isRequired,
  service: PropTypes.shape({ type: PropTypes.string }).isRequired,
  meter: PropTypes.shape({ id: PropTypes.string, name: PropTypes.node }).isRequired,
  index: PropTypes.any.isRequired,
  options: PropTypes.shape({ meters: PropTypes.string }).isRequired,
};
