// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Anchor } from 'grommet';
import React, { useMemo, useState } from 'react';
import {
  HighlightUsingContext,
} from '../../shared/component/HighlightUsingContext';
import { pagePermissions } from '../../shared/constants/Permissions';
import { usePermissionChecker } from '../../shared/hooks';
import { insertIf } from '../../shared/util/BasicUtil';
import DataMappingEditor from './DataMappingEditor';

export const useColumns = () => {
  const [dataToMutate, setDataToMutate] = useState();
  const { hasPermissions } = usePermissionChecker();
  const canEdit = hasPermissions(pagePermissions.payg.mappings.edit);

  const columns = useMemo(() => ([{
    property: 'site',
    header: 'Site',
    sort: true,
    primary: true,
    render: ({
      site,
    }) => (
      <HighlightUsingContext>
        {site || '--'}
      </HighlightUsingContext>
    ),
  }, {
    property: 'sku',
    header: 'SKU',
    sort: true,
    render: ({ sku }) => (
      <HighlightUsingContext>
        {sku || '--'}
      </HighlightUsingContext>
    ),
  },
  {
    property: 'beginMonth',
    header: 'Begin Month',
    sort: true,
    render: ({ beginMonth }) => (
      <HighlightUsingContext>
        {beginMonth || '--'}
      </HighlightUsingContext>
    ),
  },
  {
    property: 'termInYears',
    header: 'Term',
    sort: true,
    render: ({ termInYears }) => (
      <HighlightUsingContext>
        {termInYears || '--'}
      </HighlightUsingContext>
    ),
  },
  {
    property: 'context.companyName',
    header: 'Company',
    sortable: true,
    render: ({ context = {} }) => {
      const { companyName } = context;
      return (
        <HighlightUsingContext>
          {companyName || '--'}
        </HighlightUsingContext>
      );
    },
  },
  {
    property: 'context.customerName',
    header: 'Billing Account',
    render: ({ context = {}, billingAccount }) => {
      const { customerName } = context;
      return (
        <HighlightUsingContext>
          {customerName || billingAccount || '--'}
        </HighlightUsingContext>
      );
    },
  },
  {
    property: 'externalQuoteId',
    header: 'Wise Quote Id',
    sortable: true,
    render: ({ externalQuoteId }) => (
      <HighlightUsingContext>
        {externalQuoteId || '--'}
      </HighlightUsingContext>
    ),
  },
  ...insertIf(canEdit, [{
    property: 'actions',
    header: 'Actions',
    size: '96px',
    align: 'start',
    sortable: false,
    id: 'actions-list-page',
    render: datum => (<Anchor key='details' onClick={() => setDataToMutate(datum)}>{datum.externalQuoteId ? 'Change' : 'Assign'}</Anchor>),
  }]),
  ]), [canEdit]);

  let layers;
  if (dataToMutate) {
    layers = (
      <DataMappingEditor
        data={dataToMutate}
        title='Are you sure?'
        submitLabel='Yes, delete the Pending File'
        cancelLabel='Cancel'
        text='Deleting will permanently erase the pending file. Please ensure this is what you are intending before choosing "Yes" below.'
        onClose={() => setDataToMutate(undefined)}
        onChange={() => setDataToMutate(undefined)}
      />
    );
  }

  return { columns, layers };
};
