// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class LocationOption extends Enumify {
  static DEVICE_ID = new LocationOption(({ name: serviceType }) => {
    const labelMap = new Map()
      .set('STOREONCE', 'System ID')
      .set('VM', 'VM System ID');

    return labelMap.has(serviceType) ? labelMap.get(serviceType) : 'Device ID';
  });

  static MAPPED = new LocationOption(() => 'Mapped Location');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label, tooltip) {
    super();
    this.label = label;
    this.tooltip = tooltip;
  }
}
