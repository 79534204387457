// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import Invoice from './Invoice';

const InvoicePage = ({ selectedCustomer, toggleSetOrder }) => (
  <Box overflow='auto' height='full'>
    <Invoice selectedCustomer={selectedCustomer} toggleSetOrder={toggleSetOrder} />
  </Box>
);

InvoicePage.propTypes = {
  selectedCustomer: PropTypes.object.isRequired,
  toggleSetOrder: PropTypes.func.isRequired,
};

export default InvoicePage;
