// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { sessionDataSelector } from './redux/selectors';
import { sessionStorageKey } from './sessionStorageKey';

export function updateSessionFromState(state) {
  sessionStorage.setItem(sessionStorageKey, JSON.stringify(sessionDataSelector(state)));
}

export const updateStateAndSession = actionCreator => payload => (dispatch, getState) => {
  dispatch(actionCreator(payload));
  updateSessionFromState(getState());
};
