// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import { Box, FormField } from 'grommet';
import moment from 'moment';
import PropTypes from 'prop-types';
import IDUtil from '../util/IDUtil';
import DateTime from './DateTime';

const DateRange = ({
  filter,
  setFromDate,
  setToDate,
  format = 'YYYY-MM-DD',
  startLabel = 'Start',
  endLabel = 'End',
  errorProp = undefined,
}) => {
  const [fromError, setFromError] = useState('');
  const [toError, setToError] = useState('');

  const updateFromDate = (value) => {
    if (!moment(value, format, true).isValid()) {
      setFromError('Invalid Date format');
      setFromDate(undefined);
    } else {
      setFromError('');

      // from date is valid, update parent component:
      if (moment(value) >= moment(filter.to)) {
        setToDate(value);
      }
      setFromDate(value);
    }
  };

  const updateToDate = (value) => {
    if (!moment(value, format, true).isValid()) {
      setToError('Invalid Date format');
      setToDate(undefined);
    } else {
      setToError('');

      // to date is valid, update parent component:
      if (moment(value) <= moment(filter.from)) {
        setFromDate(value);
      }
      setToDate(value);
    }
  };

  return (
    <Box gap='small'>
      <Box>
        <FormField label={startLabel} error={fromError}>
          <DateTime name='contractStartMonth' id={IDUtil.getId('StartDateInput')} format={format} onChange={updateFromDate} value={filter.from} />
        </FormField>
      </Box>
      <Box>
        <FormField label={endLabel} error={errorProp || toError}>
          <DateTime name='contractEndMonth' id={IDUtil.getId('EndDateInput')} format={format} onChange={updateToDate} value={filter.to} />
        </FormField>
      </Box>
    </Box>
  );
};

DateRange.propTypes = {
  filter: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  setToDate: PropTypes.func.isRequired,
  setFromDate: PropTypes.func.isRequired,
  format: PropTypes.string,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  errorProp: PropTypes.string,
};

export default DateRange;
