// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
export class OptionsBuilder {
  constructor(options = []) {
    this._labelKey = '';
    this._valueKey = '';
    this._options = options;
    this._filterPredicate = () => true; // default to return all options
  }

  /**
   * Predicate used for filtering options
   * @param predicate
   * @returns {OptionsBuilder}
   */
  filterPredicate(predicate) {
    this._filterPredicate = predicate;
    return this;
  }

  /**
   * @param labelKey: string
   * @returns {OptionsBuilder}
   */
  labelKey(labelKey) {
    this._labelKey = labelKey;
    return this;
  }

  /**
   * @param valueKey: string
   * @returns {OptionsBuilder}
   */
  valueKey(valueKey) {
    this._valueKey = valueKey;
    return this;
  }

  build() {
    if (!this._labelKey || !this._valueKey) {
      throw Error('Must define attributes for label and value properties');
    }

    return this._options
      .filter(this._filterPredicate)
      .map(option => ({ label: option[this._labelKey], value: option[this._valueKey] }))
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
  }
}
