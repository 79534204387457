// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import StatusLabel from '../shared/component/StatusLabel';

export const AuditStatus = ({ status = undefined }) => {
  if (status >= 200 && status <= 399) {
    return (
      <Box direction='row' gap='small' align='center'>
        <Box gap='small' direction='row' pad={{ horizontal: 'none' }} align='center' style={{ width: '100%' }}>
          <Box><StatusLabel value='ok' label={status} /></Box>
        </Box>
      </Box>
    );
  }

  if (status >= 400 && status <= 499) {
    return (
      <Box direction='row' gap='small' align='center'>
        <Box gap='small' direction='row' pad={{ horizontal: 'none' }} align='center' style={{ width: '100%' }}>
          <Box><StatusLabel value='warning' label={status} /></Box>
        </Box>
      </Box>
    );
  }

  if (status >= 500) {
    return (
      <Box direction='row' gap='small' align='center'>
        <Box gap='small' direction='row' pad={{ horizontal: 'none' }} align='center' style={{ width: '100%' }}>
          <Box><StatusLabel value='critical' label={status} /></Box>
        </Box>
      </Box>
    );
  }

  return status;
};

AuditStatus.propTypes = {
  status: PropTypes.number,
};
