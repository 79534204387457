// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  Box, Button, Footer, List, Main,
} from 'grommet';
import PropTypes from 'prop-types';
import {
  useCustomerQuery,
  useCustomerServiceQuery,
  useEquipmentDeleteMutate,
  useEquipmentQuery,
} from '../../../core';
import GLBMSaving from '../../shared/component/GLBMSaving';
import GLBMSearch from '../../shared/component/GLBMSearch';
import Toast from '../../shared/component/Toast';
import {
  setEquipment,
  setOptions,
} from '../redux/ServiceActions';
import { ServiceColumn } from '../model';
import { getColumnLabel } from '../Util';
import IDUtil from '../../shared/util/IDUtil';
import ConfirmationDialog from '../../shared/dialogs/ConfirmationDialog';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import Loader from '../../shared/loader';
import { ListPlaceholder } from '../../shared/component/ListPlaceholder';
import GLBMHeading from '../../shared/component/GLBMHeading';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import DeleteEquipmentTable from './DeleteEquipmentTable';

const DeleteEquipmentList = ({
  columns = undefined,
  dirtyEquipment,
  sort = undefined,
  validation,
  setOptions,
  setEquipment,
  options,
  serviceType = undefined,
}) => {
  const navigate = useNavigate();
  const { customerId, serviceId } = useParams();

  const [equipmentList, setEquipmentList] = useState([]);
  const [layer, setLayer] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const [response, setResponse] = useState(undefined);
  const [idLabelIndex, setIdLabelIndex] = useState(undefined);

  const processEquipment = (equipment, serviceStatus) => {
    const equipmentList = [];
    if (equipment?.length) {
      equipment.sort((a, b) => ((a.name > b.name) ? -1 : 1));

      equipment.forEach(((equipment) => {
        // see if this equipment was already in the dirty equipment list, and should remain so:
        let isDirty = (!!dirtyEquipment.filter(de => de.data.equipmentId === equipment.equipmentId).length);

        // if not dirty, then this equipment has not yet been through this logic, so lets include it by default:
        if (serviceStatus === 'NEW') {
          if (!isDirty) {
            equipment.include = true; // include by default
            isDirty = true; // since we changed the include flag, it's not dirty.
          }
        }

        const device = {
          data: equipment,
          selected: false,
          dirty: isDirty,
        };

        equipmentList.push(device);
      }));
    }
    return equipmentList;
  };

  const {
    data: customer,
    isFetching: isCustomerFetching,
  } = useCustomerQuery(customerId, {
    onError: (error) => {
      setResponse({
        status: 'critical',
        message: `Unable to load customer info: ${error.consumerMessage}`,
        stacktraceRef: `Stacktrace Reference: ${error.stacktraceRef}`,
      });
    },
  });

  const {
    data: service,
    isFetching: isServiceFetching,
    isSuccess: isServiceSuccess,
    isError: isServiceError,
    error: serviceError,
  } = useCustomerServiceQuery(customerId, serviceId);

  useEffect(() => {
    if (isServiceSuccess) {
      setOptions(service);
    } else if (isServiceError) {
      setResponse({
        status: 'critical',
        message: `Unable to load service info: ${serviceError.consumerMessage}`,
        stacktraceRef: `Stacktrace Reference: ${serviceError.stacktraceRef}`,
      });
    }
  }, [isServiceSuccess, service, isServiceError, serviceError]);

  const {
    data: equipment,
    isFetching: isEquipmentFetching,
    isSuccess: isEquipmentSuccess,
    isError: isEquipmentError,
    error: equipmentError,
  } = useEquipmentQuery(customerId, serviceId, {
    enabled: !!service,
  });

  useEffect(() => {
    if (isServiceSuccess && isEquipmentSuccess) {
      setEquipment(equipment);
      setEquipmentList(processEquipment(equipment, service.status));
    } else if (isEquipmentError) {
      setResponse({
        status: 'critical',
        message: `Unable to load equipment: ${equipmentError.consumerMessage}`,
        stacktraceRef: `Stacktrace Reference: ${equipmentError.stacktraceRef}`,
      });
    }
  }, [isEquipmentSuccess, isEquipmentSuccess, equipment, isEquipmentError, serviceError]);

  const {
    mutate: deleteEquipment,
    isLoading: isDeletingEquipment,
  } = useEquipmentDeleteMutate(customerId, serviceId, {
    onSuccess: () => {
      setLayer(undefined);
      navigate(`/customers/${customer.id}/services`);
    },
    onError: (error) => {
      setResponse({
        status: 'critical',
        message: `Unable to delete equipment: ${error.consumerMessage}`,
        stacktraceRef: `Stacktrace Reference: ${error.stacktraceRef}`,
      });
    },
  });

  const isLoading = useMemo(() => isCustomerFetching || isServiceFetching || isEquipmentFetching, [isCustomerFetching, isServiceFetching, isEquipmentFetching]);

  useEffect(() => {
    if (service) {
      const mappedTiers = service?.config?.mappedTiers || [];
      const index = mappedTiers.reduce((map, item) => Object.assign(map, { [item.id]: item.name }), {});
      setIdLabelIndex(index);
    }
  }, [service]);

  const onSearchChange = (event) => {
    setSearchText(event);
  };

  const onLayerClose = () => {
    setLayer(undefined);
    setResponse(undefined);
  };

  const onEquipmentSelection = (selectedEquipment) => {
    const updatedEquipmentList = equipmentList.map(el => (el.data.equipmentId === selectedEquipment.data.equipmentId ? selectedEquipment : el));
    setEquipmentList(updatedEquipmentList);
  };

  const onDeleteEquipment = (data) => {
    deleteEquipment(data.equipmentId);
  };

  const renderConfirmationDetails = () => {
    const recValues = equipmentList.filter(e => e.selected);
    const selectedRecords = recValues.map(e => e.data.equipmentId);
    const selectedList = (
      <List
        secondaryKey='name'
        data={selectedRecords}
      />
    );
    return (
      <Box
        margin={{ top: 'small' }}
      >
        <GLBMNameValueList
          title='Selected Equipment'
          data={recValues.length !== 0 ? [
            { label: 'Equipment', value: selectedList },
          ] : []}
        />
      </Box>
    );
  };

  const deleteEquipmentFn = () => {
    const selectedEquipment = equipmentList.filter(x => x.selected);
    if (selectedEquipment.length) {
      setLayer('deleteEquipment');
    } else {
      setLayer('noneSelected');
    }
  };

  const renderLayer = () => {
    let result;
    if (layer) {
      if (layer === 'deleteEquipment') {
        result = (
          <ConfirmationDialog
            data={{
              x: this,
              customer: customerId,
              service: serviceType,
              equipmentId: equipmentList.filter(e => e.selected).map(e => e.data.equipmentId),
            }}
            title='Are You Sure?'
            submitLabel='Confirm Delete'
            cancelLabel='Cancel'
            text="Deleting an equipment will permanently erase it.
            Please ensure you are intending to completely remove this equipment before choosing 'Confirm Delete' below."
            onClose={onLayerClose}
            onChange={onDeleteEquipment}
            details={renderConfirmationDetails()}
          />
        );
      }
    }
    return result;
  };

  const renderToast = () => {
    let message = '';
    if (response) {
      message = (
        <Toast
          open={response}
          status={(response.status ? response.status : 'critical')}
        >
          { response.text }
        </Toast>
      );
    }
    return message;
  };

  const noRowsElement = (totalCount, filteredCount) => {
    if (totalCount === 0) {
      return (
        <ListPlaceholder
          emptyMessage='This customer returned zero equipment of this type.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    } if (filteredCount === 0) {
      return (
        <ListPlaceholder
          emptyMessage='Your filter returned zero results, adjust to continue.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }
    return '';
  };

  const filteredEquipment = useMemo(() => equipmentList.filter((el) => {
    const filterBlackList = [ServiceColumn.SELECT];
    const filterableColumns = columns.filter(column => !filterBlackList.includes(column));
    if (searchText) {
      return filterableColumns.some((column) => {
        const columnText = getColumnLabel(el.data, column, serviceType, idLabelIndex) || '';
        return columnText.toString().toLowerCase().includes(searchText.toLowerCase());
      });
    }
    return true;
  }), [equipmentList, columns, searchText, serviceType, idLabelIndex]);
  const noRows = noRowsElement(equipmentList.length, filteredEquipment.length);
  const columnHeaders = columns?.map(column => column.header(serviceType));

  if (!serviceType || isLoading) {
    return (
      <Box direction='row' align='center' gap='small' justify='center' fill={true}>
        <Loader text='Loading Services. Please wait ...' />
      </Box>
    );
  }

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title={`Delete ${serviceType.displayName} Equipment from ${customer.name} (${customer.id})`}
        back={`/customers/${customer.id}/services`}
        search={
            (
              <GLBMSearch
                placeholder='Search'
                id={IDUtil.getId('Search')}
                onChange={event => onSearchChange(event)}
              />
)
          }
      />
      <DeleteEquipmentTable
        columns={columns}
        idLabelIndex={idLabelIndex}
        config={service?.config}
        serviceType={serviceType}
        filteredEquipment={filteredEquipment}
        validation={validation}
        labels={columnHeaders}
        sort={sort}
        onEquipmentSelection={onEquipmentSelection}
        searchText={searchText}
      />
      {noRows}
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start'>
          <Button primary={true} label='Delete' onClick={() => deleteEquipmentFn(service)} />
          <Button label='Close' secondary={true} type='button' onClick={() => navigate(`/customers/${customer.id}/services`)} />
          <GLBMSaving saving={isDeletingEquipment} warning={layer === 'noneSelected' ? 'Please select an equipment to delete' : undefined} />
        </Footer>
      </Box>
      {renderLayer()}
      {renderToast()}
    </Main>

  );
};
const columnHeadersUtil = (columns) => {
  const select = [ServiceColumn.SELECT];
  return [...select, ...columns];
};

DeleteEquipmentList.contextTypes = {
  router: PropTypes.object,
};

DeleteEquipmentList.propTypes = {
  serviceType: PropTypes.object,
  columns: PropTypes.array,
  sort: PropTypes.object,
  filter: PropTypes.object,
  dirtyEquipment: PropTypes.array,
  validation: PropTypes.object,
  setOptions: PropTypes.func,
  setEquipment: PropTypes.func,
};

const mapStateToProps = ({
  service: {
    details: {
      options, originalOptions, equipment, dirtyEquipment, dirtyComponents, validation,
    },
  },
}) => {
  const serviceType = options ? ServiceTypeStore.getService(options.config.serviceType) : null;
  const { distinctColumns } = serviceType ? serviceType.configureResources : { distinctColumns: null };
  const columns = distinctColumns && columnHeadersUtil(distinctColumns);
  const sort = columns ? {
    property: columns[1].field(),
    direction: 'asc',
  } : null;

  return {
    options,
    originalOptions,
    equipment,
    columns,
    serviceType,
    dirtyEquipment,
    dirtyComponents,
    validation,
    sort,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setOptions,
  setEquipment,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteEquipmentList);
