// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  UPDATE_PARTNER_PAGE_FILTERS,
} from './PartnerPageAction';

// eslint-disable-next-line @typescript-eslint/default-param-last
export function partnerList(state = {
  filters: {
    list: {
      searchText: '', sort: { property: 'name', direction: 'asc', external: true },
    },
    panel: {},
  },

}, action) {
  switch (action.type) {
    case UPDATE_PARTNER_PAGE_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;
  }
}
