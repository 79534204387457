// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setDirtyOptions, setOptions } from 'services/redux/ServiceActions';
import { ReportingUnits } from 'services/model/ReportingUnits';
import {
  Box, FormField, RadioButton, Text, TextInput,
} from 'grommet';
import PropTypes from 'prop-types';
import CATextInput from '../../../../../shared/form/CATextInput';
import CARadioButton from '../../../../../shared/form/CARadioButton';
import CACheckbox from '../../../../../shared/form/CACheckbox';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';

class VMBillingMethodField extends Component {
  constructor(props) {
    super(props);

    this._onChangeBillingMethod = this._onChangeBillingMethod.bind(this);
  }

  _isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  _onChangeUtilization(property, value) {
    const { options } = this.props;

    if (value && value.length >= 2 && value.startsWith('0')) {
      value = value.slice(1);
    }

    if (this._isNumber(value)) {
      if (parseFloat(value) > 100) {
        options.config[property] = 100;
      } else if (parseFloat(value) < 0) {
        options.config[property] = 0;
      } else {
        options.config[property] = value;
      }
    } else if (value === '') {
      options.config[property] = 0;
    }
    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _onChangeBillingMethod(billingMethod) {
    const { options } = this.props;
    options.config.billingMethod = billingMethod;
    switch (billingMethod) {
      case 'VM20':
      case 'VDI':
        options.config.capUsage = true;
        options.config.subscriptionRatio = 85;
        break;
      case 'PER_HOST_VM':
        delete options.config.billingMethodUtilization;
        break;
      case 'PER_HOST_UTILIZATION':
        options.config.billingMethodUtilization = 1;
        break;
      case 'PER_CORE_UTILIZATION':
        options.config.billingMethodUtilization = 5;
        break;
      case 'PER_HOST_MEMORY_UTILIZATION':
        options.config.billingMethodUnit = ReportingUnits.GB.enumKey;
        break;
    }
    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _onChangeBillingCapUsage(event) {
    const { options } = this.props;
    options.config.capUsage = event.target.checked;

    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _onChangeIncludeVmCountMeters(event) {
    const { options } = this.props;
    options.config.includeVmCountMeters = event.target.checked;

    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _onChangeBillingMemoryUtilizationUnit(event, unit) {
    const { options } = this.props;
    options.config.billingMethodUnit = unit;

    this.props.setOptions(options);
    this.props.setDirtyOptions();
    this.props.setDirty();
  }

  _renderIncludeVmCountMeterCheckbox(readOnly) {
    return (
      <FormField label='VM Count handling'>
        <CACheckbox
          label='Include VM Count Meters (UBS Only)'
          id='check_vm_count_handling_include_vm_count_meters'
          name='check_vm_count_handling_include_vm_count_meters'
          disabled={readOnly}
          onChange={(event) => {
            this._onChangeIncludeVmCountMeters(event);
          }}
          checked={this.props.options.config.includeVmCountMeters || false}
          tooltip='When checked, VM Count Meters will be automatically added to this service. This should NOT be checked for most customers, it is only used to meet explicit contractual obligations for a few customers.'
        />
      </FormField>
    );
  }

  _getPerHostMemoryUtilizationField() {
    const { billingMethodUnit } = this.props.options.config;
    const radioButtons = [ReportingUnits.GB, ReportingUnits.GiB, ReportingUnits.TB, ReportingUnits.TiB].map(({ enumKey }) => (
      <RadioButton
        key={enumKey}
        id={`${enumKey}-memory-utilization-units`}
        name={`${enumKey}-memory-utilization-units`}
        label={enumKey}
        checked={billingMethodUnit === enumKey}
        onChange={this._onChangeBillingMemoryUtilizationUnit.bind(this, 'billingMethodUnit', enumKey)}
      />
    ));

    return (
      <Box
        flex='grow'
        className='tiny-inputs'
        direction='row'
        gap='small'
      >
        <Box style={{ minWidth: '50px' }} direction='column' />
        <Box direction='column'>
          <Text weight={100} size='small'>Billing units</Text>
          {radioButtons}
        </Box>
      </Box>
    );
  }

  _getEditableVMBillingField() {
    return (
      <FormField label='Set billing method to'>
        <CARadioButton
          label='VM2.0 (Compute Units)'
          id='radio_billing_options_vm20'
          name='billingOptionsVM20_'
          onChange={this._onChangeBillingMethod.bind(this, 'VM20')}
          checked={this.props.options.config.billingMethod === 'VM20'}
        />
        {this.props.options.config.billingMethod === 'VM20'
        && this._getVM20Fields(false)}

        <CARadioButton
          label='Per host utilization (Host ON/OFF based on total CPU utilization per host)'
          id='radio_billing_options_per_host_utilization'
          name='radio_billing_options_per_host_utilization'
          onChange={this._onChangeBillingMethod.bind(this, 'PER_HOST_UTILIZATION')}
          checked={this.props.options.config.billingMethod === 'PER_HOST_UTILIZATION'}
        />
        {this.props.options.config.billingMethod === 'PER_HOST_UTILIZATION'
        && (
          <Box
            flex='grow'
            className='tiny-inputs'
            direction='row'
            gap='small'
            align='center'
          >
            <Box style={{ minWidth: '50px' }} />
            <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}>
              <TextInput
                value={this.props.options.config.billingMethodUtilization}
                name='perHostUtilization'
                onChange={(event) => {
                  this._onChangeUtilization('billingMethodUtilization', event.target.value);
                }}
                id='perHostUtilization'
              />
            </div>
            <Text weight={100} size='small'>Utilization %</Text>
          </Box>
        )}
        <CARadioButton
          label='Per core utilization (Core ON/OFF based on core utilization)'
          id='radio_billing_options_per_core_utilization'
          name='radio_billing_options_per_core_utilization'
          onChange={this._onChangeBillingMethod.bind(this, 'PER_CORE_UTILIZATION')}
          checked={this.props.options.config.billingMethod === 'PER_CORE_UTILIZATION'}
        />
        {this.props.options.config.billingMethod === 'PER_CORE_UTILIZATION'
        && (
          <Box
            flex='grow'
            className='tiny-inputs'
            direction='row'
            gap='small'
            align='center'
          >
            <Box style={{ minWidth: '50px' }} />
            <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}>
              <TextInput
                value={this.props.options.config.billingMethodUtilization}
                name='perHostUtilization'
                onChange={(event) => {
                  this._onChangeUtilization('billingMethodUtilization', event.target.value);
                }}
                id='perHostUtilization'
              />
            </div>
            <Text weight={100} size='small'>Utilization %</Text>
          </Box>
        )}
        <CARadioButton
          label='Per host VM (Host ON/OFF based on whether the host has active VMs)'
          id='radio_billing_options_per_host_vm'
          name='radio_billing_options_per_host_vm'
          onChange={this._onChangeBillingMethod.bind(this, 'PER_HOST_VM')}
          checked={this.props.options.config.billingMethod === 'PER_HOST_VM'}
        />
        <CARadioButton
          label='Per host memory utilization'
          id='radio_billing_options_per_host_memory_utilization'
          name='radio_billing_options_per_host_memory_utilization'
          onChange={this._onChangeBillingMethod.bind(this, 'PER_HOST_MEMORY_UTILIZATION')}
          checked={this.props.options.config.billingMethod === 'PER_HOST_MEMORY_UTILIZATION'}
        />
        {this.props.options.config.billingMethod === 'PER_HOST_MEMORY_UTILIZATION'
        && this._getPerHostMemoryUtilizationField()}
        <CARadioButton
          label='VDI GreenLake'
          id='radio_billing_options_vdi'
          name='billingOptionsVDI_'
          onChange={this._onChangeBillingMethod.bind(this, 'VDI')}
          checked={this.props.options.config.billingMethod === 'VDI'}
        />
        {this.props.options.config.billingMethod === 'VDI'
        && this._getVM20Fields(false)}
      </FormField>
    );
  }

  _getVM20Fields(readOnly) {
    return (
      <Box gap='small'>
        <Box flex='grow' className='tiny-inputs' direction='row' margin={{ left: 'small' }}>
          <Box style={{ minWidth: '50px' }} />
          <CATextInput
            id='input_subscription_ratio'
            label='Subscription Ratio %'
            name='input_subscription_ratio'
            value={this.props.options.config.subscriptionRatio}
            onChange={(value) => {
              this._onChangeUtilization('subscriptionRatio', value);
            }}
            tooltip='Subscription Ratio is the percentage of physical memory available for VMs (the rest is reserved for overhead).'
          />
        </Box>
        <Box
          flex='grow'
          className='tiny-inputs'
          direction='row'
          gap='small'
        >
          <Box style={{ minWidth: '24px' }} />
          <div>
            <CACheckbox
              label='Cap usage at Installed Capacity'
              id='check_billing_options_cap_usage'
              name='check_billing_options_cap_usage'
              disabled={readOnly}
              onChange={(event) => {
                this._onChangeBillingCapUsage(event);
              }}
              checked={this.props.options.config.capUsage || false}
              tooltip='When checked, memory usage exceeding the physical memory of a host due to overprovisioned VMs is not charged for.'
            />
          </div>
        </Box>
      </Box>
    );
  }

  _getReadOnlyVMBillingField() {
    const labelFromBillingMethodMap = new Map()
      .set('VM20', 'VM2.0 (Compute Units)')
      .set('PER_HOST_UTILIZATION', 'Per host utilization (Host ON/OFF based on total CPU utilization per host)')
      .set('PER_CORE_UTILIZATION', 'Per core utilization (Core ON/OFF based on core utilization)')
      .set('PER_HOST_VM', 'Per host VM (Host ON/OFF based on whether the host has active VMs)')
      .set('PER_HOST_MEMORY_UTILIZATION', 'Per host memory utilization')
      .set('VDI', 'VDI GreenLake');

    const { billingMethod } = this.props.options.config;

    return (
      <ReadOnlyField
        label='Billing Method'
        value={labelFromBillingMethodMap.get(billingMethod)}
      >
        {billingMethod === 'VM20' && this._getVM20Fields(true)}
        {billingMethod === 'VDI' && this._getVM20Fields(true)}
      </ReadOnlyField>
    );
  }

  render() {
    return (
      <>
        {this.props.readOnly ? this._getReadOnlyVMBillingField() : this._getEditableVMBillingField()}
        {this._renderIncludeVmCountMeterCheckbox()}
      </>
    );
  }
}

VMBillingMethodField
  .propTypes = {
    setOptions: PropTypes.func.isRequired,
    setDirtyOptions: PropTypes.func.isRequired,
    setDirty: PropTypes.func.isRequired,
    options: PropTypes.shape({
      config: PropTypes.shape({
        billingMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        capUsage: PropTypes.bool,
        billingMethodUtilization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
  };

const
  mapStateToProps = ({ service: { details: { options } } }) => ({ options });

const
  mapDispatchToProps = dispatch => bindActionCreators({
    setOptions,
    setDirtyOptions,
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  VMBillingMethodField,
);
