// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Search } from 'grommet-icons';
import map from 'lodash/map';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import * as Case from 'case';

import {
  Anchor,
  Box,
  Button,
  CheckBox,
  Footer,
  Table, TableBody, TableCell,
  TableHeader, TableRow,
  TextInput,
} from 'grommet';
import { AdvancedSwitch, instanceOf, matches } from '../../../../shared/util/AdvancedEnum';
import { cmpStringsWithNumbers } from '../../../../shared/util/CompareUtil';
import IDUtil from '../../../../shared/util/IDUtil';
import { useServiceComponentsQuery } from '../../../../../core';
import GLBMLayer from '../../../../shared/component/GLBMLayer';
import GLBMTooltip from '../../../../shared/component/GLBMTooltip';
import { EquipmentIdColumn, ServiceColumn } from '../../../model';
import Loader from '../../../../shared/loader';
import { ListPlaceholder } from '../../../../shared/component/ListPlaceholder';
import { getComponentKeyField, getServiceLabel } from '../../../Util';

const ConfigurableComponentEdit = (props) => {
  const [components, setComponents] = useState([]);
  const [searchText, setSearchText] = useState('');

  const columns = useMemo(() => [ServiceColumn.INCLUDE, ...props.serviceType.configureComponents.distinctColumns], [props.serviceType]);
  const componentKeyField = useMemo(() => getComponentKeyField(props.serviceType), [props.serviceType]);

  const equipment = props.equipment.data;
  const customerId = props.customer.id;
  const serviceId = equipment.type;
  const { equipmentId } = equipment;

  const {
    isFetching: loading,
    data: serviceComponentsData,
    isSuccess: isServiceComponentsSuccess,
  } = useServiceComponentsQuery(customerId, serviceId, equipmentId, 'drives');

  useEffect(() => {
    if (isServiceComponentsSuccess) {
      const modifiedServiceComponentsData = [...serviceComponentsData];
      // loop through the list, and add to list if not already in list, else replace:
      if (props.modifiedComponents) {
        for (let i = 0; i < props.modifiedComponents.length; i += 1) {
          const modifiedDrive = props.modifiedComponents[i];
          const indexOfDrive = modifiedServiceComponentsData.findIndex(d => (d[componentKeyField] === modifiedDrive[componentKeyField]));
          if (indexOfDrive !== -1) {
            // eslint-disable-next-line no-param-reassign
            modifiedServiceComponentsData[indexOfDrive].exclude = modifiedDrive.exclude;
          }
        }
      }
      setComponents(modifiedServiceComponentsData);
    }
  }, [isServiceComponentsSuccess, serviceComponentsData]);

  const _onSubmit = () => {
    // eslint-disable-next-line react/prop-types
    props.onChange(equipmentId, components);
  };

  const _onToggleInclude = (component, event) => {
    const modifiedComponents = [...components];
    const index = modifiedComponents.findIndex(item => item[componentKeyField] === component[componentKeyField]);
    if (index >= 0) {
      const drive = modifiedComponents[index];
      drive.dirty = true;
      drive.exclude = !event.target.checked;
      setComponents(modifiedComponents);
    }
  };

  const _columnHeaders = () => columns.map(column => column.header(props.serviceType));

  const _filterComponents = useMemo(() => {
    const localSearchText = searchText?.toLowerCase();
    if (!localSearchText) {
      return components;
    }
    const results = [];
    for (let i = 0; i < components.length; i += 1) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in components[i]) {
        if (typeof components[i][key] === 'string') {
          if (components[i][key].toLowerCase()
            .indexOf(searchText) !== -1) {
            results.push(components[i]);
            break;
          }
        }
      }
    }
    return results;
  }, [components, searchText]);

  // eslint-disable-next-line no-shadow
  const _sortComponents = (components) => {
    if (Array.isArray(components)) {
      return components.sort((a, b) => cmpStringsWithNumbers(a[componentKeyField], b[componentKeyField]));
    }
    return [];
  };

  const _renderNoRowsElement = (total, filtered) => {
    if (loading) {
      return (
        <Box
          direction='row'
          align='center'
          gap='small'
          justify='center'
          fill={true}
        >
          <Loader text='Loading. Please wait ...' />
        </Box>
      );
    }
    if (total === 0) {
      return (
        <ListPlaceholder
          emptyMessage='No drives.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }
    if (filtered === 0) {
      return (
        <ListPlaceholder
          emptyMessage='Your filter returned zero drives. To continue, adjust it.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }
    return '';
  };

  const _getIncludeToggleTd = (component, column) => (
    <TableCell key={Case.camel(column.field())}>
      <CheckBox
        name='included'
        toggle={true}
        checked={!component.exclude}
        disabled={props.readOnly}
        onChange={event => _onToggleInclude(component, event)}
      />
    </TableCell>
  );

  // eslint-disable-next-line no-shadow
  const _getTableDataLabel = (component, column, serviceType, map) => {
    const fieldFn = () => `${column.field(serviceType)}`;
    return getServiceLabel(component, column, serviceType, map, fieldFn);
  };

  const _getComponentIdTd = (component, column) => {
    const componentId = _getTableDataLabel(component, column, props.serviceType);
    return (
      <GLBMTooltip
        content={(
          <>
            Seen:
            {' '}
            {moment(component.firstSeen).format('ll')}
            {' '}
            -
            {' '}
            {moment(component.lastSeen).format('ll')}
          </>
)}
      >
        <TableCell
          key={Case.camel(column.field())}
        >
          <span style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.5)' }}>
            {componentId}
          </span>
        </TableCell>
      </GLBMTooltip>
    );
  };

  const _getDefaultTd = (component, column) => {
    const now = moment.utc()
      .startOf('day');
    const diff = now.diff(moment.utc(component.lastSeen), 'days');
    // eslint-disable-next-line react/prop-types
    const value = (diff > 1) ? '-' : _getTableDataLabel(component, column, props.serviceType);
    return <TableCell key={Case.camel(column.field())}>{value}</TableCell>;
  };

  const _getTableData = (column, component) => {
    const { INCLUDE } = ServiceColumn;
    return AdvancedSwitch(column)
      .case(matches(INCLUDE), _getIncludeToggleTd)
      .case(instanceOf(EquipmentIdColumn), _getComponentIdTd)
      .default(_getDefaultTd)
      .resolveAndInvoke(component, column);
  };

  const _getTableRow = (component) => {
    const tableRowData = columns.map(column => _getTableData(column, component));
    return (
      <TableRow key={component[componentKeyField]}>
        {tableRowData}
      </TableRow>
    );
  };

  // eslint-disable-next-line no-shadow
  const _renderRows = (components) => {
    const rows = [];
    components.forEach((component) => {
      rows.push(_getTableRow(component));
    });
    return rows;
  };

  const _onSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const _onIncludeAll = (devices, include) => {
    const modifiedComponents = [...components];
    const deviceIDs = map(devices, componentKeyField);
    modifiedComponents.forEach((el) => {
      const elId = el[componentKeyField];
      if (deviceIDs.indexOf(elId) !== -1) {
        // eslint-disable-next-line no-param-reassign
        el.exclude = !include;
        // eslint-disable-next-line no-param-reassign
        el.dirty = true;
      }
    });
    setComponents(modifiedComponents);
  };

  const _getFooter = () => {
    const editable = (
      <Box gap='small' direction='row'>
        <Button
          label='OK'
          type='submit'
          primary={true}
          onClick={_onSubmit}
          id={IDUtil.getId('3PARDrivesOKButton')}
        />
        <Button
          label='Cancel'
          type='button'
          secondary={true}
          onClick={props.onClose}
        />
      </Box>
    );
    const readOnly = (
      <Button
        label='OK'
        type='button'
        primary={true}
        onClick={props.onClose}
      />
    );

    return (
      <Footer flex={false} justify='start' gap='small'>
        {props.readOnly ? readOnly : editable}
      </Footer>
    );
  };

  const filteredComponents = _sortComponents(_filterComponents);
  const noRows = _renderNoRowsElement(components.length, filteredComponents.length);

  return (
    <GLBMLayer
      position='right'
      onEsc={props.onClose}
      onClickOutside={props.onClose}
      onClose={props.onClose}
      full='vertical'
      title={props.heading}
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
        style={{
          'maxWidth': '600px',
          'width': '600px',
        }}
      >
        <Box flex={true}>
          <Box
            direction='row'
            pad={{
              horizontal: 'medium',
              vertical: 'small',
            }}
            flex={false}
            gap='small'
          >
            {
                !props.readOnly && (
                  <Box
                    flex={false}
                    direction='row'
                    justify='end'
                    align='center'
                  >
                    <span>Include:&nbsp;</span>
                    <Anchor
                      onClick={() => _onIncludeAll(filteredComponents, true)}
                      id={IDUtil.getId('IncludeAllComponentsButton')}
                    >
                      All
                    </Anchor>
                    &nbsp;|&nbsp;
                    <Anchor
                      onClick={() => _onIncludeAll(filteredComponents, false)}
                      id={IDUtil.getId('IncludeNoneComponentsButton')}
                    >
                      None
                    </Anchor>
                  </Box>
                )
              }
            <Box flex={true} />
            <TextInput
              placeholder='Search'
              icon={<Search />}
              onChange={_onSearchChange}
            />
          </Box>
          <Box flex={true} overflow='auto' pad={{ horizontal: 'small' }}>
            <Table responsive={false} scrollable={true}>
              <TableHeader>
                <TableRow>
                  {_columnHeaders()
                    .map(column => (
                      <TableCell
                        scope='col'
                        border='bottom'
                      >
                        {column}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {_renderRows(filteredComponents)}
              </TableBody>
            </Table>
            {noRows}
          </Box>

          <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
            {_getFooter()}
          </Box>
        </Box>
      </Box>
    </GLBMLayer>
  );
};

ConfigurableComponentEdit.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  serviceType: PropTypes.object.isRequired,
  modifiedComponents: PropTypes.array,
};

ConfigurableComponentEdit.defaultProps = {
  modifiedComponents: undefined,
};

export default ConfigurableComponentEdit;
