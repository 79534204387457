// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import IDUtil from '../util/IDUtil';

const GLBMSearch = ({
  value: initialValue = undefined,
  onChange = undefined,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue] = useDebounce(value, 300);

  useEffect(() => {
    if (onChange) {
      onChange(debouncedValue);
    }
  }, [debouncedValue]);

  return (
    <TextInput
      id={IDUtil.getId('ListViewToolbarSearchInput')}
      icon={<Search />}
      placeholder='Search'
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      width='medium'
      type='search'
      {...rest}
    />
  );
};

GLBMSearch.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default GLBMSearch;
