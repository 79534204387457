// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Notification,
} from 'grommet';
import { Refresh } from 'grommet-icons';
import GLBMDataSummary from '../../shared/component/GLBMDataSummary';
import GLBMSearch from '../../shared/component/GLBMSearch';
import useRememberedState from '../../shared/hooks/useRememberedState';
import ApproverEditor from './ApproverEditor';
import IDUtil from '../../shared/util/IDUtil';
import GLBMHeading from '../../shared/component/GLBMHeading';
import GLBMDataTable from '../../shared/component/GLBMDataTable';
import { useAccessApproversQuery, useMutationAccessApproversUpdate } from '../../../core';
import FooterApprovers from '../../users/FooterApproversPage';

const defaultSort = {
  direction: 'asc',
  external: true,
  property: 'role',
};

const ApproversList = () => {
  const [searchText, setSearchText] = useState('');
  const [editedApprover, setEditedApprover] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [sort, onSort] = useRememberedState('admin-approvers-data-sort', defaultSort);

  const {
    data: approversData,
    error: getApproversError,
    refetch: refreshApprovers,
    isFetching: isFetchingApprovers,
  } = useAccessApproversQuery();

  // istanbul ignore next
  const {
    mutate: updateApproversList,
    isLoading: isUpdateApproversListLoading,
  } = useMutationAccessApproversUpdate({
    onSuccess: () => {
      setIsEdit(false);
      setEditedApprover();
    },
  });

  const list = useMemo(() => (approversData ? approversData.map((el, i) => ({ ...el, index: i })) : []), [approversData]);

  const filteredList = useMemo(() => (list && searchText !== undefined ? list.filter(item => (
    item.name.toLowerCase().includes(searchText.toLowerCase()))
    || item.role.toLowerCase().includes(searchText.toLowerCase())
    || item.location.toLowerCase().includes(searchText.toLowerCase())
    || item.emails.join(', ').toLowerCase().includes(searchText.toLowerCase())) : list), [list, searchText]);
  // istanbul ignore next
  const renderLayer = () => {
    if (isEdit) {
      return (
        <ApproverEditor
          onClose={() => {
            setIsEdit(false);
            setEditedApprover();
          }}
          approver={editedApprover !== undefined ? { ...list[editedApprover] } : {}}
          isNew={editedApprover === undefined}
          isLoading={isUpdateApproversListLoading}
          onChange={editedApprover === undefined ? approver => updateApproversList([...list, { ...approver, index: list[list.length - 1].index + 1 }])
            : (approver) => {
              const newList = list.map(el => (el.index === editedApprover ? { ...approver, index: el.index } : el));
              updateApproversList(newList);
            }}
        />
      );
    }
    return undefined;
  };

  const getColumns = () => ([{
    property: 'role',
    header: 'Role',
  }, {
    property: 'location',
    header: 'Location',
  }, {
    property: 'name',
    header: 'Name',
  }, {
    property: 'emails',
    header: 'Emails',
    dataCallback: ({ emails }) => emails.join(', '),
  }]);

  const renderToast = () => {
    if (getApproversError) {
      return (
        <Notification
          title='Fetch Approvers Error'
          message={getApproversError.response.data.message}
          status='critical'
          toast={true}
        />
      );
    }
    return undefined;
  };

  return (
    <Box direction='column' fill='vertical'>

      <GLBMHeading
        search={(
          <GLBMSearch
            value={searchText}
            onChange={e => setSearchText(e)}
          />
        )}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            key={IDUtil.getId('CatalogServicesRefreshButton')}
            onClick={() => {
              refreshApprovers();
            }}
            a11yTitle='Refresh Catalog Services List'
            id={IDUtil.getId('CatalogServicesRefreshButton')}
            label='Refresh'
            busy={isFetchingApprovers}
          />,
        ]}
      />
      <GLBMDataSummary total={approversData?.length} filtered={filteredList?.length} />
      <GLBMDataTable
        primaryKey='id'
        searchText={searchText}
        columns={getColumns()}
        data={filteredList}
        onSort={onSort}
        sort={sort}
        total={filteredList?.length}
        loading={isFetchingApprovers}
      />
      {renderLayer()}
      {renderToast()}
      <FooterApprovers />
    </Box>
  );
};

export default ApproversList;
