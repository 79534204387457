// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box, Button, CheckBox, Form, FormField, Main, Text, TextInput,
} from 'grommet';
import PropTypes from 'prop-types';
import IDUtil from '../shared/util/IDUtil';
import {
  useCountriesQuery,
  usePartnerCreateMutate,
  usePartnerQuery,
  usePartnerUpdateMutate,
} from '../../core';
import GLBMSaving from '../shared/component/GLBMSaving';
import GLPBindingSelector from '../shared/component/GLPBindingSelector';
import { OptionsBuilder } from '../shared/util/OptionsBuilder';
import PartnerCustomers from './PartnerCustomers';

import { getSelectValue } from '../shared/util/FormUtil';
import AutocompleteSelect from '../shared/component/AutocompleteSelect';
import Toast from '../shared/component/Toast';
import GLBMHeading from '../shared/component/GLBMHeading';

const PartnerEditorPage = () => {
  const { partnerId } = useParams();
  const navigate = useNavigate();
  const [partner, setPartner] = useState({});
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState();
  const [countries, setCountries] = useState([]);

  // glp:
  const [enableGlpSetup, setEnableGlpSetup] = useState(false);
  const [selectedBinding, setSelectedBinding] = useState(undefined);

  const { data: countriesData } = useCountriesQuery();

  useEffect(() => {
    if (countriesData) {
      setCountries(new OptionsBuilder(countriesData).labelKey('displayName').valueKey('countryCode').build());
    }
  }, [countriesData]);

  // set the caTenantId from the selected binding
  useEffect(() => {
    setPartner(prevState => ({
      ...prevState,
      tenantId: (enableGlpSetup && selectedBinding) ? selectedBinding?.caTenantId : undefined,
    }));
    setErrors(prevState => ({
      ...prevState,
      glpBindingSelector: [],
    }));
  }, [enableGlpSetup, selectedBinding]);

  const {
    isSuccess: isPartnerDataSuccess,
    data: partnerData,
  } = usePartnerQuery(partnerId, {
    enabled: !!partnerId,
  });

  useEffect(() => {
    if (isPartnerDataSuccess) {
      setPartner(partnerData);
    }
  }, [isPartnerDataSuccess, partnerData]);

  const onPartnerSaveSuccess = () => {
    navigate('/partners');
  };

  const onPartnerSaveError = (submitError) => {
    if (submitError?.response?.data?.validationErrors) {
      const { validationErrors } = submitError.response.data;
      validationErrors?.forEach((error) => {
        const attribute = error.propertyName.substring(error.propertyName.lastIndexOf('.') + 1);
        errors[attribute] = [error.message];
      });
      setErrors(prevState => ({
        ...prevState,
        ...errors,
      }));
    }
  };

  const {
    mutate: createPartner,
    isPending: isCreatingPartner,
    error: creatingPartnerError
  } = usePartnerCreateMutate({
    onSuccess: onPartnerSaveSuccess,
    onError: onPartnerSaveError,
  });

  const {
    mutate: updatePartner,
    isPending: isUpdatingPartner,
    error: updatingPartnerError,
  } = usePartnerUpdateMutate(partnerId, {
    onSuccess: onPartnerSaveSuccess,
    onError: onPartnerSaveError,
  });

  const onClickCancel = () => {
    navigate('/partners');
  };

  const onChange = (event) => {
    const updatedPartner = { ...partner };
    const updatedErrors = { ...errors };
    const attribute = event.target.getAttribute('name');

    delete updatedErrors[attribute];
    updatedErrors[attribute] = [];

    // update partner object:
    switch (attribute) {
      case 'countryCode':
        updatedPartner[attribute] = event.value.value;
        break;
      default:
        updatedPartner[attribute] = event.target.value;
    }

    // validation:
    if (!updatedPartner[attribute]) {
      updatedErrors[attribute].push('Required');
    }

    setPartner(updatedPartner);
    setErrors(updatedErrors);
  };

  const getTotalErrors = () => {
    let totalErrors = 0;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const property in errors) {
      totalErrors += (errors[property].length);
    }
    return totalErrors;
  };

  // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
  const getError = (errors) => {
    if (!errors || errors.length === 0) {
      return undefined;
    }
    return errors.join(', ');
  };

  const onSubmit = () => {
    // Save to Backend
    const updatedError = { ...errors };

    let noErrors = (getTotalErrors() === 0);

    // check for required fields:
    const propertiesToCheck = ['name', 'partnerId', 'countryCode', 'ownerFirstName', 'ownerLastName', 'ownerEmail'];
    propertiesToCheck.forEach((property) => {
      if (!partner[property]) {
        updatedError[property] = ['Required'];
        noErrors = false;
      }
    });

    // check for GLP setup: (only if it is enabled)
    if (enableGlpSetup && (!selectedBinding || !partner.tenantId)) {
      updatedError.glpBindingSelector = ['Required'];
      noErrors = false;
    }

    if (!noErrors) {
      setErrors(updatedError);
      return;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (partner.hasOwnProperty('id')) {
      updatePartner(partner);
    } else {
      createPartner(partner);
    }
  };

  const onToastClose = () => {
    setResponse(undefined);
  };

  const renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Toast
          open={response}
          status={(response.status ? response.status : 'critical')}
          onClose={onToastClose}
        >
          {response.message}
        </Toast>
      );
    }
    return message;
  };

  const renderCountry = (country) => {
    const selectValue = getSelectValue(country, countries) || { label: '' };
    return (
      <FormField
        label='Partner Country'
        error={getError(errors.countryCode)}
      >
        <AutocompleteSelect
          options={countries}
          name='countryCode'
          value={selectValue}
          onChange={onChange}
          plain={true}
          labelKey='label'
          valueKey='value'
        />
      </FormField>
    );
  };

  const isNew = (!partner.id);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        back='/partners'
        title={isNew ? 'Create New Partner' : 'Edit Existing Partner'}
      />
      <Box flex={true} pad={{ horizontal: 'medium' }} overflow='auto'>
        <Box flex={true}>
          <Box flex={false} width='480px'>
            <Form>
              <FormField
                label='Partner Name'
                error={getError(errors.name)}
              >
                <TextInput
                  id={IDUtil.getId('PartnerEditorPartnerNameInput')}
                  data-testid={IDUtil.getId('PartnerEditorPartnerNameInput')}
                  name='name'
                  value={partner.name || ''}
                  onChange={onChange}
                />
              </FormField>
              <FormField
                label='Partner Id'
                help='(unique across partners)'
                error={getError(errors.partnerId)}
              >
                <TextInput
                  id={IDUtil.getId('PartnerEditorPartnerIdInput')}
                  data-testid={IDUtil.getId('PartnerEditorPartnerIdInput')}
                  name='partnerId'
                  value={partner.partnerId || ''}
                  onChange={onChange}
                />
              </FormField>
              {renderCountry(partner.countryCode || undefined)}
              <FormField
                label='Owner First Name'
                help={isNew ? <Text weight={100} size='small'>Can not be changed once created</Text> : ''}
                error={getError(errors.ownerFirstName)}
              >
                <TextInput
                  id={IDUtil.getId('PartnerEditorOwnerFirstNameInput')}
                  data-testid={IDUtil.getId('PartnerEditorOwnerFirstNameInput')}
                  name='ownerFirstName'
                  value={partner.ownerFirstName || ''}
                  disabled={!isNew}
                  onChange={onChange}
                />
              </FormField>
              <FormField
                label='Owner Last Name'
                help={isNew ? <Text weight={100} size='small'>Can not be changed once created</Text> : ''}
                error={getError(errors.ownerLastName)}
              >
                <TextInput
                  id={IDUtil.getId('PartnerEditorOwnerLastNameInput')}
                  data-testid={IDUtil.getId('PartnerEditorOwnerLastNameInput')}
                  name='ownerLastName'
                  value={partner.ownerLastName || ''}
                  disabled={!isNew}
                  onChange={onChange}
                />
              </FormField>
              <FormField
                label='Owner Email'
                help={isNew ? <Text weight={100} size='small'>Can not be changed once created</Text> : ''}
                error={getError(errors.ownerEmail)}
              >
                <TextInput
                  id={IDUtil.getId('PartnerEditorOwnerEmailInput')}
                  data-testid={IDUtil.getId('PartnerEditorOwnerEmailInput')}
                  name='ownerEmail'
                  value={partner.ownerEmail || ''}
                  disabled={!isNew}
                  onChange={onChange}
                />
              </FormField>
              {isNew && (
              <FormField
                label='Enable GLP Setup'
                help='Check this box if this Partner will be configured in GLP.'
              >
                <CheckBox
                  name='enableGlpSetup'
                  id={IDUtil.getId('PartnerEditorEnableGLPSetup')}
                  data-testid={IDUtil.getId('PartnerEditorEnableGLPSetup')}
                  checked={enableGlpSetup}
                  onChange={event => setEnableGlpSetup(event.target.checked)}
                />
              </FormField>
              )}
              {(isNew && enableGlpSetup) && (
                <Box>
                  <FormField
                    label='GLP Workspace'
                    error={getError(errors.glpBindingSelector)}
                    required={true}
                    htmlFor='glpBindingSelector'
                  >
                    <GLPBindingSelector onBindingSelected={setSelectedBinding} plain={true} />
                  </FormField>
                  <FormField
                    label='CA Tenant Id'
                    help={<Text weight={100} size='small'>Can not be changed once created</Text>}
                    htmlFor='tenantId'
                  >
                    <TextInput
                      id='tenantId'
                      data-testid={IDUtil.getId('PartnerEditorCATenantId')}
                      name='tenantId'
                      value={selectedBinding?.caTenantId || ''}
                      disabled={true}
                    />
                  </FormField>
                </Box>
              )}
            </Form>
          </Box>
          {!isNew
              && (
                <Box size={{ height: { min: 'medium', max: 'medium' } }}>
                  <PartnerCustomers customers={partner.customerIds} />
                </Box>
              )}
        </Box>
      </Box>
      <Box direction='row' gap='small' pad={{ horizontal: 'small', vertical: 'small' }} border='top'>
        <Button
          label='Save'
          id={IDUtil.getId('EditorViewToolbarSaveButton')}
          type='button'
          primary={true}
          onClick={onSubmit}
          disabled={isUpdatingPartner || isCreatingPartner}
        />
        <Button
          label='Cancel'
          id={IDUtil.getId('EditorViewToolbarCancelButton')}
          type='button'
          secondary={true}
          onClick={onClickCancel}
          disabled={isUpdatingPartner || isCreatingPartner}
        />
        <GLBMSaving saving={isCreatingPartner || isUpdatingPartner} error={creatingPartnerError?.response?.data?.message || updatingPartnerError?.response?.data?.message} />
      </Box>
      {renderToast()}
    </Main>
  );
};

PartnerEditorPage.contextTypes = {
  router: PropTypes.object,
};

export default PartnerEditorPage;
