// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
/* eslint-disable no-param-reassign */
import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  Anchor,
  Box,
  Button,
  Menu,
  Text,
} from 'grommet';
import { More, Refresh } from 'grommet-icons';
import Highlight from 'react-highlighter';
import { useToast } from '../shared/toast/ToastProvider';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMSearch from '../shared/component/GLBMSearch';
import IDUtil from '../shared/util/IDUtil';
import RejectAccessRequest from './accessRequestActions/RejectAccessRequest';
import { useAccessRequestStatusUpdateMutate, useAccessRequestsQuery } from '../../core';
import { ApiContext } from '../../AppContext';
import ViewAccessRequestDetails from './accessRequestActions/ViewAccessRequestDetails';

function AccessRequests() {
  const [searchText, setSearchText] = useState('');
  const [sort, setSort] = useState({ property: 'requestDateTime', direction: 'desc' });
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  const [rejectionComment, setRejectionComment] = useState('');
  const [showAccessRequestDetails, setShowAccessRequestDetails] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(undefined);

  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();

  const approvalActions = {
    APPROVE_ACCESS_REQUEST: 'Approve Access Request',
    REJECT_ACCESS_REQUEST: 'Reject Access Request',
    REQUEST_MANAGER_APPROVAL: 'Request Manager Approval',
  };
  const navigate = useNavigate();
  const {
    data: approversData,
    isFetching,
    isLoading,
    refetch: refreshApprovers,
  } = useAccessRequestsQuery();
  const {
    mutate: updateAccessRequest,
  } = useAccessRequestStatusUpdateMutate({
    onSuccess: () => {
      refreshApprovers();
      addToast({
        status: 'normal',
        title: 'Status Updated',
        message: 'Approvers status updated',
      });
    },
  });

  const list = useMemo(() => (approversData ? approversData.map((el, i) => ({ ...el, index: i })) : []), [approversData]);
  const filteredList = useMemo(() => (list && searchText !== undefined ? list.filter(item => (
    item.name?.toLowerCase().includes(searchText.toLowerCase()))
    || item.status?.displayName?.toLowerCase().includes(searchText.toLowerCase())
    || item.userEmail?.toLowerCase().includes(searchText.toLowerCase())
    || item.managerEmail?.toLowerCase().includes(searchText.toLowerCase())) : list), [list, searchText]);

  const openAction = (data, status) => {
    const urlTemplate = contextFn('access.requests.status.update.path', {
      approvedRequestId: data.requestId,
    });
    data.action = status;
    setSelectedRequest(data);
    if (status === 'REJECT_ACCESS_REQUEST') {
      setOpenRejectModal(true);
    }
    if (status === 'REQUEST_MANAGER_APPROVAL') {
      data.action = status;
      setSelectedRequest(data);
      const payload = {
        payload: {
          action: status,
        },
        approvedRequestId: data.requestId,
        urlTemplate,
      };

      updateAccessRequest(payload);
    }
    if (status === 'APPROVE_ACCESS_REQUEST') {
      data.action = status;
      setSelectedRequest(data);
      const payload = {
        payload: {
          action: status,
        },
        approvedRequestId: data.requestId,
        urlTemplate,
      };
      updateAccessRequest(payload);
    }
  };
  const openViewAccessRequestDetails = (userId) => {
    setShowAccessRequestDetails(true);
    setSelectedUserId(userId);
  };

  const getAction = datum => (
    <Menu
      responsive={true}
      icon={<More />}
      items={[{
        id: IDUtil.getId('ViewAccessRequestDetails'),
        label: 'View Access Request Details',
        onClick: () => openViewAccessRequestDetails(datum),
      },
      ...datum.approvalActions.map((status, i) => ({
        id: IDUtil.getId('ListItemDropDownEditApproversButton', i),
        label: approvalActions[status],
        onClick: () => openAction(datum, status),
      }))]}
    />
  );
  const getColumns = () => ([
    {
      property: 'requestDateTime,',
      header: 'Request Date',
      dataCallback: ({ requestDateTime }) => moment.utc(requestDateTime).format('x'),
      render: ({ requestDateTime }) => (<Highlight search={searchText}>{moment.utc(requestDateTime).format('ll')}</Highlight>),
    },
    {
      property: 'name',
      header: 'Name',
      render: ({ name, user }) => (
        <Anchor onClick={() => navigate(`/users/${user?.id}`)}>
          <Highlight search={searchText}>{name}</Highlight>
        </Anchor>
      ),
    }, {
      property: 'userEmail',
      header: 'Email',
      render: ({ userEmail }) => (<Highlight search={searchText}>{userEmail}</Highlight>),
    },
    {
      property: 'managerEmail',
      header: 'Manager',
      render: ({ managerEmail }) => (managerEmail ? <Highlight search={searchText}>{managerEmail}</Highlight> : <Text margin={{ left: 'small' }}>-</Text>),

    },
    {
      property: 'status',
      header: 'Approval Status',
      dataCallback: ({ status }) => status.displayName,
      render: ({ status }) => (<Highlight search={searchText}>{status.displayName}</Highlight>),
    },

    {
      property: 'approvalActions',
      header: 'Actions',
      render: datum => getAction(datum),
      sortable: false,
      size: '100px',
    }]);

  const saveRejectedAction = (datum) => {
    const urlTemplate = contextFn('access.requests.status.update.path', {
      approvedRequestId: datum.requestId,
    });
    const data = {
      payload: {
        action: selectedRequest.action,
        comment: rejectionComment,
      },
      approvedRequestId: selectedRequest.requestId,
      urlTemplate,
    };
    updateAccessRequest(data);
    setOpenRejectModal(false);
  };

  const openRejectAccessRequest = () => (
    <RejectAccessRequest
      rejectionComment={rejectionComment}
      setRejectionComment={setRejectionComment}
      onSave={() => saveRejectedAction(selectedRequest)}
      onClose={() => setOpenRejectModal(false)}
    />
  );

  return (
    <Box data-testid='accessRequestsWrapper' direction='column' fill='vertical'>
      <GLBMHeading
        date-testid='accessRequestTable'
        title='Access Requests'
        search={(
          <GLBMSearch
            value={searchText}
            onChange={e => setSearchText(e)}
          />
          )}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            key={IDUtil.getId('AccessRequestsRefreshButton')}
            onClick={() => {
              refreshApprovers();
            }}
            a11yTitle='Refresh Access Request Page'
            id={IDUtil.getId('AccessRequestsRefreshButton')}
            label='Refresh'
            busy={isFetching}
          />,
        ]}
      />
      <GLBMDataSummary total={approversData?.length} filtered={filteredList?.length} />
      <GLBMDataTable
        searchText={searchText}
        columns={getColumns()}
        data={filteredList}
        total={filteredList.length}
        loading={isLoading || isFetching}
        onSort={setSort}
        sort={sort}
      />
      {openRejectModal && openRejectAccessRequest()}
      {showAccessRequestDetails && (
      <ViewAccessRequestDetails
        user={selectedUserId}
        onClose={() => {
          setShowAccessRequestDetails(false);
        }}
      />
      )}
    </Box>
  );
}

AccessRequests.propTypes = {};

export default AccessRequests;
