// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Box } from 'grommet';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useCustomersQuery } from '../../../core';
import UserStore from '../../stores/UserStore';
import { BillingType } from '../constants/BillingType';
import AutocompleteSelect from './AutocompleteSelect';
import { StatusIcon } from './StatusIcon';

const getStatus = (status) => {
  const billingType = BillingType.enumValueOf(status);
  return (
    <Box margin={{ right: 'small' }}>
      <StatusIcon size='small' value={billingType?.severity || 'unknown'} />
    </Box>
  );
};

const CustomerSelector = ({
  initialSelection = '',
  onCustomerSelected = undefined,
  excludedStates = undefined,
  excludedServiceStates = undefined,
  excludedPurposes = undefined,
  excludeNoServices = false,
  multiple = false,
  persistSelection = false,
  allowNoSelection = false,
  enableAllOption = false,
  ...rest
}) => {
  const [selected, setSelected] = useState(undefined);
  const {
    isFetching: isCustomersFetching,
    data: customerData,
  } = useCustomersQuery();

  useEffect(() => {
    if (onCustomerSelected && selected) {
      onCustomerSelected(selected.values, selected.last);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const onSelectMultiple = (id, option) => {
    if (option?.value === 'All') {
      setSelected({
        values: ['All'],
        last: 'All'
      });
      return;
    }
    const idList = Array.isArray(id) ? id : [id];
    const list = idList.filter(item => item !== 'All');
    setSelected({
      values: list,
      last: option.value
    });
  };

  const onSelect = (id, option, filteredItem) => {
    if (multiple) {
      onSelectMultiple(id, option);
    } else {
      const singleId = Array.isArray(id) ? id[0] : id;

      // Only persist customer selection on single-select customer dropdowns
      if (filteredItem && persistSelection) {
        localStorage.setItem(`analytics-selected-customer-${UserStore.getUser()?.id}`, singleId);
      }
      const item = {
        values: singleId,
        last: option?.value
      };
      setSelected(item);
    }
  };

  const renderCustomerRow = customer => (
    <Box direction='row' align='center' justify='between'>
      <Box direction='row' align='center'>
        <span>{getStatus(customer.status)}</span>
        <span className='ellipsis' style={{ 'fontWeight': '500' }}>{customer.name || customer.id}</span>
      </Box>
      {customer.name && (
      <small key={customer.id}>
        <em className='secondary'>
              &nbsp;&nbsp;&nbsp;(
          {customer.id}
          )
        </em>
      </small>
      )}
    </Box>
  );

  const renderSelectedLabel = customer => (
    <Box direction='row' align='center' justify='between'>
      <Box direction='row' align='center'>
        <span>{getStatus(customer.status)}</span>
        <span className='ellipsis' style={{ 'fontWeight': '500' }}>{customer.name || customer.id}</span>
      </Box>
    </Box>
  );

  const makeInitialSelection = (options) => {
    if (initialSelection) {
      const initialSelectionList = Array.isArray(initialSelection) ? initialSelection : [initialSelection];
      const matchingOptions = options.filter(c => initialSelectionList.includes(c.value));
      const matchingSelections = matchingOptions.map(o => o.value);
      const option = matchingOptions.length > 0 ? matchingOptions[matchingOptions.length - 1] : undefined;
      if (option) { // found at least some of the initial data
        onSelect(matchingSelections, option, false);
      } else {
        onSelect(options[0].value, options[0], false);
      }
    } else { // attempt to pull from local storage
      const persistedId = persistSelection ? localStorage.getItem(`analytics-selected-customer-${UserStore.getUser()?.id}`) : undefined;
      const persistedOption = persistedId ? options.find(c => persistedId === c.value) : undefined;
      const initial = persistedOption || options[0];
      onSelect(initial?.value, initial, persistedOption);
    }
  };

  const applyFilters = (customer) => {
    const validCustomerState = !excludedStates?.includes(customer.status);
    const validPurpose = !excludedPurposes?.includes(customer.purpose);
    const servicesCount = customer.services.filter(service => !excludedServiceStates?.includes(service.status)).length
        + (customer.fixedMeterIds ? customer.fixedMeterIds.length : 0);
    const validServiceStateCount = !excludeNoServices || servicesCount > 0;
    return (validCustomerState && validPurpose && validServiceStateCount);
  };

  const customerOptions = useMemo(() => {
    if (!isCustomersFetching && customerData) {
      const options = [];
      customerData
        .filter(customer => applyFilters(customer))
        .forEach((customer) => {
          const item = {
            label: renderCustomerRow(customer),
            selectedLabel: renderSelectedLabel(customer),
            sub: customer.name || customer.id,
            value: customer.id,
          };

          options.push(item);
        });

      options.sort((a, b) => {
        const aTitle = (a.sub || a.value).toLowerCase();
        const bTitle = (b.sub || b.value).toLowerCase();
        return (aTitle <= bTitle ? -1 : 1);
      });

      // Add all option
      if (multiple || enableAllOption) {
        const allOption = {
          label: <Box direction='row' align='center' justify='between'><span>All</span></Box>,
          selectedLabel: <Box direction='row' align='center' justify='between'><span>All</span></Box>,
          value: 'All'
        };
        options.unshift(allOption);
      }

      if (allowNoSelection && !initialSelection) {
        return options;
      }

      makeInitialSelection(options);

      return options;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomersFetching, customerData]);

  useEffect(() => {
    // Verify initial selection content.  If this changed, then a filter may have been reset
    if (selected && !isEqual(selected.values, initialSelection)) {
      makeInitialSelection(customerOptions);
    }
  }, [customerOptions, initialSelection]);

  const getMultipleLabel = (values) => {
    if (values && values.length === 1) {
      return (values[0].name);
    }
    if (values && values.length > 1) {
      return `${values.length} selected`;
    }
    return 'All';
  };

  const renderValue = (values) => {
    const valuesList = Array.isArray(values) ? values : [values];
    if (valuesList.length === 1) {
      return (
        <Box pad={{ horizontal: 'small' }} height='34px' justify='center'>
          {customerOptions.find(el => selected && el.value === valuesList[0])
            && customerOptions.find(el => selected && el.value === valuesList[0]).selectedLabel}
        </Box>
      );
    }
    return (
      <Box pad={{ horizontal: 'small' }} height='34px' justify='center'>
        {getMultipleLabel(values)}
      </Box>
    );
  };

  return (
    <AutocompleteSelect
      multiple={multiple}
      options={customerOptions || []}
      placeholder={allowNoSelection ? undefined : 'Loading...'}
      value={selected?.values}
      onChange={({ value, option }) => onSelect(value, option, true)}
      labelKey='label'
      valueLabel={renderValue}
      data-e2e='open drop'
      valueKey={{ key: 'value', reduce: true }}
      {...rest}
    />
  );
};

CustomerSelector.propTypes = {
  initialSelection: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  onCustomerSelected: PropTypes.func,
  excludedStates: PropTypes.array,
  excludedServiceStates: PropTypes.array,
  excludedPurposes: PropTypes.array,
  multiple: PropTypes.bool,
  persistSelection: PropTypes.bool,
  allowNoSelection: PropTypes.bool,
  enableAllOption: PropTypes.bool,
  excludeNoServices: PropTypes.bool,
};

export default CustomerSelector;
