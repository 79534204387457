// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import pluralize from 'pluralize';
import { v4 as uuidv4 } from 'uuid';
import {
  Box, Button, Header, Text,
} from 'grommet';
import {
  Add, Checkmark, Copy, Edit, Trash, View,
} from 'grommet-icons';
import { StatusIcon } from '../../../../../../shared/component/StatusIcon';
import { cmpStringsWithNumbers } from '../../../../../../shared/util/CompareUtil';
import IDUtil from '../../../../../../shared/util/IDUtil';
import { ServiceStep } from '../../../../../model';
import MappedTiersEditor from './MappedTiersEditor';
import CustomTiersDetails from '../customTiers/CustomTiersDetails';
import { useValidationMutation } from '../../../../../../../core';

const MappedTiersList = ({
  customer,
  options,
  tiers: initialTiers,
  type,
  validation,
  disabled,
  readOnly,
  onChange,
  originalOptions,
  dirtyEquipment,
}) => {
  const [tiers, setTiers] = useState(initialTiers);
  const [layer, setLayer] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [copyTier, setCopyTier] = useState(false);

  const { mutate: fetchValidationIfNeeded } = useValidationMutation(ServiceStep.OPTIONS);

  useEffect(() => {
    setTiers(initialTiers);
  }, [initialTiers]);

  useEffect(() => {
    fetchValidationIfNeeded();
  }, [fetchValidationIfNeeded]);

  const onTierAdd = () => {
    setLayer('mappedTiers');
    setSelectedIndex(undefined);
  };

  const onTierEdit = (index, isCopy) => {
    setLayer('mappedTiers');
    setSelectedIndex(index);
    setCopyTier(isCopy);
  };

  const onTierNoMatchClick = () => {
    setLayer('unconfiguredEquipment');
  };

  const getOtherNames = (tier) => {
    const currentIndex = tier ? tiers.map(e => e.id).indexOf(tier.id) : -1;
    const otherNames = [];
    for (let i = 0; i < tiers.length; i += 1) {
      if (i !== currentIndex) {
        otherNames.push(tiers[i].name);
      }
    }
    return otherNames;
  };

  const renderUnconfiguredEquipment = () => {
    if (layer === 'unconfiguredEquipment') {
      return (
        <CustomTiersDetails
          onClose={() => {
            setLayer(undefined);
            setSelectedIndex(undefined);
          }}
          options={options}
          originalOptions={originalOptions}
          equipment={dirtyEquipment}
          components={dirtyEquipment}
          customerId={customer.id}
        />
      );
    }
    return null;
  };

  if (disabled) {
    return <Box />;
  }

  const sortedTiers = [...tiers].sort((a, b) => cmpStringsWithNumbers(a.name, b.name));

  const onTiersSave = (tier, isModified, addAnother) => {
    let updatedTiers;
    if (selectedIndex === undefined || copyTier) {
      // eslint-disable-next-line no-param-reassign
      tier.id = uuidv4();
      updatedTiers = [...tiers, tier];
    } else {
      updatedTiers = [...sortedTiers];
      updatedTiers[selectedIndex] = tier;
    }

    setLayer(addAnother ? 'mappedTiers' : undefined);

    setTiers(updatedTiers);
    setSelectedIndex(undefined);
    setCopyTier(false);

    onChange(updatedTiers, isModified);
    fetchValidationIfNeeded();
  };

  const onDeleteTier = (index) => {
    const updatedTiers = [...sortedTiers];
    updatedTiers.splice(index, 1);
    setTiers(updatedTiers);
    onChange(updatedTiers, true);
    fetchValidationIfNeeded();
  };

  const renderTierEditor = () => {
    if (layer === 'mappedTiers') {
      const heading = selectedIndex === undefined ? 'Add Mapped Tier' : copyTier ? 'Copy Mapped Tier' : 'Edit Mapped Tier';
      const tier = selectedIndex === undefined ? { id: undefined, name: '' } : { ...sortedTiers[selectedIndex] };
      const otherNames = getOtherNames(copyTier ? undefined : tier);
      return (
        <MappedTiersEditor
          onClose={() => {
            setLayer(undefined);
            setSelectedIndex(undefined);
          }}
          heading={heading}
          tier={tier}
          otherNames={otherNames}
          onChange={onTiersSave}
        />
      );
    }
    return null;
  };

  const validationResults = validation && validation.results;
  const noTiersElement = tiers.length === 0 ? (
    <Box margin='large'>
      <Text>You do not have any mapped tiers defined at the moment.</Text>
    </Box>
  ) : null;

  const tierRows = sortedTiers.map((tier, index) => {
    const countPerTier = validationResults && validationResults.countPerTier[tier.id] ? validationResults.countPerTier[tier.id] : 0;
    // eslint-disable-next-line no-use-before-define
    return getTier(index, tier, countPerTier);
  });

  const hasWarning = validationResults && validationResults.count > 0;
  const noMatchEquipment = (
    <Box
      direction='row'
      key='noMatchEquipment'
      className='secondary'
      justify='between'
      pad={{ vertical: 'small' }}
      border='top'
      responsive={false}
    >
      <Box flex={true} direction='row' gap='xsmall' align='center'>
        {hasWarning ? <StatusIcon value='warning' /> : <Checkmark color='brand' size='medium' />}
        <Text>Resources with no tier</Text>
      </Box>
      <Box flex={false} align='end' justify='center'>
        {validationResults?.count.toLocaleString()}
        {' '}
        {pluralize(type, validationResults?.count)}
      </Box>
      <Box flex={false} align='end' style={{ width: '185px' }}>
        <Button
          icon={<View />}
          onClick={onTierNoMatchClick}
          disabled={!hasWarning}
        />
      </Box>
    </Box>
  );

  return (
    <Box pad={{ horizontal: 'small' }}>
      <Header justify='between' pad={{ vertical: 'small' }}>
        <Text size='medium' weight='bold'>Mapped Tiers</Text>
        {!readOnly && (
          <Button
            icon={<Add />}
            id={IDUtil.getId('AddMappedTiersButton')}
            onClick={onTierAdd}
            a11yTitle='Add Mapped Tiers'
          />
        )}
      </Header>
      {noTiersElement}
      <Box>
        {tierRows}
        {noMatchEquipment}
      </Box>
      {renderTierEditor()}
      {renderUnconfiguredEquipment()}
    </Box>
  );

  function getTier(index, tier, countPerTier) {
    return (
      <div key={index} data-e2e='tier-row'>
        <Box
          height='44px'
          direction='row'
          align='center'
          gap='small'
          border='top'
        >
          <Box flex={true} direction='row' align='center' gap='small'>
            <span data-e2e='tier-name'>{tier.name}</span>
          </Box>
          <Box flex={true} className='secondary' align='end'>
            {countPerTier.toLocaleString()}
            {' '}
            {pluralize(type, countPerTier)}
          </Box>
          {!readOnly && (
            <Box direction='row' flex={true} align='end' justify='end'>
              <Button
                icon={<Edit />}
                onClick={() => onTierEdit(index, false)}
                a11yTitle={`Edit ${tier.name} Tier`}
              />
              <Button
                icon={<Copy />}
                onClick={() => onTierEdit(index, true)}
                a11yTitle={`Copy ${tier.name} Tier`}
              />
              <Button
                icon={<Trash />}
                onClick={() => onDeleteTier(index)}
                a11yTitle={`Delete ${tier.name} Tier`}
              />
            </Box>
          )}
        </Box>
      </div>
    );
  }
};

const mapStateToProps = store => ({
  validation: store.service.details.validation,
  originalOptions: store.service.details.originalOptions,
  dirtyEquipment: store.service.details.dirtyEquipment,
  dirtyComponents: store.service.details.dirtyComponents,
});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MappedTiersList);
