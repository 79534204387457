// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';
import {
  Box, Table, TableBody, TableCell, TableHeader, TableRow, Text,
} from 'grommet';
import { Copy } from 'grommet-icons';
import moment from 'moment';
import GLBMTooltip from '../../../../../../shared/component/GLBMTooltip';

const ResourceRevisionTooltip = ({
  showTier, showLocation, equipment, idMap,
}) => {
  const { equipmentId, mappedTierRevisions, locationRevisions } = equipment || {};

  const rows = useMemo(() => {
    // first, lets get the rows ready, key'ed by effective date across tiers + locations:
    const rowData = [...(mappedTierRevisions || []), ...(locationRevisions || [])].reduce((data, { effectiveDate }) => {
      if (!data.hasOwnProperty(effectiveDate)) {
        data[effectiveDate] = { tier: undefined, location: undefined };
      }
      return data;
    }, {});

    // then loop on tiers, and set tier.id where found:
    mappedTierRevisions?.forEach(({ id, effectiveDate }) => {
      rowData[effectiveDate].tier = id;
    });

    // then loop on locations, and set location.id where found:
    locationRevisions?.forEach(({ id, effectiveDate }) => {
      rowData[effectiveDate].location = id;
    });

    return Object.keys(rowData).sort((a, b) => (moment(a).isBefore(moment(b)) ? -1 : 1)).map(effectiveDate => (
      <TableRow key={`resource-revision-tooltip-${effectiveDate}`}>
        <TableCell className='veryThinTableRow' scope='row'><strong>{moment(effectiveDate).format('MM/YYYY')}</strong></TableCell>
        {showTier && <TableCell className='veryThinTableRow'>{idMap[rowData[effectiveDate].tier] || '-'}</TableCell>}
        {showLocation && <TableCell className='veryThinTableRow'>{idMap[rowData[effectiveDate].location] || '-'}</TableCell>}
      </TableRow>
    ));
  }, [mappedTierRevisions, locationRevisions]);

  return (
    <GLBMTooltip
      content={(
        <Box direction='column' gap='small' pad='small'>
          <Text>
            Resource
            {' '}
            <strong>{equipmentId}</strong>
            {' '}
            has multiple revisions:
          </Text>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope='col' size='medium'>
                  Revision Date
                </TableCell>
                {showTier
                && (
                  <TableCell scope='col' size='medium'>
                    Tier
                  </TableCell>
                )}
                {showLocation
                && (
                  <TableCell scope='col' size='medium'>
                    Location
                  </TableCell>
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </Box>
    )}
    >
      <Copy type='status' color='plain' style={{ height: 20, width: 20 }} />
    </GLBMTooltip>
  );
};

export default ResourceRevisionTooltip;
