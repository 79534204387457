// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRateProps } from './utils';
import MeterItems from './MeterItems';

const MeterListFlat = ({
  meters = [],
  tenant = undefined,
  rates = [],
  selectedMeter = undefined,
  onClick,
  searchText = '',
}) => {
  const filteredMeters = useMemo(() => {
    if (searchText) {
      return meters.filter(meter => meter.name.toLowerCase().includes(searchText.toLowerCase()));
    }
    return undefined;
  }, [searchText, meters]);

  const _onSelectMeter = (meter) => {
    onClick(meter.id);
  };

  useEffect(() => {
    if (filteredMeters && filteredMeters.length === 1) {
      onClick(filteredMeters[0].id);
    } else {
      onClick(undefined);
    }
    // Don't trigger when onClick changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMeters]);

  const selectedMeterId = (selectedMeter ? selectedMeter.id : undefined);
  const rateProps = useRateProps({ tenant, rates });

  const sortedMeters = useMemo(
    () => (filteredMeters || meters || []).sort((m1, m2) => m1.name.localeCompare(m2.name)),
    [filteredMeters, meters]
  );

  return (
    <MeterItems
      meters={sortedMeters}
      onSelectMeter={_onSelectMeter}
      selectedMeterId={selectedMeterId}
      rateProps={rateProps}
      border='top'
    />
  );
};

MeterListFlat.propTypes = {
  meters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  searchText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selectedMeter: PropTypes.shape({
    id: PropTypes.string,
  }),
  tenant: PropTypes.string,
  rates: PropTypes.array,
};

export default MeterListFlat;
