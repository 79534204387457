// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class AlertType extends Enumify {
  static CAPACITY_PERCENT_CHANGED_V2 = new AlertType('Capacity Change', 'Capacity: Percent Changed');

  static CAPACITY_PERCENT_FREE_V2 = new AlertType('Capacity Free', 'Capacity: Percent Free');

  static DUPLICATE_RESOURCE = new AlertType('Duplicate Resource', 'Duplicate Resource');

  static INVALID_USAGE_RECORDS = new AlertType('Invalid Usage Records', 'Invalid Usage Records');

  static INVALID_USAGE_FILE = new AlertType('Missing Data', 'Missing Data: invalid file received');

  static MISSING_DATA = new AlertType('Missing Data', 'Missing Data: no file received');

  static MISSING_RESOURCE = new AlertType('Missing Resource', 'Missing Resource: no data received');

  static NEW_EQUIPMENT = new AlertType('New Billable Resource', 'New Billable Resource');

  static UNCONFIGURED_SERVICE = new AlertType('Unconfigured Resource', 'Unconfigured Resource');

  static UNCONFIGURED_RATES = new AlertType('Unconfigured Rates', 'Unconfigured Rates');

  static PSA_UPDATE_FAILURE = new AlertType('PSA Update Failure', 'PSA Update Failure');

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label, description) {
    super();
    this.label = label;
    this.description = description;
  }
}
