// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import {
  Form, FormField, TextInput,
} from 'grommet';
import PropTypes from 'prop-types';
import IDUtil from '../../shared/util/IDUtil';

const WiseQuoteCreateEditForm = ({
  errors = {},
  onChange,
  quoteId = '',
  scsCodeLicense = '',
  scsCodeSupport = '',
  scsCodeService = '',
  wbsCodeLicense = '',
  wbsCodeSupport = '',
  wbsCodeService = '',
}) => {
  const handleChange = (event) => {
    let name;
    let value;
    if (event.target) {
      // For text input changes
      name = event.target.name;
      value = event.target.value;
    } else {
      // For direct field name and value changes
      name = event.name;
      value = event.value;
    }
    // Call onChange function from parent with updated field name and value
    onChange({ name, value });
  };
  return (
    <Form>
      <FormField
        label='WISE Quote ID'
        htmlFor='hpeFCWiseQuoteIdNameInput'
        error={errors?.quoteId}
        required={true}
      >
        <TextInput
          id={IDUtil.getId('WiseQuoteIdNameInput')}
          data-testid={IDUtil.getId('WiseQuoteIdNameInput')}
          name='quoteId'
          value={quoteId}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        label='SCS Code-License'
        htmlFor='hpeFCscsCodeLicenseInput'
        error={errors?.scsCodeLicense || undefined}
      >
        <TextInput
          id={IDUtil.getId('scsCodeLicenseInput')}
          data-testid={IDUtil.getId('scsCodeLicenseInput')}
          name='scsCodeLicense'
          value={scsCodeLicense}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        label='SCS Code-Support'
        htmlFor='hpeFCscsCodeSupportInput'
        error={errors?.scsCodeSupport || undefined}
      >
        <TextInput
          id={IDUtil.getId('scsCodeSupportInput')}
          data-testid={IDUtil.getId('scsCodeSupportInput')}
          name='scsCodeSupport'
          value={scsCodeSupport}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        label='SCS Code-Service'
        htmlFor='hpeFCscsCodeServiceInput'
        error={errors?.scsCodeService || undefined}
      >
        <TextInput
          id={IDUtil.getId('scsCodeServiceInput')}
          data-testid={IDUtil.getId('scsCodeServiceInput')}
          name='scsCodeService'
          value={scsCodeService}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        label='WBS Code-License'
        htmlFor='hpeFCwbsCodeLicenseInput'
        error={errors?.wbsCodeLicense || undefined}
      >
        <TextInput
          id={IDUtil.getId('wbsCodeLicenseInput')}
          data-testid={IDUtil.getId('wbsCodeLicenseInput')}
          name='wbsCodeLicense'
          value={wbsCodeLicense}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        label='WBS Code-Support'
        htmlFor='wbsCodeSupportInput'
        error={errors?.wbsCodeSupport || undefined}
      >
        <TextInput
          id={IDUtil.getId('wbsCodeSupportInput')}
          data-testid={IDUtil.getId('wbsCodeSupportInput')}
          name='wbsCodeSupport'
          value={wbsCodeSupport}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        label='WBS Code-Service'
        htmlFor='wbsCodeServiceInput'
        error={errors?.wbsCodeService || undefined}
      >
        <TextInput
          id={IDUtil.getId('wbsCodeServiceInput')}
          data-testid={IDUtil.getId('wbsCodeServiceInput')}
          name='wbsCodeService'
          value={wbsCodeService}
          onChange={handleChange}
        />
      </FormField>
    </Form>
  );
};
WiseQuoteCreateEditForm.contextTypes = {
  router: PropTypes.object,
};

WiseQuoteCreateEditForm.propTypes = {
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  quoteId: PropTypes.string,
  scsCodeLicense: PropTypes.string,
  scsCodeSupport: PropTypes.string,
  scsCodeService: PropTypes.string,
  wbsCodeLicense: PropTypes.string,
  wbsCodeSupport: PropTypes.string,
  wbsCodeService: PropTypes.string,
};

export default WiseQuoteCreateEditForm;
