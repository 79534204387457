// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PurposeType } from '../shared/constants/PurposeType';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import { insertIf } from '../shared/util/BasicUtil';

const PartnerConnectionInfoPanel = ({ data = {}, title = undefined, hideGLCPurpose = false }) => {
  const payload = useMemo(() => [
    { label: 'GLBM Partner', value: data && data.partnerName ? `${data.partnerName} (${data.partnerId})` : '-' },
    { label: 'GLBM Partner ID', value: data && data.partnerExternalId ? data.partnerExternalId : '-' },
    { label: 'CA Tenant', value: data && data.caTenantName ? `${data.caTenantName} (${data.caTenantId})` : '-' },
    { label: 'CA Tenant Company', value: data && data.caTenantCompany ? data.caTenantCompany : '-' },
    { label: 'GLC Tenant', value: data && data.glcTenantName ? `${data.glcTenantName} (${data.glcTenantId})` : '-' },
    // eslint-disable-next-line no-nested-ternary
    ...insertIf(!hideGLCPurpose, [{ label: 'GLC Purpose', value: data && data.glcPurpose ? (PurposeType.enumValueOf(data.glcPurpose) ? PurposeType.enumValueOf(data.glcPurpose).label : data.glcPurpose) : '-' }]),
    { label: 'GLP Workspace ID', value: data?.glpWorkspaceId || '-' },
  ], [data, hideGLCPurpose]);

  return (
    <GLBMNameValueList
      title={title}
      data={payload}
    />
  );
};

PartnerConnectionInfoPanel.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  hideGLCPurpose: PropTypes.bool,
};

export default PartnerConnectionInfoPanel;
