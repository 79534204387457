// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Footer, Form, FormField, TextInput
} from 'grommet';
import toNumber from 'lodash/toNumber';
import find from 'lodash/find';
import moment from 'moment/moment';
import GLBMLayer from '../../../../shared/component/GLBMLayer';
import DateTime from '../../../../shared/component/DateTime';

const LocationRateEditor = ({
  title, initialValues, newRev, onClose, otherRates, onSubmit
}) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [validateOn, setValidateOn] = useState('submit');

  return (
    <GLBMLayer
      position='right'
      closer={true}
      onClose={onClose}
      onClickOutside={onClose}
      onEsc={onClose}
      flush={true}
      full='vertical'
      title={title}
    >
      <Box fill='vertical'>
        <Form
          value={formValues}
          onChange={(nextValues) => {
            setFormValues({
              ...nextValues,
              commitmentAmount: nextValues.commitmentAmount === '' ? '' : toNumber(nextValues.commitmentAmount),
              discountPct: nextValues.discountPct === '' ? '' : toNumber(nextValues.discountPct),
            });
          }}
          onSubmit={({ value }) => {
            onSubmit({
              ...value,
              effectiveDate: value?.effectiveDate && moment(value.effectiveDate).format('YYYY-MM-DD'),
            }, newRev);
          }}
          onValidate={() => {
            setValidateOn('change');
          }}
          validate={validateOn}
          style={{ height: '100%' }}
          messages={{ required: 'Required' }}
        >
          <Box fill={true}>
            <Box flex={true} pad={{ horizontal: 'small' }}>
              <FormField
                label='Start Date'
                name='effectiveDate'
                htmlFor='location-rate-start-date'
                id='location-rate-start-date'
                component={DateTime}
                format='YYYY-MM'
                required={true}
                wrapValue={x => ({ target: { value: x } })}
                validate={(val) => {
                  if (!moment(val, 'YYYY-MM', true).isValid()) {
                    return 'Enter valid date. Use format: YYYY-MM';
                  }
                  const found = find(otherRates, ['effectiveDate', val]);
                  if (found) {
                    return 'Duplicate date.';
                  }
                  return undefined;
                }}
              />
              <FormField
                label='Monthly Usage Reserve'
                name='commitmentAmount'
                htmlFor='location-rate-commitmentAmount'
                validate={(val) => {
                  if (val < 0) {
                    return 'Enter value greater than or equal to 0';
                  }
                  return undefined;
                }}
                component={TextInput}
                type='number'
                required={true}
                id='location-rate-commitmentAmount'
              />
              <FormField
                label='Monthly Savings Percent'
                name='discountPct'
                htmlFor='location-rate-discountPct'
                validate={(val) => {
                  if (val < 0 || val > 100) {
                    return 'Enter value between 0 and 100';
                  }
                  return undefined;
                }}
                component={TextInput}
                type='number'
                required={true}
                id='location-rate-discountPct'
              />
            </Box>
            <Box border='top' pad='small' flex={false}>
              <Footer flex={false} justify='start' gap='small'>
                <Button
                  label='Submit'
                  type='submit'
                  primary={true}
                />
                <Button
                  label='Cancel'
                  onClick={onClose}
                />
              </Footer>
            </Box>
          </Box>
        </Form>
      </Box>
    </GLBMLayer>
  );
};

LocationRateEditor.propTypes = {
  title: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  newRev: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  otherRates: PropTypes.array.isRequired,
};

export default LocationRateEditor;
