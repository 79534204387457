// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { Enumify } from 'enumify';

export default class PartnerRelationshipView extends Enumify {
  static READ_ONLY = new PartnerRelationshipView();

  static PROTECTED = new PartnerRelationshipView();

  static EDIT = new PartnerRelationshipView();

  static HIDDEN = new PartnerRelationshipView();

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()
}
