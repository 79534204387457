// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import Case from 'case';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

function PartnerBadge({ tenant }) {
  return (
    <Box
      flex={false}
      background='dark-2'
      pad={{ horizontal: 'small' }}
      margin={{ horizontal: 'xsmall' }}
      round='small'
      justify='center'
      height='xxsmall'
    >
      <Heading level='2' margin='none'>{Case.capital(tenant)}</Heading>
    </Box>
  );
}

PartnerBadge.propTypes = {
  tenant: PropTypes.string.isRequired,
};

export default PartnerBadge;
