// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useCallback, useMemo, useState } from 'react';
import {
  Box, Button, Footer, Form, FormField, Notification, Paragraph,
} from 'grommet';
import { FormAdd, Trash } from 'grommet-icons';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { isAxiosError } from 'axios';
import GLBMLayer from '../shared/component/GLBMLayer';
import IDUtil from '../shared/util/IDUtil';
import { useCapacityAlertEmailValidation } from '../../core';
import type { AlertRecipient } from '../../core/types';

type Recipient = AlertRecipient;
interface Props {
  recipient: Recipient
  onChange: (r: Recipient, addAnother: boolean) => void
  onClose: () => void
  onRemove?: () => void
  title: string
}

const RecipientEditor = ({
  recipient: initialRecipient,
  onChange,
  onRemove = undefined,
  onClose,
  title,
}: Props) => {
  const [recipient, setRecipient] = useState({ ...initialRecipient });
  const [errors, setErrors] = useState<{ [key in keyof Recipient]?: string }>({});
  const [formError, setFormError] = useState<string>();
  const [buttonBusy, setButtonBusy] = useState(false);
  const [buttonSuccess, setButtonSuccess] = useState(false);

  const {
    mutate: validateEmail
  } = useCapacityAlertEmailValidation();

  const onSubmit = useCallback((event) => {
    const addAnother = event.nativeEvent.submitter?.id !== 'submit';
    setButtonBusy(true);

    // call server to validate email:
    validateEmail(recipient.email, {
      onSuccess: () => {
        onChange(recipient, addAnother);
        if (addAnother) {
          setRecipient({ firstName: '', lastName: '', email: '' });
          setButtonBusy(false);
          setButtonSuccess(true);
          setTimeout(() => {
            setButtonSuccess(false);
          }, 3000);
        }
      },
      onError: (_error) => {
        setButtonBusy(false);
        if (isAxiosError(_error)) {
          const newErrors = map(filter(_error.response.data.validationErrors, ({ propertyName }) => propertyName.endsWith('.email')), 'message');
          if (newErrors.length) {
            setErrors(prevErrors => ({
              ...prevErrors,
              email: newErrors.join('; '),
            }));
          }
        } else {
          setFormError(_error.message);
        }
      }
    });
  }, [onChange, recipient, validateEmail]);

  const secondaryControl = useMemo(() => {
    if (onRemove) {
      return (
        <Button
          plain={true}
          icon={<Trash />}
          label='Remove'
          onClick={onRemove}
          id={IDUtil.getId('EditorLayerToolbarRemoveButton')}
        />
      );
    }
    if (!buttonBusy) {
      return (
        <Button
          icon={<FormAdd />}
          label='Add Another'
          type='submit'
          id={IDUtil.getId('EditorLayerToolbarAddAnotherButton')}
        />
      );
    }
    return null;
  }, [buttonBusy, onRemove]);

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      title={title}
    >
      <Box flex={true}>
        <Form
          style={{ height: '100%' }}
          value={recipient}
          onChange={setRecipient}
          errors={errors}
          onSubmit={onSubmit}
        >
          <Box fill='vertical' flex={false}>
            <Box flex={true} overflow='auto' pad={{ horizontal: 'small' }}>
              <Box flex={false}>
                <Paragraph>
                  Edit Capacity Alert Recipient Settings which will be used when sending capacity alerts for this Billing Account.
                </Paragraph>
                <FormField
                  name='firstName'
                  id='firstName'
                  htmlFor='firstName'
                  label='First Name'
                  required={true}
                />
                <FormField
                  name='lastName'
                  id='lastName'
                  htmlFor='lastName'
                  label='Last Name'
                  required={true}
                />
                <FormField
                  name='email'
                  id='email'
                  htmlFor='email'
                  label='Email'
                  required={true}
                  error={errors?.email}
                />
              </Box>
            </Box>
            <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
              {formError && (
                <Notification status='critical' message={formError} />
              )}
              <Footer flex={false} justify='between' gap='small'>
                <Box direction='row' gap='small'>
                  <Button
                    primary={true}
                    type='submit'
                    id='submit'
                    busy={buttonBusy}
                    success={buttonSuccess}
                    label='OK'
                  />
                  <Button secondary={true} onClick={onClose} label='Cancel' />
                </Box>
                {secondaryControl}
              </Footer>
            </Box>
          </Box>
        </Form>
      </Box>
    </GLBMLayer>
  );
};

export default RecipientEditor;
