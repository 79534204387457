// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  Box, Button, Notification, Spinner, Text,
} from 'grommet';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useMemo, useState } from 'react';
import GLBMLayer from '../../shared/component/GLBMLayer';
import { runAction } from '../../../core';
import DynamicAction from './DynamicAction';

const buildHandler = (action) => {
  if (action.type === 'RUN' || (action.type === 'DYNAMIC' && !action.inputs)) {
    return {
      label: 'Run',
      component: null,
    };
  }
  return {
    label: 'Configure',
    component: props => <DynamicAction {...props} />,
  };
};

const ActionRow = ({ action }) => {
  const [notification, setNotification] = useState(null);
  const [showExecParams, setShowExecParams] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const handler = useMemo(() => buildHandler(action), [action]);

  const executeActionHandler = async (handlerFunction) => {
    setShowSpinner(true);
    try {
      await handlerFunction();
      setNotification({
        status: 'normal',
        message: 'Success!',
      });
      setShowExecParams(false);
    } catch (error) {
      console.error(error);
      const { message } = error.response?.data ? error.response.data : error;
      setNotification({
        status: 'critical',
        message,
      });
    } finally {
      setShowSpinner(false);
    }
  };

  const onActionTrigger = async () => {
    if (handler.component) {
      setShowExecParams(true);
    } else {
      setShowExecParams(false);
      await executeActionHandler(() => runAction(action.method, action.path));
    }
  };

  const hideExecParams = () => {
    setShowExecParams(false);
  };

  const renderExecParams = () => {
    if (showExecParams) {
      return (
        <GLBMLayer
          a11yTitle={`Execute administrative action: ${action.name}`}
          data-testid='exec-params-layer'
          onEsc={hideExecParams}
          onClickOutside={hideExecParams}
          onClose={hideExecParams}
          position='right'
          full='vertical'
          title={action.name}
        >
          <handler.component action={action} onExecute={executeActionHandler} onClose={hideExecParams} />
        </GLBMLayer>
      );
    }
    return null;
  };

  const renderSpinner = () => (showSpinner ? <Spinner data-testid='spinner' margin={{ right: 'xsmall' }} /> : null);

  const clearNotification = () => setNotification(null);

  const renderNotification = () => {
    if (notification) {
      return (
        <Notification toast={true} status={notification.status} message={notification.message} onClose={clearNotification} />
      );
    }
    return null;
  };

  return (
    <Box
      data-e2e='action-item'
      justify='between'
      flex={false}
      direction='row'
      border='top'
      pad='small'
      align='center'
    >
      <Box align='start' flex={true}>
        <Text data-e2e='main-text' weight='bold'>{action.name}</Text>
        <Text data-e2e='sub-text'>{action.description}</Text>
      </Box>
      <Box direction='row' align='center'>
        {renderSpinner()}
        <Button
          label={handler.label}
          onClick={onActionTrigger}
          secondary={true}
        />
      </Box>
      {renderExecParams()}
      {renderNotification()}
    </Box>
  );
};

ActionRow.propTypes = {
  action: PropTypes.object.isRequired,
};

export default ActionRow;
