// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useCallback } from 'react';

export const useFormFieldProps = () => (
  useCallback((name, label, help = undefined) => ({
    'label': `${label}`,
    'name': name,
    'id': `glbm.edit.parameter-${name}`,
    'htmlFor': `glbm.edit.parameter-${name}`,
    help,
    'data-testid': `glbm.form.inputs.edit.parameter.input-${name}`,
    'key': `glbm.form.inputs.edit.parameter.key-${name}`,
  }), []));
export const useInputPropertiesAsKeyValuePairs = (definitions, values) => definitions?.reduce((accu, def) => {
  accu.push({
    label: def.display,
    value: values?.[def.field] || def.defaultValue
  });
  return accu;
}, []);
