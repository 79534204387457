// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import {
  Box, Button, Text,
} from 'grommet';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import GLBMLayer from '../shared/component/GLBMLayer';
import AuthActions from '../actions/AuthActions';

function SessionModal(props) {
  const [countdown, setCountDown] = useState(60);
  const [isShowed, setIsShowed] = useState(false);

  const { oktaAuth } = useOktaAuth();

  const signOut = async () => {
    const idToken = await oktaAuth.tokenManager.get('idToken');
    const accessToken = await oktaAuth.tokenManager.get('accessToken');
    await AuthActions.logout();
    await oktaAuth.signOut({ idToken, accessToken });
  };

  useEffect(() => {
    setIsShowed(props.show);
  }, [props.show]);

  useEffect(() => {
    if (isShowed) {
      if (countdown > 0) {
        setTimeout(() => setCountDown(countdown - 1), 1000);
      } else {
        props.timeoutLogout();
        signOut();
      }
    } else {
      setCountDown(60);
    }
  }, [countdown, isShowed]);

  const onContinueClick = () => {
    props.continue();
  };

  const onLogoutClick = () => {
    props.logout();
    signOut();
  };

  return isShowed ? (
    <GLBMLayer>
      <Box
        flex={true}
        margin={{
          top: 'medium', bottom: 'small', right: 'medium', left: 'medium',
        }}
        pad='small'
      >
        <Box margin={{ bottom: 'small' }}>
          <h3 style={{ margin: '0px' }}>
            Session Timeout
          </h3>
        </Box>
        <Box margin={{ top: 'none', bottom: 'medium' }}>
          <Text>Your session is about to expire.</Text>
          <Text>{`You will be logged out in ${countdown} seconds.`}</Text>
        </Box>
        <Box direction='row' flex={true} justify='between' style={{ width: '300px' }}>
          <Button label='Continue' primary={true} onClick={() => onContinueClick()} />
          <Button label='Logout' secondary={true} onClick={() => onLogoutClick()} />
        </Box>
      </Box>
    </GLBMLayer>
  ) : null;
}

SessionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  continue: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  timeoutLogout: PropTypes.func.isRequired,
};

export default SessionModal;
