// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { Search } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CheckBox,
  Footer, Table, TableBody, TableCell,
  TableHeader,
  TableRow,
  TextInput,
} from 'grommet';
import IDUtil from '../util/IDUtil';
import { ListPlaceholder } from './ListPlaceholder';
import { renderColumnTableCell } from '../util/tableUtils';
import GLBMLayer from './GLBMLayer';

function ServiceListEditor(props) {
  const [items, setItems] = useState([]);
  const [sortIndex, setSortIndex] = useState(1);
  const [sortProperties] = useState(['selected', 'label']);
  const [searchText, setSearchText] = useState('');
  const [sortAscending, setSortAscending] = useState(false);

  const _sortList = (itemsForSort) => {
    const index = sortIndex;
    const ascending = sortAscending;

    if (index >= sortProperties.length) {
      return itemsForSort;
    }

    const property = sortProperties[index];

    let sortedItems;
    if (property === 'selected') {
      sortedItems = itemsForSort.sort((a, b) => ((a.selected === b.selected) ? 0 : (a.selected) ? -1 : 1));
    } else {
      sortedItems = itemsForSort.sort((a, b) => {
        let diff;
        if (a[property] === undefined && b[property] === undefined) {
          diff = 0;
        } else if (a[property] === undefined && b[property] !== undefined) {
          diff = -1;
        } else if (a[property] !== undefined && b[property] === undefined) {
          diff = 1;
        } else {
          diff = (a[property] === b[property]) ? 0 : (a[property].toLowerCase() > b[property].toLowerCase()) ? -1 : 1;
        }
        return diff;
      });
    }
    if (!ascending) {
      sortedItems.reverse();
    }

    return sortedItems;
  };

  useEffect(() => {
    if (props.items && props.selected) {
      setItems(props.items
        .map(el => ({ ...el, selected: props.selected.includes(el.type) })));
    }
  }, [props.items, props.selected]);

  const _onSubmit = () => {
    if (items && items.length > 0) {
      props.onChange(items.filter(item => item.selected).map(el => el.type));
    }
    props.onClose();
  };

  const _filter = (itemsForFilter) => {
    const results = [];
    if (!searchText) {
      return itemsForFilter;
    }

    if (Array.isArray(itemsForFilter)) {
      const properties = ['label'];
      for (let i = 0; i < itemsForFilter.length; i += 1) {
        for (let k = 0; k < properties.length; k += 1) {
          if (itemsForFilter[i].hasOwnProperty(properties[k]) && itemsForFilter[i][properties[k]] !== undefined) {
            if (itemsForFilter[i][properties[k]].toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
              results.push(itemsForFilter[i]);
              break;
            }
          }
        }
      }
    }
    return results;
  };

  function _noRowsElement(filteredAdmins) {
    if (filteredAdmins === 0) {
      return (
        <ListPlaceholder
          emptyMessage='Your filter returned zero Services, adjust to continue.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }
    return '';
  }

  const _onSelected = (type, e) => {
    const newItems = [...items];
    newItems.find(el => el.type === type).selected = e.target.checked;
    setItems(newItems);
  };

  const _onSearchChange = (event) => {
    const search = event.target.value.toLowerCase();
    setSearchText(search);
  };

  const _onSortList = (index, ascending) => {
    setSortIndex(index);
    setSortAscending(ascending);
  };

  const filteredItems = _filter(_sortList(items));

  // if no rows:
  const noRows = _noRowsElement(filteredItems.length);

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      onClose={props.onClose}
      onEsc={props.onClose}
      onClickOutside={props.onClose}
      closer={true}
      flush={true}
      title='Assign Services'
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
        style={{ 'maxWidth': '600px', 'width': '600px' }}
      >
        <Box flex={true}>
          <Box direction='row' pad='small' flex={false}>
            <TextInput
              placeholder='Search'
              icon={<Search />}
              onChange={_onSearchChange}
            />
          </Box>
          <Box flex={true} overflow='auto' pad='small'>
            <Table responsive={false} scrollable={true}>
              <TableHeader>
                <TableRow>
                  {renderColumnTableCell(['Select', 'Service Name'])}
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredItems.map((item, index) => (
                  <TableRow key={item.type}>
                    <TableCell>
                      <CheckBox
                        checked={item.selected}
                        id={IDUtil.getId('ServiceListSelectCheckbox', index)}
                        onChange={e => _onSelected(item.type, e)}
                      />
                    </TableCell>
                    <TableCell>{item.label}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {noRows}
          </Box>

          <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
            <Footer flex={false} justify='start' gap='small'>
              <Button
                label='Assign Selected'
                id={IDUtil.getId('ServiceEditorAssignSelectedButton')}
                primary={true}
                onClick={_onSubmit}
              />
              <Button label='Cancel' type='button' secondary={true} onClick={props.onClose} />
            </Footer>
          </Box>
        </Box>
      </Box>
    </GLBMLayer>
  );
}

ServiceListEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
};

export default ServiceListEditor;
