// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import {
  Box,
  Button,
  Footer,
  FormField,
  Paragraph,
  RadioButton,
} from 'grommet';
import PropTypes from 'prop-types';
import { StatusIcon } from '../../../../shared/component/StatusIcon';
import GLBMLayer from '../../../../shared/component/GLBMLayer';
import { ReportBy } from '../../../model';

class ReportByEditor extends Component {
  constructor(props) {
    super(props);

    this._onSubmit = this._onSubmit.bind(this);
    this._onChange = this._onChange.bind(this);
    this._getExplanation = this._getExplanation.bind(this);

    this.state = {
      heading: this.props.heading,
      reportBy: this.props.reportBy,
    };
  }

  _onSubmit() {
    const service = {
      reportBy: this.state.reportBy,
    };
    this.props.onChange(service);
  }

  _onChange(event) {
    let value;
    if (event) {
      switch (event.target.type) {
        case 'radio':
          value = event.target.name.split('-')[1];
          break;
        default:
          value = event.target.value;
          break;
      }
    }
    this.setState({
      reportBy: value,
    });
  }

  _getExplanation() {
    return ReportBy.enumValueOf(this.state.reportBy).description;
  }

  _renderReportByRadioButtons(reportBy) {
    return this.props.reportByOptions.map(reportByEnum => (
      <RadioButton
        key={reportByEnum.enumKey}
        id={`reportBy-${reportByEnum.enumKey}`}
        name={`reportBy-${reportByEnum.enumKey}`}
        label={reportByEnum.label}
        checked={reportBy === reportByEnum.enumKey}
        onChange={this._onChange}
      />
    ));
  }

  _renderReportBy(reportBy) {
    const editableField = (
      <FormField label='Report By'>
        {this._renderReportByRadioButtons(reportBy)}
      </FormField>
    );
    return editableField;
  }

  render() {
    let closeControl;
    if (this.props.onClose) {
      closeControl = (
        <Button
          label='Cancel'
          onClick={() => this.props.onClose()}
          secondary={true}
        />
      );
    }

    return (
      <GLBMLayer
        position='right'
        onClose={this.props.onClose}
        onEsc={this.props.onClose}
        onClickOutside={this.props.onClose}
        closer={true}
        flush={true}
        overlayClose={true}
        full='vertical'
        title={this.props.heading}
      >
        <Box
          pad='none'
          direction='column'
          fill='vertical'
          flex={true}
          style={{ 'maxWidth': '575px', 'width': '575px' }}
        >
          <Box direction='row' align='center' pad='medium' gap='small'>
            <StatusIcon value='warning' size='medium' />
            <Paragraph>
              Changing this setting for an individual service is very uncommon. It will likely invalidate existing rates and equipment mappings.
            </Paragraph>
          </Box>
          <Box flex={true} pad='medium'>
            {this._renderReportBy(this.state.reportBy)}
            <Box margin={{ top: 'small', bottom: 'small' }}>
              <Paragraph margin='none'>{this._getExplanation()}</Paragraph>
            </Box>
          </Box>

          <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
            <Footer flex={false} justify='start' gap='small'>
              <Button primary={true} onClick={() => this._onSubmit()} label='Apply' />
              {closeControl}
            </Footer>
          </Box>
        </Box>
      </GLBMLayer>
    );
  }
}

ReportByEditor.propTypes = {
  heading: PropTypes.string.isRequired,
  reportBy: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReportByEditor;
