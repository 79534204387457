// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { Enumify } from 'enumify';

export class ValidationIssue extends Enumify {
  static VALID = new ValidationIssue(
    '',
    'ok',
  );

  static OPTIONS_SINGLE_TIER_REQUIRED = new ValidationIssue(
    'Single Tier Name is required',
    'critical',
  );

  static OPTIONS_SINGLE_TIER_INVALID = new ValidationIssue(
    'Single Tier Name is invalid',
    'critical',
  );

  static OPTIONS_MAPPED_TIERS_REQUIRED = new ValidationIssue(
    'At least one Mapped Tier is required',
    'critical',
  );

  static OPTIONS_MAPPED_TIERS_UNACCOUNTED = new ValidationIssue(
    'Not all resources are accounted for in the mapped tiers',
    'warning',
  );

  static OPTIONS_CUSTOM_TIERS_REQUIRED = new ValidationIssue(
    'At least one Custom Tier is required',
    'critical',
  );

  static OPTIONS_CUSTOM_TIERS_UNACCOUNTED = new ValidationIssue(
    'Not all resources are accounted for in the custom tiers',
    'warning',
  );

  static OPTIONS_LOCATION_REQUIRED = new ValidationIssue(
    'At least one Location is required',
    'critical',
  );

  static OPTIONS_AZURE_TENANT_REQUIRED = new ValidationIssue(
    'Azure Tenant is required',
    'critical',
  );

  static OPTIONS_GOOGLE_ANTHOS_PROJECT_EXPORT_REQUIRED = new ValidationIssue(
    'At least one Project Name required',
    'critical',
  );

  static OPTIONS_AZURE_TENANT_REQUIRED_ALL = new ValidationIssue(
    'Azure Customer is required, or select All',
    'critical',
  );

  static OPTIONS_AZURE_DISCOUNT_TIERS = new ValidationIssue(
    'Adjustment Percentages are required',
    'critical',
  );

  static OPTIONS_SWLIC_LICENSE_TYPE_REQUIRED = new ValidationIssue(
    'Select at least one license type',
    'critical',
  );

  static RESOURCES_MISSING_LOCATION_TIER = new ValidationIssue(
    'Resource is missing location and tier',
    'warning',
  );

  static RESOURCES_MISSING_LOCATION = new ValidationIssue(
    'Resource is missing location',
    'warning',
  );

  static RESOURCES_MISSING_TIER = new ValidationIssue(
    'Resource is missing tier',
    'warning',
  );

  static RATES_MISSING_METERS = new ValidationIssue(
    'Unable to load Meters',
    'warning',
  );

  static RATES_MISSING_TIER_TYPE = new ValidationIssue(
    'Meter is missing Pricing Model',
    'critical',
  );

  static RATES_MISSING_RATE = new ValidationIssue(
    'Meter is missing rates',
    'warning',
  );

  static RATES_MISSING_PRICING_METHOD = new ValidationIssue(
    'Meter is missing pricing method and markup or direct rate',
    'warning',
  );

  static LOCATION_MISSING_COMMITMENTS = new ValidationIssue(
    'At least one or more Location is missing Contractual Revision Values',
    'critical',
  );

  static getRateRevisionError = number => new ValidationIssue(
    `${number} rate revision${number > 1 ? 's' : ''} have a pricing band that has a start range that exceeds its corresponding Reserved Capacity`,
    'critical',
  );

  static _ = this.closeEnum(); // TypeScript: Color.closeEnum()

  constructor(label, severity) {
    super();
    this.label = label;
    this.severity = severity;
  }
}
