// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Anchor,
  Box,
  Button, ColumnConfig, DataTable,
  Header, Heading,
  Text,
} from 'grommet';
import { Add, FormEdit } from 'grommet-icons';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import IDUtil from '../shared/util/IDUtil';
import RecipientEditor from './RecipientEditor';
import { ListPlaceholder } from '../shared/component/ListPlaceholder';
import type { AlertRecipient } from '../../core/types';

type Recipient = AlertRecipient;
type Recipients = Recipient[];

interface Props {
  canEdit: boolean
  onChange: (recipients: Recipients) => void
  recipients?: Recipients
}

const RecipientList = ({
  recipients: initialRecipients = [],
  canEdit,
  onChange
}: Props) => {
  const [layer, setLayer] = useState<string>();
  const [recipients, setRecipients] = useState(initialRecipients);
  const recipientsWithKey = useMemo(() => map(recipients, r => ({ ...r, id: `${r.firstName} ${r.lastName} ${r.email}` })), [recipients]);
  const [selectedRecipientId, setSelectedRecipientId] = useState<string>();

  const onEditRecipientClicked = useCallback((recipient: typeof recipientsWithKey[number]) => {
    setLayer('editRecipient');
    setSelectedRecipientId(recipient.id);
  }, []);

  useEffect(() => {
    onChange(recipients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipients]);

  const onClose = useCallback(() => {
    setLayer(undefined);
  }, []);

  const onAddRecipientClicked = useCallback(() => {
    setLayer('addRecipient');
    setSelectedRecipientId(undefined);
  }, []);

  const onRecipientEditorApplied = useCallback((recipient: Recipient, addAnother: boolean) => {
    const modifiedRecipients = [...recipients];

    if (selectedRecipientId === undefined) {
      modifiedRecipients.push(recipient);
    } else {
      const foundIndex = findIndex(recipientsWithKey, ['id', selectedRecipientId]);
      modifiedRecipients[foundIndex] = recipient;
    }

    setSelectedRecipientId(undefined);
    if (addAnother) {
      setLayer('addRecipient');
    } else {
      setLayer(undefined);
    }
    setRecipients(modifiedRecipients);
  }, [recipients, recipientsWithKey, selectedRecipientId]);

  const onRecipientRemoved = useCallback(() => {
    const modifiedRecipients = [...recipients];
    const foundIndex = findIndex(recipientsWithKey, ['id', selectedRecipientId]);
    modifiedRecipients.splice(foundIndex, 1);
    setLayer(undefined);
    setSelectedRecipientId(undefined);
    setRecipients(modifiedRecipients);
  }, [recipients, recipientsWithKey, selectedRecipientId]);

  const layerNode = useMemo(() => {
    let result: React.ReactNode;
    if (layer) {
      if (layer === 'addRecipient') {
        const recipient = {
          firstName: '',
          lastName: '',
          email: '',
        };

        result = (
          <RecipientEditor
            title='Add Recipient'
            recipient={recipient}
            onClose={onClose}
            onChange={onRecipientEditorApplied}
            onRemove={undefined}
          />
        );
      } else if (layer === 'editRecipient') {
        result = (
          <RecipientEditor
            title='Edit Recipient'
            recipient={find(recipientsWithKey, ['id', selectedRecipientId])}
            onClose={onClose}
            onRemove={onRecipientRemoved}
            onChange={onRecipientEditorApplied}
          />
        );
      }
    }
    return result;
  }, [layer, onClose, onRecipientEditorApplied, onRecipientRemoved, recipientsWithKey, selectedRecipientId]);

  const columns = useMemo<ColumnConfig<typeof recipientsWithKey[number]>[]>(() => [
    { header: 'First name', property: 'firstName' },
    { header: 'Last name', property: 'lastName' },
    { header: 'Email', property: 'email' },
    ...(canEdit ? [
      {
        header: 'Action',
        property: 'index',
        render: (datum: typeof recipientsWithKey[number]) => (
          <Anchor
            icon={<FormEdit />}
            label='Edit'
            id={IDUtil.getId('RecipientListEditButton', datum.id)}
            onClick={() => onEditRecipientClicked(datum)}
          />
        )
      }
    ] : [])
  ], [canEdit, onEditRecipientClicked]);

  return (
    <Box flex={false}>
      <Header justify='between' align='center'>
        <Heading level='3'>External recipients</Heading>
        {canEdit ? (
          <Button
            icon={<Add />}
            label='Add recipient'
            id={IDUtil.getId('RecipientListToolbarAddButton')}
            onClick={() => onAddRecipientClicked()}
          />
        ) : ''}
      </Header>
      <Box margin={{ bottom: 'small' }}>
        {canEdit
          ? <Text margin='none'>Add customer addresses to receive alert emails. Assigned ASMs will also receive them</Text>
          : <Text margin='none'>Listed customer addresses receives alert emails. Assigned ASMs will also receive them</Text>}
      </Box>
      <DataTable
        data-testid='table-recipients'
        data={recipientsWithKey}
        columns={columns}
        primaryKey='id'
      />
      {recipients?.length === 0 && (
        <ListPlaceholder
          emptyMessage='You have not added any external capacity alert recipients to this Billing Account.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      )}
      {layerNode}
    </Box>
  );
};

export default RecipientList;
