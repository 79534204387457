// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { Select } from 'grommet';
import { getElementText } from '../util/BasicUtil';

/**
 * AutocompleteSelect has the same API as Select
 * This component was created to better support the auto-complete search. Currently there are no enhancements to for the
 * Grommet Select "inline" property for radio-buttons/check-boxes.
 *
 * The enhancements include:
 * 1. Facilitate the basic filtering of the options using the filter label element/text
 * 2. Auto-focus the input
 * 3. Add debounce function for search functionality to improve performance
 * 4. Improved filtering performance
 * 5. Sorting, with default alphabetical sort on the text content. {@link sortFn}
 */
export default class AutocompleteSelect extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    /* if no sort function defined then it will do a basic alphabetical sort based on the text content of the label */
    sortFn: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
    };
  }

  sortOptions = (options) => {
    const copiedOptions = [...options];
    return this.props.sortFn ? copiedOptions.sort(this.props.sortFn) : options;
  };

  componentDidMount() {
    /* When drop down list is shown auto-focus search input */
    if (!this.props.inline) {
      ReactDOM.findDOMNode(this.refs.select).onclick = () => {
        setTimeout(() => {
          const input = document.body.querySelector('.grommetux-input');
          if (input) {
            input.focus();
          }
        });
      };
    }
  }

  _onSearch = (option) => {
    this.setState({ searchText: option });
  };

  _filterOptions = (options = []) => (this.state.searchText ? options.filter(option => this._containsSearchText(option)) : options);

  _getLabelText(option) {
    const label = option[this?.props?.labelKey || 'label'];
    return React.isValidElement(label) ? getElementText(label).trim() : label?.toString().trim();
  }

  _containsSearchText = (option) => {
    const filter = this.state.searchText.toLowerCase();
    const labelText = this._getLabelText(option);

    return labelText.toLowerCase().includes(filter);
  };

  _onClose = () => {
    this._onSearch('');
  };

  render() {
    const { options, onSearch, ...otherAttributes } = this.props;
    const filteredOptions = this._filterOptions(options);
    const sortedOptions = this.sortOptions(filteredOptions);

    /* Supports custom onSearch function from props */
    const onSearchHandler = onSearch || this._onSearch;
    return (
      <Select
        ref='select'
        options={sortedOptions}
        onSearch={onSearchHandler}
        searchPlaceholder='Search'
        onClose={() => this._onClose()}
        {...otherAttributes}
      />
    );
  }
}
