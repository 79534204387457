// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import moment from 'moment/moment';
import React, { useMemo } from 'react';
import {
  Anchor,
  Box, Button, CheckBox, Text,
} from 'grommet';
import { Edit, Trash } from 'grommet-icons';
import { HighlightUsingContext } from '../shared/component/HighlightUsingContext';
import { pagePermissions } from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import { insertIf } from '../shared/util/BasicUtil';
import IDUtil from '../shared/util/IDUtil';

const EMPTY_CELL = '--';

export const useColumns = (editAccountKey, deleteAccountKey, activateAccountKey) => {
  const { hasPermissions } = usePermissionChecker();

  const canEdit = hasPermissions(pagePermissions.customers.view.encryptionKey.actions.edit);
  const canDelete = hasPermissions(pagePermissions.customers.view.encryptionKey.actions.delete);

  return useMemo(() => ([
    {
      property: 'active',
      header: 'Active',
      render: datum => (
        <CheckBox
          checked={datum.active}
          toggle={true}
          onChange={() => activateAccountKey(datum)}
        />
      ),
    },
    {
      property: 'name',
      header: 'Name',
      render: datum => (
        <Anchor onClick={() => editAccountKey(datum)}>
          <Text weight='bold'>
            <HighlightUsingContext>{datum.name || EMPTY_CELL}</HighlightUsingContext>
          </Text>
        </Anchor>
      ),
      primary: true,
    },
    {
      property: 'created',
      header: 'Generated On (UTC)',
      dataCallback: ({ created }) => moment.utc(created).format('x'),
      render: ({ created, index }) => <Text id={IDUtil.getId('StartTime', index)}>{created ? moment.utc(created).format('lll') : EMPTY_CELL}</Text>,
    },
    {
      property: 'createdBy',
      header: 'Generated By',
      render: ({ createdBy }) => <HighlightUsingContext>{createdBy || EMPTY_CELL}</HighlightUsingContext>,
    },
    {
      property: 'lastUsed',
      header: 'Last Used On (UTC)',
      dataCallback: ({ lastUsed }) => moment.utc(lastUsed).format('x'),
      render: ({ lastUsed, index }) => <Text id={IDUtil.getId('StartTime', index)}>{lastUsed ? moment.utc(lastUsed).format('lll') : EMPTY_CELL}</Text>,
    },
    {
      property: 'fips',
      header: 'Fips',
      render: ({ fips }) => (fips ? 'Yes' : 'No'),
    },
    ...insertIf(canEdit || canDelete, [{
      property: 'action',
      header: 'Actions',
      align: 'start',
      sortable: false,
      render: datum => (
        <Box direction='row' gap='xsmall'>
          {canEdit && (<Button icon={<Edit />} onClick={() => editAccountKey(datum)} />)}
          {canDelete && (<Button icon={<Trash />} onClick={() => deleteAccountKey(datum)} />)}
        </Box>
      )
    }]),
  ]), [canEdit, canDelete, editAccountKey, deleteAccountKey, activateAccountKey]);
};
