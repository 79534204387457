// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import {
  Box, FormField,
} from 'grommet';
import PropTypes from 'prop-types';

const GLBMFilterPropertyBox = ({
  label,
  required = false,
  contentProps = undefined,
  children
}) => (
  <Box tag='section' pad={{ horizontal: 'medium', vertical: 'small' }} flex={false}>
    <FormField
      label={label}
      contentProps={contentProps}
      required={required}
    >
      {children}
    </FormField>
  </Box>
);

GLBMFilterPropertyBox.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
  contentProps: PropTypes.object,
};

export default GLBMFilterPropertyBox;
