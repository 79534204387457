// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
export default {

  // software license: license types:
  'fc.service.license.type.PER_10_VM': 'Per 10 VM',
  'fc.service.license.type.PER_VM_SOCKETS': 'Per VM Socket',
  'fc.service.license.type.PER_FE_TB': 'Per FE TB',
  'fc.service.license.type.PER_OPERATING_INSTANCE': 'Per Operating Instance',
  'fc.service.license.type.PER_USER': 'Per User',
  'fc.service.license.type.PER_VOI': 'Per Virtual Operating Instance',

  // cohesity license: license types:
  'fc.service.license.type.DATAPLATFORM': 'DataPlatform',
  'fc.service.license.type.DATAPROTECT': 'DataProtect',
  'fc.service.license.type.CLOUDARCHIVE': 'CloudArchive',
  'fc.service.license.type.CLOUDSPIN': 'CloudSpin',
  'fc.service.license.type.CLOUDTIER': 'CloudTier',
};
