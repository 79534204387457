// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  CheckBox,
  Form,
  FormField,
  MaskedInput,
  Select,
  TextInput,
} from 'grommet';
import CurrencyUtils from 'i18n/CurrencyUtil';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { pagePermissions } from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import { getError, getSelectValue } from '../shared/util/FormUtil';
import AutocompleteSelect from '../shared/component/AutocompleteSelect';
import ReadOnlyField from '../shared/component/ReadOnlyField';
import IDUtil from '../shared/util/IDUtil';
import { useCountriesQuery } from '../../core';
import CompanySelector from '../companies/components/CompanySelector';
import DateTime from '../shared/component/DateTime';
import { PurposeType } from '../shared/constants/PurposeType';

const StyledFormField = styled(FormField)`
  div > button {
    border: none;
  }
`;

const BillingInfoForm = ({
  customer = undefined,
  errors: propsErrors = {},
  currencies = [],
  locales = [],
  readOnly = false,
  adding = false,
  canEditCurrency = false,
  onDOMChange = undefined,
  onChange = undefined
}) => {
  const { hasPermissions } = usePermissionChecker();
  const { data: dataCountries } = useCountriesQuery();
  const countriesOptions = dataCountries ? dataCountries.map(el => el.country3Code)
    .filter(el => !!el) : [];
  const getBillingCurrencyHelp = () => {
    const contractCurrency = customer.contractCurrency || '';
    const contractLocale = customer.locale || '';
    const example = CurrencyUtils.getCurrencyString(1234567.89, 2, contractLocale, contractCurrency);
    if (example) {
      return `Example: ${example}`;
    }
    return 'Example:';
  };
  const purposeOptions = useMemo(
    () => PurposeType.values()
      .map(purpose => ({
        label: purpose.label,
        value: purpose.enumKey,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    []
  );
  const isPurposeEditEnabled = hasPermissions(pagePermissions.customers.view.purpose.page_update);

  /**
   * Render Helpers
   */
  const renderBillingCurrency = (contractCurrency, errors) => {
    const selectValue = getSelectValue(contractCurrency, currencies) || { label: '' };
    const readOnlyField = (
      <ReadOnlyField
        label='Billing Currency'
        value={selectValue.label}
      />
    );
    const editableField = (
      <StyledFormField
        label='Billing Currency'
        help={getBillingCurrencyHelp()}
        error={getError(errors)}
      >
        <AutocompleteSelect
          options={currencies}
          name='contractCurrency'
          value={selectValue.value}
          id={IDUtil.getId('BillingInfoEditorCurrency')}
          onChange={(event) => {
            onDOMChange('contractCurrency', false, event.option.value);
          }}
          labelKey='label'
          valueKey={{
            key: 'value',
            reduce: true,
          }}
        />
      </StyledFormField>
    );

    return canEditCurrency && !readOnly ? editableField : readOnlyField;
  };

  const renderLocale = (locale, errors) => {
    const selectValue = getSelectValue(locale, locales) || { label: '' };
    const readOnlyField = (
      <ReadOnlyField
        label='Locale'
        value={selectValue.label}
      />
    );
    const editableField = (
      <StyledFormField
        label='Locale'
        error={getError(errors)}
      >
        <AutocompleteSelect
          options={locales}
          name='locale'
          id={IDUtil.getId('BillingInfoEditorLocale')}
          value={selectValue.value}
          onChange={(event) => {
            onDOMChange('locale', false, event.option.value);
          }}
          labelKey='label'
          valueKey={{
            key: 'value',
            reduce: true,
          }}
        />
      </StyledFormField>
    );

    return canEditCurrency && !readOnly ? editableField : readOnlyField;
  };

  const renderPromotionDate = promotionDate => (
    <FormField
      label='Consumption Billing Promotion Date'
      disabled={true}
    >
      {promotionDate
        ? (
          <TextInput
            value={moment(promotionDate)
              .format('L LTS')}
            disabled={true}
            id={IDUtil.getId('BillingInfoEditorPromotionDate')}
          />
        )
        : (
          <TextInput
            plain={true}
            value='N/A'
            disabled={true}
            id={IDUtil.getId('BillingInfoEditorPromotionDate')}
          />
        )}
    </FormField>
  );

  const renderStartMonth = (contractStartMonth, startMonthErrors) => {
    const readOnlyField = (
      <ReadOnlyField
        label='Start Month'
        value={contractStartMonth || ''}
      />
    );
    const editableField = (
      <FormField
        label='Start Month'
        error={getError(startMonthErrors)}
      >
        <DateTime
          id={IDUtil.getId('BillingInfoEditorContractStartDateInput')}
          name='contractStartMonth'
          format='YYYY-MM'
          onChange={(event) => {
            onChange(event);
          }}
          value={contractStartMonth || ''}
          calendarProps={{
            bounds: ['2013-01-01', '2116-01-01'],
          }}
        />
      </FormField>
    );

    return readOnly ? readOnlyField : editableField;
  };

  const renderEndMonth = contractEndMonth => (contractEndMonth ? (
    <ReadOnlyField
      label='End Month'
      value={contractEndMonth || ''}
    />
  ) : undefined);

  const renderCompanyName = (company, error) => {
    const readOnlyField = (
      <ReadOnlyField
        label='Billing Account Name'
        value={company || ''}
      />
    );
    const editableField = (
      <FormField
        label='Billing Account Name'
        error={getError(error)}
      >
        <TextInput
          id={IDUtil.getId('BillingInfoEditorCompanyNameInput')}
          name='name'
          value={company || ''}
          onChange={(event) => {
            onDOMChange('name', false, event.target.value);
          }}
        />
      </FormField>
    );

    return readOnly ? readOnlyField : editableField;
  };

  const getAccountIdHelp = () => 'Example: HP-region-subregion-country-customerID';

  const renderAccountId = (accountId, error) => {
    const editableField = (
      <FormField
        label='Billing ID'
        help={getAccountIdHelp()}
        error={error?.join(', ') || undefined}
      >
        <MaskedInput
          id={IDUtil.getId('BillingInfoEditorBillingId')}
          mask={[
            {
              length: 2,
              options: ['HP'],
              restrictToOptions: true,
              placeholder: 'HP',
            },
            { fixed: '-' },
            {
              length: [3, 4],
              options: ['AMS', 'APJ', 'EMEA', 'TEST'],
              restrictToOptions: true,
              placeholder: 'region',
            },
            { fixed: '-' },
            {
              length: [2, 5],
              regexp: /^[A-Z0-9]+$/,
              placeholder: 'subregion',
            },
            { fixed: '-' },
            {
              length: 3,
              options: countriesOptions,
              restrictToOptions: true,
              placeholder: 'country',
            },
            { fixed: '-' },
            {
              length: 5,
              regexp: /^[0-9]+$/,
              placeholder: 'customerID',
            },
          ]}
          name='id'
          value={accountId || ''}
          onChange={(event) => {
            onDOMChange('id', false, event.target.value, countriesOptions);
          }}
        />
      </FormField>
    );
    return adding ? editableField : undefined;
  };

  const renderSecureSites = (secureSite, errors) => {
    const readOnlyField = (
      <ReadOnlyField
        label='Secure Sites Customer'
        value={secureSite || false}
      />
    );
    const editableField = (
      <FormField
        label='Secure Site Billing Account'
        help='Check this box if billing account will be uploading usage manually.'
        error={getError(errors)}
      >
        <CheckBox
          name='secureSite'
          id={IDUtil.getId('BillingInfoEditorSecureSite')}
          checked={secureSite || false}
          onChange={(event) => {
            onDOMChange('secureSite', false, event.target.checked);
          }}
        />
      </FormField>
    );

    return (!readOnly ? editableField : readOnlyField);
  };

  const renderCompany = companyId => (
    <CompanySelector
      readOnly={readOnly}
      companyId={companyId}
      onCompanySelected={onDOMChange}
      initialSelection={companyId}
    />
  );

  const renderBillBy = billedBy => (
    <ReadOnlyField
      label='Billed By'
      value={billedBy || ''}
      id={IDUtil.getId('BillingInfoEditorBilledBy')}
    />
  );

  const renderPurpose = (purpose, errors) => {
    const readOnlyField = (
      <ReadOnlyField
        label='Purpose'
        value={purpose && PurposeType.enumValueOf(purpose) ? PurposeType.enumValueOf(purpose).label : purpose || ''}
        id={IDUtil.getId('BillingInfoEditorPurpose')}
      />
    );

    const editableField = (
      <FormField
        label='Purpose'
        required={true}
        error={getError(errors)}
      >
        <Select
          options={purposeOptions}
          name='purpose'
          value={purpose}
          id={IDUtil.getId('BillingInfoEditorPurpose')}
          onChange={(event) => {
            onDOMChange('purpose', false, event.option.value);
          }}
          labelKey='label'
          valueKey={{
            key: 'value',
            reduce: true,
          }}
        />
      </FormField>
    );

    return (!readOnly && isPurposeEditEnabled ? editableField : readOnlyField);
  };

  const {
    id: accountId,
    name: companyName,
    contractStartMonth,
    contractEndMonth,
    contractCurrency,
    locale,
    secureSite,
    promotedDateTime,
    billedBy,
    purpose,
    companyId,
  } = customer;

  const {
    id: accountIdErrors,
    name: nameErrors,
    contractStartMonth: startMonthErrors,
    contractCurrency: currencyErrors,
    locale: localeErrors,
    purpose: purposeErrors,
    secureSiteErrors,
  } = propsErrors;

  return (
    <Form>
      {renderAccountId(accountId, accountIdErrors)}
      {renderCompanyName(companyName, nameErrors)}
      {renderPromotionDate(promotedDateTime)}
      {renderStartMonth(contractStartMonth, startMonthErrors)}
      {renderEndMonth(contractEndMonth)}
      {renderBillingCurrency(contractCurrency, currencyErrors)}
      {renderLocale(locale, localeErrors)}
      {renderSecureSites(secureSite, secureSiteErrors)}
      {renderCompany(companyId)}
      {renderBillBy(billedBy)}
      {renderPurpose(purpose, purposeErrors)}
    </Form>
  );
};

BillingInfoForm.propTypes = {
  readOnly: PropTypes.bool,
  adding: PropTypes.bool,
  locales: PropTypes.array,
  errors: PropTypes.object,
  customer: PropTypes.any,
  currencies: PropTypes.array,
  canEditCurrency: PropTypes.bool,
  onDOMChange: PropTypes.func,
  onChange: PropTypes.func,
};

export default BillingInfoForm;
