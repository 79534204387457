// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Footer, Spinner,
  Text,
} from 'grommet';
import moment from 'moment/moment';
import { useAlertRetryPSAMutation } from '../../core';
import GLBMLayer from '../shared/component/GLBMLayer';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';

import { useToast } from '../shared/toast/ToastProvider';

const AlertsPSARetryDialog = ({ alert, onClose = null, onChange = null }) => {
  const { addToast } = useToast();

  const {
    isLoading,
    mutate: retryPSAAlert,
  } = useAlertRetryPSAMutation({
    onSuccess: () => {
      const showSuccess = () => {
        addToast({
          status: 'normal',
          title: 'PSA retry successful',
        });
      };
      onChange([alert.id]);
      showSuccess();
    },
    onError: (error) => {
      addToast({
        status: 'critical',
        title: 'PSA retry not successful',
        message: error.response.data.message,
      });
    },
  });

  const _onSubmit = () => {
    retryPSAAlert({ customerId: alert.customerId });
  };

  const renderConfirmationDetails = () => (
    <GLBMNameValueList
      title='Alert'
      data={[
        { label: 'Date', value: moment(alert.start).format('ll') },
        { label: 'Alert', value: alert.typeDescription },
        { label: 'Billing Account', value: alert.customerId },
        { label: 'Message', value: alert.message },
      ]}
    />
  );

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      overlayClose={true}
      flush={true}
      title='Retry PSA Update'
    >
      <Box
        pad='none'
        style={{ 'width': '600px', 'minWidth': '600px' }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box pad='medium' flex={true}>
          <Box direction='row' align='start' margin={{ vertical: 'medium' }} gap='small'>
            <Text weight={100} margin={{ top: 'none' }}>
              Set the Activation Date in PSA. Retry?
            </Text>
          </Box>
          <Box gap='medium'>
            {renderConfirmationDetails()}
          </Box>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button
            primary={true}
            onClick={_onSubmit}
            label='Retry PSA Update'
            disabled={isLoading}
          />
          <Button
            secondary={true}
            label='Cancel'
            onClick={onClose}
            disabled={isLoading}
          />
          {(isLoading)
            && (
              <Box direction='row' gap='xsmall' pad={{ left: 'small' }}>
                <Spinner />
                <Text>Please Wait ...</Text>
              </Box>
            )}
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

AlertsPSARetryDialog.propTypes = {
  alert: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

export default AlertsPSARetryDialog;
