// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import {
  Box, Button, Footer, Paragraph
} from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
import GLBMLayer from '../../shared/component/GLBMLayer';

const PartnerRelationshipsMustDisconnectDialog = ({
  onCancel,
  onSubmit,
}) => (
  <GLBMLayer
    position='center'
    closer={false}
    onEsc={onCancel}
    onClickOutside={onCancel}
    title='Cannot edit partner relationships'
  >
    <Box
      flex={true}
      direction='column'
      pad={{ horizontal: 'medium' }}
    >
      <Paragraph>
        The Partner Relationships for this billing account cannot be edited because
        the billing account has already been promoted to production and is connected to tenants.
        If the Partner Relationships need to be changed, you must first go to the Manage Connections
        page and disconnect this billing account, then return to the Billing Info page to change the Partner Relationships.
      </Paragraph>
    </Box>
    <Box pad='small' margin={{ top: 'none' }} flex={false}>
      <Footer flex={false} justify='start'>
        <Button
          label='Manage Connections'
          primary={true}
          onClick={onSubmit}
        />
        <Button
          label='Cancel'
          onClick={onCancel}
          secondary={true}
        />
      </Footer>
    </Box>
  </GLBMLayer>
);

PartnerRelationshipsMustDisconnectDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PartnerRelationshipsMustDisconnectDialog;
