// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { Box, CheckBoxGroup, DropButton } from 'grommet';
import { Columns } from 'grommet-icons';
import map from 'lodash/map';
import useRememberedState from '../hooks/useRememberedState';

const ColumnSelector = ({
  availableColumns,
  requiredColumns = [],
  selectedColumns,
  setSelectedColumns
}) => {
  const columnOptions = useMemo(() => availableColumns.map(c => ({
    header: c.header,
    property: c.property,
    disabled: requiredColumns.includes(c.property),
  })), [availableColumns, requiredColumns]);

  return (
    <DropButton
      kind='toolbar'
      // Weird alignment issue without Box when kind != toolbar
      icon={<Box><Columns /></Box>}
      badge={availableColumns.length !== selectedColumns.length}
      dropContent={(
        <CheckBoxGroup
          options={columnOptions}
          labelKey='header'
          valueKey='property'
          value={selectedColumns.map(v => v.property)}
          onChange={({ value }) => {
            setSelectedColumns(availableColumns.filter(col => value.includes(col.property) || requiredColumns.includes(col.property)));
          }}
        />
      )}
      dropAlign={{
        top: 'bottom',
        left: 'left'
      }}
      data-e2e={availableColumns.length !== selectedColumns.length ? 'column-selector-with-badge' : 'column-selector'}
      data-testid='column-selector-button'
    />
  );
};

ColumnSelector.propTypes = {
  availableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      property: PropTypes.string.isRequired,
    })
  ).isRequired,
  requiredColumns: PropTypes.arrayOf(PropTypes.string),
  selectedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      property: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSelectedColumns: PropTypes.func.isRequired,
};

export default ColumnSelector;

export const useColumnSelectorProps = (availableColumns, stateKey, defaultColumns, requiredColumnsProp) => {
  const [storedColumns, setStoredColumns] = useRememberedState(stateKey, defaultColumns || map(availableColumns, 'property'));
  const { current: requiredColumns } = useRef(requiredColumnsProp);
  const [selectedColumns, setSelectedColumns] = useState(() => (
    availableColumns.filter(col => storedColumns.includes(col.property) || requiredColumns.includes(col.property))
  ));
  useEffect(() => {
    setStoredColumns(map(selectedColumns, 'property'));
  }, [selectedColumns, setStoredColumns]);
  useEffect(() => {
    setSelectedColumns(
      availableColumns.filter(col => storedColumns.includes(col.property) || requiredColumns.includes(col.property))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableColumns]);
  return [selectedColumns, {
    availableColumns,
    requiredColumns,
    selectedColumns,
    setSelectedColumns,
  }];
};
